.w-5{width:10px;}
.h-5{height:10px;}
.dark #preloader {
	background-color: #0ab99d
}

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #f7f7f7;
	z-index: 999999
}

.preloader {
	width: 50px;
	height: 50px;
	display: inline-block;
	padding: 0;
	text-align: left;
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -25px;
	margin-top: -25px
}

.preloader span {
	position: absolute;
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	background: #0ab99d;
	-webkit-animation: preloader 1.3s linear infinite;
	animation: preloader 1.3s linear infinite
}

.preloader span:last-child {
	animation-delay: -.9s;
	-webkit-animation-delay: -.9s
}

@keyframes preloader {
	0% {
		transform: scale(0, 0);
		opacity: .5
	}

	100% {
		transform: scale(1, 1);
		opacity: 0
	}
}

@-webkit-keyframes preloader {
	0% {
		-webkit-transform: scale(0, 0);
		opacity: .5
	}

	100% {
		-webkit-transform: scale(1, 1);
		opacity: 0
	}
}
#slvj-window{
	width: 100%;
	min-height: 100%;
	z-index: 30;

	background: rgba(0,0,0,0.8);

	position: fixed;
	top: 0;
	left: 0;
}

#slvj-window,
#slvj-window *{
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
			box-sizing: border-box;
}

#slvj-background-close{
	width: 100%;
	min-height: 100%;
	z-index: 31;

	position: fixed;
	top: 0;
	left: 0;
}

#slvj-back-lightbox{
	width: 700px;
	height: 540px;
	margin: auto;

	z-index: 32;
}

.slvj-lightbox{
	position: relative; 

	width: 100%;
	height: 100%;
	padding: 30px;
	margin: 0;

	background-color: #FFFFFF;

	-webkit-border-radius: 10px;
	   -moz-border-radius: 10px;
	   		border-radius: 10px;

	box-shadow: 0 2px 2px 2px rgba(0,0,0,0.3);
	z-index: 53;
}

#slvj-close-icon{
	background-image: url(img/close-icon.png);
	background-repeat: no-repeat;
	width: 11px;
	height: 11px;

	position: absolute;
	top: 10px;
	right: 10px;

	z-index: 551;
}

#slvj-close-icon:hover{
	cursor: pointer;
}
.bredcrumb-sec{
	position: relative;
	z-index: 1;
}
.bredcrumb-sec:before{
	background: url(), rgba(10, 9, 9, .7);
	position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}