/*!* Bootstrap v5.3.1 (https://getbootstrap.com/)
* Copyright 2011-2023 The Bootstrap Authors
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)*/@import "https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,700&amp;family=Teko:wght@600&amp;display=swap";
:root, [data-bs-theme=light] {
	--bs-blue:#0d6efd;
	--bs-indigo:#6610f2;
	--bs-purple:#6f42c1;
	--bs-pink:#d63384;
	--bs-red:#dc3545;
	--bs-orange:#fd7e14;
	--bs-yellow:#ffac4b;
	--bs-green:#198754;
	--bs-teal:#20c997;
	--bs-cyan:#0dcaf0;
	--bs-black:#000;
	--bs-white:#fff;
	--bs-gray:#6c757d;
	--bs-gray-dark:#343a40;
	--bs-gray-100:#f8f9fa;
	--bs-gray-200:#e9ecef;
	--bs-gray-300:#dee2e6;
	--bs-gray-400:#ced4da;
	--bs-gray-500:#adb5bd;
	--bs-gray-600:#6c757d;
	--bs-gray-700:#495057;
	--bs-gray-800:#343a40;
	--bs-gray-900:#212529;
	--bs-primary:#e6c43a;
	--bs-secondary:#696969;
	--bs-success:#35cc6c;
	--bs-info:#fff;
	--bs-light:#f5f8e5;
	--bs-danger:#f30d0d;
	--bs-dark:#000;
	--bs-altdark:#222;
	--bs-sky:#136495;
	--bs-mute:#f6f6f6;
	--bs-tertiary:#136495;
	--bs-primary-rgb:228, 191, 38;
	--bs-secondary-rgb:105, 105, 105;
	--bs-success-rgb:53, 204, 108;
	--bs-info-rgb:255, 255, 255;
	--bs-light-rgb:245, 248, 229;
	--bs-danger-rgb:243, 13, 13;
	--bs-dark-rgb:0, 0, 0;
	--bs-altdark-rgb:34, 34, 34;
	--bs-sky-rgb:19, 100, 149;
	--bs-mute-rgb:246, 246, 246;
	--bs-tertiary-rgb:254, 89, 0;
	--bs-primary-text-emphasis:#5a6228;
	--bs-secondary-text-emphasis:#2a2a2a;
	--bs-success-text-emphasis:#15522b;
	--bs-info-text-emphasis:#055160;
	--bs-warning-text-emphasis:#66451e;
	--bs-danger-text-emphasis:#58151c;
	--bs-light-text-emphasis:#495057;
	--bs-dark-text-emphasis:#495057;
	--bs-primary-bg-subtle:#f9fde0;
	--bs-secondary-bg-subtle:#e1e1e1;
	--bs-success-bg-subtle:#d7f5e2;
	--bs-info-bg-subtle:#cff4fc;
	--bs-warning-bg-subtle:#ffeedb;
	--bs-danger-bg-subtle:#f8d7da;
	--bs-light-bg-subtle:#fcfcfd;
	--bs-dark-bg-subtle:#ced4da;
	--bs-primary-border-subtle:#f3fbc1;
	--bs-secondary-border-subtle:#c3c3c3;
	--bs-success-border-subtle:#aeebc4;
	--bs-info-border-subtle:#9eeaf9;
	--bs-warning-border-subtle:#ffdeb7;
	--bs-danger-border-subtle:#f1aeb5;
	--bs-light-border-subtle:#e9ecef;
	--bs-dark-border-subtle:#adb5bd;
	--bs-white-rgb:255, 255, 255;
	--bs-black-rgb:0, 0, 0;
	--bs-font-sans-serif:system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--bs-font-monospace:SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	--bs-gradient:linear-gradient(180deg, hsla(0,0%,100%,0.15), hsla(0,0%,100%,0));
	--bs-body-font-family:var(--bs-font-sans-serif);
	--bs-body-font-size:1rem;
	--bs-body-font-weight:400;
	--bs-body-line-height:1.5;
	--bs-body-color:#696969;
	--bs-body-color-rgb:105, 105, 105;
	--bs-body-bg:#fff;
	--bs-body-bg-rgb:255, 255, 255;
	--bs-emphasis-color:#000;
	--bs-emphasis-color-rgb:0, 0, 0;
	--bs-secondary-color:hsla(0,0%,41.2%,0.75);
	--bs-secondary-color-rgb:105, 105, 105;
	--bs-secondary-bg:#e9ecef;
	--bs-secondary-bg-rgb:233, 236, 239;
	--bs-tertiary-color:hsla(0,0%,41.2%,0.5);
	--bs-tertiary-color-rgb:105, 105, 105;
	--bs-tertiary-bg:#f8f9fa;
	--bs-tertiary-bg-rgb:248, 249, 250;
	--bs-heading-color:#030523;
	--bs-link-color:#e6c43a;
	--bs-link-color-rgb:224, 244, 101;
	--bs-link-decoration:underline;
	--bs-link-hover-color:#b3c351;
	--bs-link-hover-color-rgb:179, 195, 81;
	--bs-code-color:#d63384;
	--bs-highlight-bg:#ffeedb;
	--bs-border-width:1px;
	--bs-border-style:solid;
	--bs-border-color:#e8e8e8;
	--bs-border-color-translucent:rgba(0,0,0,0.175);
	--bs-border-radius:0.375rem;
	--bs-border-radius-sm:0.25rem;
	--bs-border-radius-lg:0.5rem;
	--bs-border-radius-xl:1rem;
	--bs-border-radius-xxl:2rem;
	--bs-border-radius-2xl:var(--bs-border-radius-xxl);
	--bs-border-radius-pill:50rem;
	--bs-box-shadow:0px 15px 68px rgba(11,3,35,0.15);
	--bs-box-shadow-sm:0 0.125rem 0.25rem rgba(0,0,0,0.075);
	--bs-box-shadow-lg:0 1rem 3rem rgba(0,0,0,0.175);
	--bs-box-shadow-inset:inset 0 1px 2px rgba(0,0,0,0.075);
	--bs-focus-ring-width:0.25rem;
	--bs-focus-ring-opacity:0.25;
	--bs-focus-ring-color:rgba(224,244,101,0.25);
	--bs-form-valid-color:#35cc6c;
	--bs-form-valid-border-color:#35cc6c;
	--bs-form-invalid-color:#dc3545;
	--bs-form-invalid-border-color:#dc3545
	}
	[data-bs-theme=dark] {
	color-scheme:dark;
	--bs-body-color:#dee2e6;
	--bs-body-color-rgb:222, 226, 230;
	--bs-body-bg:#212529;
	--bs-body-bg-rgb:33, 37, 41;
	--bs-emphasis-color:#fff;
	--bs-emphasis-color-rgb:255, 255, 255;
	--bs-secondary-color:rgba(222,226,230,0.75);
	--bs-secondary-color-rgb:222, 226, 230;
	--bs-secondary-bg:#343a40;
	--bs-secondary-bg-rgb:52, 58, 64;
	--bs-tertiary-color:rgba(222,226,230,0.5);
	--bs-tertiary-color-rgb:222, 226, 230;
	--bs-tertiary-bg:#2b3035;
	--bs-tertiary-bg-rgb:43, 48, 53;
	--bs-primary-text-emphasis:#ecf8a3;
	--bs-secondary-text-emphasis:#a5a5a5;
	--bs-success-text-emphasis:#86e0a7;
	--bs-info-text-emphasis:#6edff6;
	--bs-warning-text-emphasis:#ffcd93;
	--bs-danger-text-emphasis:#ea868f;
	--bs-light-text-emphasis:#f8f9fa;
	--bs-dark-text-emphasis:#dee2e6;
	--bs-primary-bg-subtle:#2d3114;
	--bs-secondary-bg-subtle:#151515;
	--bs-success-bg-subtle:#0b2916;
	--bs-info-bg-subtle:#032830;
	--bs-warning-bg-subtle:#33220f;
	--bs-danger-bg-subtle:#2c0b0e;
	--bs-light-bg-subtle:#343a40;
	--bs-dark-bg-subtle:#1a1d20;
	--bs-primary-border-subtle:#86923d;
	--bs-secondary-border-subtle:#3f3f3f;
	--bs-success-border-subtle:#207a41;
	--bs-info-border-subtle:#087990;
	--bs-warning-border-subtle:#99672d;
	--bs-danger-border-subtle:#842029;
	--bs-light-border-subtle:#495057;
	--bs-dark-border-subtle:#343a40;
	--bs-heading-color:inherit;
	--bs-link-color:#ecf8a3;
	--bs-link-hover-color:#f0f9b5;
	--bs-link-color-rgb:236, 248, 163;
	--bs-link-hover-color-rgb:240, 249, 181;
	--bs-code-color:#e685b5;
	--bs-border-color:#495057;
	--bs-border-color-translucent:hsla(0,0%,100%,0.15);
	--bs-form-valid-color:#75b798;
	--bs-form-valid-border-color:#75b798;
	--bs-form-invalid-color:#ea868f;
	--bs-form-invalid-border-color:#ea868f
	}
	*, :after, :before {
		box-sizing:border-box
	}
	@media(prefers-reduced-motion:no-preference) {
	:root {
		scroll-behavior:smooth
	}
	}
	body {
		margin:0;
		font-family:var(--bs-body-font-family);
		font-size:var(--bs-body-font-size);
		font-weight:var(--bs-body-font-weight);
		line-height:var(--bs-body-line-height);
		color:var(--bs-body-color);
		text-align:var(--bs-body-text-align);
		background-color:var(--bs-body-bg);
		-webkit-text-size-adjust:100%;
		-webkit-tap-highlight-color:transparent
	}
	hr {
		margin:1rem 0;
		color:inherit;
		border:0;
		border-top:var(--bs-border-width) solid;
		opacity:.25
	}
	.contact-txt .call-widget a span, .faq-item button, .h1, .h2, .h3, .h4, .h5, .h6, .lead, .payment-option .accordion-button, .product-details-tab .nav-tabs li .nav-link, .slide-bar .box-item span, .slide-bar.reverse .box-item span, h1, h2, h3, h4, h5, h6 {
		margin-top:0;
	margin-bottom:.5rem;
		font-weight:500;
		line-height:1.2;
		color:var(--bs-heading-color)
	}
	.h1, .slide-bar .box-item span, .slide-bar.reverse .box-item span, h1 {
		font-size:calc(1.375rem + 1.5vw)
	}
	@media(min-width:1200px) {
	.h1, .slide-bar .box-item span, .slide-bar.reverse .box-item span, h1 {
		font-size:2.5rem
	}
	}
	.h2, h2 {
	font-size:calc(1.325rem + .9vw)
	}
	@media(min-width:1200px) {
	.h2, h2 {
		font-size:2rem
	}
	}
	.h3, h3 {
	font-size:calc(1.3rem + .6vw)
	}
	@media(min-width:1200px) {
	.h3, h3 {
		font-size:1.75rem
	}
	}
	.contact-txt .call-widget a span, .faq-item button, .h4, .lead, .product-details-tab .nav-tabs li .nav-link, h4 {
	font-size:calc(1.275rem + .3vw)
	}
	@media(min-width:1200px) {
	.contact-txt .call-widget a span, .faq-item button, .h4, .lead, .product-details-tab .nav-tabs li .nav-link, h4 {
		font-size:1.5rem
	}
	}
	.h5, h5 {
		font-size:1.25rem
	}
	.h6, .payment-option .accordion-button, h6 {
		font-size:1rem
	}
	p {
		margin-top:0;
		margin-bottom:1rem
	}
	abbr[title] {
		text-decoration:underline dotted;
		cursor:help;
		text-decoration-skip-ink:none
	}
	address {
		margin-bottom:1rem;
		font-style:normal;
		line-height:inherit
	}
	ol, ul {
		padding-left:2rem
	}
	dl, ol, ul {
		margin-top:0;
		margin-bottom:1rem
	}
	ol ol, ol ul, ul ol, ul ul {
		margin-bottom:0
	}
	dt {
		font-weight:700
	}
	dd {
	margin-bottom:.5rem;
		margin-left:0
	}
	blockquote {
		margin:0 0 1rem
	}
	b, strong {
		font-weight:bolder
	}
	.small, small {
		font-size:.875em
	}
	.mark, mark {
		padding:.1875em;
		background-color:var(--bs-highlight-bg)
	}
	sub, sup {
		position:relative;
		font-size:.75em;
		line-height:0;
		vertical-align:baseline
	}
	sub {
		bottom:-.25em
	}
	sup {
		top:-.5em
	}
	a {
		color:rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
		text-decoration:underline
	}
	a:hover {
	--bs-link-color-rgb:var(--bs-link-hover-color-rgb)
	}
	a:not([href]):not([class]), a:not([href]):not([class]):hover {
		color:inherit;
		text-decoration:none
	}
	code, kbd, pre, samp {
		font-family:var(--bs-font-monospace);
		font-size:1em
	}
	pre {
		display:block;
		margin-top:0;
		margin-bottom:1rem;
		overflow:auto;
		font-size:.875em
	}
	pre code {
		font-size:inherit;
		color:inherit;
		word-break:normal
	}
	code {
		font-size:.875em;
		color:var(--bs-code-color);
		word-wrap:break-word
	}
	a>code {
		color:inherit
	}
	kbd {
	padding:.1875rem .375rem;
		font-size:.875em;
		color:var(--bs-body-bg);
		background-color:var(--bs-body-color);
	border-radius:.25rem
	}
	kbd kbd {
		padding:0;
		font-size:1em
	}
	figure {
		margin:0 0 1rem
	}
	img, svg {
		vertical-align:middle
	}
	table {
		caption-side:bottom;
		border-collapse:collapse
	}
	caption {
	padding-top:.5rem;
	padding-bottom:.5rem;
		color:var(--bs-secondary-color);
		text-align:left
	}
	th {
		text-align:inherit;
		text-align:-webkit-match-parent
	}
	tbody, td, tfoot, th, thead, tr {
		border:0 solid;
		border-color:inherit
	}
	label {
		display:inline-block
	}
	button {
		border-radius:0
	}
	button:focus:not(:focus-visible) {
		outline:0
	}
	button, input, optgroup, select, textarea {
		margin:0;
		font-family:inherit;
		font-size:inherit;
		line-height:inherit
	}
	button, select {
		text-transform:none
	}
	[role=button] {
	cursor:pointer
	}
	select {
		word-wrap:normal
	}
	select:disabled {
		opacity:1
	}
	[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
	display:none!important
	}
	[type=button], [type=reset], [type=submit], button {
		appearance: button;
	-webkit-appearance:button
	}
	[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
		cursor:pointer
	}
	::-moz-focus-inner {
	padding:0;
	border-style:none
	}
	textarea {
		resize:vertical
	}
	fieldset {
		min-width:0;
		padding:0;
		margin:0;
		border:0
	}
	legend {
		float:left;
		width:100%;
		padding:0;
	margin-bottom:.5rem;
	font-size:calc(1.275rem + .3vw);
		line-height:inherit
	}
	@media(min-width:1200px) {
	legend {
		font-size:1.5rem
	}
	}
	legend+* {
		clear:left
	}
	::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-year-field {
	padding:0
	}
	::-webkit-inner-spin-button {
	height:auto
	}
	[type=search] {
		appearance: textfield;
	-webkit-appearance:textfield;
	outline-offset:-2px
	}
	::-webkit-search-decoration {
	-webkit-appearance:none
	}
	::-webkit-color-swatch-wrapper {
	padding:0
	}
	::file-selector-button {
	font:inherit;
	appearance: button;
	-webkit-appearance:button
	}
	output {
		display:inline-block
	}
	iframe {
		border:0
	}
	summary {
		display:list-item;
		cursor:pointer
	}
	progress {
		vertical-align:baseline
	}
	[hidden] {
	display:none!important
	}
	.lead {
		font-size:1.25rem;
		font-weight:300
	}
	.display-1 {
		font-size:calc(1.625rem + 4.5vw);
		font-weight:300;
		line-height:1.2
	}
	@media(min-width:1200px) {
	.display-1 {
		font-size:5rem
	}
	}
	.display-2 {
		font-size:calc(1.575rem + 3.9vw);
		font-weight:300;
		line-height:1.2
	}
	@media(min-width:1200px) {
	.display-2 {
		font-size:4.5rem
	}
	}
	.display-3 {
		font-size:calc(1.525rem + 3.3vw);
		font-weight:300;
		line-height:1.2
	}
	@media(min-width:1200px) {
	.display-3 {
		font-size:4rem
	}
	}
	.display-4 {
		font-size:calc(1.475rem + 2.7vw);
		font-weight:300;
		line-height:1.2
	}
	@media(min-width:1200px) {
	.display-4 {
		font-size:3.5rem
	}
	}
	.display-5 {
		font-size:calc(1.425rem + 2.1vw);
		font-weight:300;
		line-height:1.2
	}
	@media(min-width:1200px) {
	.display-5 {
		font-size:3rem
	}
	}
	.display-6 {
		font-size:calc(1.375rem + 1.5vw);
		font-weight:300;
		line-height:1.2
	}
	@media(min-width:1200px) {
	.display-6 {
		font-size:2.5rem
	}
	}
	.list-inline, .list-unstyled {
		padding-left:0;
		list-style:none
	}
	.list-inline-item {
		display:inline-block
	}
	.list-inline-item:not(:last-child) {
	margin-right:.5rem
	}
	.initialism {
		font-size:.875em;
		text-transform:uppercase
	}
	.blockquote {
		margin-bottom:1rem;
		font-size:1.25rem
	}
	.blockquote>:last-child {
		margin-bottom:0
	}
	.blockquote-footer {
		margin-top:-1rem;
		margin-bottom:1rem;
		font-size:.875em;
		color:#6c757d
	}
	.blockquote-footer:before {
		content:"\2014\00A0"
	}
	.img-fluid, .img-thumbnail {
		max-width:100%;
		height:auto
	}
	.img-thumbnail {
	padding:.25rem;
		background-color:var(--bs-body-bg);
		border:var(--bs-border-width) solid var(--bs-border-color);
		border-radius:var(--bs-border-radius)
	}
	.figure {
		display:inline-block
	}
	.figure-img {
	margin-bottom:.5rem;
		line-height:1
	}
	.figure-caption {
		font-size:.875em;
		color:var(--bs-secondary-color)
	}
	.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
	--bs-gutter-x:1.5rem;
	--bs-gutter-y:0;
		width:100%;
	padding-right:calc(var(--bs-gutter-x)*0.5);
	padding-left:calc(var(--bs-gutter-x)*0.5);
		margin-right:auto;
		margin-left:auto
	}
	@media(min-width:576px) {
	.container, .container-sm {
		max-width:540px
	}
	}
	@media(min-width:768px) {
	.container, .container-md, .container-sm {
		max-width:720px
	}
	}
	@media(min-width:992px) {
	.container, .container-lg, .container-md, .container-sm {
		max-width:960px
	}
	}
	@media(min-width:1200px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
		max-width:1140px
	}
	}
	@media(min-width:1400px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
		max-width:1320px
	}
	}
	:root {
	--bs-breakpoint-xs:0;
	--bs-breakpoint-sm:576px;
	--bs-breakpoint-md:768px;
	--bs-breakpoint-lg:992px;
	--bs-breakpoint-xl:1200px;
	--bs-breakpoint-xxl:1400px
	}
	.row {
	--bs-gutter-x:1.5rem;
	--bs-gutter-y:0;
		display:flex;
		flex-wrap:wrap;
	margin-top:calc(var(--bs-gutter-y)*-1);
	margin-right:calc(var(--bs-gutter-x)*-0.5);
	margin-left:calc(var(--bs-gutter-x)*-0.5)
	}
	.row>* {
		flex-shrink:0;
		width:100%;
		max-width:100%;
	padding-right:calc(var(--bs-gutter-x)*0.5);
	padding-left:calc(var(--bs-gutter-x)*0.5);
		margin-top:var(--bs-gutter-y)
	}
	.col {
		flex:1 0 0%
	}
	.row-cols-auto>* {
		flex:0 0 auto;
		width:auto
	}
	.row-cols-1>* {
		flex:0 0 auto;
		width:100%
	}
	.row-cols-2>* {
		flex:0 0 auto;
		width:50%
	}
	.row-cols-3>* {
		flex:0 0 auto;
		width:33.33333%
	}
	.row-cols-4>* {
		flex:0 0 auto;
		width:25%
	}
	.row-cols-5>* {
		flex:0 0 auto;
		width:20%
	}
	.row-cols-6>* {
		flex:0 0 auto;
		width:16.66667%
	}
	.col-auto {
		flex:0 0 auto;
		width:auto
	}
	.col-1 {
		flex:0 0 auto;
		width:8.33333%
	}
	.col-2 {
		flex:0 0 auto;
		width:16.66667%
	}
	.col-3 {
		flex:0 0 auto;
		width:25%
	}
	.col-4 {
		flex:0 0 auto;
		width:33.33333%
	}
	.col-5 {
		flex:0 0 auto;
		width:41.66667%
	}
	.col-6 {
		flex:0 0 auto;
		width:50%
	}
	.col-7 {
		flex:0 0 auto;
		width:58.33333%
	}
	.col-8 {
		flex:0 0 auto;
		width:66.66667%
	}
	.col-9 {
		flex:0 0 auto;
		width:75%
	}
	.col-10 {
		flex:0 0 auto;
		width:83.33333%
	}
	.col-11 {
		flex:0 0 auto;
		width:91.66667%
	}
	.col-12 {
		flex:0 0 auto;
		width:100%
	}
	.offset-1 {
		margin-left:8.33333%
	}
	.offset-2 {
		margin-left:16.66667%
	}
	.offset-3 {
		margin-left:25%
	}
	.offset-4 {
		margin-left:33.33333%
	}
	.offset-5 {
		margin-left:41.66667%
	}
	.offset-6 {
		margin-left:50%
	}
	.offset-7 {
		margin-left:58.33333%
	}
	.offset-8 {
		margin-left:66.66667%
	}
	.offset-9 {
		margin-left:75%
	}
	.offset-10 {
		margin-left:83.33333%
	}
	.offset-11 {
		margin-left:91.66667%
	}
	.g-0, .gx-0 {
	--bs-gutter-x:0
	}
	.g-0, .gy-0 {
	--bs-gutter-y:0
	}
	.g-1, .gx-1 {
	--bs-gutter-x:0.25rem
	}
	.g-1, .gy-1 {
	--bs-gutter-y:0.25rem
	}
	.g-2, .gx-2 {
	--bs-gutter-x:0.5rem
	}
	.g-2, .gy-2 {
	--bs-gutter-y:0.5rem
	}
	.g-3, .gx-3 {
	--bs-gutter-x:1rem
	}
	.g-3, .gy-3 {
	--bs-gutter-y:1rem
	}
	.g-4, .gx-4 {
	--bs-gutter-x:1.5rem
	}
	.g-4, .gy-4 {
	--bs-gutter-y:1.5rem
	}
	.g-5, .gx-5 {
	--bs-gutter-x:3rem
	}
	.g-5, .gy-5 {
	--bs-gutter-y:3rem
	}
	@media(min-width:576px) {
	.col-sm {
		flex:1 0 0%
	}
	.row-cols-sm-auto>* {
		flex:0 0 auto;
		width:auto
	}
	.row-cols-sm-1>* {
		flex:0 0 auto;
		width:100%
	}
	.row-cols-sm-2>* {
		flex:0 0 auto;
		width:50%
	}
	.row-cols-sm-3>* {
		flex:0 0 auto;
		width:33.33333%
	}
	.row-cols-sm-4>* {
		flex:0 0 auto;
		width:25%
	}
	.row-cols-sm-5>* {
		flex:0 0 auto;
		width:20%
	}
	.row-cols-sm-6>* {
		flex:0 0 auto;
		width:16.66667%
	}
	.col-sm-auto {
		flex:0 0 auto;
		width:auto
	}
	.col-sm-1 {
		flex:0 0 auto;
		width:8.33333%
	}
	.col-sm-2 {
		flex:0 0 auto;
		width:16.66667%
	}
	.col-sm-3 {
		flex:0 0 auto;
		width:25%
	}
	.col-sm-4 {
		flex:0 0 auto;
		width:33.33333%
	}
	.col-sm-5 {
		flex:0 0 auto;
		width:41.66667%
	}
	.col-sm-6 {
		flex:0 0 auto;
		width:50%
	}
	.col-sm-7 {
		flex:0 0 auto;
		width:58.33333%
	}
	.col-sm-8 {
		flex:0 0 auto;
		width:66.66667%
	}
	.col-sm-9 {
		flex:0 0 auto;
		width:75%
	}
	.col-sm-10 {
		flex:0 0 auto;
		width:83.33333%
	}
	.col-sm-11 {
		flex:0 0 auto;
		width:91.66667%
	}
	.col-sm-12 {
		flex:0 0 auto;
		width:100%
	}
	.offset-sm-0 {
		margin-left:0
	}
	.offset-sm-1 {
		margin-left:8.33333%
	}
	.offset-sm-2 {
		margin-left:16.66667%
	}
	.offset-sm-3 {
		margin-left:25%
	}
	.offset-sm-4 {
		margin-left:33.33333%
	}
	.offset-sm-5 {
		margin-left:41.66667%
	}
	.offset-sm-6 {
		margin-left:50%
	}
	.offset-sm-7 {
		margin-left:58.33333%
	}
	.offset-sm-8 {
		margin-left:66.66667%
	}
	.offset-sm-9 {
		margin-left:75%
	}
	.offset-sm-10 {
		margin-left:83.33333%
	}
	.offset-sm-11 {
		margin-left:91.66667%
	}
	.g-sm-0, .gx-sm-0 {
	--bs-gutter-x:0
	}
	.g-sm-0, .gy-sm-0 {
	--bs-gutter-y:0
	}
	.g-sm-1, .gx-sm-1 {
	--bs-gutter-x:0.25rem
	}
	.g-sm-1, .gy-sm-1 {
	--bs-gutter-y:0.25rem
	}
	.g-sm-2, .gx-sm-2 {
	--bs-gutter-x:0.5rem
	}
	.g-sm-2, .gy-sm-2 {
	--bs-gutter-y:0.5rem
	}
	.g-sm-3, .gx-sm-3 {
	--bs-gutter-x:1rem
	}
	.g-sm-3, .gy-sm-3 {
	--bs-gutter-y:1rem
	}
	.g-sm-4, .gx-sm-4 {
	--bs-gutter-x:1.5rem
	}
	.g-sm-4, .gy-sm-4 {
	--bs-gutter-y:1.5rem
	}
	.g-sm-5, .gx-sm-5 {
	--bs-gutter-x:3rem
	}
	.g-sm-5, .gy-sm-5 {
	--bs-gutter-y:3rem
	}
	}
	@media(min-width:768px) {
	.col-md {
		flex:1 0 0%
	}
	.row-cols-md-auto>* {
		flex:0 0 auto;
		width:auto
	}
	.row-cols-md-1>* {
		flex:0 0 auto;
		width:100%
	}
	.row-cols-md-2>* {
		flex:0 0 auto;
		width:50%
	}
	.row-cols-md-3>* {
		flex:0 0 auto;
		width:33.33333%
	}
	.row-cols-md-4>* {
		flex:0 0 auto;
		width:25%
	}
	.row-cols-md-5>* {
		flex:0 0 auto;
		width:20%
	}
	.row-cols-md-6>* {
		flex:0 0 auto;
		width:16.66667%
	}
	.col-md-auto {
		flex:0 0 auto;
		width:auto
	}
	.col-md-1 {
		flex:0 0 auto;
		width:8.33333%
	}
	.col-md-2 {
		flex:0 0 auto;
		width:16.66667%
	}
	.col-md-3 {
		flex:0 0 auto;
		width:25%
	}
	.col-md-4 {
		flex:0 0 auto;
		width:33.33333%
	}
	.col-md-5 {
		flex:0 0 auto;
		width:41.66667%
	}
	.col-md-6 {
		flex:0 0 auto;
		width:50%
	}
	.col-md-7 {
		flex:0 0 auto;
		width:58.33333%
	}
	.col-md-8 {
		flex:0 0 auto;
		width:66.66667%
	}
	.col-md-9 {
		flex:0 0 auto;
		width:75%
	}
	.col-md-10 {
		flex:0 0 auto;
		width:83.33333%
	}
	.col-md-11 {
		flex:0 0 auto;
		width:91.66667%
	}
	.col-md-12 {
		flex:0 0 auto;
		width:100%
	}
	.offset-md-0 {
		margin-left:0
	}
	.offset-md-1 {
		margin-left:8.33333%
	}
	.offset-md-2 {
		margin-left:16.66667%
	}
	.offset-md-3 {
		margin-left:25%
	}
	.offset-md-4 {
		margin-left:33.33333%
	}
	.offset-md-5 {
		margin-left:41.66667%
	}
	.offset-md-6 {
		margin-left:50%
	}
	.offset-md-7 {
		margin-left:58.33333%
	}
	.offset-md-8 {
		margin-left:66.66667%
	}
	.offset-md-9 {
		margin-left:75%
	}
	.offset-md-10 {
		margin-left:83.33333%
	}
	.offset-md-11 {
		margin-left:91.66667%
	}
	.g-md-0, .gx-md-0 {
	--bs-gutter-x:0
	}
	.g-md-0, .gy-md-0 {
	--bs-gutter-y:0
	}
	.g-md-1, .gx-md-1 {
	--bs-gutter-x:0.25rem
	}
	.g-md-1, .gy-md-1 {
	--bs-gutter-y:0.25rem
	}
	.g-md-2, .gx-md-2 {
	--bs-gutter-x:0.5rem
	}
	.g-md-2, .gy-md-2 {
	--bs-gutter-y:0.5rem
	}
	.g-md-3, .gx-md-3 {
	--bs-gutter-x:1rem
	}
	.g-md-3, .gy-md-3 {
	--bs-gutter-y:1rem
	}
	.g-md-4, .gx-md-4 {
	--bs-gutter-x:1.5rem
	}
	.g-md-4, .gy-md-4 {
	--bs-gutter-y:1.5rem
	}
	.g-md-5, .gx-md-5 {
	--bs-gutter-x:3rem
	}
	.g-md-5, .gy-md-5 {
	--bs-gutter-y:3rem
	}
	}
	@media(min-width:992px) {
	.col-lg {
		flex:1 0 0%
	}
	.row-cols-lg-auto>* {
		flex:0 0 auto;
		width:auto
	}
	.row-cols-lg-1>* {
		flex:0 0 auto;
		width:100%
	}
	.row-cols-lg-2>* {
		flex:0 0 auto;
		width:50%
	}
	.row-cols-lg-3>* {
		flex:0 0 auto;
		width:33.33333%
	}
	.row-cols-lg-4>* {
		flex:0 0 auto;
		width:25%
	}
	.row-cols-lg-5>* {
		flex:0 0 auto;
		width:20%
	}
	.row-cols-lg-6>* {
		flex:0 0 auto;
		width:16.66667%
	}
	.col-lg-auto {
		flex:0 0 auto;
		width:auto
	}
	.col-lg-1 {
		flex:0 0 auto;
		width:8.33333%
	}
	.col-lg-2 {
		flex:0 0 auto;
		width:16.66667%
	}
	.col-lg-3 {
		flex:0 0 auto;
		width:25%
	}
	.col-lg-4 {
		flex:0 0 auto;
		width:33.33333%
	}
	.col-lg-5 {
		flex:0 0 auto;
		width:41.66667%
	}
	.col-lg-6 {
		flex:0 0 auto;
		width:50%
	}
	.col-lg-7 {
		flex:0 0 auto;
		width:58.33333%
	}
	.col-lg-8 {
		flex:0 0 auto;
		width:66.66667%
	}
	.col-lg-9 {
		flex:0 0 auto;
		width:75%
	}
	.col-lg-10 {
		flex:0 0 auto;
		width:83.33333%
	}
	.col-lg-11 {
		flex:0 0 auto;
		width:91.66667%
	}
	.col-lg-12 {
		flex:0 0 auto;
		width:100%
	}
	.offset-lg-0 {
		margin-left:0
	}
	.offset-lg-1 {
		margin-left:8.33333%
	}
	.offset-lg-2 {
		margin-left:16.66667%
	}
	.offset-lg-3 {
		margin-left:25%
	}
	.offset-lg-4 {
		margin-left:33.33333%
	}
	.offset-lg-5 {
		margin-left:41.66667%
	}
	.offset-lg-6 {
		margin-left:50%
	}
	.offset-lg-7 {
		margin-left:58.33333%
	}
	.offset-lg-8 {
		margin-left:66.66667%
	}
	.offset-lg-9 {
		margin-left:75%
	}
	.offset-lg-10 {
		margin-left:83.33333%
	}
	.offset-lg-11 {
		margin-left:91.66667%
	}
	.g-lg-0, .gx-lg-0 {
	--bs-gutter-x:0
	}
	.g-lg-0, .gy-lg-0 {
	--bs-gutter-y:0
	}
	.g-lg-1, .gx-lg-1 {
	--bs-gutter-x:0.25rem
	}
	.g-lg-1, .gy-lg-1 {
	--bs-gutter-y:0.25rem
	}
	.g-lg-2, .gx-lg-2 {
	--bs-gutter-x:0.5rem
	}
	.g-lg-2, .gy-lg-2 {
	--bs-gutter-y:0.5rem
	}
	.g-lg-3, .gx-lg-3 {
	--bs-gutter-x:1rem
	}
	.g-lg-3, .gy-lg-3 {
	--bs-gutter-y:1rem
	}
	.g-lg-4, .gx-lg-4 {
	--bs-gutter-x:1.5rem
	}
	.g-lg-4, .gy-lg-4 {
	--bs-gutter-y:1.5rem
	}
	.g-lg-5, .gx-lg-5 {
	--bs-gutter-x:3rem
	}
	.g-lg-5, .gy-lg-5 {
	--bs-gutter-y:3rem
	}
	}
	@media(min-width:1200px) {
	.col-xl {
		flex:1 0 0%
	}
	.row-cols-xl-auto>* {
		flex:0 0 auto;
		width:auto
	}
	.row-cols-xl-1>* {
		flex:0 0 auto;
		width:100%
	}
	.row-cols-xl-2>* {
		flex:0 0 auto;
		width:50%
	}
	.row-cols-xl-3>* {
		flex:0 0 auto;
		width:33.33333%
	}
	.row-cols-xl-4>* {
		flex:0 0 auto;
		width:25%
	}
	.row-cols-xl-5>* {
		flex:0 0 auto;
		width:20%
	}
	.row-cols-xl-6>* {
		flex:0 0 auto;
		width:16.66667%
	}
	.col-xl-auto {
		flex:0 0 auto;
		width:auto
	}
	.col-xl-1 {
		flex:0 0 auto;
		width:8.33333%
	}
	.col-xl-2 {
		flex:0 0 auto;
		width:16.66667%
	}
	.col-xl-3 {
		flex:0 0 auto;
		width:25%
	}
	.col-xl-4 {
		flex:0 0 auto;
		width:33.33333%
	}
	.col-xl-5 {
		flex:0 0 auto;
		width:41.66667%
	}
	.col-xl-6 {
		flex:0 0 auto;
		width:50%
	}
	.col-xl-7 {
		flex:0 0 auto;
		width:58.33333%
	}
	.col-xl-8 {
		flex:0 0 auto;
		width:66.66667%
	}
	.col-xl-9 {
		flex:0 0 auto;
		width:75%
	}
	.col-xl-10 {
		flex:0 0 auto;
		width:83.33333%
	}
	.col-xl-11 {
		flex:0 0 auto;
		width:91.66667%
	}
	.col-xl-12 {
		flex:0 0 auto;
		width:100%
	}
	.offset-xl-0 {
		margin-left:0
	}
	.offset-xl-1 {
		margin-left:8.33333%
	}
	.offset-xl-2 {
		margin-left:16.66667%
	}
	.offset-xl-3 {
		margin-left:25%
	}
	.offset-xl-4 {
		margin-left:33.33333%
	}
	.offset-xl-5 {
		margin-left:41.66667%
	}
	.offset-xl-6 {
		margin-left:50%
	}
	.offset-xl-7 {
		margin-left:58.33333%
	}
	.offset-xl-8 {
		margin-left:66.66667%
	}
	.offset-xl-9 {
		margin-left:75%
	}
	.offset-xl-10 {
		margin-left:83.33333%
	}
	.offset-xl-11 {
		margin-left:91.66667%
	}
	.g-xl-0, .gx-xl-0 {
	--bs-gutter-x:0
	}
	.g-xl-0, .gy-xl-0 {
	--bs-gutter-y:0
	}
	.g-xl-1, .gx-xl-1 {
	--bs-gutter-x:0.25rem
	}
	.g-xl-1, .gy-xl-1 {
	--bs-gutter-y:0.25rem
	}
	.g-xl-2, .gx-xl-2 {
	--bs-gutter-x:0.5rem
	}
	.g-xl-2, .gy-xl-2 {
	--bs-gutter-y:0.5rem
	}
	.g-xl-3, .gx-xl-3 {
	--bs-gutter-x:1rem
	}
	.g-xl-3, .gy-xl-3 {
	--bs-gutter-y:1rem
	}
	.g-xl-4, .gx-xl-4 {
	--bs-gutter-x:1.5rem
	}
	.g-xl-4, .gy-xl-4 {
	--bs-gutter-y:1.5rem
	}
	.g-xl-5, .gx-xl-5 {
	--bs-gutter-x:3rem
	}
	.g-xl-5, .gy-xl-5 {
	--bs-gutter-y:3rem
	}
	}
	@media(min-width:1400px) {
	.col-xxl {
		flex:1 0 0%
	}
	.row-cols-xxl-auto>* {
		flex:0 0 auto;
		width:auto
	}
	.row-cols-xxl-1>* {
		flex:0 0 auto;
		width:100%
	}
	.row-cols-xxl-2>* {
		flex:0 0 auto;
		width:50%
	}
	.row-cols-xxl-3>* {
		flex:0 0 auto;
		width:33.33333%
	}
	.row-cols-xxl-4>* {
		flex:0 0 auto;
		width:25%
	}
	.row-cols-xxl-5>* {
		flex:0 0 auto;
		width:20%
	}
	.row-cols-xxl-6>* {
		flex:0 0 auto;
		width:16.66667%
	}
	.col-xxl-auto {
		flex:0 0 auto;
		width:auto
	}
	.col-xxl-1 {
		flex:0 0 auto;
		width:8.33333%
	}
	.col-xxl-2 {
		flex:0 0 auto;
		width:16.66667%
	}
	.col-xxl-3 {
		flex:0 0 auto;
		width:25%
	}
	.col-xxl-4 {
		flex:0 0 auto;
		width:33.33333%
	}
	.col-xxl-5 {
		flex:0 0 auto;
		width:41.66667%
	}
	.col-xxl-6 {
		flex:0 0 auto;
		width:50%
	}
	.col-xxl-7 {
		flex:0 0 auto;
		width:58.33333%
	}
	.col-xxl-8 {
		flex:0 0 auto;
		width:66.66667%
	}
	.col-xxl-9 {
		flex:0 0 auto;
		width:75%
	}
	.col-xxl-10 {
		flex:0 0 auto;
		width:83.33333%
	}
	.col-xxl-11 {
		flex:0 0 auto;
		width:91.66667%
	}
	.col-xxl-12 {
		flex:0 0 auto;
		width:100%
	}
	.offset-xxl-0 {
		margin-left:0
	}
	.offset-xxl-1 {
		margin-left:8.33333%
	}
	.offset-xxl-2 {
		margin-left:16.66667%
	}
	.offset-xxl-3 {
		margin-left:25%
	}
	.offset-xxl-4 {
		margin-left:33.33333%
	}
	.offset-xxl-5 {
		margin-left:41.66667%
	}
	.offset-xxl-6 {
		margin-left:50%
	}
	.offset-xxl-7 {
		margin-left:58.33333%
	}
	.offset-xxl-8 {
		margin-left:66.66667%
	}
	.offset-xxl-9 {
		margin-left:75%
	}
	.offset-xxl-10 {
		margin-left:83.33333%
	}
	.offset-xxl-11 {
		margin-left:91.66667%
	}
	.g-xxl-0, .gx-xxl-0 {
	--bs-gutter-x:0
	}
	.g-xxl-0, .gy-xxl-0 {
	--bs-gutter-y:0
	}
	.g-xxl-1, .gx-xxl-1 {
	--bs-gutter-x:0.25rem
	}
	.g-xxl-1, .gy-xxl-1 {
	--bs-gutter-y:0.25rem
	}
	.g-xxl-2, .gx-xxl-2 {
	--bs-gutter-x:0.5rem
	}
	.g-xxl-2, .gy-xxl-2 {
	--bs-gutter-y:0.5rem
	}
	.g-xxl-3, .gx-xxl-3 {
	--bs-gutter-x:1rem
	}
	.g-xxl-3, .gy-xxl-3 {
	--bs-gutter-y:1rem
	}
	.g-xxl-4, .gx-xxl-4 {
	--bs-gutter-x:1.5rem
	}
	.g-xxl-4, .gy-xxl-4 {
	--bs-gutter-y:1.5rem
	}
	.g-xxl-5, .gx-xxl-5 {
	--bs-gutter-x:3rem
	}
	.g-xxl-5, .gy-xxl-5 {
	--bs-gutter-y:3rem
	}
	}
	.table {
	--bs-table-color-type:initial;
	--bs-table-bg-type:initial;
	--bs-table-color-state:initial;
	--bs-table-bg-state:initial;
	--bs-table-color:var(--bs-body-color);
	--bs-table-bg:var(--bs-body-bg);
	--bs-table-border-color:var(--bs-border-color);
	--bs-table-accent-bg:transparent;
	--bs-table-striped-color:var(--bs-body-color);
	--bs-table-striped-bg:rgba(0,0,0,0.05);
	--bs-table-active-color:var(--bs-body-color);
	--bs-table-active-bg:rgba(0,0,0,0.1);
	--bs-table-hover-color:var(--bs-body-color);
	--bs-table-hover-bg:rgba(0,0,0,0.075);
		width:100%;
		margin-bottom:1rem;
		vertical-align:top;
		border-color:var(--bs-table-border-color)
	}
	.table>:not(caption)>*>* {
	padding:.5rem;
		color:var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
		background-color:var(--bs-table-bg);
		border-bottom-width:var(--bs-border-width);
		box-shadow:inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)))
	}
	.table>tbody {
		vertical-align:inherit
	}
	.table>thead {
		vertical-align:bottom
	}
	.table-group-divider {
	border-top:calc(var(--bs-border-width)*2) solid
	}
	.caption-top {
		caption-side:top
	}
	.table-sm>:not(caption)>*>* {
	padding:.25rem
	}
	.table-bordered>:not(caption)>* {
		border-left-width:0;
		border-bottom-width:var(--bs-border-width);
		border-right-width:0;
		border-top-width:var(--bs-border-width)
	}
	.table-bordered>:not(caption)>*>* {
		border-left-width:var(--bs-border-width);
		border-bottom-width:0;
		border-right-width:var(--bs-border-width);
		border-top-width:0
	}
	.table-borderless>:not(caption)>*>* {
		border-bottom-width:0
	}
	.table-borderless>:not(:first-child) {
		border-top-width:0
	}
	.table-striped-columns>:not(caption)>tr>:nth-child(2n), .table-striped>tbody>tr:nth-of-type(odd)>* {
	--bs-table-color-type:var(--bs-table-striped-color);
	--bs-table-bg-type:var(--bs-table-striped-bg)
	}
	.table-active {
	--bs-table-color-state:var(--bs-table-active-color);
	--bs-table-bg-state:var(--bs-table-active-bg)
	}
	.table-hover>tbody>tr:hover>* {
	--bs-table-color-state:var(--bs-table-hover-color);
	--bs-table-bg-state:var(--bs-table-hover-bg)
	}
	.table-primary {
	--bs-table-color:#000;
	--bs-table-bg:#f9fde0;
	--bs-table-border-color:#e0e4ca;
	--bs-table-striped-bg:#edf0d5;
	--bs-table-striped-color:#000;
	--bs-table-active-bg:#e0e4ca;
	--bs-table-active-color:#000;
	--bs-table-hover-bg:#e6eacf;
	--bs-table-hover-color:#000
	}
	.table-primary, .table-secondary {
		color:var(--bs-table-color);
		border-color:var(--bs-table-border-color)
	}
	.table-secondary {
	--bs-table-color:#000;
	--bs-table-bg:#e1e1e1;
	--bs-table-border-color:#cbcbcb;
	--bs-table-striped-bg:#d6d6d6;
	--bs-table-striped-color:#000;
	--bs-table-active-bg:#cbcbcb;
	--bs-table-active-color:#000;
	--bs-table-hover-bg:#d0d0d0;
	--bs-table-hover-color:#000
	}
	.table-success {
	--bs-table-color:#000;
	--bs-table-bg:#d7f5e2;
	--bs-table-border-color:#c2ddcb;
	--bs-table-striped-bg:#cce9d7;
	--bs-table-striped-color:#000;
	--bs-table-active-bg:#c2ddcb;
	--bs-table-active-color:#000;
	--bs-table-hover-bg:#c7e3d1;
	--bs-table-hover-color:#000
	}
	.table-info, .table-success {
		color:var(--bs-table-color);
		border-color:var(--bs-table-border-color)
	}
	.table-info {
	--bs-table-color:#000;
	--bs-table-bg:#cff4fc;
	--bs-table-border-color:#badce3;
	--bs-table-striped-bg:#c5e8ef;
	--bs-table-striped-color:#000;
	--bs-table-active-bg:#badce3;
	--bs-table-active-color:#000;
	--bs-table-hover-bg:#bfe2e9;
	--bs-table-hover-color:#000
	}
	.table-warning {
	--bs-table-color:#000;
	--bs-table-bg:#ffeedb;
	--bs-table-border-color:#e6d6c5;
	--bs-table-striped-bg:#f2e2d0;
	--bs-table-striped-color:#000;
	--bs-table-active-bg:#e6d6c5;
	--bs-table-active-color:#000;
	--bs-table-hover-bg:#ecdccb;
	--bs-table-hover-color:#000
	}
	.table-danger, .table-warning {
		color:var(--bs-table-color);
		border-color:var(--bs-table-border-color)
	}
	.table-danger {
	--bs-table-color:#000;
	--bs-table-bg:#f8d7da;
	--bs-table-border-color:#dfc2c4;
	--bs-table-striped-bg:#eccccf;
	--bs-table-striped-color:#000;
	--bs-table-active-bg:#dfc2c4;
	--bs-table-active-color:#000;
	--bs-table-hover-bg:#e5c7ca;
	--bs-table-hover-color:#000
	}
	.table-light {
	--bs-table-color:#000;
	--bs-table-bg:#f5f8e5;
	--bs-table-border-color:#dddfce;
	--bs-table-striped-bg:#e9ecda;
	--bs-table-striped-color:#000;
	--bs-table-active-bg:#dddfce;
	--bs-table-active-color:#000;
	--bs-table-hover-bg:#e3e5d4;
	--bs-table-hover-color:#000
	}
	.table-dark, .table-light {
		color:var(--bs-table-color);
		border-color:var(--bs-table-border-color)
	}
	.table-dark {
	--bs-table-color:#fff;
	--bs-table-bg:#212529;
	--bs-table-border-color:#373b3e;
	--bs-table-striped-bg:#2c3034;
	--bs-table-striped-color:#fff;
	--bs-table-active-bg:#373b3e;
	--bs-table-active-color:#fff;
	--bs-table-hover-bg:#323539;
	--bs-table-hover-color:#fff
	}
	.table-responsive {
		overflow-x:auto;
		-webkit-overflow-scrolling:touch
	}
	@media(max-width:575.98px) {
	.table-responsive-sm {
		overflow-x:auto;
		-webkit-overflow-scrolling:touch
	}
	}
	@media(max-width:767.98px) {
	.table-responsive-md {
		overflow-x:auto;
		-webkit-overflow-scrolling:touch
	}
	}
	@media(max-width:991.98px) {
	.table-responsive-lg {
		overflow-x:auto;
		-webkit-overflow-scrolling:touch
	}
	}
	@media(max-width:1199.98px) {
	.table-responsive-xl {
		overflow-x:auto;
		-webkit-overflow-scrolling:touch
	}
	}
	@media(max-width:1399.98px) {
	.table-responsive-xxl {
		overflow-x:auto;
		-webkit-overflow-scrolling:touch
	}
	}
	.form-label {
	margin-bottom:.5rem
	}
	.col-form-label {
	padding-top:calc(.375rem + var(--bs-border-width));
	padding-bottom:calc(.375rem + var(--bs-border-width));
		margin-bottom:0;
		font-size:inherit;
		line-height:1.5
	}
	.col-form-label-lg {
	padding-top:calc(.5rem + var(--bs-border-width));
	padding-bottom:calc(.5rem + var(--bs-border-width));
		font-size:1.25rem
	}
	.col-form-label-sm {
	padding-top:calc(.25rem + var(--bs-border-width));
	padding-bottom:calc(.25rem + var(--bs-border-width));
	font-size:.875rem
	}
	.form-text {
	margin-top:.25rem;
		font-size:.875em;
		color:var(--bs-secondary-color)
	}
	.form-control {
		display:block;
		width:100%;
	padding:.375rem .75rem;
		font-size:1rem;
		font-weight:400;
		line-height:1.5;
		color:var(--bs-body-color);
		appearance:none;
		background-color:var(--bs-body-bg);
		background-clip:padding-box;
		border:var(--bs-border-width) solid var(--bs-border-color);
		border-radius:var(--bs-border-radius);
		transition:border-color .15s ease-in-out, box-shadow .15s ease-in-out
	}
	@media(prefers-reduced-motion:reduce) {
	.form-control {
		transition:none
	}
	}
	.form-control[type=file] {
		overflow:hidden
	}
	.form-control[type=file]:not(:disabled):not([readonly]) {
		cursor:pointer
	}
	.form-control:focus {
		color:var(--bs-body-color);
		background-color:var(--bs-body-bg);
		border-color:#f0fab2;
		outline:0;
	box-shadow:0 0 0 .25rem rgba(224,244,101,.25)
	}
	.form-control::-webkit-date-and-time-value {
	min-width:85px;
	height:1.5em;
	margin:0
	}
	.form-control::-webkit-datetime-edit {
	display:block;
	padding:0
	}
	.form-control::placeholder {
	color:var(--bs-secondary-color);
	opacity:1
	}
	.form-control:disabled {
		background-color:var(--bs-secondary-bg);
		opacity:1
	}
	.form-control::file-selector-button {
	padding:.375rem .75rem;
	margin:-.375rem -.75rem;
	margin-inline-end:.75rem;
	color:var(--bs-body-color);
	background-color:var(--bs-tertiary-bg);
	pointer-events:none;
	border:0 solid;
	border-color:inherit;
	border-inline-end-width:var(--bs-border-width);
	border-radius:0;
	transition:color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
	}
	@media(prefers-reduced-motion:reduce) {
	.form-control::file-selector-button {
	transition:none
	}
	}
	.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
	background-color:var(--bs-secondary-bg)
	}
	.form-control-plaintext {
		display:block;
		width:100%;
	padding:.375rem 0;
		margin-bottom:0;
		line-height:1.5;
		color:var(--bs-body-color);
		background-color:transparent;
		border-width:medium;
		border-left:0 solid transparent;
		border-bottom:var(--bs-border-width) solid transparent;
		border-right:0 solid transparent;
		border-top:var(--bs-border-width) solid transparent
	}
	.form-control-plaintext:focus {
		outline:0
	}
	.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
		padding-right:0;
		padding-left:0
	}
	.form-control-sm {
	min-height:calc(1.5em + .5rem + var(--bs-border-width)*2);
	padding:.25rem .5rem;
	font-size:.875rem;
		border-radius:var(--bs-border-radius-sm)
	}
	.form-control-sm::file-selector-button {
	padding:.25rem .5rem;
	margin:-.25rem -.5rem;
	margin-inline-end:.5rem
	}
	.form-control-lg {
	min-height:calc(1.5em + 1rem + var(--bs-border-width)*2);
	padding:.5rem 1rem;
		font-size:1.25rem;
		border-radius:var(--bs-border-radius-lg)
	}
	.form-control-lg::file-selector-button {
	padding:.5rem 1rem;
	margin:-.5rem -1rem;
	margin-inline-end:1rem
	}
	textarea.form-control {
	min-height:calc(1.5em + .75rem + var(--bs-border-width)*2)
	}
	textarea.form-control-sm {
	min-height:calc(1.5em + .5rem + var(--bs-border-width)*2)
	}
	textarea.form-control-lg {
	min-height:calc(1.5em + 1rem + var(--bs-border-width)*2)
	}
	.form-control-color {
		width:3rem;
	height:calc(1.5em + .75rem + var(--bs-border-width)*2);
	padding:.375rem
	}
	.form-control-color:not(:disabled):not([readonly]) {
		cursor:pointer
	}
	.form-control-color::-moz-color-swatch {
	border:0!important;
	border-radius:var(--bs-border-radius)
	}
	.form-control-color::-webkit-color-swatch {
	border:0!important;
	border-radius:var(--bs-border-radius)
	}
	.form-control-color.form-control-sm {
	height:calc(1.5em + .5rem + var(--bs-border-width)*2)
	}
	.form-control-color.form-control-lg {
	height:calc(1.5em + 1rem + var(--bs-border-width)*2)
	}
	.form-select {
	--bs-form-select-bg-img:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
		display:block;
		width:100%;
	padding:.375rem 2.25rem .375rem .75rem;
		font-size:1rem;
		font-weight:400;
		line-height:1.5;
		color:var(--bs-body-color);
		appearance:none;
		background-color:var(--bs-body-bg);
		background-image:var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
		background-repeat:no-repeat;
	background-position:right .75rem center;
		background-size:16px 12px;
		border:var(--bs-border-width) solid var(--bs-border-color);
		border-radius:var(--bs-border-radius);
		transition:border-color .15s ease-in-out, box-shadow .15s ease-in-out
	}
	@media(prefers-reduced-motion:reduce) {
	.form-select {
		transition:none
	}
	}
	.form-select:focus {
		border-color:#f0fab2;
		outline:0;
	box-shadow:0 0 0 .25rem rgba(224,244,101,.25)
	}
	.form-select[multiple], .form-select[size]:not([size="1"]) {
	padding-right:.75rem;
		background-image:none
	}
	.form-select:disabled {
		background-color:var(--bs-secondary-bg)
	}
	.form-select:-moz-focusring {
	color:transparent;
	text-shadow:0 0 0 var(--bs-body-color)
	}
	.form-select-sm {
	padding-top:.25rem;
	padding-bottom:.25rem;
	padding-left:.5rem;
	font-size:.875rem;
		border-radius:var(--bs-border-radius-sm)
	}
	.form-select-lg {
	padding-top:.5rem;
	padding-bottom:.5rem;
		padding-left:1rem;
		font-size:1.25rem;
		border-radius:var(--bs-border-radius-lg)
	}
	[data-bs-theme=dark] .form-select {
	--bs-form-select-bg-img:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E")
	}
	.form-check {
		display:block;
		min-height:1.5rem;
		padding-left:1.5em;
	margin-bottom:.125rem
	}
	.form-check .form-check-input {
		float:left;
		margin-left:-1.5em
	}
	.form-check-reverse {
		padding-right:1.5em;
		padding-left:0;
		text-align:right
	}
	.form-check-reverse .form-check-input {
		float:right;
		margin-right:-1.5em;
		margin-left:0
	}
	.form-check-input {
	--bs-form-check-bg:var(--bs-body-bg);
		width:1em;
		height:1em;
		margin-top:.25em;
		vertical-align:top;
		appearance:none;
		background-color:var(--bs-form-check-bg);
		background-image:var(--bs-form-check-bg-image);
		background-repeat:no-repeat;
		background-position:50%;
		background-size:contain;
		border:var(--bs-border-width) solid var(--bs-border-color);
		print-color-adjust:exact
	}
	.form-check-input[type=checkbox] {
		border-radius:.25em
	}
	.form-check-input[type=radio] {
		border-radius:50%
	}
	.form-check-input:active {
		filter:brightness(90%)
	}
	.form-check-input:focus {
		border-color:#f0fab2;
		outline:0;
	box-shadow:0 0 0 .25rem rgba(224,244,101,.25)
	}
	.form-check-input:checked {
		background-color:#e6c43a;
		border-color:#e6c43a
	}
	.form-check-input:checked[type=checkbox] {
	--bs-form-check-bg-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E")
	}
	.form-check-input:checked[type=radio] {
	--bs-form-check-bg-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E")
	}
	.form-check-input[type=checkbox]:indeterminate {
		background-color:#e6c43a;
		border-color:#e6c43a;
	--bs-form-check-bg-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E")
	}
	.form-check-input:disabled {
		pointer-events:none;
		filter:none;
		opacity:.5
	}
	.form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label {
	cursor:default;
	opacity:.5
	}
	.form-switch {
		padding-left:2.5em
	}
	.form-switch .form-check-input {
	--bs-form-switch-bg:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3E%3C/svg%3E");
		width:2em;
		margin-left:-2.5em;
		background-image:var(--bs-form-switch-bg);
		background-position:0;
		border-radius:2em;
		transition:background-position .15s ease-in-out
	}
	@media(prefers-reduced-motion:reduce) {
	.form-switch .form-check-input {
		transition:none
	}
	}
	.form-switch .form-check-input:focus {
	--bs-form-switch-bg:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23f0fab2'/%3E%3C/svg%3E")
	}
	.form-switch .form-check-input:checked {
		background-position:100%;
	--bs-form-switch-bg:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
	}
	.form-switch.form-check-reverse {
		padding-right:2.5em;
		padding-left:0
	}
	.form-switch.form-check-reverse .form-check-input {
		margin-right:-2.5em;
		margin-left:0
	}
	.form-check-inline {
		display:inline-block;
		margin-right:1rem
	}
	.btn-check {
		position:absolute;
		clip:rect(0,0,0,0);
		pointer-events:none
	}
	.btn-check:disabled+.btn, .btn-check[disabled]+.btn {
		pointer-events:none;
		filter:none;
		opacity:.65
	}
	[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
	--bs-form-switch-bg:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(255, 255, 255, 0.25)'/%3E%3C/svg%3E")
	}
	.form-range {
		width:100%;
		height:1.5rem;
		padding:0;
		appearance:none;
		background-color:transparent
	}
	.form-range:focus {
		outline:0
	}
	.form-range:focus::-webkit-slider-thumb {
	box-shadow:0 0 0 1px #fff, 0 0 0 .25rem rgba(224,244,101,.25)
	}
	.form-range:focus::-moz-range-thumb {
	box-shadow:0 0 0 1px #fff, 0 0 0 .25rem rgba(224,244,101,.25)
	}
	.form-range::-moz-focus-outer {
	border:0
	}
	.form-range::-webkit-slider-thumb {
	width:1rem;
	height:1rem;
	margin-top:-.25rem;
	appearance:none;
	background-color:#e6c43a;
	border:0;
	border-radius:1rem;
	transition:background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
	}
	@media(prefers-reduced-motion:reduce) {
	.form-range::-webkit-slider-thumb {
	transition:none
	}
	}
	.form-range::-webkit-slider-thumb:active {
	background-color:#f6fcd1
	}
	.form-range::-webkit-slider-runnable-track {
	width:100%;
	height:.5rem;
	color:transparent;
	cursor:pointer;
	background-color:var(--bs-tertiary-bg);
	border-color:transparent;
	border-radius:1rem
	}
	.form-range::-moz-range-thumb {
	width:1rem;
	height:1rem;
	appearance:none;
	background-color:#e6c43a;
	border:0;
	border-radius:1rem;
	transition:background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
	}
	@media(prefers-reduced-motion:reduce) {
	.form-range::-moz-range-thumb {
	transition:none
	}
	}
	.form-range::-moz-range-thumb:active {
	background-color:#f6fcd1
	}
	.form-range::-moz-range-track {
	width:100%;
	height:.5rem;
	color:transparent;
	cursor:pointer;
	background-color:var(--bs-tertiary-bg);
	border-color:transparent;
	border-radius:1rem
	}
	.form-range:disabled {
		pointer-events:none
	}
	.form-range:disabled::-webkit-slider-thumb {
	background-color:var(--bs-secondary-color)
	}
	.form-range:disabled::-moz-range-thumb {
	background-color:var(--bs-secondary-color)
	}
	.form-floating {
		position:relative
	}
	.form-floating>.form-control, .form-floating>.form-control-plaintext, .form-floating>.form-select {
	height:calc(3.5rem + var(--bs-border-width)*2);
	min-height:calc(3.5rem + var(--bs-border-width)*2);
		line-height:1.25
	}
	.form-floating>label {
		position:absolute;
		top:0;
		left:0;
		z-index:2;
		height:100%;
	padding:1rem .75rem;
		overflow:hidden;
		text-align:start;
		text-overflow:ellipsis;
		white-space:nowrap;
		pointer-events:none;
		border:var(--bs-border-width) solid transparent;
		transform-origin:0 0;
		transition:opacity .1s ease-in-out, transform .1s ease-in-out
	}
	@media(prefers-reduced-motion:reduce) {
	.form-floating>label {
		transition:none
	}
	}
	.form-floating>.form-control, .form-floating>.form-control-plaintext {
	padding:1rem .75rem
	}
	.form-floating>.form-control-plaintext::placeholder, .form-floating>.form-control::placeholder {
	color:transparent
	}
	.form-floating>.form-control-plaintext:focus, .form-floating>.form-control-plaintext:not(:placeholder-shown), .form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
		padding-top:1.625rem;
	padding-bottom:.625rem
	}
	.form-floating>.form-control-plaintext:-webkit-autofill, .form-floating>.form-control:-webkit-autofill {
	padding-top:1.625rem;
	padding-bottom:.625rem
	}
	.form-floating>.form-select {
		padding-top:1.625rem;
	padding-bottom:.625rem
	}
	.form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
	color:rgba(var(--bs-body-color-rgb), .65);
	transform:scale(.85) translateY(-.5rem) translateX(.15rem)
	}
	.form-floating>.form-control-plaintext~label:after, .form-floating>.form-control:focus~label:after, .form-floating>.form-control:not(:placeholder-shown)~label:after, .form-floating>.form-select~label:after {
	position:absolute;
	inset:1rem .375rem;
	z-index:-1;
	height:1.5em;
	content:"";
	background-color:var(--bs-body-bg);
	border-radius:var(--bs-border-radius)
	}
	.form-floating>.form-control:-webkit-autofill~label {
	color:rgba(var(--bs-body-color-rgb), .65);
	transform:scale(.85) translateY(-.5rem) translateX(.15rem)
	}
	.form-floating>.form-control-plaintext~label {
	border-left-width:0;
	border-bottom-width:var(--bs-border-width);
	border-right-width:0;
	border-top-width:var(--bs-border-width)
	}
	.form-floating>.form-control:disabled~label, .form-floating>:disabled~label {
	color:#6c757d
	}
	.form-floating>.form-control:disabled~label:after, .form-floating>:disabled~label:after {
	background-color:var(--bs-secondary-bg)
	}
	.input-group {
		position:relative;
		display:flex;
		flex-wrap:wrap;
		align-items:stretch;
		width:100%
	}
	.input-group>.form-control, .input-group>.form-floating, .input-group>.form-select {
		position:relative;
		flex:1 1 auto;
		width:1%;
		min-width:0
	}
	.input-group>.form-control:focus, .input-group>.form-floating:focus-within, .input-group>.form-select:focus {
	z-index:5
	}
	.input-group .btn {
		position:relative;
		z-index:2
	}
	.input-group .btn:focus {
		z-index:5
	}
	.input-group-text {
		display:flex;
		align-items:center;
	padding:.375rem .75rem;
		font-size:1rem;
		font-weight:400;
		line-height:1.5;
		color:var(--bs-body-color);
		text-align:center;
		white-space:nowrap;
		background-color:var(--bs-tertiary-bg);
		border:var(--bs-border-width) solid var(--bs-border-color);
		border-radius:var(--bs-border-radius)
	}
	.input-group-lg>.btn, .input-group-lg>.form-control, .input-group-lg>.form-select, .input-group-lg>.input-group-text {
	padding:.5rem 1rem;
		font-size:1.25rem;
		border-radius:var(--bs-border-radius-lg)
	}
	.input-group-sm>.btn, .input-group-sm>.form-control, .input-group-sm>.form-select, .input-group-sm>.input-group-text {
	padding:.25rem .5rem;
	font-size:.875rem;
		border-radius:var(--bs-border-radius-sm)
	}
	.input-group-lg>.form-select, .input-group-sm>.form-select {
		padding-right:3rem
	}
	.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4), .input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control, .input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select, .input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control, .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select, .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
		border-top-right-radius:0;
		border-bottom-right-radius:0
	}
	.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
	margin-left:calc(var(--bs-border-width)*-1);
		border-top-left-radius:0;
		border-bottom-left-radius:0
	}
	.input-group>.form-floating:not(:first-child)>.form-control, .input-group>.form-floating:not(:first-child)>.form-select {
		border-top-left-radius:0;
		border-bottom-left-radius:0
	}
	.valid-feedback {
		display:none;
		width:100%;
	margin-top:.25rem;
		font-size:.875em;
		color:var(--bs-form-valid-color)
	}
	.valid-tooltip {
		position:absolute;
		top:100%;
		z-index:5;
		display:none;
		max-width:100%;
	padding:.25rem .5rem;
	margin-top:.1rem;
	font-size:.875rem;
		color:#fff;
		background-color:var(--bs-success);
		border-radius:var(--bs-border-radius)
	}
	.is-valid~.valid-feedback, .is-valid~.valid-tooltip, .was-validated :valid~.valid-feedback, .was-validated :valid~.valid-tooltip {
	display:block
	}
	.form-control.is-valid, .was-validated .form-control:valid {
	border-color:var(--bs-form-valid-border-color);
	padding-right:calc(1.5em + .75rem);
	background-image:url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMzNUNDNkMnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=);
	background-repeat:no-repeat;
	background-position:right calc(.375em + .1875rem) center;
	background-size:calc(.75em + .375rem) calc(.75em + .375rem)
	}
	.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
	border-color:var(--bs-form-valid-border-color);
	box-shadow:0 0 0 .25rem rgba(var(--bs-success-rgb), .25)
	}
	.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
	padding-right:calc(1.5em + .75rem);
	background-position:top calc(.375em + .1875rem) right calc(.375em + .1875rem)
	}
	.form-select.is-valid, .was-validated .form-select:valid {
	border-color:var(--bs-form-valid-border-color)
	}
	.form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"] {
	--bs-form-select-bg-icon:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2335CC6C' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
	padding-right:4.125rem;
	background-position:right .75rem center, center right 2.25rem;
	background-size:16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
	}
	.form-select.is-valid:focus, .was-validated .form-select:valid:focus {
	border-color:var(--bs-form-valid-border-color);
	box-shadow:0 0 0 .25rem rgba(var(--bs-success-rgb), .25)
	}
	.form-control-color.is-valid, .was-validated .form-control-color:valid {
	width:calc(3.75rem + 1.5em)
	}
	.form-check-input.is-valid, .was-validated .form-check-input:valid {
	border-color:var(--bs-form-valid-border-color)
	}
	.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
	background-color:var(--bs-form-valid-color)
	}
	.form-check-input.is-valid:focus, .was-validated .form-check-input:valid:focus {
	box-shadow:0 0 0 .25rem rgba(var(--bs-success-rgb), .25)
	}
	.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
	color:var(--bs-form-valid-color)
	}
	.form-check-inline .form-check-input~.valid-feedback {
	margin-left:.5em
	}
	.input-group>.form-control:not(:focus).is-valid, .input-group>.form-floating:not(:focus-within).is-valid, .input-group>.form-select:not(:focus).is-valid, .was-validated .input-group>.form-control:not(:focus):valid, .was-validated .input-group>.form-floating:not(:focus-within):valid, .was-validated .input-group>.form-select:not(:focus):valid {
	z-index:3
	}
	.invalid-feedback {
		display:none;
		width:100%;
	margin-top:.25rem;
		font-size:.875em;
		color:var(--bs-form-invalid-color)
	}
	.invalid-tooltip {
		position:absolute;
		top:100%;
		z-index:5;
		display:none;
		max-width:100%;
	padding:.25rem .5rem;
	margin-top:.1rem;
	font-size:.875rem;
		color:#fff;
		background-color:var(--bs-danger);
		border-radius:var(--bs-border-radius)
	}
	.is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
	display:block
	}
	.form-control.is-invalid, .was-validated .form-control:invalid {
	border-color:var(--bs-form-invalid-border-color);
	padding-right:calc(1.5em + .75rem);
	background-image:url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMicgaGVpZ2h0PScxMicgZmlsbD0nbm9uZScgc3Ryb2tlPScjZGMzNTQ1Jz48Y2lyY2xlIGN4PSc2JyBjeT0nNicgcj0nNC41Jy8+PHBhdGggc3Ryb2tlLWxpbmVqb2luPSdyb3VuZCcgZD0nTTUuOCAzLjZoLjRMNiA2LjV6Jy8+PGNpcmNsZSBjeD0nNicgY3k9JzguMicgcj0nLjYnIGZpbGw9JyNkYzM1NDUnIHN0cm9rZT0nbm9uZScvPjwvc3ZnPg==);
	background-repeat:no-repeat;
	background-position:right calc(.375em + .1875rem) center;
	background-size:calc(.75em + .375rem) calc(.75em + .375rem)
	}
	.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
	border-color:var(--bs-form-invalid-border-color);
	box-shadow:0 0 0 .25rem rgba(var(--bs-danger-rgb), .25)
	}
	.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
	padding-right:calc(1.5em + .75rem);
	background-position:top calc(.375em + .1875rem) right calc(.375em + .1875rem)
	}
	.form-select.is-invalid, .was-validated .form-select:invalid {
	border-color:var(--bs-form-invalid-border-color)
	}
	.form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"] {
	--bs-form-select-bg-icon:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
	padding-right:4.125rem;
	background-position:right .75rem center, center right 2.25rem;
	background-size:16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
	}
	.form-select.is-invalid:focus, .was-validated .form-select:invalid:focus {
	border-color:var(--bs-form-invalid-border-color);
	box-shadow:0 0 0 .25rem rgba(var(--bs-danger-rgb), .25)
	}
	.form-control-color.is-invalid, .was-validated .form-control-color:invalid {
	width:calc(3.75rem + 1.5em)
	}
	.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
	border-color:var(--bs-form-invalid-border-color)
	}
	.form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
	background-color:var(--bs-form-invalid-color)
	}
	.form-check-input.is-invalid:focus, .was-validated .form-check-input:invalid:focus {
	box-shadow:0 0 0 .25rem rgba(var(--bs-danger-rgb), .25)
	}
	.form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
	color:var(--bs-form-invalid-color)
	}
	.form-check-inline .form-check-input~.invalid-feedback {
	margin-left:.5em
	}
	.input-group>.form-control:not(:focus).is-invalid, .input-group>.form-floating:not(:focus-within).is-invalid, .input-group>.form-select:not(:focus).is-invalid, .was-validated .input-group>.form-control:not(:focus):invalid, .was-validated .input-group>.form-floating:not(:focus-within):invalid, .was-validated .input-group>.form-select:not(:focus):invalid {
	z-index:4
	}
	.btn {
	--bs-btn-padding-x:32px;
	--bs-btn-padding-y:17px;
	--bs-btn-font-size:1rem;
	--bs-btn-font-weight:400;
	--bs-btn-line-height:1.5;
	--bs-btn-color:var(--bs-body-color);
	--bs-btn-bg:transparent;
	--bs-btn-border-width:var(--bs-border-width);
	--bs-btn-border-color:transparent;
	--bs-btn-border-radius:var(--bs-border-radius);
	--bs-btn-hover-border-color:transparent;
	--bs-btn-box-shadow:inset 0 1px 0 hsla(0,0%,100%,0.15), 0 1px 1px rgba(0,0,0,0.075);
	--bs-btn-disabled-opacity:0.65;
	--bs-btn-focus-box-shadow:0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
		display:inline-block;
		padding:var(--bs-btn-padding-y) var(--bs-btn-padding-x);
		font-family:var(--bs-btn-font-family);
		font-size:var(--bs-btn-font-size);
		font-weight:var(--bs-btn-font-weight);
		line-height:var(--bs-btn-line-height);
		color:var(--bs-btn-color);
		text-align:center;
		text-decoration:none;
		vertical-align:middle;
		cursor:pointer;
		user-select:none;
		border:var(--bs-btn-border-width) solid var(--bs-btn-border-color);
		border-radius:var(--bs-btn-border-radius);
		background-color:var(--bs-btn-bg);
		transition:color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
	}
	@media(prefers-reduced-motion:reduce) {
	.btn {
		transition:none
	}
	}
	.btn:hover {
		color:var(--bs-btn-hover-color);
		background-color:var(--bs-btn-hover-bg);
		border-color:var(--bs-btn-hover-border-color)
	}
	.btn-check+.btn:hover {
		color:var(--bs-btn-color);
		background-color:var(--bs-btn-bg);
		border-color:var(--bs-btn-border-color)
	}
	.btn:focus-visible {
	color:var(--bs-btn-hover-color);
	background-color:var(--bs-btn-hover-bg)
	}
	.btn-check:focus-visible+.btn, .btn:focus-visible {
	border-color:var(--bs-btn-hover-border-color);
	outline:0;
	box-shadow:var(--bs-btn-focus-box-shadow)
	}
	.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
		color:var(--bs-btn-active-color);
		background-color:var(--bs-btn-active-bg);
		border-color:var(--bs-btn-active-border-color)
	}
	.btn-check:checked+.btn:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible, .btn:first-child:active:focus-visible, :not(.btn-check)+.btn:active:focus-visible {
	box-shadow:var(--bs-btn-focus-box-shadow)
	}
	.btn.disabled, .btn:disabled, fieldset:disabled .btn {
		color:var(--bs-btn-disabled-color);
		pointer-events:none;
		background-color:var(--bs-btn-disabled-bg);
		border-color:var(--bs-btn-disabled-border-color);
		opacity:var(--bs-btn-disabled-opacity)
	}
	.btn-primary {
	--bs-btn-color:#000;
	--bs-btn-bg:#e6c43a;
	--bs-btn-border-color:#e6c43a;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#e5f67c;
	--bs-btn-hover-border-color:#e3f574;
	--bs-btn-focus-shadow-rgb:190, 207, 86;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#e6f684;
	--bs-btn-active-border-color:#e3f574;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#000;
	--bs-btn-disabled-bg:#e6c43a;
	--bs-btn-disabled-border-color:#e6c43a
	}
	.btn-secondary {
	--bs-btn-color:#fff;
	--bs-btn-bg:#696969;
	--bs-btn-border-color:#696969;
	--bs-btn-hover-color:#fff;
	--bs-btn-hover-bg:#595959;
	--bs-btn-hover-border-color:#545454;
	--bs-btn-focus-shadow-rgb:128, 128, 128;
	--bs-btn-active-color:#fff;
	--bs-btn-active-bg:#545454;
	--bs-btn-active-border-color:#4f4f4f;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#fff;
	--bs-btn-disabled-bg:#696969;
	--bs-btn-disabled-border-color:#696969
	}
	.btn-success {
	--bs-btn-color:#000;
	--bs-btn-bg:#35cc6c;
	--bs-btn-border-color:#35cc6c;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#53d482;
	--bs-btn-hover-border-color:#49d17b;
	--bs-btn-focus-shadow-rgb:45, 173, 92;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#5dd689;
	--bs-btn-active-border-color:#49d17b;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#000;
	--bs-btn-disabled-bg:#35cc6c;
	--bs-btn-disabled-border-color:#35cc6c
	}
	.btn-info {
	--bs-btn-color:#000;
	--bs-btn-bg:#fff;
	--bs-btn-border-color:#fff;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#fff;
	--bs-btn-hover-border-color:#fff;
	--bs-btn-focus-shadow-rgb:217, 217, 217;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#fff;
	--bs-btn-active-border-color:#fff;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#000;
	--bs-btn-disabled-bg:#fff;
	--bs-btn-disabled-border-color:#fff
	}
	.btn-light {
	--bs-btn-color:#000;
	--bs-btn-bg:#f5f8e5;
	--bs-btn-border-color:#f5f8e5;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#d0d3c3;
	--bs-btn-hover-border-color:#c4c6b7;
	--bs-btn-focus-shadow-rgb:208, 211, 195;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#c4c6b7;
	--bs-btn-active-border-color:#b8baac;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#000;
	--bs-btn-disabled-bg:#f5f8e5;
	--bs-btn-disabled-border-color:#f5f8e5
	}
	.btn-danger {
	--bs-btn-color:#000;
	--bs-btn-bg:#f30d0d;
	--bs-btn-border-color:#f30d0d;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#f53131;
	--bs-btn-hover-border-color:#f42525;
	--bs-btn-focus-shadow-rgb:207, 11, 11;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#f53d3d;
	--bs-btn-active-border-color:#f42525;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#000;
	--bs-btn-disabled-bg:#f30d0d;
	--bs-btn-disabled-border-color:#f30d0d
	}
	.btn-dark {
	--bs-btn-color:#fff;
	--bs-btn-bg:#000;
	--bs-btn-border-color:#000;
	--bs-btn-hover-color:#fff;
	--bs-btn-hover-bg:#262626;
	--bs-btn-hover-border-color:#1a1a1a;
	--bs-btn-focus-shadow-rgb:38, 38, 38;
	--bs-btn-active-color:#fff;
	--bs-btn-active-bg:#333;
	--bs-btn-active-border-color:#1a1a1a;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#fff;
	--bs-btn-disabled-bg:#000;
	--bs-btn-disabled-border-color:#000
	}
	.btn-altdark {
	--bs-btn-color:#fff;
	--bs-btn-bg:#222;
	--bs-btn-border-color:#222;
	--bs-btn-hover-color:#fff;
	--bs-btn-hover-bg:#1d1d1d;
	--bs-btn-hover-border-color:#1b1b1b;
	--bs-btn-focus-shadow-rgb:67, 67, 67;
	--bs-btn-active-color:#fff;
	--bs-btn-active-bg:#1b1b1b;
	--bs-btn-active-border-color:#1a1a1a;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#fff;
	--bs-btn-disabled-bg:#222;
	--bs-btn-disabled-border-color:#222
	}
	.btn-sky {
	--bs-btn-color:#000;
	--bs-btn-bg:#136495;
	--bs-btn-border-color:#136495;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#39cfbf;
	--bs-btn-hover-border-color:#2dcdbc;
	--bs-btn-focus-shadow-rgb:19, 169, 153;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#45d2c3;
	--bs-btn-active-border-color:#2dcdbc;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#000;
	--bs-btn-disabled-bg:#136495;
	--bs-btn-disabled-border-color:#136495
	}
	.btn-mute {
	--bs-btn-color:#000;
	--bs-btn-bg:#f6f6f6;
	--bs-btn-border-color:#f6f6f6;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#f7f7f7;
	--bs-btn-hover-border-color:#f7f7f7;
	--bs-btn-focus-shadow-rgb:209, 209, 209;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#f8f8f8;
	--bs-btn-active-border-color:#f7f7f7;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#000;
	--bs-btn-disabled-bg:#f6f6f6;
	--bs-btn-disabled-border-color:#f6f6f6
	}
	.btn-tertiary {
	--bs-btn-color:#000;
	--bs-btn-bg:#136495;
	--bs-btn-border-color:#136495;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#fe7226;
	--bs-btn-hover-border-color:#fe6a1a;
	--bs-btn-focus-shadow-rgb:216, 76, 0;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#fe7a33;
	--bs-btn-active-border-color:#fe6a1a;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#000;
	--bs-btn-disabled-bg:#136495;
	--bs-btn-disabled-border-color:#136495
	}
	.btn-outline-primary {
	--bs-btn-color:#e6c43a;
	--bs-btn-border-color:#e6c43a;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#e6c43a;
	--bs-btn-hover-border-color:#e6c43a;
	--bs-btn-focus-shadow-rgb:224, 244, 101;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#e6c43a;
	--bs-btn-active-border-color:#e6c43a;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#e6c43a;
	--bs-btn-disabled-bg:transparent;
	--bs-btn-disabled-border-color:#e6c43a;
	--bs-gradient:none
	}
	.btn-outline-secondary {
	--bs-btn-color:#696969;
	--bs-btn-border-color:#696969;
	--bs-btn-hover-color:#fff;
	--bs-btn-hover-bg:#696969;
	--bs-btn-hover-border-color:#696969;
	--bs-btn-focus-shadow-rgb:105, 105, 105;
	--bs-btn-active-color:#fff;
	--bs-btn-active-bg:#696969;
	--bs-btn-active-border-color:#696969;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#696969;
	--bs-btn-disabled-bg:transparent;
	--bs-btn-disabled-border-color:#696969;
	--bs-gradient:none
	}
	.btn-outline-success {
	--bs-btn-color:#35cc6c;
	--bs-btn-border-color:#35cc6c;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#35cc6c;
	--bs-btn-hover-border-color:#35cc6c;
	--bs-btn-focus-shadow-rgb:53, 204, 108;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#35cc6c;
	--bs-btn-active-border-color:#35cc6c;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#35cc6c;
	--bs-btn-disabled-bg:transparent;
	--bs-btn-disabled-border-color:#35cc6c;
	--bs-gradient:none
	}
	.btn-outline-info {
	--bs-btn-color:#fff;
	--bs-btn-border-color:#fff;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#fff;
	--bs-btn-hover-border-color:#fff;
	--bs-btn-focus-shadow-rgb:255, 255, 255;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#fff;
	--bs-btn-active-border-color:#fff;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#fff;
	--bs-btn-disabled-bg:transparent;
	--bs-btn-disabled-border-color:#fff;
	--bs-gradient:none
	}
	.btn-outline-light {
	--bs-btn-color:#f5f8e5;
	--bs-btn-border-color:#f5f8e5;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#f5f8e5;
	--bs-btn-hover-border-color:#f5f8e5;
	--bs-btn-focus-shadow-rgb:245, 248, 229;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#f5f8e5;
	--bs-btn-active-border-color:#f5f8e5;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#f5f8e5;
	--bs-btn-disabled-bg:transparent;
	--bs-btn-disabled-border-color:#f5f8e5;
	--bs-gradient:none
	}
	.btn-outline-danger {
	--bs-btn-color:#f30d0d;
	--bs-btn-border-color:#f30d0d;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#f30d0d;
	--bs-btn-hover-border-color:#f30d0d;
	--bs-btn-focus-shadow-rgb:243, 13, 13;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#f30d0d;
	--bs-btn-active-border-color:#f30d0d;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#f30d0d;
	--bs-btn-disabled-bg:transparent;
	--bs-btn-disabled-border-color:#f30d0d;
	--bs-gradient:none
	}
	.btn-outline-dark {
	--bs-btn-color:#000;
	--bs-btn-border-color:#000;
	--bs-btn-hover-color:#fff;
	--bs-btn-hover-bg:#000;
	--bs-btn-hover-border-color:#000;
	--bs-btn-focus-shadow-rgb:0, 0, 0;
	--bs-btn-active-color:#fff;
	--bs-btn-active-bg:#000;
	--bs-btn-active-border-color:#000;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#000;
	--bs-btn-disabled-bg:transparent;
	--bs-btn-disabled-border-color:#000;
	--bs-gradient:none
	}
	.btn-outline-altdark {
	--bs-btn-color:#222;
	--bs-btn-border-color:#222;
	--bs-btn-hover-color:#fff;
	--bs-btn-hover-bg:#222;
	--bs-btn-hover-border-color:#222;
	--bs-btn-focus-shadow-rgb:34, 34, 34;
	--bs-btn-active-color:#fff;
	--bs-btn-active-bg:#222;
	--bs-btn-active-border-color:#222;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#222;
	--bs-btn-disabled-bg:transparent;
	--bs-btn-disabled-border-color:#222;
	--bs-gradient:none
	}
	.btn-outline-sky {
	--bs-btn-color:#136495;
	--bs-btn-border-color:#136495;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#136495;
	--bs-btn-hover-border-color:#136495;
	--bs-btn-focus-shadow-rgb:19, 100, 149;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#136495;
	--bs-btn-active-border-color:#136495;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#136495;
	--bs-btn-disabled-bg:transparent;
	--bs-btn-disabled-border-color:#136495;
	--bs-gradient:none
	}
	.btn-outline-mute {
	--bs-btn-color:#f6f6f6;
	--bs-btn-border-color:#f6f6f6;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#f6f6f6;
	--bs-btn-hover-border-color:#f6f6f6;
	--bs-btn-focus-shadow-rgb:246, 246, 246;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#f6f6f6;
	--bs-btn-active-border-color:#f6f6f6;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#f6f6f6;
	--bs-btn-disabled-bg:transparent;
	--bs-btn-disabled-border-color:#f6f6f6;
	--bs-gradient:none
	}
	.btn-outline-tertiary {
	--bs-btn-color:#136495;
	--bs-btn-border-color:#136495;
	--bs-btn-hover-color:#000;
	--bs-btn-hover-bg:#136495;
	--bs-btn-hover-border-color:#136495;
	--bs-btn-focus-shadow-rgb:254, 89, 0;
	--bs-btn-active-color:#000;
	--bs-btn-active-bg:#136495;
	--bs-btn-active-border-color:#136495;
	--bs-btn-active-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	--bs-btn-disabled-color:#136495;
	--bs-btn-disabled-bg:transparent;
	--bs-btn-disabled-border-color:#136495;
	--bs-gradient:none
	}
	.btn-link {
	--bs-btn-font-weight:400;
	--bs-btn-color:var(--bs-link-color);
	--bs-btn-bg:transparent;
	--bs-btn-border-color:transparent;
	--bs-btn-hover-color:var(--bs-link-hover-color);
	--bs-btn-hover-border-color:transparent;
	--bs-btn-active-color:var(--bs-link-hover-color);
	--bs-btn-active-border-color:transparent;
	--bs-btn-disabled-color:#6c757d;
	--bs-btn-disabled-border-color:transparent;
	--bs-btn-box-shadow:0 0 0 #000;
	--bs-btn-focus-shadow-rgb:190, 207, 86;
		text-decoration:underline
	}
	.btn-link:focus-visible {
	color:var(--bs-btn-color)
	}
	.btn-link:hover {
		color:var(--bs-btn-hover-color)
	}
	.btn-group-lg>.btn, .btn-lg {
	--bs-btn-padding-y:0.5rem;
	--bs-btn-padding-x:1rem;
	--bs-btn-font-size:1.25rem;
	--bs-btn-border-radius:var(--bs-border-radius-lg)
	}
	.btn-group-sm>.btn, .btn-sm {
	--bs-btn-padding-y:0.25rem;
	--bs-btn-padding-x:0.5rem;
	--bs-btn-font-size:0.875rem;
	--bs-btn-border-radius:var(--bs-border-radius-sm)
	}
	.fade {
		transition:opacity .15s linear
	}
	@media(prefers-reduced-motion:reduce) {
	.fade {
		transition:none
	}
	}
	.fade:not(.show) {
		opacity:0
	}
	.collapse:not(.show) {
		display:none
	}
	.collapsing {
		height:0;
		overflow:hidden;
		transition:height .35s ease
	}
	@media(prefers-reduced-motion:reduce) {
	.collapsing {
		transition:none
	}
	}
	.collapsing.collapse-horizontal {
		width:0;
		height:auto;
		transition:width .35s ease
	}
	@media(prefers-reduced-motion:reduce) {
	.collapsing.collapse-horizontal {
		transition:none
	}
	}
	.dropdown, .dropdown-center, .dropend, .dropstart, .dropup, .dropup-center {
		position:relative
	}
	.dropdown-toggle {
		white-space:nowrap
	}
	.dropdown-toggle:after {
		display:inline-block;
		margin-left:.255em;
		vertical-align:.255em;
		content:"";
		border-top:.3em solid;
		border-right:.3em solid transparent;
		border-bottom:0;
		border-left:.3em solid transparent
	}
	.dropdown-toggle:empty:after {
		margin-left:0
	}
	.dropdown-menu {
	--bs-dropdown-zindex:1000;
	--bs-dropdown-min-width:10rem;
	--bs-dropdown-padding-x:0;
	--bs-dropdown-padding-y:0.5rem;
	--bs-dropdown-spacer:0.125rem;
	--bs-dropdown-font-size:1rem;
	--bs-dropdown-color:var(--bs-body-color);
	--bs-dropdown-bg:var(--bs-body-bg);
	--bs-dropdown-border-color:var(--bs-border-color-translucent);
	--bs-dropdown-border-radius:var(--bs-border-radius);
	--bs-dropdown-border-width:var(--bs-border-width);
	--bs-dropdown-inner-border-radius:calc(var(--bs-border-radius) - var(--bs-border-width));
	--bs-dropdown-divider-bg:var(--bs-border-color-translucent);
	--bs-dropdown-divider-margin-y:0.5rem;
	--bs-dropdown-box-shadow:0px 15px 68px rgba(11,3,35,0.15);
	--bs-dropdown-link-color:var(--bs-body-color);
	--bs-dropdown-link-hover-color:var(--bs-body-color);
	--bs-dropdown-link-hover-bg:var(--bs-tertiary-bg);
	--bs-dropdown-link-active-color:#fff;
	--bs-dropdown-link-active-bg:#e6c43a;
	--bs-dropdown-link-disabled-color:var(--bs-tertiary-color);
	--bs-dropdown-item-padding-x:1rem;
	--bs-dropdown-item-padding-y:0.25rem;
	--bs-dropdown-header-color:#6c757d;
	--bs-dropdown-header-padding-x:1rem;
	--bs-dropdown-header-padding-y:0.5rem;
		position:absolute;
		z-index:var(--bs-dropdown-zindex);
		display:none;
		min-width:var(--bs-dropdown-min-width);
		padding:var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
		margin:0;
		font-size:var(--bs-dropdown-font-size);
		color:var(--bs-dropdown-color);
		text-align:left;
		list-style:none;
		background-color:var(--bs-dropdown-bg);
		background-clip:padding-box;
		border:var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
		border-radius:var(--bs-dropdown-border-radius)
	}
	.dropdown-menu[data-bs-popper] {
		top:100%;
		left:0;
		margin-top:var(--bs-dropdown-spacer)
	}
	.dropdown-menu-start {
	--bs-position:start
	}
	.dropdown-menu-start[data-bs-popper] {
		right:auto;
		left:0
	}
	.dropdown-menu-end {
	--bs-position:end
	}
	.dropdown-menu-end[data-bs-popper] {
		right:0;
		left:auto
	}
	@media(min-width:576px) {
	.dropdown-menu-sm-start {
	--bs-position:start
	}
	.dropdown-menu-sm-start[data-bs-popper] {
		right:auto;
		left:0
	}
	.dropdown-menu-sm-end {
	--bs-position:end
	}
	.dropdown-menu-sm-end[data-bs-popper] {
		right:0;
		left:auto
	}
	}
	@media(min-width:768px) {
	.dropdown-menu-md-start {
	--bs-position:start
	}
	.dropdown-menu-md-start[data-bs-popper] {
		right:auto;
		left:0
	}
	.dropdown-menu-md-end {
	--bs-position:end
	}
	.dropdown-menu-md-end[data-bs-popper] {
		right:0;
		left:auto
	}
	}
	@media(min-width:992px) {
	.dropdown-menu-lg-start {
	--bs-position:start
	}
	.dropdown-menu-lg-start[data-bs-popper] {
		right:auto;
		left:0
	}
	.dropdown-menu-lg-end {
	--bs-position:end
	}
	.dropdown-menu-lg-end[data-bs-popper] {
		right:0;
		left:auto
	}
	}
	@media(min-width:1200px) {
	.dropdown-menu-xl-start {
	--bs-position:start
	}
	.dropdown-menu-xl-start[data-bs-popper] {
		right:auto;
		left:0
	}
	.dropdown-menu-xl-end {
	--bs-position:end
	}
	.dropdown-menu-xl-end[data-bs-popper] {
		right:0;
		left:auto
	}
	}
	@media(min-width:1400px) {
	.dropdown-menu-xxl-start {
	--bs-position:start
	}
	.dropdown-menu-xxl-start[data-bs-popper] {
		right:auto;
		left:0
	}
	.dropdown-menu-xxl-end {
	--bs-position:end
	}
	.dropdown-menu-xxl-end[data-bs-popper] {
		right:0;
		left:auto
	}
	}
	.dropup .dropdown-menu[data-bs-popper] {
		top:auto;
		bottom:100%;
		margin-top:0;
		margin-bottom:var(--bs-dropdown-spacer)
	}
	.dropup .dropdown-toggle:after {
		display:inline-block;
		margin-left:.255em;
		vertical-align:.255em;
		content:"";
		border-top:0;
		border-right:.3em solid transparent;
		border-bottom:.3em solid;
		border-left:.3em solid transparent
	}
	.dropup .dropdown-toggle:empty:after {
		margin-left:0
	}
	.dropend .dropdown-menu[data-bs-popper] {
		top:0;
		right:auto;
		left:100%;
		margin-top:0;
		margin-left:var(--bs-dropdown-spacer)
	}
	.dropend .dropdown-toggle:after {
		display:inline-block;
		margin-left:.255em;
		vertical-align:.255em;
		content:"";
		border-top:.3em solid transparent;
		border-right:0;
		border-bottom:.3em solid transparent;
		border-left:.3em solid
	}
	.dropend .dropdown-toggle:empty:after {
		margin-left:0
	}
	.dropend .dropdown-toggle:after {
		vertical-align:0
	}
	.dropstart .dropdown-menu[data-bs-popper] {
		top:0;
		right:100%;
		left:auto;
		margin-top:0;
		margin-right:var(--bs-dropdown-spacer)
	}
	.dropstart .dropdown-toggle:after {
		display:inline-block;
		margin-left:.255em;
		vertical-align:.255em;
		content:"";
		display:none
	}
	.dropstart .dropdown-toggle:before {
		display:inline-block;
		margin-right:.255em;
		vertical-align:.255em;
		content:"";
		border-top:.3em solid transparent;
		border-right:.3em solid;
		border-bottom:.3em solid transparent
	}
	.dropstart .dropdown-toggle:empty:after {
		margin-left:0
	}
	.dropstart .dropdown-toggle:before {
		vertical-align:0
	}
	.dropdown-divider {
		height:0;
		margin:var(--bs-dropdown-divider-margin-y) 0;
		overflow:hidden;
		border-top:1px solid var(--bs-dropdown-divider-bg);
		opacity:1
	}
	.dropdown-item {
		display:block;
		width:100%;
		padding:var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
		clear:both;
		font-weight:400;
		color:var(--bs-dropdown-link-color);
		text-align:inherit;
		text-decoration:none;
		white-space:nowrap;
		background-color:transparent;
		border:0;
		border-radius:var(--bs-dropdown-item-border-radius, 0)
	}
	.dropdown-item:focus, .dropdown-item:hover {
		color:var(--bs-dropdown-link-hover-color);
		background-color:var(--bs-dropdown-link-hover-bg)
	}
	.dropdown-item.active, .dropdown-item:active {
		color:var(--bs-dropdown-link-active-color);
		text-decoration:none;
		background-color:var(--bs-dropdown-link-active-bg)
	}
	.dropdown-item.disabled, .dropdown-item:disabled {
		color:var(--bs-dropdown-link-disabled-color);
		pointer-events:none;
		background-color:transparent
	}
	.dropdown-menu.show {
		display:block
	}
	.dropdown-header {
		display:block;
		padding:var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
		margin-bottom:0;
	font-size:.875rem;
		color:var(--bs-dropdown-header-color);
		white-space:nowrap
	}
	.dropdown-item-text {
		display:block;
		padding:var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
		color:var(--bs-dropdown-link-color)
	}
	.dropdown-menu-dark {
	--bs-dropdown-color:#dee2e6;
	--bs-dropdown-bg:#343a40;
	--bs-dropdown-border-color:var(--bs-border-color-translucent);
	--bs-dropdown-link-color:#dee2e6;
	--bs-dropdown-link-hover-color:#fff;
	--bs-dropdown-divider-bg:var(--bs-border-color-translucent);
	--bs-dropdown-link-hover-bg:hsla(0,0%,100%,0.15);
	--bs-dropdown-link-active-color:#fff;
	--bs-dropdown-link-active-bg:#e6c43a;
	--bs-dropdown-link-disabled-color:#adb5bd;
	--bs-dropdown-header-color:#adb5bd
	}
	.btn-group, .btn-group-vertical {
		position:relative;
		display:inline-flex;
		vertical-align:middle
	}
	.btn-group-vertical>.btn, .btn-group>.btn {
		position:relative;
		flex:1 1 auto
	}
	.btn-group-vertical>.btn-check:checked+.btn, .btn-group-vertical>.btn-check:focus+.btn, .btn-group-vertical>.btn.active, .btn-group-vertical>.btn:active, .btn-group-vertical>.btn:focus, .btn-group-vertical>.btn:hover, .btn-group>.btn-check:checked+.btn, .btn-group>.btn-check:focus+.btn, .btn-group>.btn.active, .btn-group>.btn:active, .btn-group>.btn:focus, .btn-group>.btn:hover {
		z-index:1
	}
	.btn-toolbar {
		display:flex;
		flex-wrap:wrap;
		justify-content:flex-start
	}
	.btn-toolbar .input-group {
		width:auto
	}
	.btn-group {
		border-radius:var(--bs-border-radius)
	}
	.btn-group>.btn-group:not(:first-child), .btn-group>:not(.btn-check:first-child)+.btn {
	margin-left:calc(var(--bs-border-width)*-1)
	}
	.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn.dropdown-toggle-split:first-child, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
		border-top-right-radius:0;
		border-bottom-right-radius:0
	}
	.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:nth-child(n+3), .btn-group>:not(.btn-check)+.btn {
		border-top-left-radius:0;
		border-bottom-left-radius:0
	}
	.dropdown-toggle-split {
		padding-right:24px;
		padding-left:24px
	}
	.dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after {
		margin-left:0
	}
	.dropstart .dropdown-toggle-split:before {
		margin-right:0
	}
	.btn-group-sm>.btn+.dropdown-toggle-split, .btn-sm+.dropdown-toggle-split {
	padding-right:.375rem;
	padding-left:.375rem
	}
	.btn-group-lg>.btn+.dropdown-toggle-split, .btn-lg+.dropdown-toggle-split {
	padding-right:.75rem;
	padding-left:.75rem
	}
	.btn-group-vertical {
		flex-direction:column;
		align-items:flex-start;
		justify-content:center
	}
	.btn-group-vertical>.btn, .btn-group-vertical>.btn-group {
		width:100%
	}
	.btn-group-vertical>.btn-group:not(:first-child), .btn-group-vertical>.btn:not(:first-child) {
	margin-top:calc(var(--bs-border-width)*-1)
	}
	.btn-group-vertical>.btn-group:not(:last-child)>.btn, .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
		border-bottom-right-radius:0;
		border-bottom-left-radius:0
	}
	.btn-group-vertical>.btn-group:not(:first-child)>.btn, .btn-group-vertical>.btn~.btn {
	border-top-left-radius:0;
	border-top-right-radius:0
	}
	.nav {
	--bs-nav-link-padding-x:1rem;
	--bs-nav-link-padding-y:0.5rem;
	--bs-nav-link-color:var(--bs-link-color);
	--bs-nav-link-hover-color:var(--bs-link-hover-color);
	--bs-nav-link-disabled-color:var(--bs-secondary-color);
		display:flex;
		flex-wrap:wrap;
		padding-left:0;
		margin-bottom:0;
		list-style:none
	}
	.nav-link {
		display:block;
		padding:var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
		font-size:var(--bs-nav-link-font-size);
		font-weight:var(--bs-nav-link-font-weight);
		color:var(--bs-nav-link-color);
		text-decoration:none;
		background:0 0;
		border:0;
		transition:color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
	}
	@media(prefers-reduced-motion:reduce) {
	.nav-link {
		transition:none
	}
	}
	.nav-link:focus, .nav-link:hover {
		color:var(--bs-nav-link-hover-color)
	}
	.nav-link:focus-visible {
	outline:0;
	box-shadow:0 0 0 .25rem rgba(224,244,101,.25)
	}
	.nav-link.disabled, .nav-link:disabled {
		color:var(--bs-nav-link-disabled-color);
		pointer-events:none;
		cursor:default
	}
	.nav-tabs {
	--bs-nav-tabs-border-width:var(--bs-border-width);
	--bs-nav-tabs-border-color:var(--bs-border-color);
	--bs-nav-tabs-border-radius:var(--bs-border-radius);
	--bs-nav-tabs-link-hover-border-color:var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
	--bs-nav-tabs-link-active-color:var(--bs-emphasis-color);
	--bs-nav-tabs-link-active-bg:var(--bs-body-bg);
	--bs-nav-tabs-link-active-border-color:var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
		border-bottom:var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color)
	}
	.nav-tabs .nav-link {
	margin-bottom:calc(var(--bs-nav-tabs-border-width)*-1);
		border:var(--bs-nav-tabs-border-width) solid transparent;
		border-top-left-radius:var(--bs-nav-tabs-border-radius);
		border-top-right-radius:var(--bs-nav-tabs-border-radius)
	}
	.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
		isolation:isolate;
		border-color:var(--bs-nav-tabs-link-hover-border-color)
	}
	.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
		color:var(--bs-nav-tabs-link-active-color);
		background-color:var(--bs-nav-tabs-link-active-bg);
		border-color:var(--bs-nav-tabs-link-active-border-color)
	}
	.nav-tabs .dropdown-menu {
	margin-top:calc(var(--bs-nav-tabs-border-width)*-1);
		border-top-left-radius:0;
		border-top-right-radius:0
	}
	.nav-pills {
	--bs-nav-pills-border-radius:var(--bs-border-radius);
	--bs-nav-pills-link-active-color:#fff;
	--bs-nav-pills-link-active-bg:#e6c43a
	}
	.nav-pills .nav-link {
		border-radius:var(--bs-nav-pills-border-radius)
	}
	.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
		color:var(--bs-nav-pills-link-active-color);
		background-color:var(--bs-nav-pills-link-active-bg)
	}
	.nav-underline {
	--bs-nav-underline-gap:1rem;
	--bs-nav-underline-border-width:0.125rem;
	--bs-nav-underline-link-active-color:var(--bs-emphasis-color);
		gap:var(--bs-nav-underline-gap)
	}
	.nav-underline .nav-link {
		padding-right:0;
		padding-left:0;
		border-bottom:var(--bs-nav-underline-border-width) solid transparent
	}
	.nav-underline .nav-link:focus, .nav-underline .nav-link:hover {
		border-bottom-color:currentcolor
	}
	.nav-underline .nav-link.active, .nav-underline .show>.nav-link {
		font-weight:700;
		color:var(--bs-nav-underline-link-active-color);
		border-bottom-color:currentcolor
	}
	.nav-fill .nav-item, .nav-fill>.nav-link {
		flex:1 1 auto;
		text-align:center
	}
	.nav-justified .nav-item, .nav-justified>.nav-link {
		flex-basis:0;
		flex-grow:1;
		text-align:center
	}
	.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
		width:100%
	}
	.tab-content>.tab-pane {
		display:none
	}
	.tab-content>.active {
		display:block
	}
	.navbar {
	--bs-navbar-padding-x:0;
	--bs-navbar-padding-y:0.5rem;
	--bs-navbar-color:rgba(var(--bs-emphasis-color-rgb), 0.65);
	--bs-navbar-hover-color:rgba(var(--bs-emphasis-color-rgb), 0.8);
	--bs-navbar-disabled-color:rgba(var(--bs-emphasis-color-rgb), 0.3);
	--bs-navbar-active-color:rgba(var(--bs-emphasis-color-rgb), 1);
	--bs-navbar-brand-padding-y:0.3125rem;
	--bs-navbar-brand-margin-end:1rem;
	--bs-navbar-brand-font-size:1.25rem;
	--bs-navbar-brand-color:rgba(var(--bs-emphasis-color-rgb), 1);
	--bs-navbar-brand-hover-color:rgba(var(--bs-emphasis-color-rgb), 1);
	--bs-navbar-nav-link-padding-x:0.5rem;
	--bs-navbar-toggler-padding-y:0.25rem;
	--bs-navbar-toggler-padding-x:0.75rem;
	--bs-navbar-toggler-font-size:1.25rem;
	--bs-navbar-toggler-icon-bg:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(105, 105, 105, 0.75)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
	--bs-navbar-toggler-border-color:rgba(var(--bs-emphasis-color-rgb), 0.15);
	--bs-navbar-toggler-border-radius:var(--bs-border-radius);
	--bs-navbar-toggler-focus-width:0.25rem;
	--bs-navbar-toggler-transition:box-shadow 0.15s ease-in-out;
		position:relative;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		justify-content:space-between;
		padding:var(--bs-navbar-padding-y) var(--bs-navbar-padding-x)
	}
	.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
		display:flex;
		flex-wrap:inherit;
		align-items:center;
		justify-content:space-between
	}
	.navbar-brand {
		padding-top:var(--bs-navbar-brand-padding-y);
		padding-bottom:var(--bs-navbar-brand-padding-y);
		margin-right:var(--bs-navbar-brand-margin-end);
		font-size:var(--bs-navbar-brand-font-size);
		color:var(--bs-navbar-brand-color);
		text-decoration:none;
		white-space:nowrap
	}
	.navbar-brand:focus, .navbar-brand:hover {
		color:var(--bs-navbar-brand-hover-color)
	}
	.navbar-nav {
	--bs-nav-link-padding-x:0;
	--bs-nav-link-padding-y:0.5rem;
	--bs-nav-link-color:var(--bs-navbar-color);
	--bs-nav-link-hover-color:var(--bs-navbar-hover-color);
	--bs-nav-link-disabled-color:var(--bs-navbar-disabled-color);
		display:flex;
		flex-direction:column;
		padding-left:0;
		margin-bottom:0;
		list-style:none
	}
	.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
		color:var(--bs-navbar-active-color)
	}
	.navbar-nav .dropdown-menu {
		position:static
	}
	.navbar-text {
	padding-top:.5rem;
	padding-bottom:.5rem;
		color:var(--bs-navbar-color)
	}
	.navbar-text a, .navbar-text a:focus, .navbar-text a:hover {
		color:var(--bs-navbar-active-color)
	}
	.navbar-collapse {
		flex-basis:100%;
		flex-grow:1;
		align-items:center
	}
	.navbar-toggler {
		padding:var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
		font-size:var(--bs-navbar-toggler-font-size);
		line-height:1;
		color:var(--bs-navbar-color);
		background-color:transparent;
		border:var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
		border-radius:var(--bs-navbar-toggler-border-radius);
		transition:var(--bs-navbar-toggler-transition)
	}
	@media(prefers-reduced-motion:reduce) {
	.navbar-toggler {
		transition:none
	}
	}
	.navbar-toggler:hover {
		text-decoration:none
	}
	.navbar-toggler:focus {
		text-decoration:none;
		outline:0;
		box-shadow:0 0 0 var(--bs-navbar-toggler-focus-width)
	}
	.navbar-toggler-icon {
		display:inline-block;
		width:1.5em;
		height:1.5em;
		vertical-align:middle;
		background-image:var(--bs-navbar-toggler-icon-bg);
		background-repeat:no-repeat;
		background-position:50%;
		background-size:100%
	}
	.navbar-nav-scroll {
		max-height:var(--bs-scroll-height, 75vh);
		overflow-y:auto
	}
	@media(min-width:576px) {
	.navbar-expand-sm {
		flex-wrap:nowrap;
		justify-content:flex-start
	}
	.navbar-expand-sm .navbar-nav {
		flex-direction:row
	}
	.navbar-expand-sm .navbar-nav .dropdown-menu {
		position:absolute
	}
	.navbar-expand-sm .navbar-nav .nav-link {
		padding-right:var(--bs-navbar-nav-link-padding-x);
		padding-left:var(--bs-navbar-nav-link-padding-x)
	}
	.navbar-expand-sm .navbar-nav-scroll {
		overflow:visible
	}
	.navbar-expand-sm .navbar-collapse {
		display:flex!important;
		flex-basis:auto
	}
	.navbar-expand-sm .navbar-toggler {
		display:none
	}
	.navbar-expand-sm .offcanvas {
		position:static;
		z-index:auto;
		flex-grow:1;
		width:auto!important;
		height:auto!important;
		visibility:visible!important;
		background-color:transparent!important;
		border:0!important;
		transform:none!important;
		transition:none
	}
	.navbar-expand-sm .offcanvas .offcanvas-header {
		display:none
	}
	.navbar-expand-sm .offcanvas .offcanvas-body {
		display:flex;
		flex-grow:0;
		padding:0;
		overflow-y:visible
	}
	}
	@media(min-width:768px) {
	.navbar-expand-md {
		flex-wrap:nowrap;
		justify-content:flex-start
	}
	.navbar-expand-md .navbar-nav {
		flex-direction:row
	}
	.navbar-expand-md .navbar-nav .dropdown-menu {
		position:absolute
	}
	.navbar-expand-md .navbar-nav .nav-link {
		padding-right:var(--bs-navbar-nav-link-padding-x);
		padding-left:var(--bs-navbar-nav-link-padding-x)
	}
	.navbar-expand-md .navbar-nav-scroll {
		overflow:visible
	}
	.navbar-expand-md .navbar-collapse {
		display:flex!important;
		flex-basis:auto
	}
	.navbar-expand-md .navbar-toggler {
		display:none
	}
	.navbar-expand-md .offcanvas {
		position:static;
		z-index:auto;
		flex-grow:1;
		width:auto!important;
		height:auto!important;
		visibility:visible!important;
		background-color:transparent!important;
		border:0!important;
		transform:none!important;
		transition:none
	}
	.navbar-expand-md .offcanvas .offcanvas-header {
		display:none
	}
	.navbar-expand-md .offcanvas .offcanvas-body {
		display:flex;
		flex-grow:0;
		padding:0;
		overflow-y:visible
	}
	}
	@media(min-width:992px) {
	.navbar-expand-lg {
		flex-wrap:nowrap;
		justify-content:flex-start
	}
	.navbar-expand-lg .navbar-nav {
		flex-direction:row
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position:absolute
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right:var(--bs-navbar-nav-link-padding-x);
		padding-left:var(--bs-navbar-nav-link-padding-x)
	}
	.navbar-expand-lg .navbar-nav-scroll {
		overflow:visible
	}
	.navbar-expand-lg .navbar-collapse {
		display:flex!important;
		flex-basis:auto
	}
	.navbar-expand-lg .navbar-toggler {
		display:none
	}
	.navbar-expand-lg .offcanvas {
		position:static;
		z-index:auto;
		flex-grow:1;
		width:auto!important;
		height:auto!important;
		visibility:visible!important;
		background-color:transparent!important;
		border:0!important;
		transform:none!important;
		transition:none
	}
	.navbar-expand-lg .offcanvas .offcanvas-header {
		display:none
	}
	.navbar-expand-lg .offcanvas .offcanvas-body {
		display:flex;
		flex-grow:0;
		padding:0;
		overflow-y:visible
	}
	}
	@media(min-width:1200px) {
	.navbar-expand-xl {
		flex-wrap:nowrap;
		justify-content:flex-start
	}
	.navbar-expand-xl .navbar-nav {
		flex-direction:row
	}
	.navbar-expand-xl .navbar-nav .dropdown-menu {
		position:absolute
	}
	.navbar-expand-xl .navbar-nav .nav-link {
		padding-right:var(--bs-navbar-nav-link-padding-x);
		padding-left:var(--bs-navbar-nav-link-padding-x)
	}
	.navbar-expand-xl .navbar-nav-scroll {
		overflow:visible
	}
	.navbar-expand-xl .navbar-collapse {
		display:flex!important;
		flex-basis:auto
	}
	.navbar-expand-xl .navbar-toggler {
		display:none
	}
	.navbar-expand-xl .offcanvas {
		position:static;
		z-index:auto;
		flex-grow:1;
		width:auto!important;
		height:auto!important;
		visibility:visible!important;
		background-color:transparent!important;
		border:0!important;
		transform:none!important;
		transition:none
	}
	.navbar-expand-xl .offcanvas .offcanvas-header {
		display:none
	}
	.navbar-expand-xl .offcanvas .offcanvas-body {
		display:flex;
		flex-grow:0;
		padding:0;
		overflow-y:visible
	}
	}
	@media(min-width:1400px) {
	.navbar-expand-xxl {
		flex-wrap:nowrap;
		justify-content:flex-start
	}
	.navbar-expand-xxl .navbar-nav {
		flex-direction:row
	}
	.navbar-expand-xxl .navbar-nav .dropdown-menu {
		position:absolute
	}
	.navbar-expand-xxl .navbar-nav .nav-link {
		padding-right:var(--bs-navbar-nav-link-padding-x);
		padding-left:var(--bs-navbar-nav-link-padding-x)
	}
	.navbar-expand-xxl .navbar-nav-scroll {
		overflow:visible
	}
	.navbar-expand-xxl .navbar-collapse {
		display:flex!important;
		flex-basis:auto
	}
	.navbar-expand-xxl .navbar-toggler {
		display:none
	}
	.navbar-expand-xxl .offcanvas {
		position:static;
		z-index:auto;
		flex-grow:1;
		width:auto!important;
		height:auto!important;
		visibility:visible!important;
		background-color:transparent!important;
		border:0!important;
		transform:none!important;
		transition:none
	}
	.navbar-expand-xxl .offcanvas .offcanvas-header {
		display:none
	}
	.navbar-expand-xxl .offcanvas .offcanvas-body {
		display:flex;
		flex-grow:0;
		padding:0;
		overflow-y:visible
	}
	}
	.navbar-expand {
		flex-wrap:nowrap;
		justify-content:flex-start
	}
	.navbar-expand .navbar-nav {
		flex-direction:row
	}
	.navbar-expand .navbar-nav .dropdown-menu {
		position:absolute
	}
	.navbar-expand .navbar-nav .nav-link {
		padding-right:var(--bs-navbar-nav-link-padding-x);
		padding-left:var(--bs-navbar-nav-link-padding-x)
	}
	.navbar-expand .navbar-nav-scroll {
		overflow:visible
	}
	.navbar-expand .navbar-collapse {
		display:flex!important;
		flex-basis:auto
	}
	.navbar-expand .navbar-toggler {
		display:none
	}
	.navbar-expand .offcanvas {
		position:static;
		z-index:auto;
		flex-grow:1;
		width:auto!important;
		height:auto!important;
		visibility:visible!important;
		background-color:transparent!important;
		border:0!important;
		transform:none!important;
		transition:none
	}
	.navbar-expand .offcanvas .offcanvas-header {
		display:none
	}
	.navbar-expand .offcanvas .offcanvas-body {
		display:flex;
		flex-grow:0;
		padding:0;
		overflow-y:visible
	}
	.navbar-dark, .navbar[data-bs-theme=dark] {
	--bs-navbar-color:hsla(0,0%,100%,0.55);
	--bs-navbar-hover-color:hsla(0,0%,100%,0.75);
	--bs-navbar-disabled-color:hsla(0,0%,100%,0.25);
	--bs-navbar-active-color:#fff;
	--bs-navbar-brand-color:#fff;
	--bs-navbar-brand-hover-color:#fff;
	--bs-navbar-toggler-border-color:hsla(0,0%,100%,0.1)
	}
	.navbar-dark, .navbar[data-bs-theme=dark], [data-bs-theme=dark] .navbar-toggler-icon {
	--bs-navbar-toggler-icon-bg:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
	}
	.card {
	--bs-card-spacer-y:1rem;
	--bs-card-spacer-x:1rem;
	--bs-card-title-spacer-y:0.5rem;
	--bs-card-border-width:0px;
	--bs-card-border-color:var(--bs-border-color-translucent);
	--bs-card-border-radius:var(--bs-border-radius);
	--bs-card-inner-border-radius:calc(var(--bs-border-radius));
	--bs-card-cap-padding-y:0.5rem;
	--bs-card-cap-padding-x:1rem;
	--bs-card-cap-bg:rgba(var(--bs-body-color-rgb), 0.03);
	--bs-card-bg:var(--bs-body-bg);
	--bs-card-img-overlay-padding:1rem;
	--bs-card-group-margin:0.75rem;
		position:relative;
		display:flex;
		flex-direction:column;
		min-width:0;
		height:var(--bs-card-height);
		color:var(--bs-body-color);
		word-wrap:break-word;
		background-color:var(--bs-card-bg);
		background-clip:border-box;
		border:var(--bs-card-border-width) solid var(--bs-card-border-color);
		border-radius:var(--bs-card-border-radius)
	}
	.card>hr {
		margin-right:0;
		margin-left:0
	}
	.card>.list-group {
		border-top:inherit;
		border-bottom:inherit
	}
	.card>.list-group:first-child {
		border-top-width:0;
		border-top-left-radius:var(--bs-card-inner-border-radius);
		border-top-right-radius:var(--bs-card-inner-border-radius)
	}
	.card>.list-group:last-child {
		border-bottom-width:0;
		border-bottom-right-radius:var(--bs-card-inner-border-radius);
		border-bottom-left-radius:var(--bs-card-inner-border-radius)
	}
	.card>.card-header+.list-group, .card>.list-group+.card-footer {
		border-top:0
	}
	.card-body {
		flex:1 1 auto;
		padding:var(--bs-card-spacer-y) var(--bs-card-spacer-x);
		color:var(--bs-card-color)
	}
	.card-title {
		margin-bottom:var(--bs-card-title-spacer-y);
		color:var(--bs-card-title-color)
	}
	.card-subtitle {
	margin-top:calc(var(--bs-card-title-spacer-y)*-0.5);
		color:var(--bs-card-subtitle-color)
	}
	.card-subtitle, .card-text:last-child {
		margin-bottom:0
	}
	.card-link+.card-link {
		margin-left:var(--bs-card-spacer-x)
	}
	.card-header {
		padding:var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
		margin-bottom:0;
		color:var(--bs-card-cap-color);
		background-color:var(--bs-card-cap-bg);
		border-bottom:var(--bs-card-border-width) solid var(--bs-card-border-color)
	}
	.card-header:first-child {
		border-radius:var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0
	}
	.card-footer {
		padding:var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
		color:var(--bs-card-cap-color);
		background-color:var(--bs-card-cap-bg);
		border-top:var(--bs-card-border-width) solid var(--bs-card-border-color)
	}
	.card-footer:last-child {
		border-radius:0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius)
	}
	.card-header-tabs {
	margin-right:calc(var(--bs-card-cap-padding-x)*-0.5);
	margin-bottom:calc(var(--bs-card-cap-padding-y)*-1);
	margin-left:calc(var(--bs-card-cap-padding-x)*-0.5);
		border-bottom:0
	}
	.card-header-tabs .nav-link.active {
		background-color:var(--bs-card-bg);
		border-bottom-color:var(--bs-card-bg)
	}
	.card-header-pills {
	margin-right:calc(var(--bs-card-cap-padding-x)*-0.5);
	margin-left:calc(var(--bs-card-cap-padding-x)*-0.5)
	}
	.card-img-overlay {
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		padding:var(--bs-card-img-overlay-padding);
		border-radius:var(--bs-card-inner-border-radius)
	}
	.card-img, .card-img-bottom, .card-img-top {
		width:100%
	}
	.card-img, .card-img-top {
		border-top-left-radius:var(--bs-card-inner-border-radius);
		border-top-right-radius:var(--bs-card-inner-border-radius)
	}
	.card-img, .card-img-bottom {
		border-bottom-right-radius:var(--bs-card-inner-border-radius);
		border-bottom-left-radius:var(--bs-card-inner-border-radius)
	}
	.card-group>.card {
		margin-bottom:var(--bs-card-group-margin)
	}
	@media(min-width:576px) {
	.card-group {
		display:flex;
		flex-flow:row wrap
	}
	.card-group>.card {
		flex:1 0 0%;
		margin-bottom:0
	}
	.card-group>.card+.card {
		margin-left:0;
		border-left:0
	}
	.card-group>.card:not(:last-child) {
		border-top-right-radius:0;
		border-bottom-right-radius:0
	}
	.card-group>.card:not(:last-child) .card-header, .card-group>.card:not(:last-child) .card-img-top {
		border-top-right-radius:0
	}
	.card-group>.card:not(:last-child) .card-footer, .card-group>.card:not(:last-child) .card-img-bottom {
		border-bottom-right-radius:0
	}
	.card-group>.card:not(:first-child) {
		border-top-left-radius:0;
		border-bottom-left-radius:0
	}
	.card-group>.card:not(:first-child) .card-header, .card-group>.card:not(:first-child) .card-img-top {
		border-top-left-radius:0
	}
	.card-group>.card:not(:first-child) .card-footer, .card-group>.card:not(:first-child) .card-img-bottom {
		border-bottom-left-radius:0
	}
	}
	.accordion {
	--bs-accordion-color:var(--bs-body-color);
	--bs-accordion-bg:var(--bs-body-bg);
	--bs-accordion-transition:color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
	--bs-accordion-border-color:var(--bs-border-color);
	--bs-accordion-border-width:var(--bs-border-width);
	--bs-accordion-border-radius:var(--bs-border-radius);
	--bs-accordion-inner-border-radius:calc(var(--bs-border-radius) - var(--bs-border-width));
	--bs-accordion-btn-padding-x:1.25rem;
	--bs-accordion-btn-padding-y:1rem;
	--bs-accordion-btn-color:var(--bs-body-color);
	--bs-accordion-btn-bg:var(--bs-accordion-bg);
	--bs-accordion-btn-icon:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23696969'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E");
	--bs-accordion-btn-icon-width:1.25rem;
	--bs-accordion-btn-icon-transform:rotate(-180deg);
	--bs-accordion-btn-icon-transition:transform 0.2s ease-in-out;
	--bs-accordion-btn-active-icon:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%235a6228'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E");
	--bs-accordion-btn-focus-border-color:#f0fab2;
	--bs-accordion-btn-focus-box-shadow:0 0 0 0.25rem rgba(224,244,101,0.25);
	--bs-accordion-body-padding-x:1.25rem;
	--bs-accordion-body-padding-y:1rem;
	--bs-accordion-active-color:var(--bs-primary-text-emphasis);
	--bs-accordion-active-bg:var(--bs-primary-bg-subtle)
	}
	.accordion-button {
		position:relative;
		display:flex;
		align-items:center;
		width:100%;
		padding:var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
		font-size:1rem;
		color:var(--bs-accordion-btn-color);
		text-align:left;
		background-color:var(--bs-accordion-btn-bg);
		border:0;
		border-radius:0;
		overflow-anchor:none;
		transition:var(--bs-accordion-transition)
	}
	@media(prefers-reduced-motion:reduce) {
	.accordion-button {
		transition:none
	}
	}
	.accordion-button:not(.collapsed) {
		color:var(--bs-accordion-active-color);
		background-color:var(--bs-accordion-active-bg);
	box-shadow:inset 0 calc(var(--bs-accordion-border-width)*-1) 0 var(--bs-accordion-border-color)
	}
	.accordion-button:not(.collapsed):after {
		background-image:var(--bs-accordion-btn-active-icon);
		transform:var(--bs-accordion-btn-icon-transform)
	}
	.accordion-button:after {
		flex-shrink:0;
		width:var(--bs-accordion-btn-icon-width);
		height:var(--bs-accordion-btn-icon-width);
		margin-left:auto;
		content:"";
		background-image:var(--bs-accordion-btn-icon);
		background-size:var(--bs-accordion-btn-icon-width);
		transition:var(--bs-accordion-btn-icon-transition)
	}
	@media(prefers-reduced-motion:reduce) {
	.accordion-button:after {
		transition:none
	}
	}
	.accordion-button:hover {
		z-index:2
	}
	.accordion-button:focus {
		z-index:3;
		border-color:var(--bs-accordion-btn-focus-border-color);
		outline:0;
		box-shadow:var(--bs-accordion-btn-focus-box-shadow)
	}
	.accordion-header {
		margin-bottom:0
	}
	.accordion-item {
		color:var(--bs-accordion-color);
		background-color:var(--bs-accordion-bg);
		border:var(--bs-accordion-border-width) solid var(--bs-accordion-border-color)
	}
	.accordion-item:first-of-type {
		border-top-left-radius:var(--bs-accordion-border-radius);
		border-top-right-radius:var(--bs-accordion-border-radius)
	}
	.accordion-item:first-of-type .accordion-button {
		border-top-left-radius:var(--bs-accordion-inner-border-radius);
		border-top-right-radius:var(--bs-accordion-inner-border-radius)
	}
	.accordion-item:not(:first-of-type) {
		border-top:0
	}
	.accordion-item:last-of-type {
		border-bottom-right-radius:var(--bs-accordion-border-radius);
		border-bottom-left-radius:var(--bs-accordion-border-radius)
	}
	.accordion-item:last-of-type .accordion-button.collapsed {
		border-bottom-right-radius:var(--bs-accordion-inner-border-radius);
		border-bottom-left-radius:var(--bs-accordion-inner-border-radius)
	}
	.accordion-item:last-of-type .accordion-collapse {
		border-bottom-right-radius:var(--bs-accordion-border-radius);
		border-bottom-left-radius:var(--bs-accordion-border-radius)
	}
	.accordion-body {
		padding:var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x)
	}
	.accordion-flush .accordion-collapse {
		border-width:0
	}
	.accordion-flush .accordion-item {
		border-right:0;
		border-left:0;
		border-radius:0
	}
	.accordion-flush .accordion-item:first-child {
		border-top:0
	}
	.accordion-flush .accordion-item:last-child {
		border-bottom:0
	}
	.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
		border-radius:0
	}
	[data-bs-theme=dark] .accordion-button:after {
	--bs-accordion-btn-icon:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ecf8a3'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E");
	--bs-accordion-btn-active-icon:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ecf8a3'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E")
	}
	.breadcrumb {
	--bs-breadcrumb-padding-x:0;
	--bs-breadcrumb-padding-y:0;
	--bs-breadcrumb-margin-bottom:1rem;
	--bs-breadcrumb-divider-color:var(--bs-secondary-color);
	--bs-breadcrumb-item-padding-x:0.5rem;
	--bs-breadcrumb-item-active-color:var(--bs-secondary-color);
		display:flex;
		flex-wrap:wrap;
		padding:var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
		margin-bottom:var(--bs-breadcrumb-margin-bottom);
		font-size:var(--bs-breadcrumb-font-size);
		list-style:none;
		background-color:var(--bs-breadcrumb-bg);
		border-radius:var(--bs-breadcrumb-border-radius)
	}
	.breadcrumb-item+.breadcrumb-item {
		padding-left:var(--bs-breadcrumb-item-padding-x)
	}
	.breadcrumb-item+.breadcrumb-item:before {
		float:left;
		padding-right:var(--bs-breadcrumb-item-padding-x);
		color:var(--bs-breadcrumb-divider-color);
		content:var(--bs-breadcrumb-divider, "||")
	}
	.breadcrumb-item.active {
		color:var(--bs-breadcrumb-item-active-color)
	}
	.pagination {
	--bs-pagination-padding-x:0.75rem;
	--bs-pagination-padding-y:0.375rem;
	--bs-pagination-font-size:1rem;
	--bs-pagination-color:var(--bs-link-color);
	--bs-pagination-bg:var(--bs-body-bg);
	--bs-pagination-border-width:var(--bs-border-width);
	--bs-pagination-border-color:var(--bs-border-color);
	--bs-pagination-border-radius:var(--bs-border-radius);
	--bs-pagination-hover-color:var(--bs-link-hover-color);
	--bs-pagination-hover-bg:var(--bs-tertiary-bg);
	--bs-pagination-hover-border-color:var(--bs-border-color);
	--bs-pagination-focus-color:var(--bs-link-hover-color);
	--bs-pagination-focus-bg:var(--bs-secondary-bg);
	--bs-pagination-focus-box-shadow:0 0 0 0.25rem rgba(224,244,101,0.25);
	--bs-pagination-active-color:#fff;
	--bs-pagination-active-bg:#e6c43a;
	--bs-pagination-active-border-color:#e6c43a;
	--bs-pagination-disabled-color:var(--bs-secondary-color);
	--bs-pagination-disabled-bg:var(--bs-secondary-bg);
	--bs-pagination-disabled-border-color:var(--bs-border-color);
		display:flex;
		padding-left:0;
		list-style:none
	}
	.page-link {
		position:relative;
		display:block;
		padding:var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
		font-size:var(--bs-pagination-font-size);
		color:var(--bs-pagination-color);
		text-decoration:none;
		background-color:var(--bs-pagination-bg);
		border:var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
		transition:color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
	}
	@media(prefers-reduced-motion:reduce) {
	.page-link {
		transition:none
	}
	}
	.page-link:hover {
		z-index:2;
		color:var(--bs-pagination-hover-color);
		background-color:var(--bs-pagination-hover-bg);
		border-color:var(--bs-pagination-hover-border-color)
	}
	.page-link:focus {
		z-index:3;
		color:var(--bs-pagination-focus-color);
		background-color:var(--bs-pagination-focus-bg);
		outline:0;
		box-shadow:var(--bs-pagination-focus-box-shadow)
	}
	.active>.page-link, .page-link.active {
		z-index:3;
		color:var(--bs-pagination-active-color);
		background-color:var(--bs-pagination-active-bg);
		border-color:var(--bs-pagination-active-border-color)
	}
	.disabled>.page-link, .page-link.disabled {
		color:var(--bs-pagination-disabled-color);
		pointer-events:none;
		background-color:var(--bs-pagination-disabled-bg);
		border-color:var(--bs-pagination-disabled-border-color)
	}
	.page-item:not(:first-child) .page-link {
	margin-left:calc(var(--bs-border-width)*-1)
	}
	.page-item:first-child .page-link {
		border-top-left-radius:var(--bs-pagination-border-radius);
		border-bottom-left-radius:var(--bs-pagination-border-radius)
	}
	.page-item:last-child .page-link {
		border-top-right-radius:var(--bs-pagination-border-radius);
		border-bottom-right-radius:var(--bs-pagination-border-radius)
	}
	.pagination-lg {
	--bs-pagination-padding-x:1.5rem;
	--bs-pagination-padding-y:0.75rem;
	--bs-pagination-font-size:1.25rem;
	--bs-pagination-border-radius:var(--bs-border-radius-lg)
	}
	.pagination-sm {
	--bs-pagination-padding-x:0.5rem;
	--bs-pagination-padding-y:0.25rem;
	--bs-pagination-font-size:0.875rem;
	--bs-pagination-border-radius:var(--bs-border-radius-sm)
	}
	.badge {
	--bs-badge-padding-x:0.65em;
	--bs-badge-padding-y:0.35em;
	--bs-badge-font-size:0.75em;
	--bs-badge-font-weight:700;
	--bs-badge-color:#fff;
	--bs-badge-border-radius:var(--bs-border-radius);
		display:inline-block;
		padding:var(--bs-badge-padding-y) var(--bs-badge-padding-x);
		font-size:var(--bs-badge-font-size);
		font-weight:var(--bs-badge-font-weight);
		line-height:1;
		color:var(--bs-badge-color);
		text-align:center;
		white-space:nowrap;
		vertical-align:baseline;
		border-radius:var(--bs-badge-border-radius)
	}
	.badge:empty {
		display:none
	}
	.btn .badge {
		position:relative;
		top:-1px
	}
	.alert {
	--bs-alert-bg:transparent;
	--bs-alert-padding-x:1rem;
	--bs-alert-padding-y:1rem;
	--bs-alert-margin-bottom:1rem;
	--bs-alert-color:inherit;
	--bs-alert-border-color:transparent;
	--bs-alert-border:var(--bs-border-width) solid var(--bs-alert-border-color);
	--bs-alert-border-radius:var(--bs-border-radius);
	--bs-alert-link-color:inherit;
		position:relative;
		padding:var(--bs-alert-padding-y) var(--bs-alert-padding-x);
		margin-bottom:var(--bs-alert-margin-bottom);
		color:var(--bs-alert-color);
		background-color:var(--bs-alert-bg);
		border:var(--bs-alert-border);
		border-radius:var(--bs-alert-border-radius)
	}
	.alert-heading {
		color:inherit
	}
	.alert-link {
		font-weight:700;
		color:var(--bs-alert-link-color)
	}
	.alert-dismissible {
		padding-right:3rem
	}
	.alert-dismissible .btn-close {
		position:absolute;
		top:0;
		right:0;
		z-index:2;
		padding:1.25rem 1rem
	}
	.alert-primary {
	--bs-alert-color:var(--bs-primary-text-emphasis);
	--bs-alert-bg:var(--bs-primary-bg-subtle);
	--bs-alert-border-color:var(--bs-primary-border-subtle);
	--bs-alert-link-color:var(--bs-primary-text-emphasis)
	}
	.alert-secondary {
	--bs-alert-color:var(--bs-secondary-text-emphasis);
	--bs-alert-bg:var(--bs-secondary-bg-subtle);
	--bs-alert-border-color:var(--bs-secondary-border-subtle);
	--bs-alert-link-color:var(--bs-secondary-text-emphasis)
	}
	.alert-success {
	--bs-alert-color:var(--bs-success-text-emphasis);
	--bs-alert-bg:var(--bs-success-bg-subtle);
	--bs-alert-border-color:var(--bs-success-border-subtle);
	--bs-alert-link-color:var(--bs-success-text-emphasis)
	}
	.alert-info {
	--bs-alert-color:var(--bs-info-text-emphasis);
	--bs-alert-bg:var(--bs-info-bg-subtle);
	--bs-alert-border-color:var(--bs-info-border-subtle);
	--bs-alert-link-color:var(--bs-info-text-emphasis)
	}
	.alert-light {
	--bs-alert-color:var(--bs-light-text-emphasis);
	--bs-alert-bg:var(--bs-light-bg-subtle);
	--bs-alert-border-color:var(--bs-light-border-subtle);
	--bs-alert-link-color:var(--bs-light-text-emphasis)
	}
	.alert-danger {
	--bs-alert-color:var(--bs-danger-text-emphasis);
	--bs-alert-bg:var(--bs-danger-bg-subtle);
	--bs-alert-border-color:var(--bs-danger-border-subtle);
	--bs-alert-link-color:var(--bs-danger-text-emphasis)
	}
	.alert-dark {
	--bs-alert-color:var(--bs-dark-text-emphasis);
	--bs-alert-bg:var(--bs-dark-bg-subtle);
	--bs-alert-border-color:var(--bs-dark-border-subtle);
	--bs-alert-link-color:var(--bs-dark-text-emphasis)
	}
	.alert-altdark {
	--bs-alert-color:var(--bs-altdark-text-emphasis);
	--bs-alert-bg:var(--bs-altdark-bg-subtle);
	--bs-alert-border-color:var(--bs-altdark-border-subtle);
	--bs-alert-link-color:var(--bs-altdark-text-emphasis)
	}
	.alert-sky {
	--bs-alert-color:var(--bs-sky-text-emphasis);
	--bs-alert-bg:var(--bs-sky-bg-subtle);
	--bs-alert-border-color:var(--bs-sky-border-subtle);
	--bs-alert-link-color:var(--bs-sky-text-emphasis)
	}
	.alert-mute {
	--bs-alert-color:var(--bs-mute-text-emphasis);
	--bs-alert-bg:var(--bs-mute-bg-subtle);
	--bs-alert-border-color:var(--bs-mute-border-subtle);
	--bs-alert-link-color:var(--bs-mute-text-emphasis)
	}
	.alert-tertiary {
	--bs-alert-color:var(--bs-tertiary-text-emphasis);
	--bs-alert-bg:var(--bs-tertiary-bg-subtle);
	--bs-alert-border-color:var(--bs-tertiary-border-subtle);
	--bs-alert-link-color:var(--bs-tertiary-text-emphasis)
	}
	@keyframes progress-bar-stripes {
	0% {
	background-position-x:1rem
	}
	}
	.progress, .progress-stacked {
	--bs-progress-height:1rem;
	--bs-progress-font-size:0.75rem;
	--bs-progress-bg:var(--bs-secondary-bg);
	--bs-progress-border-radius:var(--bs-border-radius);
	--bs-progress-box-shadow:var(--bs-box-shadow-inset);
	--bs-progress-bar-color:#fff;
	--bs-progress-bar-bg:#e6c43a;
	--bs-progress-bar-transition:width 0.6s ease;
		display:flex;
		height:var(--bs-progress-height);
		overflow:hidden;
		font-size:var(--bs-progress-font-size);
		background-color:var(--bs-progress-bg);
		border-radius:var(--bs-progress-border-radius)
	}
	.progress-bar {
		display:flex;
		flex-direction:column;
		justify-content:center;
		overflow:hidden;
		color:var(--bs-progress-bar-color);
		text-align:center;
		white-space:nowrap;
		background-color:var(--bs-progress-bar-bg);
		transition:var(--bs-progress-bar-transition)
	}
	@media(prefers-reduced-motion:reduce) {
	.progress-bar {
		transition:none
	}
	}
	.progress-bar-striped {
		background-image:linear-gradient(45deg, hsla(0,0%,100%,.15) 25%, transparent 0, transparent 50%, hsla(0,0%,100%,.15) 0, hsla(0,0%,100%,.15) 75%, transparent 0, transparent);
		background-size:var(--bs-progress-height) var(--bs-progress-height)
	}
	.progress-stacked>.progress {
		overflow:visible
	}
	.progress-stacked>.progress>.progress-bar {
		width:100%
	}
	.progress-bar-animated {
		animation:progress-bar-stripes 1s linear infinite
	}
	@media(prefers-reduced-motion:reduce) {
	.progress-bar-animated {
		animation:none
	}
	}
	.list-group {
	--bs-list-group-color:var(--bs-body-color);
	--bs-list-group-bg:var(--bs-body-bg);
	--bs-list-group-border-color:var(--bs-border-color);
	--bs-list-group-border-width:var(--bs-border-width);
	--bs-list-group-border-radius:var(--bs-border-radius);
	--bs-list-group-item-padding-x:1rem;
	--bs-list-group-item-padding-y:0.5rem;
	--bs-list-group-action-color:var(--bs-secondary-color);
	--bs-list-group-action-hover-color:var(--bs-emphasis-color);
	--bs-list-group-action-hover-bg:var(--bs-tertiary-bg);
	--bs-list-group-action-active-color:var(--bs-body-color);
	--bs-list-group-action-active-bg:var(--bs-secondary-bg);
	--bs-list-group-disabled-color:var(--bs-secondary-color);
	--bs-list-group-disabled-bg:var(--bs-body-bg);
	--bs-list-group-active-color:#fff;
	--bs-list-group-active-bg:#e6c43a;
	--bs-list-group-active-border-color:#e6c43a;
		display:flex;
		flex-direction:column;
		padding-left:0;
		margin-bottom:0;
		border-radius:var(--bs-list-group-border-radius)
	}
	.list-group-numbered {
		list-style-type:none;
		counter-reset:section
	}
	.list-group-numbered>.list-group-item:before {
		content:counters(section, ".") ". ";
		counter-increment:section
	}
	.list-group-item-action {
		width:100%;
		color:var(--bs-list-group-action-color);
		text-align:inherit
	}
	.list-group-item-action:focus, .list-group-item-action:hover {
		z-index:1;
		color:var(--bs-list-group-action-hover-color);
		text-decoration:none;
		background-color:var(--bs-list-group-action-hover-bg)
	}
	.list-group-item-action:active {
		color:var(--bs-list-group-action-active-color);
		background-color:var(--bs-list-group-action-active-bg)
	}
	.list-group-item {
		position:relative;
		display:block;
		padding:var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
		color:var(--bs-list-group-color);
		text-decoration:none;
		background-color:var(--bs-list-group-bg);
		border:var(--bs-list-group-border-width) solid var(--bs-list-group-border-color)
	}
	.list-group-item:first-child {
		border-top-left-radius:inherit;
		border-top-right-radius:inherit
	}
	.list-group-item:last-child {
		border-bottom-right-radius:inherit;
		border-bottom-left-radius:inherit
	}
	.list-group-item.disabled, .list-group-item:disabled {
		color:var(--bs-list-group-disabled-color);
		pointer-events:none;
		background-color:var(--bs-list-group-disabled-bg)
	}
	.list-group-item.active {
		z-index:2;
		color:var(--bs-list-group-active-color);
		background-color:var(--bs-list-group-active-bg);
		border-color:var(--bs-list-group-active-border-color)
	}
	.list-group-item+.list-group-item {
		border-top-width:0
	}
	.list-group-item+.list-group-item.active {
	margin-top:calc(var(--bs-list-group-border-width)*-1);
		border-top-width:var(--bs-list-group-border-width)
	}
	.list-group-horizontal {
		flex-direction:row
	}
	.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
		border-bottom-left-radius:var(--bs-list-group-border-radius);
		border-top-right-radius:0
	}
	.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
		border-top-right-radius:var(--bs-list-group-border-radius);
		border-bottom-left-radius:0
	}
	.list-group-horizontal>.list-group-item.active {
		margin-top:0
	}
	.list-group-horizontal>.list-group-item+.list-group-item {
		border-top-width:var(--bs-list-group-border-width);
		border-left-width:0
	}
	.list-group-horizontal>.list-group-item+.list-group-item.active {
	margin-left:calc(var(--bs-list-group-border-width)*-1);
		border-left-width:var(--bs-list-group-border-width)
	}
	@media(min-width:576px) {
	.list-group-horizontal-sm {
		flex-direction:row
	}
	.list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
		border-bottom-left-radius:var(--bs-list-group-border-radius);
		border-top-right-radius:0
	}
	.list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
		border-top-right-radius:var(--bs-list-group-border-radius);
		border-bottom-left-radius:0
	}
	.list-group-horizontal-sm>.list-group-item.active {
		margin-top:0
	}
	.list-group-horizontal-sm>.list-group-item+.list-group-item {
		border-top-width:var(--bs-list-group-border-width);
		border-left-width:0
	}
	.list-group-horizontal-sm>.list-group-item+.list-group-item.active {
	margin-left:calc(var(--bs-list-group-border-width)*-1);
		border-left-width:var(--bs-list-group-border-width)
	}
	}
	@media(min-width:768px) {
	.list-group-horizontal-md {
		flex-direction:row
	}
	.list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
		border-bottom-left-radius:var(--bs-list-group-border-radius);
		border-top-right-radius:0
	}
	.list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
		border-top-right-radius:var(--bs-list-group-border-radius);
		border-bottom-left-radius:0
	}
	.list-group-horizontal-md>.list-group-item.active {
		margin-top:0
	}
	.list-group-horizontal-md>.list-group-item+.list-group-item {
		border-top-width:var(--bs-list-group-border-width);
		border-left-width:0
	}
	.list-group-horizontal-md>.list-group-item+.list-group-item.active {
	margin-left:calc(var(--bs-list-group-border-width)*-1);
		border-left-width:var(--bs-list-group-border-width)
	}
	}
	@media(min-width:992px) {
	.list-group-horizontal-lg {
		flex-direction:row
	}
	.list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
		border-bottom-left-radius:var(--bs-list-group-border-radius);
		border-top-right-radius:0
	}
	.list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
		border-top-right-radius:var(--bs-list-group-border-radius);
		border-bottom-left-radius:0
	}
	.list-group-horizontal-lg>.list-group-item.active {
		margin-top:0
	}
	.list-group-horizontal-lg>.list-group-item+.list-group-item {
		border-top-width:var(--bs-list-group-border-width);
		border-left-width:0
	}
	.list-group-horizontal-lg>.list-group-item+.list-group-item.active {
	margin-left:calc(var(--bs-list-group-border-width)*-1);
		border-left-width:var(--bs-list-group-border-width)
	}
	}
	@media(min-width:1200px) {
	.list-group-horizontal-xl {
		flex-direction:row
	}
	.list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
		border-bottom-left-radius:var(--bs-list-group-border-radius);
		border-top-right-radius:0
	}
	.list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
		border-top-right-radius:var(--bs-list-group-border-radius);
		border-bottom-left-radius:0
	}
	.list-group-horizontal-xl>.list-group-item.active {
		margin-top:0
	}
	.list-group-horizontal-xl>.list-group-item+.list-group-item {
		border-top-width:var(--bs-list-group-border-width);
		border-left-width:0
	}
	.list-group-horizontal-xl>.list-group-item+.list-group-item.active {
	margin-left:calc(var(--bs-list-group-border-width)*-1);
		border-left-width:var(--bs-list-group-border-width)
	}
	}
	@media(min-width:1400px) {
	.list-group-horizontal-xxl {
		flex-direction:row
	}
	.list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
		border-bottom-left-radius:var(--bs-list-group-border-radius);
		border-top-right-radius:0
	}
	.list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
		border-top-right-radius:var(--bs-list-group-border-radius);
		border-bottom-left-radius:0
	}
	.list-group-horizontal-xxl>.list-group-item.active {
		margin-top:0
	}
	.list-group-horizontal-xxl>.list-group-item+.list-group-item {
		border-top-width:var(--bs-list-group-border-width);
		border-left-width:0
	}
	.list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
	margin-left:calc(var(--bs-list-group-border-width)*-1);
		border-left-width:var(--bs-list-group-border-width)
	}
	}
	.list-group-flush {
		border-radius:0
	}
	.list-group-flush>.list-group-item {
		border-left-width:0;
		border-bottom-width:var(--bs-list-group-border-width);
		border-right-width:0;
		border-top-width:0
	}
	.list-group-flush>.list-group-item:last-child {
		border-bottom-width:0
	}
	.list-group-item-primary {
	--bs-list-group-color:var(--bs-primary-text-emphasis);
	--bs-list-group-bg:var(--bs-primary-bg-subtle);
	--bs-list-group-border-color:var(--bs-primary-border-subtle);
	--bs-list-group-action-hover-color:var(--bs-emphasis-color);
	--bs-list-group-action-hover-bg:var(--bs-primary-border-subtle);
	--bs-list-group-action-active-color:var(--bs-emphasis-color);
	--bs-list-group-action-active-bg:var(--bs-primary-border-subtle);
	--bs-list-group-active-color:var(--bs-primary-bg-subtle);
	--bs-list-group-active-bg:var(--bs-primary-text-emphasis);
	--bs-list-group-active-border-color:var(--bs-primary-text-emphasis)
	}
	.list-group-item-secondary {
	--bs-list-group-color:var(--bs-secondary-text-emphasis);
	--bs-list-group-bg:var(--bs-secondary-bg-subtle);
	--bs-list-group-border-color:var(--bs-secondary-border-subtle);
	--bs-list-group-action-hover-color:var(--bs-emphasis-color);
	--bs-list-group-action-hover-bg:var(--bs-secondary-border-subtle);
	--bs-list-group-action-active-color:var(--bs-emphasis-color);
	--bs-list-group-action-active-bg:var(--bs-secondary-border-subtle);
	--bs-list-group-active-color:var(--bs-secondary-bg-subtle);
	--bs-list-group-active-bg:var(--bs-secondary-text-emphasis);
	--bs-list-group-active-border-color:var(--bs-secondary-text-emphasis)
	}
	.list-group-item-success {
	--bs-list-group-color:var(--bs-success-text-emphasis);
	--bs-list-group-bg:var(--bs-success-bg-subtle);
	--bs-list-group-border-color:var(--bs-success-border-subtle);
	--bs-list-group-action-hover-color:var(--bs-emphasis-color);
	--bs-list-group-action-hover-bg:var(--bs-success-border-subtle);
	--bs-list-group-action-active-color:var(--bs-emphasis-color);
	--bs-list-group-action-active-bg:var(--bs-success-border-subtle);
	--bs-list-group-active-color:var(--bs-success-bg-subtle);
	--bs-list-group-active-bg:var(--bs-success-text-emphasis);
	--bs-list-group-active-border-color:var(--bs-success-text-emphasis)
	}
	.list-group-item-info {
	--bs-list-group-color:var(--bs-info-text-emphasis);
	--bs-list-group-bg:var(--bs-info-bg-subtle);
	--bs-list-group-border-color:var(--bs-info-border-subtle);
	--bs-list-group-action-hover-color:var(--bs-emphasis-color);
	--bs-list-group-action-hover-bg:var(--bs-info-border-subtle);
	--bs-list-group-action-active-color:var(--bs-emphasis-color);
	--bs-list-group-action-active-bg:var(--bs-info-border-subtle);
	--bs-list-group-active-color:var(--bs-info-bg-subtle);
	--bs-list-group-active-bg:var(--bs-info-text-emphasis);
	--bs-list-group-active-border-color:var(--bs-info-text-emphasis)
	}
	.list-group-item-light {
	--bs-list-group-color:var(--bs-light-text-emphasis);
	--bs-list-group-bg:var(--bs-light-bg-subtle);
	--bs-list-group-border-color:var(--bs-light-border-subtle);
	--bs-list-group-action-hover-color:var(--bs-emphasis-color);
	--bs-list-group-action-hover-bg:var(--bs-light-border-subtle);
	--bs-list-group-action-active-color:var(--bs-emphasis-color);
	--bs-list-group-action-active-bg:var(--bs-light-border-subtle);
	--bs-list-group-active-color:var(--bs-light-bg-subtle);
	--bs-list-group-active-bg:var(--bs-light-text-emphasis);
	--bs-list-group-active-border-color:var(--bs-light-text-emphasis)
	}
	.list-group-item-danger {
	--bs-list-group-color:var(--bs-danger-text-emphasis);
	--bs-list-group-bg:var(--bs-danger-bg-subtle);
	--bs-list-group-border-color:var(--bs-danger-border-subtle);
	--bs-list-group-action-hover-color:var(--bs-emphasis-color);
	--bs-list-group-action-hover-bg:var(--bs-danger-border-subtle);
	--bs-list-group-action-active-color:var(--bs-emphasis-color);
	--bs-list-group-action-active-bg:var(--bs-danger-border-subtle);
	--bs-list-group-active-color:var(--bs-danger-bg-subtle);
	--bs-list-group-active-bg:var(--bs-danger-text-emphasis);
	--bs-list-group-active-border-color:var(--bs-danger-text-emphasis)
	}
	.list-group-item-dark {
	--bs-list-group-color:var(--bs-dark-text-emphasis);
	--bs-list-group-bg:var(--bs-dark-bg-subtle);
	--bs-list-group-border-color:var(--bs-dark-border-subtle);
	--bs-list-group-action-hover-color:var(--bs-emphasis-color);
	--bs-list-group-action-hover-bg:var(--bs-dark-border-subtle);
	--bs-list-group-action-active-color:var(--bs-emphasis-color);
	--bs-list-group-action-active-bg:var(--bs-dark-border-subtle);
	--bs-list-group-active-color:var(--bs-dark-bg-subtle);
	--bs-list-group-active-bg:var(--bs-dark-text-emphasis);
	--bs-list-group-active-border-color:var(--bs-dark-text-emphasis)
	}
	.list-group-item-altdark {
	--bs-list-group-color:var(--bs-altdark-text-emphasis);
	--bs-list-group-bg:var(--bs-altdark-bg-subtle);
	--bs-list-group-border-color:var(--bs-altdark-border-subtle);
	--bs-list-group-action-hover-color:var(--bs-emphasis-color);
	--bs-list-group-action-hover-bg:var(--bs-altdark-border-subtle);
	--bs-list-group-action-active-color:var(--bs-emphasis-color);
	--bs-list-group-action-active-bg:var(--bs-altdark-border-subtle);
	--bs-list-group-active-color:var(--bs-altdark-bg-subtle);
	--bs-list-group-active-bg:var(--bs-altdark-text-emphasis);
	--bs-list-group-active-border-color:var(--bs-altdark-text-emphasis)
	}
	.list-group-item-sky {
	--bs-list-group-color:var(--bs-sky-text-emphasis);
	--bs-list-group-bg:var(--bs-sky-bg-subtle);
	--bs-list-group-border-color:var(--bs-sky-border-subtle);
	--bs-list-group-action-hover-color:var(--bs-emphasis-color);
	--bs-list-group-action-hover-bg:var(--bs-sky-border-subtle);
	--bs-list-group-action-active-color:var(--bs-emphasis-color);
	--bs-list-group-action-active-bg:var(--bs-sky-border-subtle);
	--bs-list-group-active-color:var(--bs-sky-bg-subtle);
	--bs-list-group-active-bg:var(--bs-sky-text-emphasis);
	--bs-list-group-active-border-color:var(--bs-sky-text-emphasis)
	}
	.list-group-item-mute {
	--bs-list-group-color:var(--bs-mute-text-emphasis);
	--bs-list-group-bg:var(--bs-mute-bg-subtle);
	--bs-list-group-border-color:var(--bs-mute-border-subtle);
	--bs-list-group-action-hover-color:var(--bs-emphasis-color);
	--bs-list-group-action-hover-bg:var(--bs-mute-border-subtle);
	--bs-list-group-action-active-color:var(--bs-emphasis-color);
	--bs-list-group-action-active-bg:var(--bs-mute-border-subtle);
	--bs-list-group-active-color:var(--bs-mute-bg-subtle);
	--bs-list-group-active-bg:var(--bs-mute-text-emphasis);
	--bs-list-group-active-border-color:var(--bs-mute-text-emphasis)
	}
	.list-group-item-tertiary {
	--bs-list-group-color:var(--bs-tertiary-text-emphasis);
	--bs-list-group-bg:var(--bs-tertiary-bg-subtle);
	--bs-list-group-border-color:var(--bs-tertiary-border-subtle);
	--bs-list-group-action-hover-color:var(--bs-emphasis-color);
	--bs-list-group-action-hover-bg:var(--bs-tertiary-border-subtle);
	--bs-list-group-action-active-color:var(--bs-emphasis-color);
	--bs-list-group-action-active-bg:var(--bs-tertiary-border-subtle);
	--bs-list-group-active-color:var(--bs-tertiary-bg-subtle);
	--bs-list-group-active-bg:var(--bs-tertiary-text-emphasis);
	--bs-list-group-active-border-color:var(--bs-tertiary-text-emphasis)
	}
	.btn-close {
	--bs-btn-close-color:#000;
	--bs-btn-close-bg:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E");
	--bs-btn-close-opacity:0.5;
	--bs-btn-close-hover-opacity:0.75;
	--bs-btn-close-focus-shadow:0 0 0 0.25rem rgba(224,244,101,0.25);
	--bs-btn-close-focus-opacity:1;
	--bs-btn-close-disabled-opacity:0.25;
	--bs-btn-close-white-filter:invert(1) grayscale(100%) brightness(200%);
		box-sizing:content-box;
		width:1em;
		height:1em;
		padding:.25em;
		background:transparent var(--bs-btn-close-bg) 50%/1em auto no-repeat;
		border:0;
	border-radius:.375rem;
		opacity:var(--bs-btn-close-opacity)
	}
	.btn-close, .btn-close:hover {
		color:var(--bs-btn-close-color)
	}
	.btn-close:hover {
		text-decoration:none;
		opacity:var(--bs-btn-close-hover-opacity)
	}
	.btn-close:focus {
		outline:0;
		box-shadow:var(--bs-btn-close-focus-shadow);
		opacity:var(--bs-btn-close-focus-opacity)
	}
	.btn-close.disabled, .btn-close:disabled {
		pointer-events:none;
		user-select:none;
		opacity:var(--bs-btn-close-disabled-opacity)
	}
	.btn-close-white, [data-bs-theme=dark] .btn-close {
		filter:var(--bs-btn-close-white-filter)
	}
	.toast {
	--bs-toast-zindex:1090;
	--bs-toast-padding-x:0.75rem;
	--bs-toast-padding-y:0.5rem;
	--bs-toast-spacing:1.5rem;
	--bs-toast-max-width:350px;
	--bs-toast-font-size:0.875rem;
	--bs-toast-bg:rgba(var(--bs-body-bg-rgb), 0.85);
	--bs-toast-border-width:var(--bs-border-width);
	--bs-toast-border-color:var(--bs-border-color-translucent);
	--bs-toast-border-radius:var(--bs-border-radius);
	--bs-toast-box-shadow:var(--bs-box-shadow);
	--bs-toast-header-color:var(--bs-secondary-color);
	--bs-toast-header-bg:rgba(var(--bs-body-bg-rgb), 0.85);
	--bs-toast-header-border-color:var(--bs-border-color-translucent);
		width:var(--bs-toast-max-width);
		max-width:100%;
		font-size:var(--bs-toast-font-size);
		color:var(--bs-toast-color);
		pointer-events:auto;
		background-color:var(--bs-toast-bg);
		background-clip:padding-box;
		border:var(--bs-toast-border-width) solid var(--bs-toast-border-color);
		box-shadow:var(--bs-toast-box-shadow);
		border-radius:var(--bs-toast-border-radius)
	}
	.toast.showing {
		opacity:0
	}
	.toast:not(.show) {
		display:none
	}
	.toast-container {
	--bs-toast-zindex:1090;
		position:absolute;
		z-index:var(--bs-toast-zindex);
		width:max-content;
		max-width:100%;
		pointer-events:none
	}
	.toast-container>:not(:last-child) {
		margin-bottom:var(--bs-toast-spacing)
	}
	.toast-header {
		display:flex;
		align-items:center;
		padding:var(--bs-toast-padding-y) var(--bs-toast-padding-x);
		color:var(--bs-toast-header-color);
		background-color:var(--bs-toast-header-bg);
		background-clip:padding-box;
		border-bottom:var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
		border-top-left-radius:calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
		border-top-right-radius:calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width))
	}
	.toast-header .btn-close {
	margin-right:calc(var(--bs-toast-padding-x)*-0.5);
		margin-left:var(--bs-toast-padding-x)
	}
	.toast-body {
		padding:var(--bs-toast-padding-x);
		word-wrap:break-word
	}
	.modal {
	--bs-modal-zindex:1055;
	--bs-modal-width:500px;
	--bs-modal-padding:1rem;
	--bs-modal-margin:0.5rem;
	--bs-modal-bg:var(--bs-body-bg);
	--bs-modal-border-color:var(--bs-border-color-translucent);
	--bs-modal-border-width:var(--bs-border-width);
	--bs-modal-border-radius:var(--bs-border-radius-lg);
	--bs-modal-box-shadow:0 0.125rem 0.25rem rgba(0,0,0,0.075);
	--bs-modal-inner-border-radius:calc(var(--bs-border-radius-lg) - var(--bs-border-width));
	--bs-modal-header-padding-x:1rem;
	--bs-modal-header-padding-y:1rem;
	--bs-modal-header-padding:1rem 1rem;
	--bs-modal-header-border-color:var(--bs-border-color);
	--bs-modal-header-border-width:var(--bs-border-width);
	--bs-modal-title-line-height:1.5;
	--bs-modal-footer-gap:0.5rem;
	--bs-modal-footer-border-color:var(--bs-border-color);
	--bs-modal-footer-border-width:var(--bs-border-width);
		position:fixed;
		top:0;
		left:0;
		z-index:var(--bs-modal-zindex);
		display:none;
		width:100%;
		height:100%;
		overflow-x:hidden;
		overflow-y:auto;
		outline:0
	}
	.modal-dialog {
		position:relative;
		width:auto;
		margin:var(--bs-modal-margin);
		pointer-events:none
	}
	.modal.fade .modal-dialog {
		transition:transform .3s ease-out;
		transform:translateY(-50px)
	}
	@media(prefers-reduced-motion:reduce) {
	.modal.fade .modal-dialog {
		transition:none
	}
	}
	.modal.show .modal-dialog {
		transform:none
	}
	.modal.modal-static .modal-dialog {
		transform:scale(1.02)
	}
	.modal-dialog-scrollable {
	height:calc(100% - var(--bs-modal-margin)*2)
	}
	.modal-dialog-scrollable .modal-content {
		max-height:100%;
		overflow:hidden
	}
	.modal-dialog-scrollable .modal-body {
		overflow-y:auto
	}
	.modal-dialog-centered {
		display:flex;
		align-items:center;
	min-height:calc(100% - var(--bs-modal-margin)*2)
	}
	.modal-content {
		position:relative;
		display:flex;
		flex-direction:column;
		width:100%;
		color:var(--bs-modal-color);
		pointer-events:auto;
		background-color:var(--bs-modal-bg);
		background-clip:padding-box;
		border:var(--bs-modal-border-width) solid var(--bs-modal-border-color);
		border-radius:var(--bs-modal-border-radius);
		outline:0
	}
	.modal-backdrop {
	--bs-backdrop-zindex:1050;
	--bs-backdrop-bg:#000;
	--bs-backdrop-opacity:0.5;
		position:fixed;
		top:0;
		left:0;
		z-index:var(--bs-backdrop-zindex);
		width:100vw;
		height:100vh;
		background-color:var(--bs-backdrop-bg)
	}
	.modal-backdrop.fade {
		opacity:0
	}
	.modal-backdrop.show {
		opacity:var(--bs-backdrop-opacity)
	}
	.modal-header {
		display:flex;
		flex-shrink:0;
		align-items:center;
		justify-content:space-between;
		padding:var(--bs-modal-header-padding);
		border-bottom:var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
		border-top-left-radius:var(--bs-modal-inner-border-radius);
		border-top-right-radius:var(--bs-modal-inner-border-radius)
	}
	.modal-header .btn-close {
	padding:calc(var(--bs-modal-header-padding-y)*0.5) calc(var(--bs-modal-header-padding-x)*0.5);
	margin:calc(var(--bs-modal-header-padding-y)*-0.5) calc(var(--bs-modal-header-padding-x)*-0.5) calc(var(--bs-modal-header-padding-y)*-0.5) auto
	}
	.modal-title {
		margin-bottom:0;
		line-height:var(--bs-modal-title-line-height)
	}
	.modal-body {
		position:relative;
		flex:1 1 auto;
		padding:var(--bs-modal-padding)
	}
	.modal-footer {
		display:flex;
		flex-shrink:0;
		flex-wrap:wrap;
		align-items:center;
		justify-content:flex-end;
	padding:calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap)*0.5);
		background-color:var(--bs-modal-footer-bg);
		border-top:var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
		border-bottom-right-radius:var(--bs-modal-inner-border-radius);
		border-bottom-left-radius:var(--bs-modal-inner-border-radius)
	}
	.modal-footer>* {
	margin:calc(var(--bs-modal-footer-gap)*0.5)
	}
	@media(min-width:576px) {
	.modal {
	--bs-modal-margin:1.75rem;
	--bs-modal-box-shadow:0px 15px 68px rgba(11,3,35,0.15)
	}
	.modal-dialog {
		max-width:var(--bs-modal-width);
		margin-right:auto;
		margin-left:auto
	}
	.modal-sm {
	--bs-modal-width:300px
	}
	}
	@media(min-width:992px) {
	.modal-lg, .modal-xl {
	--bs-modal-width:800px
	}
	}
	@media(min-width:1200px) {
	.modal-xl {
	--bs-modal-width:1140px
	}
	}
	.modal-fullscreen {
		width:100vw;
		max-width:none;
		height:100%;
		margin:0
	}
	.modal-fullscreen .modal-content {
		height:100%;
		border:0;
		border-radius:0
	}
	.modal-fullscreen .modal-footer, .modal-fullscreen .modal-header {
		border-radius:0
	}
	.modal-fullscreen .modal-body {
		overflow-y:auto
	}
	@media(max-width:575.98px) {
	.modal-fullscreen-sm-down {
		width:100vw;
		max-width:none;
		height:100%;
		margin:0
	}
	.modal-fullscreen-sm-down .modal-content {
		height:100%;
		border:0;
		border-radius:0
	}
	.modal-fullscreen-sm-down .modal-footer, .modal-fullscreen-sm-down .modal-header {
		border-radius:0
	}
	.modal-fullscreen-sm-down .modal-body {
		overflow-y:auto
	}
	}
	@media(max-width:767.98px) {
	.modal-fullscreen-md-down {
		width:100vw;
		max-width:none;
		height:100%;
		margin:0
	}
	.modal-fullscreen-md-down .modal-content {
		height:100%;
		border:0;
		border-radius:0
	}
	.modal-fullscreen-md-down .modal-footer, .modal-fullscreen-md-down .modal-header {
		border-radius:0
	}
	.modal-fullscreen-md-down .modal-body {
		overflow-y:auto
	}
	}
	@media(max-width:991.98px) {
	.modal-fullscreen-lg-down {
		width:100vw;
		max-width:none;
		height:100%;
		margin:0
	}
	.modal-fullscreen-lg-down .modal-content {
		height:100%;
		border:0;
		border-radius:0
	}
	.modal-fullscreen-lg-down .modal-footer, .modal-fullscreen-lg-down .modal-header {
		border-radius:0
	}
	.modal-fullscreen-lg-down .modal-body {
		overflow-y:auto
	}
	}
	@media(max-width:1199.98px) {
	.modal-fullscreen-xl-down {
		width:100vw;
		max-width:none;
		height:100%;
		margin:0
	}
	.modal-fullscreen-xl-down .modal-content {
		height:100%;
		border:0;
		border-radius:0
	}
	.modal-fullscreen-xl-down .modal-footer, .modal-fullscreen-xl-down .modal-header {
		border-radius:0
	}
	.modal-fullscreen-xl-down .modal-body {
		overflow-y:auto
	}
	}
	@media(max-width:1399.98px) {
	.modal-fullscreen-xxl-down {
		width:100vw;
		max-width:none;
		height:100%;
		margin:0
	}
	.modal-fullscreen-xxl-down .modal-content {
		height:100%;
		border:0;
		border-radius:0
	}
	.modal-fullscreen-xxl-down .modal-footer, .modal-fullscreen-xxl-down .modal-header {
		border-radius:0
	}
	.modal-fullscreen-xxl-down .modal-body {
		overflow-y:auto
	}
	}
	.tooltip {
	--bs-tooltip-zindex:1080;
	--bs-tooltip-max-width:200px;
	--bs-tooltip-padding-x:0.5rem;
	--bs-tooltip-padding-y:0.25rem;
	--bs-tooltip-font-size:0.875rem;
	--bs-tooltip-color:var(--bs-body-bg);
	--bs-tooltip-bg:var(--bs-emphasis-color);
	--bs-tooltip-border-radius:var(--bs-border-radius);
	--bs-tooltip-opacity:0.9;
	--bs-tooltip-arrow-width:0.8rem;
	--bs-tooltip-arrow-height:0.4rem;
		z-index:var(--bs-tooltip-zindex);
		display:block;
		margin:var(--bs-tooltip-margin);
		font-family:var(--bs-font-sans-serif);
		font-style:normal;
		font-weight:400;
		line-height:1.5;
		text-align:left;
		text-align:start;
		text-decoration:none;
		text-shadow:none;
		text-transform:none;
		letter-spacing:normal;
		word-break:normal;
		white-space:normal;
		word-spacing:normal;
		line-break:auto;
		font-size:var(--bs-tooltip-font-size);
		word-wrap:break-word;
		opacity:0
	}
	.tooltip.show {
		opacity:var(--bs-tooltip-opacity)
	}
	.tooltip .tooltip-arrow {
		display:block;
		width:var(--bs-tooltip-arrow-width);
		height:var(--bs-tooltip-arrow-height)
	}
	.tooltip .tooltip-arrow:before {
		position:absolute;
		content:"";
		border-color:transparent;
		border-style:solid
	}
	.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow, .bs-tooltip-top .tooltip-arrow {
	bottom:calc(var(--bs-tooltip-arrow-height)*-1)
	}
	.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow:before, .bs-tooltip-top .tooltip-arrow:before {
		top:-1px;
	border-left-width:calc(var(--bs-tooltip-arrow-width)*0.5);
		border-bottom-width:0;
	border-right-width:calc(var(--bs-tooltip-arrow-width)*0.5);
		border-top-width:var(--bs-tooltip-arrow-height);
		border-top-color:var(--bs-tooltip-bg)
	}
	.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow, .bs-tooltip-end .tooltip-arrow {
	left:calc(var(--bs-tooltip-arrow-height)*-1);
		width:var(--bs-tooltip-arrow-height);
		height:var(--bs-tooltip-arrow-width)
	}
	.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow:before, .bs-tooltip-end .tooltip-arrow:before {
		right:-1px;
		border-left-width:0;
	border-bottom-width:calc(var(--bs-tooltip-arrow-width)*0.5);
		border-right-width:var(--bs-tooltip-arrow-height);
	border-top-width:calc(var(--bs-tooltip-arrow-width)*0.5);
		border-right-color:var(--bs-tooltip-bg)
	}
	.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow, .bs-tooltip-bottom .tooltip-arrow {
	top:calc(var(--bs-tooltip-arrow-height)*-1)
	}
	.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow:before, .bs-tooltip-bottom .tooltip-arrow:before {
		bottom:-1px;
	border-left-width:calc(var(--bs-tooltip-arrow-width)*0.5);
		border-bottom-width:var(--bs-tooltip-arrow-height);
	border-right-width:calc(var(--bs-tooltip-arrow-width)*0.5);
		border-top-width:0;
		border-bottom-color:var(--bs-tooltip-bg)
	}
	.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow {
	right:calc(var(--bs-tooltip-arrow-height)*-1);
		width:var(--bs-tooltip-arrow-height);
		height:var(--bs-tooltip-arrow-width)
	}
	.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow:before, .bs-tooltip-start .tooltip-arrow:before {
		left:-1px;
		border-left-width:var(--bs-tooltip-arrow-height);
	border-bottom-width:calc(var(--bs-tooltip-arrow-width)*0.5);
		border-right-width:0;
	border-top-width:calc(var(--bs-tooltip-arrow-width)*0.5);
		border-left-color:var(--bs-tooltip-bg)
	}
	.tooltip-inner {
		max-width:var(--bs-tooltip-max-width);
		padding:var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
		color:var(--bs-tooltip-color);
		text-align:center;
		background-color:var(--bs-tooltip-bg);
		border-radius:var(--bs-tooltip-border-radius)
	}
	.popover {
	--bs-popover-zindex:1070;
	--bs-popover-max-width:276px;
	--bs-popover-font-size:0.875rem;
	--bs-popover-bg:var(--bs-body-bg);
	--bs-popover-border-width:var(--bs-border-width);
	--bs-popover-border-color:var(--bs-border-color-translucent);
	--bs-popover-border-radius:var(--bs-border-radius-lg);
	--bs-popover-inner-border-radius:calc(var(--bs-border-radius-lg) - var(--bs-border-width));
	--bs-popover-box-shadow:0px 15px 68px rgba(11,3,35,0.15);
	--bs-popover-header-padding-x:1rem;
	--bs-popover-header-padding-y:0.5rem;
	--bs-popover-header-font-size:1rem;
	--bs-popover-header-color:#030523;
	--bs-popover-header-bg:var(--bs-secondary-bg);
	--bs-popover-body-padding-x:1rem;
	--bs-popover-body-padding-y:1rem;
	--bs-popover-body-color:var(--bs-body-color);
	--bs-popover-arrow-width:1rem;
	--bs-popover-arrow-height:0.5rem;
	--bs-popover-arrow-border:var(--bs-popover-border-color);
		z-index:var(--bs-popover-zindex);
		display:block;
		max-width:var(--bs-popover-max-width);
		font-family:var(--bs-font-sans-serif);
		font-style:normal;
		font-weight:400;
		line-height:1.5;
		text-align:left;
		text-align:start;
		text-decoration:none;
		text-shadow:none;
		text-transform:none;
		letter-spacing:normal;
		word-break:normal;
		white-space:normal;
		word-spacing:normal;
		line-break:auto;
		font-size:var(--bs-popover-font-size);
		word-wrap:break-word;
		background-color:var(--bs-popover-bg);
		background-clip:padding-box;
		border:var(--bs-popover-border-width) solid var(--bs-popover-border-color);
		border-radius:var(--bs-popover-border-radius)
	}
	.popover .popover-arrow {
		display:block;
		width:var(--bs-popover-arrow-width);
		height:var(--bs-popover-arrow-height)
	}
	.popover .popover-arrow:after, .popover .popover-arrow:before {
		position:absolute;
		display:block;
		content:"";
		border:0 solid transparent
	}
	.bs-popover-auto[data-popper-placement^=top]>.popover-arrow, .bs-popover-top>.popover-arrow {
	bottom:calc(var(--bs-popover-arrow-height)*-1 - var(--bs-popover-border-width))
	}
	.bs-popover-auto[data-popper-placement^=top]>.popover-arrow:after, .bs-popover-auto[data-popper-placement^=top]>.popover-arrow:before, .bs-popover-top>.popover-arrow:after, .bs-popover-top>.popover-arrow:before {
	border-left-width:calc(var(--bs-popover-arrow-width)*0.5);
		border-bottom-width:0;
	border-right-width:calc(var(--bs-popover-arrow-width)*0.5);
		border-top-width:var(--bs-popover-arrow-height)
	}
	.bs-popover-auto[data-popper-placement^=top]>.popover-arrow:before, .bs-popover-top>.popover-arrow:before {
		bottom:0;
		border-top-color:var(--bs-popover-arrow-border)
	}
	.bs-popover-auto[data-popper-placement^=top]>.popover-arrow:after, .bs-popover-top>.popover-arrow:after {
		bottom:var(--bs-popover-border-width);
		border-top-color:var(--bs-popover-bg)
	}
	.bs-popover-auto[data-popper-placement^=right]>.popover-arrow, .bs-popover-end>.popover-arrow {
	left:calc(var(--bs-popover-arrow-height)*-1 - var(--bs-popover-border-width));
		width:var(--bs-popover-arrow-height);
		height:var(--bs-popover-arrow-width)
	}
	.bs-popover-auto[data-popper-placement^=right]>.popover-arrow:after, .bs-popover-auto[data-popper-placement^=right]>.popover-arrow:before, .bs-popover-end>.popover-arrow:after, .bs-popover-end>.popover-arrow:before {
		border-left-width:0;
	border-bottom-width:calc(var(--bs-popover-arrow-width)*0.5);
		border-right-width:var(--bs-popover-arrow-height);
	border-top-width:calc(var(--bs-popover-arrow-width)*0.5)
	}
	.bs-popover-auto[data-popper-placement^=right]>.popover-arrow:before, .bs-popover-end>.popover-arrow:before {
		left:0;
		border-right-color:var(--bs-popover-arrow-border)
	}
	.bs-popover-auto[data-popper-placement^=right]>.popover-arrow:after, .bs-popover-end>.popover-arrow:after {
		left:var(--bs-popover-border-width);
		border-right-color:var(--bs-popover-bg)
	}
	.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow, .bs-popover-bottom>.popover-arrow {
	top:calc(var(--bs-popover-arrow-height)*-1 - var(--bs-popover-border-width))
	}
	.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow:after, .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow:before, .bs-popover-bottom>.popover-arrow:after, .bs-popover-bottom>.popover-arrow:before {
	border-left-width:calc(var(--bs-popover-arrow-width)*0.5);
		border-bottom-width:var(--bs-popover-arrow-height);
	border-right-width:calc(var(--bs-popover-arrow-width)*0.5);
		border-top-width:0
	}
	.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow:before, .bs-popover-bottom>.popover-arrow:before {
		top:0;
		border-bottom-color:var(--bs-popover-arrow-border)
	}
	.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow:after, .bs-popover-bottom>.popover-arrow:after {
		top:var(--bs-popover-border-width);
		border-bottom-color:var(--bs-popover-bg)
	}
	.bs-popover-auto[data-popper-placement^=bottom] .popover-header:before, .bs-popover-bottom .popover-header:before {
		position:absolute;
		top:0;
		left:50%;
		display:block;
		width:var(--bs-popover-arrow-width);
	margin-left:calc(var(--bs-popover-arrow-width)*-0.5);
		content:"";
		border-bottom:var(--bs-popover-border-width) solid var(--bs-popover-header-bg)
	}
	.bs-popover-auto[data-popper-placement^=left]>.popover-arrow, .bs-popover-start>.popover-arrow {
	right:calc(var(--bs-popover-arrow-height)*-1 - var(--bs-popover-border-width));
		width:var(--bs-popover-arrow-height);
		height:var(--bs-popover-arrow-width)
	}
	.bs-popover-auto[data-popper-placement^=left]>.popover-arrow:after, .bs-popover-auto[data-popper-placement^=left]>.popover-arrow:before, .bs-popover-start>.popover-arrow:after, .bs-popover-start>.popover-arrow:before {
		border-left-width:var(--bs-popover-arrow-height);
	border-bottom-width:calc(var(--bs-popover-arrow-width)*0.5);
		border-right-width:0;
	border-top-width:calc(var(--bs-popover-arrow-width)*0.5)
	}
	.bs-popover-auto[data-popper-placement^=left]>.popover-arrow:before, .bs-popover-start>.popover-arrow:before {
		right:0;
		border-left-color:var(--bs-popover-arrow-border)
	}
	.bs-popover-auto[data-popper-placement^=left]>.popover-arrow:after, .bs-popover-start>.popover-arrow:after {
		right:var(--bs-popover-border-width);
		border-left-color:var(--bs-popover-bg)
	}
	.popover-header {
		padding:var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
		margin-bottom:0;
		font-size:var(--bs-popover-header-font-size);
		color:var(--bs-popover-header-color);
		background-color:var(--bs-popover-header-bg);
		border-bottom:var(--bs-popover-border-width) solid var(--bs-popover-border-color);
		border-top-left-radius:var(--bs-popover-inner-border-radius);
		border-top-right-radius:var(--bs-popover-inner-border-radius)
	}
	.popover-header:empty {
		display:none
	}
	.popover-body {
		padding:var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
		color:var(--bs-popover-body-color)
	}
	.carousel {
		position:relative
	}
	.carousel.pointer-event {
		touch-action:pan-y
	}
	.carousel-inner {
		position:relative;
		width:100%;
		overflow:hidden
	}
	.carousel-inner:after {
		display:block;
		clear:both;
		content:""
	}
	.carousel-item {
		position:relative;
		display:none;
		float:left;
		width:100%;
		margin-right:-100%;
		backface-visibility:hidden;
		transition:transform .6s ease-in-out
	}
	@media(prefers-reduced-motion:reduce) {
	.carousel-item {
		transition:none
	}
	}
	.carousel-item-next, .carousel-item-prev, .carousel-item.active {
		display:block
	}
	.active.carousel-item-end, .carousel-item-next:not(.carousel-item-start) {
		transform:translateX(100%)
	}
	.active.carousel-item-start, .carousel-item-prev:not(.carousel-item-end) {
		transform:translateX(-100%)
	}
	.carousel-fade .carousel-item {
		opacity:0;
		transition-property:opacity;
		transform:none
	}
	.carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end, .carousel-fade .carousel-item.active {
		z-index:1;
		opacity:1
	}
	.carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
		z-index:0;
		opacity:0;
		transition:opacity 0s .6s
	}
	@media(prefers-reduced-motion:reduce) {
	.carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
		transition:none
	}
	}
	.carousel-control-next, .carousel-control-prev {
		position:absolute;
		top:0;
		bottom:0;
		z-index:1;
		display:flex;
		align-items:center;
		justify-content:center;
		width:15%;
		padding:0;
		color:#fff;
		text-align:center;
		background:0 0;
		border:0;
		opacity:.5;
		transition:opacity .15s ease
	}
	@media(prefers-reduced-motion:reduce) {
	.carousel-control-next, .carousel-control-prev {
		transition:none
	}
	}
	.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
		color:#fff;
		text-decoration:none;
		outline:0;
		opacity:.9
	}
	.carousel-control-prev {
		left:0
	}
	.carousel-control-next {
		right:0
	}
	.carousel-control-next-icon, .carousel-control-prev-icon {
		display:inline-block;
		width:2rem;
		height:2rem;
		background-repeat:no-repeat;
		background-position:50%;
		background-size:100% 100%
	}
	.carousel-control-prev-icon {
		background-image:url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nI2ZmZic+PHBhdGggZD0nTTExLjM1NCAxLjY0NmEuNS41IDAgMDEwIC43MDhMNS43MDcgOGw1LjY0NyA1LjY0NmEuNS41IDAgMDEtLjcwOC43MDhsLTYtNmEuNS41IDAgMDEwLS43MDhsNi02YS41LjUgMCAwMS43MDggMHonLz48L3N2Zz4=)
	}
	.carousel-control-next-icon {
		background-image:url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nI2ZmZic+PHBhdGggZD0nTTQuNjQ2IDEuNjQ2YS41LjUgMCAwMS43MDggMGw2IDZhLjUuNSAwIDAxMCAuNzA4bC02IDZhLjUuNSAwIDAxLS43MDgtLjcwOEwxMC4yOTMgOCA0LjY0NiAyLjM1NGEuNS41IDAgMDEwLS43MDh6Jy8+PC9zdmc+)
	}
	.carousel-indicators {
		position:absolute;
		right:0;
		bottom:0;
		left:0;
		z-index:2;
		display:flex;
		justify-content:center;
		padding:0;
		margin-right:15%;
		margin-bottom:1rem;
		margin-left:15%
	}
	.carousel-indicators [data-bs-target] {
		box-sizing:content-box;
		flex:0 1 auto;
		width:30px;
		height:3px;
		padding:0;
		margin-right:3px;
		margin-left:3px;
		text-indent:-999px;
		cursor:pointer;
		background-color:#fff;
		background-clip:padding-box;
		border:0;
		border-top:10px solid transparent;
		border-bottom:10px solid transparent;
		opacity:.5;
		transition:opacity .6s ease
	}
	@media(prefers-reduced-motion:reduce) {
	.carousel-indicators [data-bs-target] {
		transition:none
	}
	}
	.carousel-indicators .active {
		opacity:1
	}
	.carousel-caption {
		position:absolute;
		right:15%;
		bottom:1.25rem;
		left:15%;
		padding-top:1.25rem;
		padding-bottom:1.25rem;
		color:#fff;
		text-align:center
	}
	.carousel-dark .carousel-control-next-icon, .carousel-dark .carousel-control-prev-icon {
		filter:invert(1) grayscale(100)
	}
	.carousel-dark .carousel-indicators [data-bs-target] {
		background-color:#000
	}
	.carousel-dark .carousel-caption {
		color:#000
	}
	[data-bs-theme=dark].carousel .carousel-control-next-icon, [data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon, [data-bs-theme=dark] .carousel .carousel-control-prev-icon {
		filter:invert(1) grayscale(100)
	}
	[data-bs-theme=dark].carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target] {
		background-color:#000
	}
	[data-bs-theme=dark].carousel .carousel-caption, [data-bs-theme=dark] .carousel .carousel-caption {
		color:#000
	}
	.spinner-border, .spinner-grow {
		display:inline-block;
		width:var(--bs-spinner-width);
		height:var(--bs-spinner-height);
		vertical-align:var(--bs-spinner-vertical-align);
		border-radius:50%;
		animation:var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name)
	}
	@keyframes spinner-border {
	to {
	transform:rotate(1turn)
	}
	}
	.spinner-border {
	--bs-spinner-width:2rem;
	--bs-spinner-height:2rem;
	--bs-spinner-vertical-align:-0.125em;
	--bs-spinner-border-width:0.25em;
	--bs-spinner-animation-speed:0.75s;
	--bs-spinner-animation-name:spinner-border;
		border:var(--bs-spinner-border-width) solid;
		border-right-color:transparent
	}
	.spinner-border-sm {
	--bs-spinner-width:1rem;
	--bs-spinner-height:1rem;
	--bs-spinner-border-width:0.2em
	}
	@keyframes spinner-grow {
	0% {
	transform:scale(0)
	}
	50% {
	opacity:1;
	transform:none
	}
	}
	.spinner-grow {
	--bs-spinner-width:2rem;
	--bs-spinner-height:2rem;
	--bs-spinner-vertical-align:-0.125em;
	--bs-spinner-animation-speed:0.75s;
	--bs-spinner-animation-name:spinner-grow;
		background-color:currentcolor;
		opacity:0
	}
	.spinner-grow-sm {
	--bs-spinner-width:1rem;
	--bs-spinner-height:1rem
	}
	@media(prefers-reduced-motion:reduce) {
	.spinner-border, .spinner-grow {
	--bs-spinner-animation-speed:1.5s
	}
	}
	.offcanvas, .offcanvas-lg, .offcanvas-md, .offcanvas-sm, .offcanvas-xl, .offcanvas-xxl {
	--bs-offcanvas-zindex:1045;
	--bs-offcanvas-width:400px;
	--bs-offcanvas-height:30vh;
	--bs-offcanvas-padding-x:1rem;
	--bs-offcanvas-padding-y:1rem;
	--bs-offcanvas-color:var(--bs-body-color);
	--bs-offcanvas-bg:var(--bs-body-bg);
	--bs-offcanvas-border-width:var(--bs-border-width);
	--bs-offcanvas-border-color:var(--bs-border-color-translucent);
	--bs-offcanvas-box-shadow:0 0.125rem 0.25rem rgba(0,0,0,0.075);
	--bs-offcanvas-transition:transform 0.3s ease-in-out;
	--bs-offcanvas-title-line-height:1.5
	}
	@media(max-width:575.98px) {
	.offcanvas-sm {
		position:fixed;
		bottom:0;
		z-index:var(--bs-offcanvas-zindex);
		display:flex;
		flex-direction:column;
		max-width:100%;
		color:var(--bs-offcanvas-color);
		visibility:hidden;
		background-color:var(--bs-offcanvas-bg);
		background-clip:padding-box;
		outline:0;
		transition:var(--bs-offcanvas-transition)
	}
	}
	@media(max-width:575.98px) and (prefers-reduced-motion:reduce) {
	.offcanvas-sm {
		transition:none
	}
	}
	@media(max-width:575.98px) {
	.offcanvas-sm.offcanvas-start {
		top:0;
		left:0;
		width:var(--bs-offcanvas-width);
		border-right:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateX(-100%)
	}
	.offcanvas-sm.offcanvas-end {
		top:0;
		right:0;
		width:var(--bs-offcanvas-width);
		border-left:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateX(100%)
	}
	.offcanvas-sm.offcanvas-top {
		top:0;
		border-bottom:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateY(-100%)
	}
	.offcanvas-sm.offcanvas-bottom, .offcanvas-sm.offcanvas-top {
		right:0;
		left:0;
		height:var(--bs-offcanvas-height);
		max-height:100%
	}
	.offcanvas-sm.offcanvas-bottom {
		border-top:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateY(100%)
	}
	.offcanvas-sm.show:not(.hiding), .offcanvas-sm.showing {
		transform:none
	}
	.offcanvas-sm.hiding, .offcanvas-sm.show, .offcanvas-sm.showing {
		visibility:visible
	}
	}
	@media(min-width:576px) {
	.offcanvas-sm {
	--bs-offcanvas-height:auto;
	--bs-offcanvas-border-width:0;
		background-color:transparent!important
	}
	.offcanvas-sm .offcanvas-header {
		display:none
	}
	.offcanvas-sm .offcanvas-body {
		display:flex;
		flex-grow:0;
		padding:0;
		overflow-y:visible;
		background-color:transparent!important
	}
	}
	@media(max-width:767.98px) {
	.offcanvas-md {
		position:fixed;
		bottom:0;
		z-index:var(--bs-offcanvas-zindex);
		display:flex;
		flex-direction:column;
		max-width:100%;
		color:var(--bs-offcanvas-color);
		visibility:hidden;
		background-color:var(--bs-offcanvas-bg);
		background-clip:padding-box;
		outline:0;
		transition:var(--bs-offcanvas-transition)
	}
	}
	@media(max-width:767.98px) and (prefers-reduced-motion:reduce) {
	.offcanvas-md {
		transition:none
	}
	}
	@media(max-width:767.98px) {
	.offcanvas-md.offcanvas-start {
		top:0;
		left:0;
		width:var(--bs-offcanvas-width);
		border-right:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateX(-100%)
	}
	.offcanvas-md.offcanvas-end {
		top:0;
		right:0;
		width:var(--bs-offcanvas-width);
		border-left:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateX(100%)
	}
	.offcanvas-md.offcanvas-top {
		top:0;
		border-bottom:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateY(-100%)
	}
	.offcanvas-md.offcanvas-bottom, .offcanvas-md.offcanvas-top {
		right:0;
		left:0;
		height:var(--bs-offcanvas-height);
		max-height:100%
	}
	.offcanvas-md.offcanvas-bottom {
		border-top:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateY(100%)
	}
	.offcanvas-md.show:not(.hiding), .offcanvas-md.showing {
		transform:none
	}
	.offcanvas-md.hiding, .offcanvas-md.show, .offcanvas-md.showing {
		visibility:visible
	}
	}
	@media(min-width:768px) {
	.offcanvas-md {
	--bs-offcanvas-height:auto;
	--bs-offcanvas-border-width:0;
		background-color:transparent!important
	}
	.offcanvas-md .offcanvas-header {
		display:none
	}
	.offcanvas-md .offcanvas-body {
		display:flex;
		flex-grow:0;
		padding:0;
		overflow-y:visible;
		background-color:transparent!important
	}
	}
	@media(max-width:991.98px) {
	.offcanvas-lg {
		position:fixed;
		bottom:0;
		z-index:var(--bs-offcanvas-zindex);
		display:flex;
		flex-direction:column;
		max-width:100%;
		color:var(--bs-offcanvas-color);
		visibility:hidden;
		background-color:var(--bs-offcanvas-bg);
		background-clip:padding-box;
		outline:0;
		transition:var(--bs-offcanvas-transition)
	}
	}
	@media(max-width:991.98px) and (prefers-reduced-motion:reduce) {
	.offcanvas-lg {
		transition:none
	}
	}
	@media(max-width:991.98px) {
	.offcanvas-lg.offcanvas-start {
		top:0;
		left:0;
		width:var(--bs-offcanvas-width);
		border-right:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateX(-100%)
	}
	.offcanvas-lg.offcanvas-end {
		top:0;
		right:0;
		width:var(--bs-offcanvas-width);
		border-left:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateX(100%)
	}
	.offcanvas-lg.offcanvas-top {
		top:0;
		border-bottom:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateY(-100%)
	}
	.offcanvas-lg.offcanvas-bottom, .offcanvas-lg.offcanvas-top {
		right:0;
		left:0;
		height:var(--bs-offcanvas-height);
		max-height:100%
	}
	.offcanvas-lg.offcanvas-bottom {
		border-top:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateY(100%)
	}
	.offcanvas-lg.show:not(.hiding), .offcanvas-lg.showing {
		transform:none
	}
	.offcanvas-lg.hiding, .offcanvas-lg.show, .offcanvas-lg.showing {
		visibility:visible
	}
	}
	@media(min-width:992px) {
	.offcanvas-lg {
	--bs-offcanvas-height:auto;
	--bs-offcanvas-border-width:0;
		background-color:transparent!important
	}
	.offcanvas-lg .offcanvas-header {
		display:none
	}
	.offcanvas-lg .offcanvas-body {
		display:flex;
		flex-grow:0;
		padding:0;
		overflow-y:visible;
		background-color:transparent!important
	}
	}
	@media(max-width:1199.98px) {
	.offcanvas-xl {
		position:fixed;
		bottom:0;
		z-index:var(--bs-offcanvas-zindex);
		display:flex;
		flex-direction:column;
		max-width:100%;
		color:var(--bs-offcanvas-color);
		visibility:hidden;
		background-color:var(--bs-offcanvas-bg);
		background-clip:padding-box;
		outline:0;
		transition:var(--bs-offcanvas-transition)
	}
	}
	@media(max-width:1199.98px) and (prefers-reduced-motion:reduce) {
	.offcanvas-xl {
		transition:none
	}
	}
	@media(max-width:1199.98px) {
	.offcanvas-xl.offcanvas-start {
		top:0;
		left:0;
		width:var(--bs-offcanvas-width);
		border-right:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateX(-100%)
	}
	.offcanvas-xl.offcanvas-end {
		top:0;
		right:0;
		width:var(--bs-offcanvas-width);
		border-left:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateX(100%)
	}
	.offcanvas-xl.offcanvas-top {
		top:0;
		border-bottom:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateY(-100%)
	}
	.offcanvas-xl.offcanvas-bottom, .offcanvas-xl.offcanvas-top {
		right:0;
		left:0;
		height:var(--bs-offcanvas-height);
		max-height:100%
	}
	.offcanvas-xl.offcanvas-bottom {
		border-top:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateY(100%)
	}
	.offcanvas-xl.show:not(.hiding), .offcanvas-xl.showing {
		transform:none
	}
	.offcanvas-xl.hiding, .offcanvas-xl.show, .offcanvas-xl.showing {
		visibility:visible
	}
	}
	@media(min-width:1200px) {
	.offcanvas-xl {
	--bs-offcanvas-height:auto;
	--bs-offcanvas-border-width:0;
		background-color:transparent!important
	}
	.offcanvas-xl .offcanvas-header {
		display:none
	}
	.offcanvas-xl .offcanvas-body {
		display:flex;
		flex-grow:0;
		padding:0;
		overflow-y:visible;
		background-color:transparent!important
	}
	}
	@media(max-width:1399.98px) {
	.offcanvas-xxl {
		position:fixed;
		bottom:0;
		z-index:var(--bs-offcanvas-zindex);
		display:flex;
		flex-direction:column;
		max-width:100%;
		color:var(--bs-offcanvas-color);
		visibility:hidden;
		background-color:var(--bs-offcanvas-bg);
		background-clip:padding-box;
		outline:0;
		transition:var(--bs-offcanvas-transition)
	}
	}
	@media(max-width:1399.98px) and (prefers-reduced-motion:reduce) {
	.offcanvas-xxl {
		transition:none
	}
	}
	@media(max-width:1399.98px) {
	.offcanvas-xxl.offcanvas-start {
		top:0;
		left:0;
		width:var(--bs-offcanvas-width);
		border-right:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateX(-100%)
	}
	.offcanvas-xxl.offcanvas-end {
		top:0;
		right:0;
		width:var(--bs-offcanvas-width);
		border-left:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateX(100%)
	}
	.offcanvas-xxl.offcanvas-top {
		top:0;
		border-bottom:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateY(-100%)
	}
	.offcanvas-xxl.offcanvas-bottom, .offcanvas-xxl.offcanvas-top {
		right:0;
		left:0;
		height:var(--bs-offcanvas-height);
		max-height:100%
	}
	.offcanvas-xxl.offcanvas-bottom {
		border-top:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateY(100%)
	}
	.offcanvas-xxl.show:not(.hiding), .offcanvas-xxl.showing {
		transform:none
	}
	.offcanvas-xxl.hiding, .offcanvas-xxl.show, .offcanvas-xxl.showing {
		visibility:visible
	}
	}
	@media(min-width:1400px) {
	.offcanvas-xxl {
	--bs-offcanvas-height:auto;
	--bs-offcanvas-border-width:0;
		background-color:transparent!important
	}
	.offcanvas-xxl .offcanvas-header {
		display:none
	}
	.offcanvas-xxl .offcanvas-body {
		display:flex;
		flex-grow:0;
		padding:0;
		overflow-y:visible;
		background-color:transparent!important
	}
	}
	.offcanvas {
		position:fixed;
		bottom:0;
		z-index:var(--bs-offcanvas-zindex);
		display:flex;
		flex-direction:column;
		max-width:100%;
		color:var(--bs-offcanvas-color);
		visibility:hidden;
		background-color:var(--bs-offcanvas-bg);
		background-clip:padding-box;
		outline:0;
		transition:var(--bs-offcanvas-transition)
	}
	@media(prefers-reduced-motion:reduce) {
	.offcanvas {
		transition:none
	}
	}
	.offcanvas.offcanvas-start {
		top:0;
		left:0;
		width:var(--bs-offcanvas-width);
		border-right:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateX(-100%)
	}
	.offcanvas.offcanvas-end {
		top:0;
		right:0;
		width:var(--bs-offcanvas-width);
		border-left:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateX(100%)
	}
	.offcanvas.offcanvas-top {
		top:0;
		border-bottom:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateY(-100%)
	}
	.offcanvas.offcanvas-bottom, .offcanvas.offcanvas-top {
		right:0;
		left:0;
		height:var(--bs-offcanvas-height);
		max-height:100%
	}
	.offcanvas.offcanvas-bottom {
		border-top:var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
		transform:translateY(100%)
	}
	.offcanvas.show:not(.hiding), .offcanvas.showing {
		transform:none
	}
	.offcanvas.hiding, .offcanvas.show, .offcanvas.showing {
		visibility:visible
	}
	.offcanvas-backdrop {
		position:fixed;
		top:0;
		left:0;
		z-index:1040;
		width:100vw;
		height:100vh;
		background-color:#000
	}
	.offcanvas-backdrop.fade {
		opacity:0
	}
	.offcanvas-backdrop.show {
		opacity:.5
	}
	.offcanvas-header {
		display:flex;
		align-items:center;
		justify-content:space-between;
		padding:var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x)
	}
	.offcanvas-header .btn-close {
	padding:calc(var(--bs-offcanvas-padding-y)*0.5) calc(var(--bs-offcanvas-padding-x)*0.5);
	margin-top:calc(var(--bs-offcanvas-padding-y)*-0.5);
	margin-right:calc(var(--bs-offcanvas-padding-x)*-0.5);
	margin-bottom:calc(var(--bs-offcanvas-padding-y)*-0.5)
	}
	.offcanvas-title {
		margin-bottom:0;
		line-height:var(--bs-offcanvas-title-line-height)
	}
	.offcanvas-body {
		flex-grow:1;
		padding:var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
		overflow-y:auto
	}
	.placeholder {
		display:inline-block;
		min-height:1em;
		vertical-align:middle;
		cursor:wait;
		background-color:currentcolor;
		opacity:.5
	}
	.placeholder.btn:before {
		display:inline-block;
		content:""
	}
	.placeholder-xs {
		min-height:.6em
	}
	.placeholder-sm {
		min-height:.8em
	}
	.placeholder-lg {
		min-height:1.2em
	}
	.placeholder-glow .placeholder {
		animation:placeholder-glow 2s ease-in-out infinite
	}
	@keyframes placeholder-glow {
	50% {
	opacity:.2
	}
	}
	.placeholder-wave {
		mask-image:linear-gradient(130deg, #000 55%, rgba(0,0,0,.8) 75%, #000 95%);
		mask-size:200% 100%;
		animation:placeholder-wave 2s linear infinite
	}
	@keyframes placeholder-wave {
	to {
	mask-position:-200% 0
	}
	}
	.clearfix:after {
		display:block;
		clear:both;
		content:""
	}
	.text-bg-primary {
		color:#000!important;
		background-color:RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1))!important
	}
	.text-bg-secondary {
		color:#fff!important;
		background-color:RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1))!important
	}
	.text-bg-success {
		color:#000!important;
		background-color:RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1))!important
	}
	.text-bg-info {
		color:#000!important;
		background-color:RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1))!important
	}
	.text-bg-light {
		color:#000!important;
		background-color:RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1))!important
	}
	.text-bg-danger {
		color:#000!important;
		background-color:RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1))!important
	}
	.text-bg-dark {
		color:#fff!important;
		background-color:RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1))!important
	}
	.text-bg-altdark {
		color:#fff!important;
		background-color:RGBA(var(--bs-altdark-rgb), var(--bs-bg-opacity, 1))!important
	}
	.text-bg-sky {
		color:#000!important;
		background-color:RGBA(var(--bs-sky-rgb), var(--bs-bg-opacity, 1))!important
	}
	.text-bg-mute {
		color:#000!important;
		background-color:RGBA(var(--bs-mute-rgb), var(--bs-bg-opacity, 1))!important
	}
	.text-bg-tertiary {
		color:#000!important;
		background-color:RGBA(var(--bs-tertiary-rgb), var(--bs-bg-opacity, 1))!important
	}
	.link-primary {
		color:RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1))!important
	}
	.link-primary:focus, .link-primary:hover {
		color:RGBA(230, 246, 132, var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(230, 246, 132, var(--bs-link-underline-opacity, 1))!important
	}
	.link-secondary {
		color:RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1))!important
	}
	.link-secondary:focus, .link-secondary:hover {
		color:RGBA(84, 84, 84, var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(84, 84, 84, var(--bs-link-underline-opacity, 1))!important
	}
	.link-success {
		color:RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1))!important
	}
	.link-success:focus, .link-success:hover {
		color:RGBA(93, 214, 137, var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(93, 214, 137, var(--bs-link-underline-opacity, 1))!important
	}
	.link-info {
		color:RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1))!important
	}
	.link-info:focus, .link-info:hover {
		color:RGBA(255, 255, 255, var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1))!important
	}
	.link-light {
		color:RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1))!important
	}
	.link-light:focus, .link-light:hover {
		color:RGBA(247, 249, 234, var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(247, 249, 234, var(--bs-link-underline-opacity, 1))!important
	}
	.link-danger {
		color:RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1))!important
	}
	.link-danger:focus, .link-danger:hover {
		color:RGBA(245, 61, 61, var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(245, 61, 61, var(--bs-link-underline-opacity, 1))!important
	}
	.link-dark {
		color:RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1))!important
	}
	.link-dark:focus, .link-dark:hover {
		color:RGBA(0, 0, 0, var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1))!important
	}
	.link-altdark {
		color:RGBA(var(--bs-altdark-rgb), var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(var(--bs-altdark-rgb), var(--bs-link-underline-opacity, 1))!important
	}
	.link-altdark:focus, .link-altdark:hover {
		color:RGBA(27, 27, 27, var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(27, 27, 27, var(--bs-link-underline-opacity, 1))!important
	}
	.link-sky {
		color:RGBA(var(--bs-sky-rgb), var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(var(--bs-sky-rgb), var(--bs-link-underline-opacity, 1))!important
	}
	.link-sky:focus, .link-sky:hover {
		color:RGBA(69, 210, 195, var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(69, 210, 195, var(--bs-link-underline-opacity, 1))!important
	}
	.link-mute {
		color:RGBA(var(--bs-mute-rgb), var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(var(--bs-mute-rgb), var(--bs-link-underline-opacity, 1))!important
	}
	.link-mute:focus, .link-mute:hover {
		color:RGBA(248, 248, 248, var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(248, 248, 248, var(--bs-link-underline-opacity, 1))!important
	}
	.link-tertiary {
		color:RGBA(var(--bs-tertiary-rgb), var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(var(--bs-tertiary-rgb), var(--bs-link-underline-opacity, 1))!important
	}
	.link-tertiary:focus, .link-tertiary:hover {
		color:RGBA(254, 122, 51, var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(254, 122, 51, var(--bs-link-underline-opacity, 1))!important
	}
	.link-body-emphasis {
		color:RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1))!important;
		text-decoration-color:RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1))!important
	}
	.link-body-emphasis:focus, .link-body-emphasis:hover {
		color:RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, .75))!important;
		text-decoration-color:RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75))!important
	}
	.focus-ring:focus {
		outline:0;
		box-shadow:var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color)
	}
	.icon-link {
		display:inline-flex;
	gap:.375rem;
		align-items:center;
		text-decoration-color:rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
		text-underline-offset:.25em;
		backface-visibility:hidden
	}
	.icon-link>.bi {
		flex-shrink:0;
		width:1em;
		height:1em;
		fill:currentcolor;
		transition:transform .2s ease-in-out
	}
	@media(prefers-reduced-motion:reduce) {
	.icon-link>.bi {
		transition:none
	}
	}
	.icon-link-hover:focus-visible>.bi, .icon-link-hover:hover>.bi {
	transform:var(--bs-icon-link-transform, translate3d(.25em, 0, 0))
	}
	.ratio {
		position:relative;
		width:100%
	}
	.ratio:before {
		display:block;
		padding-top:var(--bs-aspect-ratio);
		content:""
	}
	.ratio>* {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%
	}
	.ratio-1x1 {
	--bs-aspect-ratio:100%
	}
	.ratio-4x3 {
	--bs-aspect-ratio:75%
	}
	.ratio-16x9 {
	--bs-aspect-ratio:56.25%
	}
	.ratio-21x9 {
	--bs-aspect-ratio:42.85714%
	}
	.fixed-top {
		top:0
	}
	.fixed-bottom, .fixed-top {
		position:fixed;
		right:0;
		left:0;
		z-index:1030
	}
	.fixed-bottom {
		bottom:0
	}
	.sticky-top {
		top:0
	}
	.sticky-bottom, .sticky-top {
		position:sticky;
		z-index:1020
	}
	.sticky-bottom {
		bottom:0
	}
	@media(min-width:576px) {
	.sticky-sm-top {
		position:sticky;
		top:0;
		z-index:1020
	}
	.sticky-sm-bottom {
		position:sticky;
		bottom:0;
		z-index:1020
	}
	}
	@media(min-width:768px) {
	.sticky-md-top {
		position:sticky;
		top:0;
		z-index:1020
	}
	.sticky-md-bottom {
		position:sticky;
		bottom:0;
		z-index:1020
	}
	}
	@media(min-width:992px) {
	.sticky-lg-top {
		position:sticky;
		top:0;
		z-index:1020
	}
	.sticky-lg-bottom {
		position:sticky;
		bottom:0;
		z-index:1020
	}
	}
	@media(min-width:1200px) {
	.sticky-xl-top {
		position:sticky;
		top:0;
		z-index:1020
	}
	.sticky-xl-bottom {
		position:sticky;
		bottom:0;
		z-index:1020
	}
	}
	@media(min-width:1400px) {
	.sticky-xxl-top {
		position:sticky;
		top:0;
		z-index:1020
	}
	.sticky-xxl-bottom {
		position:sticky;
		bottom:0;
		z-index:1020
	}
	}
	.hstack {
		flex-direction:row;
		align-items:center
	}
	.hstack, .vstack {
		display:flex;
		align-self:stretch
	}
	.vstack {
		flex:1 1 auto;
		flex-direction:column
	}
	.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
		width:1px!important;
		height:1px!important;
		padding:0!important;
		margin:-1px!important;
		overflow:hidden!important;
		clip:rect(0,0,0,0)!important;
		white-space:nowrap!important;
		border:0!important
	}
	.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption), .visually-hidden:not(caption) {
		position:absolute!important
	}
	.stretched-link:after {
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		z-index:1;
		content:""
	}
	.text-truncate {
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap
	}
	.vr {
		display:inline-block;
		align-self:stretch;
		width:var(--bs-border-width);
		min-height:1em;
		background-color:currentcolor;
		opacity:.25
	}
	.align-baseline {
		vertical-align:baseline!important
	}
	.align-top {
		vertical-align:top!important
	}
	.align-middle {
		vertical-align:middle!important
	}
	.align-bottom {
		vertical-align:bottom!important
	}
	.align-text-bottom {
		vertical-align:text-bottom!important
	}
	.align-text-top {
		vertical-align:text-top!important
	}
	.float-start {
		float:left!important
	}
	.float-end {
		float:right!important
	}
	.float-none {
		float:none!important
	}
	.object-fit-contain {
		object-fit:contain!important
	}
	.object-fit-cover {
		object-fit:cover!important
	}
	.object-fit-fill {
		object-fit:fill!important
	}
	.object-fit-scale {
		object-fit:scale-down!important
	}
	.object-fit-none {
		object-fit:none!important
	}
	.opacity-0 {
		opacity:0!important
	}
	.opacity-25 {
		opacity:.25!important
	}
	.opacity-50 {
		opacity:.5!important
	}
	.opacity-75 {
		opacity:.75!important
	}
	.opacity-100 {
		opacity:1!important
	}
	.overflow-auto {
		overflow:auto!important
	}
	.overflow-hidden {
		overflow:hidden!important
	}
	.overflow-visible {
		overflow:visible!important
	}
	.overflow-scroll {
		overflow:scroll!important
	}
	.overflow-x-auto {
		overflow-x:auto!important
	}
	.overflow-x-hidden {
		overflow-x:hidden!important
	}
	.overflow-x-visible {
		overflow-x:visible!important
	}
	.overflow-x-scroll {
		overflow-x:scroll!important
	}
	.overflow-y-auto {
		overflow-y:auto!important
	}
	.overflow-y-hidden {
		overflow-y:hidden!important
	}
	.overflow-y-visible {
		overflow-y:visible!important
	}
	.overflow-y-scroll {
		overflow-y:scroll!important
	}
	.d-inline {
		display:inline!important
	}
	.d-inline-block {
		display:inline-block!important
	}
	.d-block {
		display:block!important
	}
	.d-grid {
		display:grid!important
	}
	.d-inline-grid {
		display:inline-grid!important
	}
	.d-table {
		display:table!important
	}
	.d-table-row {
		display:table-row!important
	}
	.d-table-cell {
		display:table-cell!important
	}
	.d-flex {
		display:flex!important
	}
	.d-inline-flex {
		display:inline-flex!important
	}
	.d-none {
		display:none!important
	}
	.shadow {
		box-shadow:0 15px 68px rgba(11,3,35,.15)!important
	}
	.shadow-sm {
	box-shadow:0 .125rem .25rem rgba(0,0,0,.075)!important
	}
	.shadow-lg {
		box-shadow:0 1rem 3rem rgba(0,0,0,.175)!important
	}
	.shadow-none {
		box-shadow:none!important
	}
	.focus-ring-primary {
	--bs-focus-ring-color:rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity))
	}
	.focus-ring-secondary {
	--bs-focus-ring-color:rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity))
	}
	.focus-ring-success {
	--bs-focus-ring-color:rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity))
	}
	.focus-ring-info {
	--bs-focus-ring-color:rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity))
	}
	.focus-ring-light {
	--bs-focus-ring-color:rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity))
	}
	.focus-ring-danger {
	--bs-focus-ring-color:rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity))
	}
	.focus-ring-dark {
	--bs-focus-ring-color:rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity))
	}
	.focus-ring-altdark {
	--bs-focus-ring-color:rgba(var(--bs-altdark-rgb), var(--bs-focus-ring-opacity))
	}
	.focus-ring-sky {
	--bs-focus-ring-color:rgba(var(--bs-sky-rgb), var(--bs-focus-ring-opacity))
	}
	.focus-ring-mute {
	--bs-focus-ring-color:rgba(var(--bs-mute-rgb), var(--bs-focus-ring-opacity))
	}
	.focus-ring-tertiary {
	--bs-focus-ring-color:rgba(var(--bs-tertiary-rgb), var(--bs-focus-ring-opacity))
	}
	.position-static {
		position:static!important
	}
	.position-relative {
		position:relative!important
	}
	.position-absolute {
		position:absolute!important
	}
	.position-fixed {
		position:fixed!important
	}
	.position-sticky {
		position:sticky!important
	}
	.top-0 {
		top:0!important
	}
	.top-50 {
		top:50%!important
	}
	.top-100 {
		top:100%!important
	}
	.bottom-0 {
		bottom:0!important
	}
	.bottom-50 {
		bottom:50%!important
	}
	.bottom-100 {
		bottom:100%!important
	}
	.start-0 {
		left:0!important
	}
	.start-50 {
		left:50%!important
	}
	.start-100 {
		left:100%!important
	}
	.end-0 {
		right:0!important
	}
	.end-50 {
		right:50%!important
	}
	.end-100 {
		right:100%!important
	}
	.translate-middle {
		transform:translate(-50%, -50%)!important
	}
	.translate-middle-x {
		transform:translateX(-50%)!important
	}
	.translate-middle-y {
		transform:translateY(-50%)!important
	}
	.border {
		border:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
	}
	.border-0 {
		border:0!important
	}
	.border-top {
		border-top:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
	}
	.border-top-0 {
		border-top:0!important
	}
	.border-end {
		border-right:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
	}
	.border-end-0 {
		border-right:0!important
	}
	.border-bottom {
		border-bottom:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
	}
	.border-bottom-0 {
		border-bottom:0!important
	}
	.border-start {
		border-left:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important
	}
	.border-start-0 {
		border-left:0!important
	}
	.border-primary {
	--bs-border-opacity:1;
		border-color:rgba(var(--bs-primary-rgb), var(--bs-border-opacity))!important
	}
	.border-secondary {
	--bs-border-opacity:1;
		border-color:rgba(var(--bs-secondary-rgb), var(--bs-border-opacity))!important
	}
	.border-success {
	--bs-border-opacity:1;
		border-color:rgba(var(--bs-success-rgb), var(--bs-border-opacity))!important
	}
	.border-info {
	--bs-border-opacity:1;
		border-color:rgba(var(--bs-info-rgb), var(--bs-border-opacity))!important
	}
	.border-light {
	--bs-border-opacity:1;
		border-color:rgba(var(--bs-light-rgb), var(--bs-border-opacity))!important
	}
	.border-danger {
	--bs-border-opacity:1;
		border-color:rgba(var(--bs-danger-rgb), var(--bs-border-opacity))!important
	}
	.border-dark {
	--bs-border-opacity:1;
		border-color:rgba(var(--bs-dark-rgb), var(--bs-border-opacity))!important
	}
	.border-altdark {
	--bs-border-opacity:1;
		border-color:rgba(var(--bs-altdark-rgb), var(--bs-border-opacity))!important
	}
	.border-sky {
	--bs-border-opacity:1;
		border-color:rgba(var(--bs-sky-rgb), var(--bs-border-opacity))!important
	}
	.border-mute {
	--bs-border-opacity:1;
		border-color:rgba(var(--bs-mute-rgb), var(--bs-border-opacity))!important
	}
	.border-tertiary {
	--bs-border-opacity:1;
		border-color:rgba(var(--bs-tertiary-rgb), var(--bs-border-opacity))!important
	}
	.border-black {
	--bs-border-opacity:1;
		border-color:rgba(var(--bs-black-rgb), var(--bs-border-opacity))!important
	}
	.border-white {
	--bs-border-opacity:1;
		border-color:rgba(var(--bs-white-rgb), var(--bs-border-opacity))!important
	}
	.border-primary-subtle {
		border-color:var(--bs-primary-border-subtle)!important
	}
	.border-secondary-subtle {
		border-color:var(--bs-secondary-border-subtle)!important
	}
	.border-success-subtle {
		border-color:var(--bs-success-border-subtle)!important
	}
	.border-info-subtle {
		border-color:var(--bs-info-border-subtle)!important
	}
	.border-warning-subtle {
		border-color:var(--bs-warning-border-subtle)!important
	}
	.border-danger-subtle {
		border-color:var(--bs-danger-border-subtle)!important
	}
	.border-light-subtle {
		border-color:var(--bs-light-border-subtle)!important
	}
	.border-dark-subtle {
		border-color:var(--bs-dark-border-subtle)!important
	}
	.border-1 {
		border-width:1px!important
	}
	.border-2 {
		border-width:2px!important
	}
	.border-3 {
		border-width:3px!important
	}
	.border-4 {
		border-width:4px!important
	}
	.border-5 {
		border-width:5px!important
	}
	.border-opacity-10 {
	--bs-border-opacity:0.1
	}
	.border-opacity-25 {
	--bs-border-opacity:0.25
	}
	.border-opacity-50 {
	--bs-border-opacity:0.5
	}
	.border-opacity-75 {
	--bs-border-opacity:0.75
	}
	.border-opacity-100 {
	--bs-border-opacity:1
	}
	.w-25 {
		width:25%!important
	}
	.w-50 {
		width:50%!important
	}
	.w-75 {
		width:75%!important
	}
	.w-100 {
		width:100%!important
	}
	.w-auto {
		width:auto!important
	}
	.mw-100 {
		max-width:100%!important
	}
	.vw-100 {
		width:100vw!important
	}
	.min-vw-100 {
		min-width:100vw!important
	}
	.h-25 {
		height:25%!important
	}
	.h-50 {
		height:50%!important
	}
	.h-75 {
		height:75%!important
	}
	.h-100 {
		height:100%!important
	}
	.h-auto {
		height:auto!important
	}
	.mh-100 {
		max-height:100%!important
	}
	.vh-100 {
		height:100vh!important
	}
	.min-vh-100 {
		min-height:100vh!important
	}
	.flex-fill {
		flex:1 1 auto!important
	}
	.flex-row {
		flex-direction:row!important
	}
	.flex-column {
		flex-direction:column!important
	}
	.flex-row-reverse {
		flex-direction:row-reverse!important
	}
	.flex-column-reverse {
		flex-direction:column-reverse!important
	}
	.flex-grow-0 {
		flex-grow:0!important
	}
	.flex-grow-1 {
		flex-grow:1!important
	}
	.flex-shrink-0 {
		flex-shrink:0!important
	}
	.flex-shrink-1 {
		flex-shrink:1!important
	}
	.flex-wrap {
		flex-wrap:wrap!important
	}
	.flex-nowrap {
		flex-wrap:nowrap!important
	}
	.flex-wrap-reverse {
		flex-wrap:wrap-reverse!important
	}
	.justify-content-start {
		justify-content:flex-start!important
	}
	.justify-content-end {
		justify-content:flex-end!important
	}
	.justify-content-center {
		justify-content:center!important
	}
	.justify-content-between {
		justify-content:space-between!important
	}
	.justify-content-around {
		justify-content:space-around!important
	}
	.justify-content-evenly {
		justify-content:space-evenly!important
	}
	.align-items-start {
		align-items:flex-start!important
	}
	.align-items-end {
		align-items:flex-end!important
	}
	.align-items-center {
		align-items:center!important
	}
	.align-items-baseline {
		align-items:baseline!important
	}
	.align-items-stretch {
		align-items:stretch!important
	}
	.align-content-start {
		align-content:flex-start!important
	}
	.align-content-end {
		align-content:flex-end!important
	}
	.align-content-center {
		align-content:center!important
	}
	.align-content-between {
		align-content:space-between!important
	}
	.align-content-around {
		align-content:space-around!important
	}
	.align-content-stretch {
		align-content:stretch!important
	}
	.align-self-auto {
		align-self:auto!important
	}
	.align-self-start {
		align-self:flex-start!important
	}
	.align-self-end {
		align-self:flex-end!important
	}
	.align-self-center {
		align-self:center!important
	}
	.align-self-baseline {
		align-self:baseline!important
	}
	.align-self-stretch {
		align-self:stretch!important
	}
	.order-first {
		order:-1!important
	}
	.order-0 {
		order:0!important
	}
	.order-1 {
		order:1!important
	}
	.order-2 {
		order:2!important
	}
	.order-3 {
		order:3!important
	}
	.order-4 {
		order:4!important
	}
	.order-5 {
		order:5!important
	}
	.order-last {
		order:6!important
	}
	.m-0 {
		margin:0!important
	}
	.m-1 {
	margin:.25rem!important
	}
	.m-2 {
	margin:.5rem!important
	}
	.m-3 {
		margin:1rem!important
	}
	.m-4 {
		margin:1.5rem!important
	}
	.m-5 {
		margin:3rem!important
	}
	.m-auto {
		margin:auto!important
	}
	.mx-0 {
		margin-right:0!important;
		margin-left:0!important
	}
	.mx-1 {
	margin-right:.25rem!important;
	margin-left:.25rem!important
	}
	.mx-2 {
	margin-right:.5rem!important;
	margin-left:.5rem!important
	}
	.mx-3 {
		margin-right:1rem!important;
		margin-left:1rem!important
	}
	.mx-4 {
		margin-right:1.5rem!important;
		margin-left:1.5rem!important
	}
	.mx-5 {
		margin-right:3rem!important;
		margin-left:3rem!important
	}
	.mx-auto {
		margin-right:auto!important;
		margin-left:auto!important
	}
	.my-0 {
		margin-top:0!important;
		margin-bottom:0!important
	}
	.my-1 {
	margin-top:.25rem!important;
	margin-bottom:.25rem!important
	}
	.my-2 {
	margin-top:.5rem!important;
	margin-bottom:.5rem!important
	}
	.my-3 {
		margin-top:1rem!important;
		margin-bottom:1rem!important
	}
	.my-4 {
		margin-top:1.5rem!important;
		margin-bottom:1.5rem!important
	}
	.my-5 {
		margin-top:3rem!important;
		margin-bottom:3rem!important
	}
	.my-auto {
		margin-top:auto!important;
		margin-bottom:auto!important
	}
	.mt-0 {
		margin-top:0!important
	}
	.mt-1 {
	margin-top:.25rem!important
	}
	.mt-2 {
	margin-top:.5rem!important
	}
	.mt-3 {
		margin-top:1rem!important
	}
	.mt-4 {
		margin-top:1.5rem!important
	}
	.mt-5 {
		margin-top:3rem!important
	}
	.mt-auto {
		margin-top:auto!important
	}
	.me-0 {
		margin-right:0!important
	}
	.me-1 {
	margin-right:.25rem!important
	}
	.me-2 {
	margin-right:.5rem!important
	}
	.me-3 {
		margin-right:1rem!important
	}
	.me-4 {
		margin-right:1.5rem!important
	}
	.me-5 {
		margin-right:3rem!important
	}
	.me-auto {
		margin-right:auto!important
	}
	.mb-0 {
		margin-bottom:0!important
	}
	.mb-1 {
	margin-bottom:.25rem!important
	}
	.mb-2 {
	margin-bottom:.5rem!important
	}
	.mb-3 {
		margin-bottom:1rem!important
	}
	.mb-4, .post-comments .comment-entry {
		margin-bottom:1.5rem!important
	}
	.event-details ul, .mb-5 {
		margin-bottom:3rem!important
	}
	.mb-auto {
		margin-bottom:auto!important
	}
	.ms-0 {
		margin-left:0!important
	}
	.ms-1 {
	margin-left:.25rem!important
	}
	.ms-2 {
	margin-left:.5rem!important
	}
	.ms-3 {
		margin-left:1rem!important
	}
	.ms-4 {
		margin-left:1.5rem!important
	}
	.ms-5 {
		margin-left:3rem!important
	}
	.ms-auto {
		margin-left:auto!important
	}
	.p-0 {
		padding:0!important
	}
	.p-1 {
	padding:.25rem!important
	}
	.p-2 {
	padding:.5rem!important
	}
	.p-3 {
		padding:1rem!important
	}
	.p-4 {
		padding:1.5rem!important
	}
	.p-5 {
		padding:3rem!important
	}
	.px-0 {
		padding-right:0!important;
		padding-left:0!important
	}
	.px-1 {
	padding-right:.25rem!important;
	padding-left:.25rem!important
	}
	.px-2 {
	padding-right:.5rem!important;
	padding-left:.5rem!important
	}
	.px-3 {
		padding-right:1rem!important;
		padding-left:1rem!important
	}
	.px-4 {
		padding-right:1.5rem!important;
		padding-left:1.5rem!important
	}
	.px-5 {
		padding-right:3rem!important;
		padding-left:3rem!important
	}
	.py-0 {
		padding-top:0!important;
		padding-bottom:0!important
	}
	.py-1 {
	padding-top:.25rem!important;
	padding-bottom:.25rem!important
	}
	.py-2 {
	padding-top:.5rem!important;
	padding-bottom:.5rem!important
	}
	.py-3 {
		padding-top:1rem!important;
		padding-bottom:1rem!important
	}
	.py-4 {
		padding-top:1.5rem!important;
		padding-bottom:1.5rem!important
	}
	.py-5 {
		padding-top:3rem!important;
		padding-bottom:3rem!important
	}
	.pt-0 {
		padding-top:0!important
	}
	.pt-1 {
	padding-top:.25rem!important
	}
	.pt-2 {
	padding-top:.5rem!important
	}
	.pt-3 {
		padding-top:1rem!important
	}
	.pt-4 {
		padding-top:1.5rem!important
	}
	.pt-5 {
		padding-top:3rem!important
	}
	.pe-0 {
		padding-right:0!important
	}
	.pe-1 {
	padding-right:.25rem!important
	}
	.pe-2 {
	padding-right:.5rem!important
	}
	.pe-3 {
		padding-right:1rem!important
	}
	.pe-4 {
		padding-right:1.5rem!important
	}
	.pe-5 {
		padding-right:3rem!important
	}
	.pb-0 {
		padding-bottom:0!important
	}
	.pb-1 {
	padding-bottom:.25rem!important
	}
	.pb-2 {
	padding-bottom:.5rem!important
	}
	.pb-3 {
		padding-bottom:1rem!important
	}
	.pb-4 {
		padding-bottom:1.5rem!important
	}
	.pb-5 {
		padding-bottom:3rem!important
	}
	.ps-0 {
		padding-left:0!important
	}
	.ps-1 {
	padding-left:.25rem!important
	}
	.ps-2 {
	padding-left:.5rem!important
	}
	.ps-3 {
		padding-left:1rem!important
	}
	.ps-4 {
		padding-left:1.5rem!important
	}
	.ps-5 {
		padding-left:3rem!important
	}
	.gap-0 {
		gap:0!important
	}
	.gap-1 {
	gap:.25rem!important
	}
	.gap-2 {
	gap:.5rem!important
	}
	.gap-3 {
		gap:1rem!important
	}
	.gap-4 {
		gap:1.5rem!important
	}
	.gap-5 {
		gap:3rem!important
	}
	.row-gap-0 {
		row-gap:0!important
	}
	.row-gap-1 {
	row-gap:.25rem!important
	}
	.row-gap-2 {
	row-gap:.5rem!important
	}
	.row-gap-3 {
		row-gap:1rem!important
	}
	.row-gap-4 {
		row-gap:1.5rem!important
	}
	.row-gap-5 {
		row-gap:3rem!important
	}
	.column-gap-0 {
		column-gap:0!important
	}
	.column-gap-1 {
	column-gap:.25rem!important
	}
	.column-gap-2 {
	column-gap:.5rem!important
	}
	.column-gap-3 {
		column-gap:1rem!important
	}
	.column-gap-4 {
		column-gap:1.5rem!important
	}
	.column-gap-5 {
		column-gap:3rem!important
	}
	.font-monospace {
		font-family:var(--bs-font-monospace)!important
	}
	.fs-1 {
		font-size:calc(1.375rem + 1.5vw)!important
	}
	.fs-2 {
	font-size:calc(1.325rem + .9vw)!important
	}
	.fs-3 {
	font-size:calc(1.3rem + .6vw)!important
	}
	.fs-4 {
	font-size:calc(1.275rem + .3vw)!important
	}
	.fs-5 {
		font-size:1.25rem!important
	}
	.fs-6 {
		font-size:1rem!important
	}
	.fst-italic {
		font-style:italic!important
	}
	.fst-normal {
		font-style:normal!important
	}
	.fw-lighter {
		font-weight:lighter!important
	}
	.fw-light {
		font-weight:300!important
	}
	.fw-normal {
		font-weight:400!important
	}
	.fw-medium {
		font-weight:500!important
	}
	.fw-semibold {
		font-weight:600!important
	}
	.fw-bold {
		font-weight:700!important
	}
	.fw-bolder {
		font-weight:bolder!important
	}
	.lh-1 {
		line-height:1!important
	}
	.lh-sm {
		line-height:1.25!important
	}
	.lh-base {
		line-height:1.5!important
	}
	.lh-lg {
		line-height:2!important
	}
	.text-start {
		text-align:left!important
	}
	.text-end {
		text-align:right!important
	}
	.text-center {
		text-align:center!important
	}
	.text-decoration-none {
		text-decoration:none!important
	}
	.text-decoration-underline {
		text-decoration:underline!important
	}
	.text-decoration-line-through {
		text-decoration:line-through!important
	}
	.text-lowercase {
		text-transform:lowercase!important
	}
	.text-uppercase {
		text-transform:uppercase!important
	}
	.text-capitalize {
		text-transform:capitalize!important
	}
	.text-wrap {
		white-space:normal!important
	}
	.text-nowrap {
		white-space:nowrap!important
	}
	.text-break {
		word-wrap:break-word!important;
		word-break:break-word!important
	}
	.text-primary {
	--bs-text-opacity:1;
		color:rgba(var(--bs-primary-rgb), var(--bs-text-opacity))!important
	}
	.text-secondary {
	--bs-text-opacity:1;
		color:rgba(var(--bs-secondary-rgb), var(--bs-text-opacity))!important
	}
	.text-success {
	--bs-text-opacity:1;
		color:rgba(var(--bs-success-rgb), var(--bs-text-opacity))!important
	}
	.text-info {
	--bs-text-opacity:1;
		color:rgba(var(--bs-info-rgb), var(--bs-text-opacity))!important
	}
	.text-light {
	--bs-text-opacity:1;
		color:rgba(var(--bs-light-rgb), var(--bs-text-opacity))!important
	}
	.text-danger {
	--bs-text-opacity:1;
		color:rgba(var(--bs-danger-rgb), var(--bs-text-opacity))!important
	}
	.text-dark {
	--bs-text-opacity:1;
		color:rgba(var(--bs-dark-rgb), var(--bs-text-opacity))!important
	}
	.text-altdark {
	--bs-text-opacity:1;
		color:rgba(var(--bs-altdark-rgb), var(--bs-text-opacity))!important
	}
	.text-sky {
	--bs-text-opacity:1;
		color:rgba(var(--bs-sky-rgb), var(--bs-text-opacity))!important
	}
	.text-mute {
	--bs-text-opacity:1;
		color:rgba(var(--bs-mute-rgb), var(--bs-text-opacity))!important
	}
	.text-tertiary {
	--bs-text-opacity:1;
		color:rgba(var(--bs-tertiary-rgb), var(--bs-text-opacity))!important
	}
	.text-black {
	--bs-text-opacity:1;
		color:rgba(var(--bs-black-rgb), var(--bs-text-opacity))!important
	}
	.text-white {
	--bs-text-opacity:1;
		color:rgba(var(--bs-white-rgb), var(--bs-text-opacity))!important
	}
	.text-body {
	--bs-text-opacity:1;
		color:rgba(var(--bs-body-color-rgb), var(--bs-text-opacity))!important
	}
	.text-muted {
	--bs-text-opacity:1;
		color:var(--bs-secondary-color)!important
	}
	.text-black-50 {
	--bs-text-opacity:1;
		color:rgba(0,0,0,.5)!important
	}
	.text-white-50 {
	--bs-text-opacity:1;
		color:hsla(0,0%,100%,.5)!important
	}
	.text-body-secondary {
	--bs-text-opacity:1;
		color:var(--bs-secondary-color)!important
	}
	.text-body-tertiary {
	--bs-text-opacity:1;
		color:var(--bs-tertiary-color)!important
	}
	.text-body-emphasis {
	--bs-text-opacity:1;
		color:var(--bs-emphasis-color)!important
	}
	.text-reset {
	--bs-text-opacity:1;
		color:inherit!important
	}
	.text-opacity-25 {
	--bs-text-opacity:0.25
	}
	.text-opacity-50 {
	--bs-text-opacity:0.5
	}
	.text-opacity-75 {
	--bs-text-opacity:0.75
	}
	.text-opacity-100 {
	--bs-text-opacity:1
	}
	.text-primary-emphasis {
		color:var(--bs-primary-text-emphasis)!important
	}
	.text-secondary-emphasis {
		color:var(--bs-secondary-text-emphasis)!important
	}
	.text-success-emphasis {
		color:var(--bs-success-text-emphasis)!important
	}
	.text-info-emphasis {
		color:var(--bs-info-text-emphasis)!important
	}
	.text-warning-emphasis {
		color:var(--bs-warning-text-emphasis)!important
	}
	.text-danger-emphasis {
		color:var(--bs-danger-text-emphasis)!important
	}
	.text-light-emphasis {
		color:var(--bs-light-text-emphasis)!important
	}
	.text-dark-emphasis {
		color:var(--bs-dark-text-emphasis)!important
	}
	.link-opacity-10, .link-opacity-10-hover:hover {
	--bs-link-opacity:0.1
	}
	.link-opacity-25, .link-opacity-25-hover:hover {
	--bs-link-opacity:0.25
	}
	.link-opacity-50, .link-opacity-50-hover:hover {
	--bs-link-opacity:0.5
	}
	.link-opacity-75, .link-opacity-75-hover:hover {
	--bs-link-opacity:0.75
	}
	.link-opacity-100, .link-opacity-100-hover:hover {
	--bs-link-opacity:1
	}
	.link-offset-1, .link-offset-1-hover:hover {
		text-underline-offset:.125em!important
	}
	.link-offset-2, .link-offset-2-hover:hover {
		text-underline-offset:.25em!important
	}
	.link-offset-3, .link-offset-3-hover:hover {
		text-underline-offset:.375em!important
	}
	.link-underline-primary {
	--bs-link-underline-opacity:1;
		text-decoration-color:rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity))!important
	}
	.link-underline-secondary {
	--bs-link-underline-opacity:1;
		text-decoration-color:rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity))!important
	}
	.link-underline-success {
	--bs-link-underline-opacity:1;
		text-decoration-color:rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity))!important
	}
	.link-underline-info {
	--bs-link-underline-opacity:1;
		text-decoration-color:rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity))!important
	}
	.link-underline-light {
	--bs-link-underline-opacity:1;
		text-decoration-color:rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity))!important
	}
	.link-underline-danger {
	--bs-link-underline-opacity:1;
		text-decoration-color:rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity))!important
	}
	.link-underline-dark {
	--bs-link-underline-opacity:1;
		text-decoration-color:rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity))!important
	}
	.link-underline-altdark {
	--bs-link-underline-opacity:1;
		text-decoration-color:rgba(var(--bs-altdark-rgb), var(--bs-link-underline-opacity))!important
	}
	.link-underline-sky {
	--bs-link-underline-opacity:1;
		text-decoration-color:rgba(var(--bs-sky-rgb), var(--bs-link-underline-opacity))!important
	}
	.link-underline-mute {
	--bs-link-underline-opacity:1;
		text-decoration-color:rgba(var(--bs-mute-rgb), var(--bs-link-underline-opacity))!important
	}
	.link-underline-tertiary {
	--bs-link-underline-opacity:1;
		text-decoration-color:rgba(var(--bs-tertiary-rgb), var(--bs-link-underline-opacity))!important
	}
	.link-underline {
	--bs-link-underline-opacity:1;
		text-decoration-color:rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1))!important
	}
	.link-underline-opacity-0, .link-underline-opacity-0-hover:hover {
	--bs-link-underline-opacity:0
	}
	.link-underline-opacity-10, .link-underline-opacity-10-hover:hover {
	--bs-link-underline-opacity:0.1
	}
	.link-underline-opacity-25, .link-underline-opacity-25-hover:hover {
	--bs-link-underline-opacity:0.25
	}
	.link-underline-opacity-50, .link-underline-opacity-50-hover:hover {
	--bs-link-underline-opacity:0.5
	}
	.link-underline-opacity-75, .link-underline-opacity-75-hover:hover {
	--bs-link-underline-opacity:0.75
	}
	.link-underline-opacity-100, .link-underline-opacity-100-hover:hover {
	--bs-link-underline-opacity:1
	}
	.bg-primary {
	--bs-bg-opacity:1;
		background-color:rgba(var(--bs-primary-rgb), var(--bs-bg-opacity))!important
	}
	.bg-secondary {
	--bs-bg-opacity:1;
		background-color:rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity))!important
	}
	.bg-success {
	--bs-bg-opacity:1;
		background-color:rgba(var(--bs-success-rgb), var(--bs-bg-opacity))!important
	}
	.bg-info {
	--bs-bg-opacity:1;
		background-color:rgba(var(--bs-info-rgb), var(--bs-bg-opacity))!important
	}
	.bg-light {
	--bs-bg-opacity:1;
		background-color:rgba(var(--bs-light-rgb), var(--bs-bg-opacity))!important
	}
	.bg-danger {
	--bs-bg-opacity:1;
		background-color:rgba(var(--bs-danger-rgb), var(--bs-bg-opacity))!important
	}
	.bg-dark {
	--bs-bg-opacity:1;
		background-color:rgba(var(--bs-dark-rgb), var(--bs-bg-opacity))!important
	}
	.bg-altdark {
	--bs-bg-opacity:1;
		background-color:rgba(var(--bs-altdark-rgb), var(--bs-bg-opacity))!important
	}
	.bg-sky {
		background-color:rgba(var(--bs-sky-rgb), var(--bs-bg-opacity))!important
	}
	.bg-mute, .bg-sky {
	--bs-bg-opacity:1
	}
	.bg-mute {
		background-color:rgba(var(--bs-mute-rgb), var(--bs-bg-opacity))!important
	}
	.bg-tertiary {
	--bs-bg-opacity:1;
		background-color:rgba(var(--bs-tertiary-rgb), var(--bs-bg-opacity))!important
	}
	.bg-black {
	--bs-bg-opacity:1;
		background-color:rgba(var(--bs-black-rgb), var(--bs-bg-opacity))!important
	}
	.bg-white {
	--bs-bg-opacity:1;
		background-color:rgba(var(--bs-white-rgb), var(--bs-bg-opacity))!important
	}
	.bg-body {
	--bs-bg-opacity:1;
		background-color:rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity))!important
	}
	.bg-transparent {
	--bs-bg-opacity:1;
		background-color:transparent!important
	}
	.bg-body-secondary {
	--bs-bg-opacity:1;
		background-color:rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity))!important
	}
	.bg-body-tertiary {
	--bs-bg-opacity:1;
		background-color:rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity))!important
	}
	.bg-opacity-10 {
	--bs-bg-opacity:0.1
	}
	.bg-opacity-25 {
	--bs-bg-opacity:0.25
	}
	.bg-opacity-50 {
	--bs-bg-opacity:0.5
	}
	.bg-opacity-75 {
	--bs-bg-opacity:0.75
	}
	.bg-opacity-100 {
	--bs-bg-opacity:1
	}
	.bg-primary-subtle {
		background-color:var(--bs-primary-bg-subtle)!important
	}
	.bg-secondary-subtle {
		background-color:var(--bs-secondary-bg-subtle)!important
	}
	.bg-success-subtle {
		background-color:var(--bs-success-bg-subtle)!important
	}
	.bg-info-subtle {
		background-color:var(--bs-info-bg-subtle)!important
	}
	.bg-warning-subtle {
		background-color:var(--bs-warning-bg-subtle)!important
	}
	.bg-danger-subtle {
		background-color:var(--bs-danger-bg-subtle)!important
	}
	.bg-light-subtle {
		background-color:var(--bs-light-bg-subtle)!important
	}
	.bg-dark-subtle {
		background-color:var(--bs-dark-bg-subtle)!important
	}
	.bg-gradient {
		background-image:var(--bs-gradient)!important
	}
	.user-select-all {
		user-select:all!important
	}
	.user-select-auto {
		user-select:auto!important
	}
	.user-select-none {
		user-select:none!important
	}
	.pe-none {
		pointer-events:none!important
	}
	.pe-auto {
		pointer-events:auto!important
	}
	.rounded {
		border-radius:var(--bs-border-radius)!important
	}
	.rounded-0 {
		border-radius:0!important
	}
	.rounded-1 {
		border-radius:var(--bs-border-radius-sm)!important
	}
	.rounded-2, .widget.instagrame-widget a img, form.form.checkout-form select {
		border-radius:var(--bs-border-radius)!important
	}
	.rounded-3 {
		border-radius:var(--bs-border-radius-lg)!important
	}
	.rounded-4 {
		border-radius:var(--bs-border-radius-xl)!important
	}
	.rounded-5 {
		border-radius:var(--bs-border-radius-xxl)!important
	}
	.rounded-circle {
		border-radius:50%!important
	}
	.rounded-pill {
		border-radius:var(--bs-border-radius-pill)!important
	}
	.rounded-top {
		border-top-left-radius:var(--bs-border-radius)!important;
		border-top-right-radius:var(--bs-border-radius)!important
	}
	.rounded-top-0 {
		border-top-left-radius:0!important;
		border-top-right-radius:0!important
	}
	.rounded-top-1 {
		border-top-left-radius:var(--bs-border-radius-sm)!important;
		border-top-right-radius:var(--bs-border-radius-sm)!important
	}
	.rounded-top-2 {
		border-top-left-radius:var(--bs-border-radius)!important;
		border-top-right-radius:var(--bs-border-radius)!important
	}
	.rounded-top-3 {
		border-top-left-radius:var(--bs-border-radius-lg)!important;
		border-top-right-radius:var(--bs-border-radius-lg)!important
	}
	.rounded-top-4 {
		border-top-left-radius:var(--bs-border-radius-xl)!important;
		border-top-right-radius:var(--bs-border-radius-xl)!important
	}
	.rounded-top-5 {
		border-top-left-radius:var(--bs-border-radius-xxl)!important;
		border-top-right-radius:var(--bs-border-radius-xxl)!important
	}
	.rounded-top-circle {
		border-top-left-radius:50%!important;
		border-top-right-radius:50%!important
	}
	.rounded-top-pill {
		border-top-left-radius:var(--bs-border-radius-pill)!important;
		border-top-right-radius:var(--bs-border-radius-pill)!important
	}
	.rounded-end {
		border-top-right-radius:var(--bs-border-radius)!important;
		border-bottom-right-radius:var(--bs-border-radius)!important
	}
	.rounded-end-0 {
		border-top-right-radius:0!important;
		border-bottom-right-radius:0!important
	}
	.rounded-end-1 {
		border-top-right-radius:var(--bs-border-radius-sm)!important;
		border-bottom-right-radius:var(--bs-border-radius-sm)!important
	}
	.rounded-end-2 {
		border-top-right-radius:var(--bs-border-radius)!important;
		border-bottom-right-radius:var(--bs-border-radius)!important
	}
	.rounded-end-3 {
		border-top-right-radius:var(--bs-border-radius-lg)!important;
		border-bottom-right-radius:var(--bs-border-radius-lg)!important
	}
	.rounded-end-4 {
		border-top-right-radius:var(--bs-border-radius-xl)!important;
		border-bottom-right-radius:var(--bs-border-radius-xl)!important
	}
	.rounded-end-5 {
		border-top-right-radius:var(--bs-border-radius-xxl)!important;
		border-bottom-right-radius:var(--bs-border-radius-xxl)!important
	}
	.rounded-end-circle {
		border-top-right-radius:50%!important;
		border-bottom-right-radius:50%!important
	}
	.rounded-end-pill {
		border-top-right-radius:var(--bs-border-radius-pill)!important;
		border-bottom-right-radius:var(--bs-border-radius-pill)!important
	}
	.rounded-bottom {
		border-bottom-right-radius:var(--bs-border-radius)!important;
		border-bottom-left-radius:var(--bs-border-radius)!important
	}
	.rounded-bottom-0 {
		border-bottom-right-radius:0!important;
		border-bottom-left-radius:0!important
	}
	.rounded-bottom-1 {
		border-bottom-right-radius:var(--bs-border-radius-sm)!important;
		border-bottom-left-radius:var(--bs-border-radius-sm)!important
	}
	.rounded-bottom-2 {
		border-bottom-right-radius:var(--bs-border-radius)!important;
		border-bottom-left-radius:var(--bs-border-radius)!important
	}
	.rounded-bottom-3 {
		border-bottom-right-radius:var(--bs-border-radius-lg)!important;
		border-bottom-left-radius:var(--bs-border-radius-lg)!important
	}
	.rounded-bottom-4 {
		border-bottom-right-radius:var(--bs-border-radius-xl)!important;
		border-bottom-left-radius:var(--bs-border-radius-xl)!important
	}
	.rounded-bottom-5 {
		border-bottom-right-radius:var(--bs-border-radius-xxl)!important;
		border-bottom-left-radius:var(--bs-border-radius-xxl)!important
	}
	.rounded-bottom-circle {
		border-bottom-right-radius:50%!important;
		border-bottom-left-radius:50%!important
	}
	.rounded-bottom-pill {
		border-bottom-right-radius:var(--bs-border-radius-pill)!important;
		border-bottom-left-radius:var(--bs-border-radius-pill)!important
	}
	.rounded-start {
		border-bottom-left-radius:var(--bs-border-radius)!important;
		border-top-left-radius:var(--bs-border-radius)!important
	}
	.rounded-start-0 {
		border-bottom-left-radius:0!important;
		border-top-left-radius:0!important
	}
	.rounded-start-1 {
		border-bottom-left-radius:var(--bs-border-radius-sm)!important;
		border-top-left-radius:var(--bs-border-radius-sm)!important
	}
	.rounded-start-2 {
		border-bottom-left-radius:var(--bs-border-radius)!important;
		border-top-left-radius:var(--bs-border-radius)!important
	}
	.rounded-start-3 {
		border-bottom-left-radius:var(--bs-border-radius-lg)!important;
		border-top-left-radius:var(--bs-border-radius-lg)!important
	}
	.rounded-start-4 {
		border-bottom-left-radius:var(--bs-border-radius-xl)!important;
		border-top-left-radius:var(--bs-border-radius-xl)!important
	}
	.rounded-start-5 {
		border-bottom-left-radius:var(--bs-border-radius-xxl)!important;
		border-top-left-radius:var(--bs-border-radius-xxl)!important
	}
	.rounded-start-circle {
		border-bottom-left-radius:50%!important;
		border-top-left-radius:50%!important
	}
	.rounded-start-pill {
		border-bottom-left-radius:var(--bs-border-radius-pill)!important;
		border-top-left-radius:var(--bs-border-radius-pill)!important
	}
	.visible {
		visibility:visible!important
	}
	.invisible {
		visibility:hidden!important
	}
	.z-n1 {
		z-index:-1!important
	}
	.z-0 {
		z-index:0!important
	}
	.z-1 {
		z-index:1!important
	}
	.z-2 {
		z-index:2!important
	}
	.z-3 {
		z-index:3!important
	}
	@media(min-width:576px) {
	.float-sm-start {
		float:left!important
	}
	.float-sm-end {
		float:right!important
	}
	.float-sm-none {
		float:none!important
	}
	.object-fit-sm-contain {
		object-fit:contain!important
	}
	.object-fit-sm-cover {
		object-fit:cover!important
	}
	.object-fit-sm-fill {
		object-fit:fill!important
	}
	.object-fit-sm-scale {
		object-fit:scale-down!important
	}
	.object-fit-sm-none {
		object-fit:none!important
	}
	.d-sm-inline {
		display:inline!important
	}
	.d-sm-inline-block {
		display:inline-block!important
	}
	.d-sm-block {
		display:block!important
	}
	.d-sm-grid {
		display:grid!important
	}
	.d-sm-inline-grid {
		display:inline-grid!important
	}
	.d-sm-table {
		display:table!important
	}
	.d-sm-table-row {
		display:table-row!important
	}
	.d-sm-table-cell {
		display:table-cell!important
	}
	.d-sm-flex {
		display:flex!important
	}
	.d-sm-inline-flex {
		display:inline-flex!important
	}
	.d-sm-none {
		display:none!important
	}
	.flex-sm-fill {
		flex:1 1 auto!important
	}
	.flex-sm-row {
		flex-direction:row!important
	}
	.flex-sm-column {
		flex-direction:column!important
	}
	.flex-sm-row-reverse {
		flex-direction:row-reverse!important
	}
	.flex-sm-column-reverse {
		flex-direction:column-reverse!important
	}
	.flex-sm-grow-0 {
		flex-grow:0!important
	}
	.flex-sm-grow-1 {
		flex-grow:1!important
	}
	.flex-sm-shrink-0 {
		flex-shrink:0!important
	}
	.flex-sm-shrink-1 {
		flex-shrink:1!important
	}
	.flex-sm-wrap {
		flex-wrap:wrap!important
	}
	.flex-sm-nowrap {
		flex-wrap:nowrap!important
	}
	.flex-sm-wrap-reverse {
		flex-wrap:wrap-reverse!important
	}
	.justify-content-sm-start {
		justify-content:flex-start!important
	}
	.justify-content-sm-end {
		justify-content:flex-end!important
	}
	.justify-content-sm-center {
		justify-content:center!important
	}
	.justify-content-sm-between {
		justify-content:space-between!important
	}
	.justify-content-sm-around {
		justify-content:space-around!important
	}
	.justify-content-sm-evenly {
		justify-content:space-evenly!important
	}
	.align-items-sm-start {
		align-items:flex-start!important
	}
	.align-items-sm-end {
		align-items:flex-end!important
	}
	.align-items-sm-center {
		align-items:center!important
	}
	.align-items-sm-baseline {
		align-items:baseline!important
	}
	.align-items-sm-stretch {
		align-items:stretch!important
	}
	.align-content-sm-start {
		align-content:flex-start!important
	}
	.align-content-sm-end {
		align-content:flex-end!important
	}
	.align-content-sm-center {
		align-content:center!important
	}
	.align-content-sm-between {
		align-content:space-between!important
	}
	.align-content-sm-around {
		align-content:space-around!important
	}
	.align-content-sm-stretch {
		align-content:stretch!important
	}
	.align-self-sm-auto {
		align-self:auto!important
	}
	.align-self-sm-start {
		align-self:flex-start!important
	}
	.align-self-sm-end {
		align-self:flex-end!important
	}
	.align-self-sm-center {
		align-self:center!important
	}
	.align-self-sm-baseline {
		align-self:baseline!important
	}
	.align-self-sm-stretch {
		align-self:stretch!important
	}
	.order-sm-first {
		order:-1!important
	}
	.order-sm-0 {
		order:0!important
	}
	.order-sm-1 {
		order:1!important
	}
	.order-sm-2 {
		order:2!important
	}
	.order-sm-3 {
		order:3!important
	}
	.order-sm-4 {
		order:4!important
	}
	.order-sm-5 {
		order:5!important
	}
	.order-sm-last {
		order:6!important
	}
	.m-sm-0 {
		margin:0!important
	}
	.m-sm-1 {
	margin:.25rem!important
	}
	.m-sm-2 {
	margin:.5rem!important
	}
	.m-sm-3 {
		margin:1rem!important
	}
	.m-sm-4 {
		margin:1.5rem!important
	}
	.m-sm-5 {
		margin:3rem!important
	}
	.m-sm-auto {
		margin:auto!important
	}
	.mx-sm-0 {
		margin-right:0!important;
		margin-left:0!important
	}
	.mx-sm-1 {
	margin-right:.25rem!important;
	margin-left:.25rem!important
	}
	.mx-sm-2 {
	margin-right:.5rem!important;
	margin-left:.5rem!important
	}
	.mx-sm-3 {
		margin-right:1rem!important;
		margin-left:1rem!important
	}
	.mx-sm-4 {
		margin-right:1.5rem!important;
		margin-left:1.5rem!important
	}
	.mx-sm-5 {
		margin-right:3rem!important;
		margin-left:3rem!important
	}
	.mx-sm-auto {
		margin-right:auto!important;
		margin-left:auto!important
	}
	.my-sm-0 {
		margin-top:0!important;
		margin-bottom:0!important
	}
	.my-sm-1 {
	margin-top:.25rem!important;
	margin-bottom:.25rem!important
	}
	.my-sm-2 {
	margin-top:.5rem!important;
	margin-bottom:.5rem!important
	}
	.my-sm-3 {
		margin-top:1rem!important;
		margin-bottom:1rem!important
	}
	.my-sm-4 {
		margin-top:1.5rem!important;
		margin-bottom:1.5rem!important
	}
	.my-sm-5 {
		margin-top:3rem!important;
		margin-bottom:3rem!important
	}
	.my-sm-auto {
		margin-top:auto!important;
		margin-bottom:auto!important
	}
	.mt-sm-0 {
		margin-top:0!important
	}
	.mt-sm-1 {
	margin-top:.25rem!important
	}
	.mt-sm-2 {
	margin-top:.5rem!important
	}
	.mt-sm-3 {
		margin-top:1rem!important
	}
	.mt-sm-4 {
		margin-top:1.5rem!important
	}
	.mt-sm-5 {
		margin-top:3rem!important
	}
	.mt-sm-auto {
		margin-top:auto!important
	}
	.me-sm-0 {
		margin-right:0!important
	}
	.me-sm-1 {
	margin-right:.25rem!important
	}
	.me-sm-2 {
	margin-right:.5rem!important
	}
	.me-sm-3 {
		margin-right:1rem!important
	}
	.me-sm-4 {
		margin-right:1.5rem!important
	}
	.me-sm-5 {
		margin-right:3rem!important
	}
	.me-sm-auto {
		margin-right:auto!important
	}
	.mb-sm-0 {
		margin-bottom:0!important
	}
	.mb-sm-1 {
	margin-bottom:.25rem!important
	}
	.mb-sm-2 {
	margin-bottom:.5rem!important
	}
	.mb-sm-3 {
		margin-bottom:1rem!important
	}
	.mb-sm-4 {
		margin-bottom:1.5rem!important
	}
	.mb-sm-5 {
		margin-bottom:3rem!important
	}
	.mb-sm-auto {
		margin-bottom:auto!important
	}
	.ms-sm-0 {
		margin-left:0!important
	}
	.ms-sm-1 {
	margin-left:.25rem!important
	}
	.ms-sm-2 {
	margin-left:.5rem!important
	}
	.ms-sm-3 {
		margin-left:1rem!important
	}
	.ms-sm-4 {
		margin-left:1.5rem!important
	}
	.ms-sm-5 {
		margin-left:3rem!important
	}
	.ms-sm-auto {
		margin-left:auto!important
	}
	.p-sm-0 {
		padding:0!important
	}
	.p-sm-1 {
	padding:.25rem!important
	}
	.p-sm-2 {
	padding:.5rem!important
	}
	.p-sm-3 {
		padding:1rem!important
	}
	.p-sm-4 {
		padding:1.5rem!important
	}
	.p-sm-5 {
		padding:3rem!important
	}
	.px-sm-0 {
		padding-right:0!important;
		padding-left:0!important
	}
	.px-sm-1 {
	padding-right:.25rem!important;
	padding-left:.25rem!important
	}
	.px-sm-2 {
	padding-right:.5rem!important;
	padding-left:.5rem!important
	}
	.px-sm-3 {
		padding-right:1rem!important;
		padding-left:1rem!important
	}
	.px-sm-4 {
		padding-right:1.5rem!important;
		padding-left:1.5rem!important
	}
	.px-sm-5 {
		padding-right:3rem!important;
		padding-left:3rem!important
	}
	.py-sm-0 {
		padding-top:0!important;
		padding-bottom:0!important
	}
	.py-sm-1 {
	padding-top:.25rem!important;
	padding-bottom:.25rem!important
	}
	.py-sm-2 {
	padding-top:.5rem!important;
	padding-bottom:.5rem!important
	}
	.py-sm-3 {
		padding-top:1rem!important;
		padding-bottom:1rem!important
	}
	.py-sm-4 {
		padding-top:1.5rem!important;
		padding-bottom:1.5rem!important
	}
	.py-sm-5 {
		padding-top:3rem!important;
		padding-bottom:3rem!important
	}
	.pt-sm-0 {
		padding-top:0!important
	}
	.pt-sm-1 {
	padding-top:.25rem!important
	}
	.pt-sm-2 {
	padding-top:.5rem!important
	}
	.pt-sm-3 {
		padding-top:1rem!important
	}
	.pt-sm-4 {
		padding-top:1.5rem!important
	}
	.pt-sm-5 {
		padding-top:3rem!important
	}
	.pe-sm-0 {
		padding-right:0!important
	}
	.pe-sm-1 {
	padding-right:.25rem!important
	}
	.pe-sm-2 {
	padding-right:.5rem!important
	}
	.pe-sm-3 {
		padding-right:1rem!important
	}
	.pe-sm-4 {
		padding-right:1.5rem!important
	}
	.pe-sm-5 {
		padding-right:3rem!important
	}
	.pb-sm-0 {
		padding-bottom:0!important
	}
	.pb-sm-1 {
	padding-bottom:.25rem!important
	}
	.pb-sm-2 {
	padding-bottom:.5rem!important
	}
	.pb-sm-3 {
		padding-bottom:1rem!important
	}
	.pb-sm-4 {
		padding-bottom:1.5rem!important
	}
	.pb-sm-5 {
		padding-bottom:3rem!important
	}
	.ps-sm-0 {
		padding-left:0!important
	}
	.ps-sm-1 {
	padding-left:.25rem!important
	}
	.ps-sm-2 {
	padding-left:.5rem!important
	}
	.ps-sm-3 {
		padding-left:1rem!important
	}
	.ps-sm-4 {
		padding-left:1.5rem!important
	}
	.ps-sm-5 {
		padding-left:3rem!important
	}
	.gap-sm-0 {
		gap:0!important
	}
	.gap-sm-1 {
	gap:.25rem!important
	}
	.gap-sm-2 {
	gap:.5rem!important
	}
	.gap-sm-3 {
		gap:1rem!important
	}
	.gap-sm-4 {
		gap:1.5rem!important
	}
	.gap-sm-5 {
		gap:3rem!important
	}
	.row-gap-sm-0 {
		row-gap:0!important
	}
	.row-gap-sm-1 {
	row-gap:.25rem!important
	}
	.row-gap-sm-2 {
	row-gap:.5rem!important
	}
	.row-gap-sm-3 {
		row-gap:1rem!important
	}
	.row-gap-sm-4 {
		row-gap:1.5rem!important
	}
	.row-gap-sm-5 {
		row-gap:3rem!important
	}
	.column-gap-sm-0 {
		column-gap:0!important
	}
	.column-gap-sm-1 {
	column-gap:.25rem!important
	}
	.column-gap-sm-2 {
	column-gap:.5rem!important
	}
	.column-gap-sm-3 {
		column-gap:1rem!important
	}
	.column-gap-sm-4 {
		column-gap:1.5rem!important
	}
	.column-gap-sm-5 {
		column-gap:3rem!important
	}
	.text-sm-start {
		text-align:left!important
	}
	.text-sm-end {
		text-align:right!important
	}
	.text-sm-center {
		text-align:center!important
	}
	}
	@media(min-width:768px) {
	.float-md-start {
		float:left!important
	}
	.float-md-end {
		float:right!important
	}
	.float-md-none {
		float:none!important
	}
	.object-fit-md-contain {
		object-fit:contain!important
	}
	.object-fit-md-cover {
		object-fit:cover!important
	}
	.object-fit-md-fill {
		object-fit:fill!important
	}
	.object-fit-md-scale {
		object-fit:scale-down!important
	}
	.object-fit-md-none {
		object-fit:none!important
	}
	.d-md-inline {
		display:inline!important
	}
	.d-md-inline-block {
		display:inline-block!important
	}
	.d-md-block {
		display:block!important
	}
	.d-md-grid {
		display:grid!important
	}
	.d-md-inline-grid {
		display:inline-grid!important
	}
	.d-md-table {
		display:table!important
	}
	.d-md-table-row {
		display:table-row!important
	}
	.d-md-table-cell {
		display:table-cell!important
	}
	.d-md-flex {
		display:flex!important
	}
	.d-md-inline-flex {
		display:inline-flex!important
	}
	.d-md-none {
		display:none!important
	}
	.flex-md-fill {
		flex:1 1 auto!important
	}
	.flex-md-row {
		flex-direction:row!important
	}
	.flex-md-column {
		flex-direction:column!important
	}
	.flex-md-row-reverse {
		flex-direction:row-reverse!important
	}
	.flex-md-column-reverse {
		flex-direction:column-reverse!important
	}
	.flex-md-grow-0 {
		flex-grow:0!important
	}
	.flex-md-grow-1 {
		flex-grow:1!important
	}
	.flex-md-shrink-0 {
		flex-shrink:0!important
	}
	.flex-md-shrink-1 {
		flex-shrink:1!important
	}
	.flex-md-wrap {
		flex-wrap:wrap!important
	}
	.flex-md-nowrap {
		flex-wrap:nowrap!important
	}
	.flex-md-wrap-reverse {
		flex-wrap:wrap-reverse!important
	}
	.justify-content-md-start {
		justify-content:flex-start!important
	}
	.justify-content-md-end {
		justify-content:flex-end!important
	}
	.justify-content-md-center {
		justify-content:center!important
	}
	.justify-content-md-between {
		justify-content:space-between!important
	}
	.justify-content-md-around {
		justify-content:space-around!important
	}
	.justify-content-md-evenly {
		justify-content:space-evenly!important
	}
	.align-items-md-start {
		align-items:flex-start!important
	}
	.align-items-md-end {
		align-items:flex-end!important
	}
	.align-items-md-center {
		align-items:center!important
	}
	.align-items-md-baseline {
		align-items:baseline!important
	}
	.align-items-md-stretch {
		align-items:stretch!important
	}
	.align-content-md-start {
		align-content:flex-start!important
	}
	.align-content-md-end {
		align-content:flex-end!important
	}
	.align-content-md-center {
		align-content:center!important
	}
	.align-content-md-between {
		align-content:space-between!important
	}
	.align-content-md-around {
		align-content:space-around!important
	}
	.align-content-md-stretch {
		align-content:stretch!important
	}
	.align-self-md-auto {
		align-self:auto!important
	}
	.align-self-md-start {
		align-self:flex-start!important
	}
	.align-self-md-end {
		align-self:flex-end!important
	}
	.align-self-md-center {
		align-self:center!important
	}
	.align-self-md-baseline {
		align-self:baseline!important
	}
	.align-self-md-stretch {
		align-self:stretch!important
	}
	.order-md-first {
		order:-1!important
	}
	.order-md-0 {
		order:0!important
	}
	.order-md-1 {
		order:1!important
	}
	.order-md-2 {
		order:2!important
	}
	.order-md-3 {
		order:3!important
	}
	.order-md-4 {
		order:4!important
	}
	.order-md-5 {
		order:5!important
	}
	.order-md-last {
		order:6!important
	}
	.m-md-0 {
		margin:0!important
	}
	.m-md-1 {
	margin:.25rem!important
	}
	.m-md-2 {
	margin:.5rem!important
	}
	.m-md-3 {
		margin:1rem!important
	}
	.m-md-4 {
		margin:1.5rem!important
	}
	.m-md-5 {
		margin:3rem!important
	}
	.m-md-auto {
		margin:auto!important
	}
	.mx-md-0 {
		margin-right:0!important;
		margin-left:0!important
	}
	.mx-md-1 {
	margin-right:.25rem!important;
	margin-left:.25rem!important
	}
	.mx-md-2 {
	margin-right:.5rem!important;
	margin-left:.5rem!important
	}
	.mx-md-3 {
		margin-right:1rem!important;
		margin-left:1rem!important
	}
	.mx-md-4 {
		margin-right:1.5rem!important;
		margin-left:1.5rem!important
	}
	.mx-md-5 {
		margin-right:3rem!important;
		margin-left:3rem!important
	}
	.mx-md-auto {
		margin-right:auto!important;
		margin-left:auto!important
	}
	.my-md-0 {
		margin-top:0!important;
		margin-bottom:0!important
	}
	.my-md-1 {
	margin-top:.25rem!important;
	margin-bottom:.25rem!important
	}
	.my-md-2 {
	margin-top:.5rem!important;
	margin-bottom:.5rem!important
	}
	.my-md-3 {
		margin-top:1rem!important;
		margin-bottom:1rem!important
	}
	.my-md-4 {
		margin-top:1.5rem!important;
		margin-bottom:1.5rem!important
	}
	.my-md-5 {
		margin-top:3rem!important;
		margin-bottom:3rem!important
	}
	.my-md-auto {
		margin-top:auto!important;
		margin-bottom:auto!important
	}
	.mt-md-0 {
		margin-top:0!important
	}
	.mt-md-1 {
	margin-top:.25rem!important
	}
	.mt-md-2 {
	margin-top:.5rem!important
	}
	.mt-md-3 {
		margin-top:1rem!important
	}
	.mt-md-4 {
		margin-top:1.5rem!important
	}
	.mt-md-5 {
		margin-top:3rem!important
	}
	.mt-md-auto {
		margin-top:auto!important
	}
	.me-md-0 {
		margin-right:0!important
	}
	.me-md-1 {
	margin-right:.25rem!important
	}
	.me-md-2 {
	margin-right:.5rem!important
	}
	.me-md-3 {
		margin-right:1rem!important
	}
	.me-md-4 {
		margin-right:1.5rem!important
	}
	.me-md-5 {
		margin-right:3rem!important
	}
	.me-md-auto {
		margin-right:auto!important
	}
	.mb-md-0 {
		margin-bottom:0!important
	}
	.mb-md-1 {
	margin-bottom:.25rem!important
	}
	.mb-md-2 {
	margin-bottom:.5rem!important
	}
	.mb-md-3 {
		margin-bottom:1rem!important
	}
	.mb-md-4 {
		margin-bottom:1.5rem!important
	}
	.mb-md-5 {
		margin-bottom:3rem!important
	}
	.mb-md-auto {
		margin-bottom:auto!important
	}
	.ms-md-0 {
		margin-left:0!important
	}
	.ms-md-1 {
	margin-left:.25rem!important
	}
	.ms-md-2 {
	margin-left:.5rem!important
	}
	.ms-md-3 {
		margin-left:1rem!important
	}
	.ms-md-4 {
		margin-left:1.5rem!important
	}
	.ms-md-5 {
		margin-left:3rem!important
	}
	.ms-md-auto {
		margin-left:auto!important
	}
	.p-md-0 {
		padding:0!important
	}
	.p-md-1 {
	padding:.25rem!important
	}
	.p-md-2 {
	padding:.5rem!important
	}
	.p-md-3 {
		padding:1rem!important
	}
	.p-md-4 {
		padding:1.5rem!important
	}
	.p-md-5 {
		padding:3rem!important
	}
	.px-md-0 {
		padding-right:0!important;
		padding-left:0!important
	}
	.px-md-1 {
	padding-right:.25rem!important;
	padding-left:.25rem!important
	}
	.px-md-2 {
	padding-right:.5rem!important;
	padding-left:.5rem!important
	}
	.px-md-3 {
		padding-right:1rem!important;
		padding-left:1rem!important
	}
	.px-md-4 {
		padding-right:1.5rem!important;
		padding-left:1.5rem!important
	}
	.px-md-5 {
		padding-right:3rem!important;
		padding-left:3rem!important
	}
	.py-md-0 {
		padding-top:0!important;
		padding-bottom:0!important
	}
	.py-md-1 {
	padding-top:.25rem!important;
	padding-bottom:.25rem!important
	}
	.py-md-2 {
	padding-top:.5rem!important;
	padding-bottom:.5rem!important
	}
	.py-md-3 {
		padding-top:1rem!important;
		padding-bottom:1rem!important
	}
	.py-md-4 {
		padding-top:1.5rem!important;
		padding-bottom:1.5rem!important
	}
	.py-md-5 {
		padding-top:3rem!important;
		padding-bottom:3rem!important
	}
	.pt-md-0 {
		padding-top:0!important
	}
	.pt-md-1 {
	padding-top:.25rem!important
	}
	.pt-md-2 {
	padding-top:.5rem!important
	}
	.pt-md-3 {
		padding-top:1rem!important
	}
	.pt-md-4 {
		padding-top:1.5rem!important
	}
	.pt-md-5 {
		padding-top:3rem!important
	}
	.pe-md-0 {
		padding-right:0!important
	}
	.pe-md-1 {
	padding-right:.25rem!important
	}
	.pe-md-2 {
	padding-right:.5rem!important
	}
	.pe-md-3 {
		padding-right:1rem!important
	}
	.pe-md-4 {
		padding-right:1.5rem!important
	}
	.pe-md-5 {
		padding-right:3rem!important
	}
	.pb-md-0 {
		padding-bottom:0!important
	}
	.pb-md-1 {
	padding-bottom:.25rem!important
	}
	.pb-md-2 {
	padding-bottom:.5rem!important
	}
	.pb-md-3 {
		padding-bottom:1rem!important
	}
	.pb-md-4 {
		padding-bottom:1.5rem!important
	}
	.pb-md-5 {
		padding-bottom:3rem!important
	}
	.ps-md-0 {
		padding-left:0!important
	}
	.ps-md-1 {
	padding-left:.25rem!important
	}
	.ps-md-2 {
	padding-left:.5rem!important
	}
	.ps-md-3 {
		padding-left:1rem!important
	}
	.ps-md-4 {
		padding-left:1.5rem!important
	}
	.ps-md-5 {
		padding-left:3rem!important
	}
	.gap-md-0 {
		gap:0!important
	}
	.gap-md-1 {
	gap:.25rem!important
	}
	.gap-md-2 {
	gap:.5rem!important
	}
	.gap-md-3 {
		gap:1rem!important
	}
	.gap-md-4 {
		gap:1.5rem!important
	}
	.gap-md-5 {
		gap:3rem!important
	}
	.row-gap-md-0 {
		row-gap:0!important
	}
	.row-gap-md-1 {
	row-gap:.25rem!important
	}
	.row-gap-md-2 {
	row-gap:.5rem!important
	}
	.row-gap-md-3 {
		row-gap:1rem!important
	}
	.row-gap-md-4 {
		row-gap:1.5rem!important
	}
	.row-gap-md-5 {
		row-gap:3rem!important
	}
	.column-gap-md-0 {
		column-gap:0!important
	}
	.column-gap-md-1 {
	column-gap:.25rem!important
	}
	.column-gap-md-2 {
	column-gap:.5rem!important
	}
	.column-gap-md-3 {
		column-gap:1rem!important
	}
	.column-gap-md-4 {
		column-gap:1.5rem!important
	}
	.column-gap-md-5 {
		column-gap:3rem!important
	}
	.text-md-start {
		text-align:left!important
	}
	.text-md-end {
		text-align:right!important
	}
	.text-md-center {
		text-align:center!important
	}
	}
	@media(min-width:992px) {
	.float-lg-start {
		float:left!important
	}
	.float-lg-end {
		float:right!important
	}
	.float-lg-none {
		float:none!important
	}
	.object-fit-lg-contain {
		object-fit:contain!important
	}
	.object-fit-lg-cover {
		object-fit:cover!important
	}
	.object-fit-lg-fill {
		object-fit:fill!important
	}
	.object-fit-lg-scale {
		object-fit:scale-down!important
	}
	.object-fit-lg-none {
		object-fit:none!important
	}
	.d-lg-inline {
		display:inline!important
	}
	.d-lg-inline-block {
		display:inline-block!important
	}
	.d-lg-block {
		display:block!important
	}
	.d-lg-grid {
		display:grid!important
	}
	.d-lg-inline-grid {
		display:inline-grid!important
	}
	.d-lg-table {
		display:table!important
	}
	.d-lg-table-row {
		display:table-row!important
	}
	.d-lg-table-cell {
		display:table-cell!important
	}
	.d-lg-flex {
		display:flex!important
	}
	.d-lg-inline-flex {
		display:inline-flex!important
	}
	.d-lg-none {
		display:none!important
	}
	.flex-lg-fill {
		flex:1 1 auto!important
	}
	.flex-lg-row {
		flex-direction:row!important
	}
	.flex-lg-column {
		flex-direction:column!important
	}
	.flex-lg-row-reverse {
		flex-direction:row-reverse!important
	}
	.flex-lg-column-reverse {
		flex-direction:column-reverse!important
	}
	.flex-lg-grow-0 {
		flex-grow:0!important
	}
	.flex-lg-grow-1 {
		flex-grow:1!important
	}
	.flex-lg-shrink-0 {
		flex-shrink:0!important
	}
	.flex-lg-shrink-1 {
		flex-shrink:1!important
	}
	.flex-lg-wrap {
		flex-wrap:wrap!important
	}
	.flex-lg-nowrap {
		flex-wrap:nowrap!important
	}
	.flex-lg-wrap-reverse {
		flex-wrap:wrap-reverse!important
	}
	.justify-content-lg-start {
		justify-content:flex-start!important
	}
	.justify-content-lg-end {
		justify-content:flex-end!important
	}
	.justify-content-lg-center {
		justify-content:center!important
	}
	.justify-content-lg-between {
		justify-content:space-between!important
	}
	.justify-content-lg-around {
		justify-content:space-around!important
	}
	.justify-content-lg-evenly {
		justify-content:space-evenly!important
	}
	.align-items-lg-start {
		align-items:flex-start!important
	}
	.align-items-lg-end {
		align-items:flex-end!important
	}
	.align-items-lg-center {
		align-items:center!important
	}
	.align-items-lg-baseline {
		align-items:baseline!important
	}
	.align-items-lg-stretch {
		align-items:stretch!important
	}
	.align-content-lg-start {
		align-content:flex-start!important
	}
	.align-content-lg-end {
		align-content:flex-end!important
	}
	.align-content-lg-center {
		align-content:center!important
	}
	.align-content-lg-between {
		align-content:space-between!important
	}
	.align-content-lg-around {
		align-content:space-around!important
	}
	.align-content-lg-stretch {
		align-content:stretch!important
	}
	.align-self-lg-auto {
		align-self:auto!important
	}
	.align-self-lg-start {
		align-self:flex-start!important
	}
	.align-self-lg-end {
		align-self:flex-end!important
	}
	.align-self-lg-center {
		align-self:center!important
	}
	.align-self-lg-baseline {
		align-self:baseline!important
	}
	.align-self-lg-stretch {
		align-self:stretch!important
	}
	.order-lg-first {
		order:-1!important
	}
	.order-lg-0 {
		order:0!important
	}
	.order-lg-1 {
		order:1!important
	}
	.order-lg-2 {
		order:2!important
	}
	.order-lg-3 {
		order:3!important
	}
	.order-lg-4 {
		order:4!important
	}
	.order-lg-5 {
		order:5!important
	}
	.order-lg-last {
		order:6!important
	}
	.m-lg-0 {
		margin:0!important
	}
	.m-lg-1 {
	margin:.25rem!important
	}
	.m-lg-2 {
	margin:.5rem!important
	}
	.m-lg-3 {
		margin:1rem!important
	}
	.m-lg-4 {
		margin:1.5rem!important
	}
	.m-lg-5 {
		margin:3rem!important
	}
	.m-lg-auto {
		margin:auto!important
	}
	.mx-lg-0 {
		margin-right:0!important;
		margin-left:0!important
	}
	.mx-lg-1 {
	margin-right:.25rem!important;
	margin-left:.25rem!important
	}
	.mx-lg-2 {
	margin-right:.5rem!important;
	margin-left:.5rem!important
	}
	.mx-lg-3 {
		margin-right:1rem!important;
		margin-left:1rem!important
	}
	.mx-lg-4 {
		margin-right:1.5rem!important;
		margin-left:1.5rem!important
	}
	.mx-lg-5 {
		margin-right:3rem!important;
		margin-left:3rem!important
	}
	.mx-lg-auto {
		margin-right:auto!important;
		margin-left:auto!important
	}
	.my-lg-0 {
		margin-top:0!important;
		margin-bottom:0!important
	}
	.my-lg-1 {
	margin-top:.25rem!important;
	margin-bottom:.25rem!important
	}
	.my-lg-2 {
	margin-top:.5rem!important;
	margin-bottom:.5rem!important
	}
	.my-lg-3 {
		margin-top:1rem!important;
		margin-bottom:1rem!important
	}
	.my-lg-4 {
		margin-top:1.5rem!important;
		margin-bottom:1.5rem!important
	}
	.my-lg-5 {
		margin-top:3rem!important;
		margin-bottom:3rem!important
	}
	.my-lg-auto {
		margin-top:auto!important;
		margin-bottom:auto!important
	}
	.mt-lg-0 {
		margin-top:0!important
	}
	.mt-lg-1 {
	margin-top:.25rem!important
	}
	.mt-lg-2 {
	margin-top:.5rem!important
	}
	.mt-lg-3 {
		margin-top:1rem!important
	}
	.mt-lg-4 {
		margin-top:1.5rem!important
	}
	.mt-lg-5 {
		margin-top:3rem!important
	}
	.mt-lg-auto {
		margin-top:auto!important
	}
	.me-lg-0 {
		margin-right:0!important
	}
	.me-lg-1 {
	margin-right:.25rem!important
	}
	.me-lg-2 {
	margin-right:.5rem!important
	}
	.me-lg-3 {
		margin-right:1rem!important
	}
	.me-lg-4 {
		margin-right:1.5rem!important
	}
	.me-lg-5 {
		margin-right:3rem!important
	}
	.me-lg-auto {
		margin-right:auto!important
	}
	.mb-lg-0 {
		margin-bottom:0!important
	}
	.mb-lg-1 {
	margin-bottom:.25rem!important
	}
	.mb-lg-2 {
	margin-bottom:.5rem!important
	}
	.mb-lg-3 {
		margin-bottom:1rem!important
	}
	.mb-lg-4 {
		margin-bottom:1.5rem!important
	}
	.mb-lg-5 {
		margin-bottom:3rem!important
	}
	.mb-lg-auto {
		margin-bottom:auto!important
	}
	.ms-lg-0 {
		margin-left:0!important
	}
	.ms-lg-1 {
	margin-left:.25rem!important
	}
	.ms-lg-2 {
	margin-left:.5rem!important
	}
	.ms-lg-3 {
		margin-left:1rem!important
	}
	.ms-lg-4 {
		margin-left:1.5rem!important
	}
	.ms-lg-5 {
		margin-left:3rem!important
	}
	.ms-lg-auto {
		margin-left:auto!important
	}
	.p-lg-0 {
		padding:0!important
	}
	.p-lg-1 {
	padding:.25rem!important
	}
	.p-lg-2 {
	padding:.5rem!important
	}
	.p-lg-3 {
		padding:1rem!important
	}
	.p-lg-4 {
		padding:1.5rem!important
	}
	.p-lg-5 {
		padding:3rem!important
	}
	.px-lg-0 {
		padding-right:0!important;
		padding-left:0!important
	}
	.px-lg-1 {
	padding-right:.25rem!important;
	padding-left:.25rem!important
	}
	.px-lg-2 {
	padding-right:.5rem!important;
	padding-left:.5rem!important
	}
	.px-lg-3 {
		padding-right:1rem!important;
		padding-left:1rem!important
	}
	.px-lg-4 {
		padding-right:1.5rem!important;
		padding-left:1.5rem!important
	}
	.px-lg-5 {
		padding-right:3rem!important;
		padding-left:3rem!important
	}
	.py-lg-0 {
		padding-top:0!important;
		padding-bottom:0!important
	}
	.py-lg-1 {
	padding-top:.25rem!important;
	padding-bottom:.25rem!important
	}
	.py-lg-2 {
	padding-top:.5rem!important;
	padding-bottom:.5rem!important
	}
	.py-lg-3 {
		padding-top:1rem!important;
		padding-bottom:1rem!important
	}
	.py-lg-4 {
		padding-top:1.5rem!important;
		padding-bottom:1.5rem!important
	}
	.py-lg-5 {
		padding-top:3rem!important;
		padding-bottom:3rem!important
	}
	.pt-lg-0 {
		padding-top:0!important
	}
	.pt-lg-1 {
	padding-top:.25rem!important
	}
	.pt-lg-2 {
	padding-top:.5rem!important
	}
	.pt-lg-3 {
		padding-top:1rem!important
	}
	.pt-lg-4 {
		padding-top:1.5rem!important
	}
	.pt-lg-5 {
		padding-top:3rem!important
	}
	.pe-lg-0 {
		padding-right:0!important
	}
	.pe-lg-1 {
	padding-right:.25rem!important
	}
	.pe-lg-2 {
	padding-right:.5rem!important
	}
	.pe-lg-3 {
		padding-right:1rem!important
	}
	.pe-lg-4 {
		padding-right:1.5rem!important
	}
	.pe-lg-5 {
		padding-right:3rem!important
	}
	.pb-lg-0 {
		padding-bottom:0!important
	}
	.pb-lg-1 {
	padding-bottom:.25rem!important
	}
	.pb-lg-2 {
	padding-bottom:.5rem!important
	}
	.pb-lg-3 {
		padding-bottom:1rem!important
	}
	.pb-lg-4 {
		padding-bottom:1.5rem!important
	}
	.pb-lg-5 {
		padding-bottom:3rem!important
	}
	.ps-lg-0 {
		padding-left:0!important
	}
	.ps-lg-1 {
	padding-left:.25rem!important
	}
	.ps-lg-2 {
	padding-left:.5rem!important
	}
	.ps-lg-3 {
		padding-left:1rem!important
	}
	.ps-lg-4 {
		padding-left:1.5rem!important
	}
	.ps-lg-5 {
		padding-left:3rem!important
	}
	.gap-lg-0 {
		gap:0!important
	}
	.gap-lg-1 {
	gap:.25rem!important
	}
	.gap-lg-2 {
	gap:.5rem!important
	}
	.gap-lg-3 {
		gap:1rem!important
	}
	.gap-lg-4 {
		gap:1.5rem!important
	}
	.gap-lg-5 {
		gap:3rem!important
	}
	.row-gap-lg-0 {
		row-gap:0!important
	}
	.row-gap-lg-1 {
	row-gap:.25rem!important
	}
	.row-gap-lg-2 {
	row-gap:.5rem!important
	}
	.row-gap-lg-3 {
		row-gap:1rem!important
	}
	.row-gap-lg-4 {
		row-gap:1.5rem!important
	}
	.row-gap-lg-5 {
		row-gap:3rem!important
	}
	.column-gap-lg-0 {
		column-gap:0!important
	}
	.column-gap-lg-1 {
	column-gap:.25rem!important
	}
	.column-gap-lg-2 {
	column-gap:.5rem!important
	}
	.column-gap-lg-3 {
		column-gap:1rem!important
	}
	.column-gap-lg-4 {
		column-gap:1.5rem!important
	}
	.column-gap-lg-5 {
		column-gap:3rem!important
	}
	.text-lg-start {
		text-align:left!important
	}
	.text-lg-end {
		text-align:right!important
	}
	.text-lg-center {
		text-align:center!important
	}
	}
	@media(min-width:1200px) {
	.float-xl-start {
		float:left!important
	}
	.float-xl-end {
		float:right!important
	}
	.float-xl-none {
		float:none!important
	}
	.object-fit-xl-contain {
		object-fit:contain!important
	}
	.object-fit-xl-cover {
		object-fit:cover!important
	}
	.object-fit-xl-fill {
		object-fit:fill!important
	}
	.object-fit-xl-scale {
		object-fit:scale-down!important
	}
	.object-fit-xl-none {
		object-fit:none!important
	}
	.d-xl-inline {
		display:inline!important
	}
	.d-xl-inline-block {
		display:inline-block!important
	}
	.d-xl-block {
		display:block!important
	}
	.d-xl-grid {
		display:grid!important
	}
	.d-xl-inline-grid {
		display:inline-grid!important
	}
	.d-xl-table {
		display:table!important
	}
	.d-xl-table-row {
		display:table-row!important
	}
	.d-xl-table-cell {
		display:table-cell!important
	}
	.d-xl-flex {
		display:flex!important
	}
	.d-xl-inline-flex {
		display:inline-flex!important
	}
	.d-xl-none {
		display:none!important
	}
	.flex-xl-fill {
		flex:1 1 auto!important
	}
	.flex-xl-row {
		flex-direction:row!important
	}
	.flex-xl-column {
		flex-direction:column!important
	}
	.flex-xl-row-reverse {
		flex-direction:row-reverse!important
	}
	.flex-xl-column-reverse {
		flex-direction:column-reverse!important
	}
	.flex-xl-grow-0 {
		flex-grow:0!important
	}
	.flex-xl-grow-1 {
		flex-grow:1!important
	}
	.flex-xl-shrink-0 {
		flex-shrink:0!important
	}
	.flex-xl-shrink-1 {
		flex-shrink:1!important
	}
	.flex-xl-wrap {
		flex-wrap:wrap!important
	}
	.flex-xl-nowrap {
		flex-wrap:nowrap!important
	}
	.flex-xl-wrap-reverse {
		flex-wrap:wrap-reverse!important
	}
	.justify-content-xl-start {
		justify-content:flex-start!important
	}
	.justify-content-xl-end {
		justify-content:flex-end!important
	}
	.justify-content-xl-center {
		justify-content:center!important
	}
	.justify-content-xl-between {
		justify-content:space-between!important
	}
	.justify-content-xl-around {
		justify-content:space-around!important
	}
	.justify-content-xl-evenly {
		justify-content:space-evenly!important
	}
	.align-items-xl-start {
		align-items:flex-start!important
	}
	.align-items-xl-end {
		align-items:flex-end!important
	}
	.align-items-xl-center {
		align-items:center!important
	}
	.align-items-xl-baseline {
		align-items:baseline!important
	}
	.align-items-xl-stretch {
		align-items:stretch!important
	}
	.align-content-xl-start {
		align-content:flex-start!important
	}
	.align-content-xl-end {
		align-content:flex-end!important
	}
	.align-content-xl-center {
		align-content:center!important
	}
	.align-content-xl-between {
		align-content:space-between!important
	}
	.align-content-xl-around {
		align-content:space-around!important
	}
	.align-content-xl-stretch {
		align-content:stretch!important
	}
	.align-self-xl-auto {
		align-self:auto!important
	}
	.align-self-xl-start {
		align-self:flex-start!important
	}
	.align-self-xl-end {
		align-self:flex-end!important
	}
	.align-self-xl-center {
		align-self:center!important
	}
	.align-self-xl-baseline {
		align-self:baseline!important
	}
	.align-self-xl-stretch {
		align-self:stretch!important
	}
	.order-xl-first {
		order:-1!important
	}
	.order-xl-0 {
		order:0!important
	}
	.order-xl-1 {
		order:1!important
	}
	.order-xl-2 {
		order:2!important
	}
	.order-xl-3 {
		order:3!important
	}
	.order-xl-4 {
		order:4!important
	}
	.order-xl-5 {
		order:5!important
	}
	.order-xl-last {
		order:6!important
	}
	.m-xl-0 {
		margin:0!important
	}
	.m-xl-1 {
	margin:.25rem!important
	}
	.m-xl-2 {
	margin:.5rem!important
	}
	.m-xl-3 {
		margin:1rem!important
	}
	.m-xl-4 {
		margin:1.5rem!important
	}
	.m-xl-5 {
		margin:3rem!important
	}
	.m-xl-auto {
		margin:auto!important
	}
	.mx-xl-0 {
		margin-right:0!important;
		margin-left:0!important
	}
	.mx-xl-1 {
	margin-right:.25rem!important;
	margin-left:.25rem!important
	}
	.mx-xl-2 {
	margin-right:.5rem!important;
	margin-left:.5rem!important
	}
	.mx-xl-3 {
		margin-right:1rem!important;
		margin-left:1rem!important
	}
	.mx-xl-4 {
		margin-right:1.5rem!important;
		margin-left:1.5rem!important
	}
	.mx-xl-5 {
		margin-right:3rem!important;
		margin-left:3rem!important
	}
	.mx-xl-auto {
		margin-right:auto!important;
		margin-left:auto!important
	}
	.my-xl-0 {
		margin-top:0!important;
		margin-bottom:0!important
	}
	.my-xl-1 {
	margin-top:.25rem!important;
	margin-bottom:.25rem!important
	}
	.my-xl-2 {
	margin-top:.5rem!important;
	margin-bottom:.5rem!important
	}
	.my-xl-3 {
		margin-top:1rem!important;
		margin-bottom:1rem!important
	}
	.my-xl-4 {
		margin-top:1.5rem!important;
		margin-bottom:1.5rem!important
	}
	.my-xl-5 {
		margin-top:3rem!important;
		margin-bottom:3rem!important
	}
	.my-xl-auto {
		margin-top:auto!important;
		margin-bottom:auto!important
	}
	.mt-xl-0 {
		margin-top:0!important
	}
	.mt-xl-1 {
	margin-top:.25rem!important
	}
	.mt-xl-2 {
	margin-top:.5rem!important
	}
	.mt-xl-3 {
		margin-top:1rem!important
	}
	.mt-xl-4 {
		margin-top:1.5rem!important
	}
	.mt-xl-5 {
		margin-top:3rem!important
	}
	.mt-xl-auto {
		margin-top:auto!important
	}
	.me-xl-0 {
		margin-right:0!important
	}
	.me-xl-1 {
	margin-right:.25rem!important
	}
	.me-xl-2 {
	margin-right:.5rem!important
	}
	.me-xl-3 {
		margin-right:1rem!important
	}
	.me-xl-4 {
		margin-right:1.5rem!important
	}
	.me-xl-5 {
		margin-right:3rem!important
	}
	.me-xl-auto {
		margin-right:auto!important
	}
	.mb-xl-0 {
		margin-bottom:0!important
	}
	.mb-xl-1 {
	margin-bottom:.25rem!important
	}
	.mb-xl-2 {
	margin-bottom:.5rem!important
	}
	.mb-xl-3 {
		margin-bottom:1rem!important
	}
	.mb-xl-4 {
		margin-bottom:1.5rem!important
	}
	.mb-xl-5 {
		margin-bottom:3rem!important
	}
	.mb-xl-auto {
		margin-bottom:auto!important
	}
	.ms-xl-0 {
		margin-left:0!important
	}
	.ms-xl-1 {
	margin-left:.25rem!important
	}
	.ms-xl-2 {
	margin-left:.5rem!important
	}
	.ms-xl-3 {
		margin-left:1rem!important
	}
	.ms-xl-4 {
		margin-left:1.5rem!important
	}
	.ms-xl-5 {
		margin-left:3rem!important
	}
	.ms-xl-auto {
		margin-left:auto!important
	}
	.p-xl-0 {
		padding:0!important
	}
	.p-xl-1 {
	padding:.25rem!important
	}
	.p-xl-2 {
	padding:.5rem!important
	}
	.p-xl-3 {
		padding:1rem!important
	}
	.p-xl-4 {
		padding:1.5rem!important
	}
	.p-xl-5 {
		padding:3rem!important
	}
	.px-xl-0 {
		padding-right:0!important;
		padding-left:0!important
	}
	.px-xl-1 {
	padding-right:.25rem!important;
	padding-left:.25rem!important
	}
	.px-xl-2 {
	padding-right:.5rem!important;
	padding-left:.5rem!important
	}
	.px-xl-3 {
		padding-right:1rem!important;
		padding-left:1rem!important
	}
	.px-xl-4 {
		padding-right:1.5rem!important;
		padding-left:1.5rem!important
	}
	.px-xl-5 {
		padding-right:3rem!important;
		padding-left:3rem!important
	}
	.py-xl-0 {
		padding-top:0!important;
		padding-bottom:0!important
	}
	.py-xl-1 {
	padding-top:.25rem!important;
	padding-bottom:.25rem!important
	}
	.py-xl-2 {
	padding-top:.5rem!important;
	padding-bottom:.5rem!important
	}
	.py-xl-3 {
		padding-top:1rem!important;
		padding-bottom:1rem!important
	}
	.py-xl-4 {
		padding-top:1.5rem!important;
		padding-bottom:1.5rem!important
	}
	.py-xl-5 {
		padding-top:3rem!important;
		padding-bottom:3rem!important
	}
	.pt-xl-0 {
		padding-top:0!important
	}
	.pt-xl-1 {
	padding-top:.25rem!important
	}
	.pt-xl-2 {
	padding-top:.5rem!important
	}
	.pt-xl-3 {
		padding-top:1rem!important
	}
	.pt-xl-4 {
		padding-top:1.5rem!important
	}
	.pt-xl-5 {
		padding-top:3rem!important
	}
	.pe-xl-0 {
		padding-right:0!important
	}
	.pe-xl-1 {
	padding-right:.25rem!important
	}
	.pe-xl-2 {
	padding-right:.5rem!important
	}
	.pe-xl-3 {
		padding-right:1rem!important
	}
	.pe-xl-4 {
		padding-right:1.5rem!important
	}
	.pe-xl-5 {
		padding-right:3rem!important
	}
	.pb-xl-0 {
		padding-bottom:0!important
	}
	.pb-xl-1 {
	padding-bottom:.25rem!important
	}
	.pb-xl-2 {
	padding-bottom:.5rem!important
	}
	.pb-xl-3 {
		padding-bottom:1rem!important
	}
	.pb-xl-4 {
		padding-bottom:1.5rem!important
	}
	.pb-xl-5 {
		padding-bottom:3rem!important
	}
	.ps-xl-0 {
		padding-left:0!important
	}
	.ps-xl-1 {
	padding-left:.25rem!important
	}
	.ps-xl-2 {
	padding-left:.5rem!important
	}
	.ps-xl-3 {
		padding-left:1rem!important
	}
	.ps-xl-4 {
		padding-left:1.5rem!important
	}
	.ps-xl-5 {
		padding-left:3rem!important
	}
	.gap-xl-0 {
		gap:0!important
	}
	.gap-xl-1 {
	gap:.25rem!important
	}
	.gap-xl-2 {
	gap:.5rem!important
	}
	.gap-xl-3 {
		gap:1rem!important
	}
	.gap-xl-4 {
		gap:1.5rem!important
	}
	.gap-xl-5 {
		gap:3rem!important
	}
	.row-gap-xl-0 {
		row-gap:0!important
	}
	.row-gap-xl-1 {
	row-gap:.25rem!important
	}
	.row-gap-xl-2 {
	row-gap:.5rem!important
	}
	.row-gap-xl-3 {
		row-gap:1rem!important
	}
	.row-gap-xl-4 {
		row-gap:1.5rem!important
	}
	.row-gap-xl-5 {
		row-gap:3rem!important
	}
	.column-gap-xl-0 {
		column-gap:0!important
	}
	.column-gap-xl-1 {
	column-gap:.25rem!important
	}
	.column-gap-xl-2 {
	column-gap:.5rem!important
	}
	.column-gap-xl-3 {
		column-gap:1rem!important
	}
	.column-gap-xl-4 {
		column-gap:1.5rem!important
	}
	.column-gap-xl-5 {
		column-gap:3rem!important
	}
	.text-xl-start {
		text-align:left!important
	}
	.text-xl-end {
		text-align:right!important
	}
	.text-xl-center {
		text-align:center!important
	}
	}
	@media(min-width:1400px) {
	.float-xxl-start {
		float:left!important
	}
	.float-xxl-end {
		float:right!important
	}
	.float-xxl-none {
		float:none!important
	}
	.object-fit-xxl-contain {
		object-fit:contain!important
	}
	.object-fit-xxl-cover {
		object-fit:cover!important
	}
	.object-fit-xxl-fill {
		object-fit:fill!important
	}
	.object-fit-xxl-scale {
		object-fit:scale-down!important
	}
	.object-fit-xxl-none {
		object-fit:none!important
	}
	.d-xxl-inline {
		display:inline!important
	}
	.d-xxl-inline-block {
		display:inline-block!important
	}
	.d-xxl-block {
		display:block!important
	}
	.d-xxl-grid {
		display:grid!important
	}
	.d-xxl-inline-grid {
		display:inline-grid!important
	}
	.d-xxl-table {
		display:table!important
	}
	.d-xxl-table-row {
		display:table-row!important
	}
	.d-xxl-table-cell {
		display:table-cell!important
	}
	.d-xxl-flex {
		display:flex!important
	}
	.d-xxl-inline-flex {
		display:inline-flex!important
	}
	.d-xxl-none {
		display:none!important
	}
	.flex-xxl-fill {
		flex:1 1 auto!important
	}
	.flex-xxl-row {
		flex-direction:row!important
	}
	.flex-xxl-column {
		flex-direction:column!important
	}
	.flex-xxl-row-reverse {
		flex-direction:row-reverse!important
	}
	.flex-xxl-column-reverse {
		flex-direction:column-reverse!important
	}
	.flex-xxl-grow-0 {
		flex-grow:0!important
	}
	.flex-xxl-grow-1 {
		flex-grow:1!important
	}
	.flex-xxl-shrink-0 {
		flex-shrink:0!important
	}
	.flex-xxl-shrink-1 {
		flex-shrink:1!important
	}
	.flex-xxl-wrap {
		flex-wrap:wrap!important
	}
	.flex-xxl-nowrap {
		flex-wrap:nowrap!important
	}
	.flex-xxl-wrap-reverse {
		flex-wrap:wrap-reverse!important
	}
	.justify-content-xxl-start {
		justify-content:flex-start!important
	}
	.justify-content-xxl-end {
		justify-content:flex-end!important
	}
	.justify-content-xxl-center {
		justify-content:center!important
	}
	.justify-content-xxl-between {
		justify-content:space-between!important
	}
	.justify-content-xxl-around {
		justify-content:space-around!important
	}
	.justify-content-xxl-evenly {
		justify-content:space-evenly!important
	}
	.align-items-xxl-start {
		align-items:flex-start!important
	}
	.align-items-xxl-end {
		align-items:flex-end!important
	}
	.align-items-xxl-center {
		align-items:center!important
	}
	.align-items-xxl-baseline {
		align-items:baseline!important
	}
	.align-items-xxl-stretch {
		align-items:stretch!important
	}
	.align-content-xxl-start {
		align-content:flex-start!important
	}
	.align-content-xxl-end {
		align-content:flex-end!important
	}
	.align-content-xxl-center {
		align-content:center!important
	}
	.align-content-xxl-between {
		align-content:space-between!important
	}
	.align-content-xxl-around {
		align-content:space-around!important
	}
	.align-content-xxl-stretch {
		align-content:stretch!important
	}
	.align-self-xxl-auto {
		align-self:auto!important
	}
	.align-self-xxl-start {
		align-self:flex-start!important
	}
	.align-self-xxl-end {
		align-self:flex-end!important
	}
	.align-self-xxl-center {
		align-self:center!important
	}
	.align-self-xxl-baseline {
		align-self:baseline!important
	}
	.align-self-xxl-stretch {
		align-self:stretch!important
	}
	.order-xxl-first {
		order:-1!important
	}
	.order-xxl-0 {
		order:0!important
	}
	.order-xxl-1 {
		order:1!important
	}
	.order-xxl-2 {
		order:2!important
	}
	.order-xxl-3 {
		order:3!important
	}
	.order-xxl-4 {
		order:4!important
	}
	.order-xxl-5 {
		order:5!important
	}
	.order-xxl-last {
		order:6!important
	}
	.m-xxl-0 {
		margin:0!important
	}
	.m-xxl-1 {
	margin:.25rem!important
	}
	.m-xxl-2 {
	margin:.5rem!important
	}
	.m-xxl-3 {
		margin:1rem!important
	}
	.m-xxl-4 {
		margin:1.5rem!important
	}
	.m-xxl-5 {
		margin:3rem!important
	}
	.m-xxl-auto {
		margin:auto!important
	}
	.mx-xxl-0 {
		margin-right:0!important;
		margin-left:0!important
	}
	.mx-xxl-1 {
	margin-right:.25rem!important;
	margin-left:.25rem!important
	}
	.mx-xxl-2 {
	margin-right:.5rem!important;
	margin-left:.5rem!important
	}
	.mx-xxl-3 {
		margin-right:1rem!important;
		margin-left:1rem!important
	}
	.mx-xxl-4 {
		margin-right:1.5rem!important;
		margin-left:1.5rem!important
	}
	.mx-xxl-5 {
		margin-right:3rem!important;
		margin-left:3rem!important
	}
	.mx-xxl-auto {
		margin-right:auto!important;
		margin-left:auto!important
	}
	.my-xxl-0 {
		margin-top:0!important;
		margin-bottom:0!important
	}
	.my-xxl-1 {
	margin-top:.25rem!important;
	margin-bottom:.25rem!important
	}
	.my-xxl-2 {
	margin-top:.5rem!important;
	margin-bottom:.5rem!important
	}
	.my-xxl-3 {
		margin-top:1rem!important;
		margin-bottom:1rem!important
	}
	.my-xxl-4 {
		margin-top:1.5rem!important;
		margin-bottom:1.5rem!important
	}
	.my-xxl-5 {
		margin-top:3rem!important;
		margin-bottom:3rem!important
	}
	.my-xxl-auto {
		margin-top:auto!important;
		margin-bottom:auto!important
	}
	.mt-xxl-0 {
		margin-top:0!important
	}
	.mt-xxl-1 {
	margin-top:.25rem!important
	}
	.mt-xxl-2 {
	margin-top:.5rem!important
	}
	.mt-xxl-3 {
		margin-top:1rem!important
	}
	.mt-xxl-4 {
		margin-top:1.5rem!important
	}
	.mt-xxl-5 {
		margin-top:3rem!important
	}
	.mt-xxl-auto {
		margin-top:auto!important
	}
	.me-xxl-0 {
		margin-right:0!important
	}
	.me-xxl-1 {
	margin-right:.25rem!important
	}
	.me-xxl-2 {
	margin-right:.5rem!important
	}
	.me-xxl-3 {
		margin-right:1rem!important
	}
	.me-xxl-4 {
		margin-right:1.5rem!important
	}
	.me-xxl-5 {
		margin-right:3rem!important
	}
	.me-xxl-auto {
		margin-right:auto!important
	}
	.mb-xxl-0 {
		margin-bottom:0!important
	}
	.mb-xxl-1 {
	margin-bottom:.25rem!important
	}
	.mb-xxl-2 {
	margin-bottom:.5rem!important
	}
	.mb-xxl-3 {
		margin-bottom:1rem!important
	}
	.mb-xxl-4 {
		margin-bottom:1.5rem!important
	}
	.mb-xxl-5 {
		margin-bottom:3rem!important
	}
	.mb-xxl-auto {
		margin-bottom:auto!important
	}
	.ms-xxl-0 {
		margin-left:0!important
	}
	.ms-xxl-1 {
	margin-left:.25rem!important
	}
	.ms-xxl-2 {
	margin-left:.5rem!important
	}
	.ms-xxl-3 {
		margin-left:1rem!important
	}
	.ms-xxl-4 {
		margin-left:1.5rem!important
	}
	.ms-xxl-5 {
		margin-left:3rem!important
	}
	.ms-xxl-auto {
		margin-left:auto!important
	}
	.p-xxl-0 {
		padding:0!important
	}
	.p-xxl-1 {
	padding:.25rem!important
	}
	.p-xxl-2 {
	padding:.5rem!important
	}
	.p-xxl-3 {
		padding:1rem!important
	}
	.p-xxl-4 {
		padding:1.5rem!important
	}
	.p-xxl-5 {
		padding:3rem!important
	}
	.px-xxl-0 {
		padding-right:0!important;
		padding-left:0!important
	}
	.px-xxl-1 {
	padding-right:.25rem!important;
	padding-left:.25rem!important
	}
	.px-xxl-2 {
	padding-right:.5rem!important;
	padding-left:.5rem!important
	}
	.px-xxl-3 {
		padding-right:1rem!important;
		padding-left:1rem!important
	}
	.px-xxl-4 {
		padding-right:1.5rem!important;
		padding-left:1.5rem!important
	}
	.px-xxl-5 {
		padding-right:3rem!important;
		padding-left:3rem!important
	}
	.py-xxl-0 {
		padding-top:0!important;
		padding-bottom:0!important
	}
	.py-xxl-1 {
	padding-top:.25rem!important;
	padding-bottom:.25rem!important
	}
	.py-xxl-2 {
	padding-top:.5rem!important;
	padding-bottom:.5rem!important
	}
	.py-xxl-3 {
		padding-top:1rem!important;
		padding-bottom:1rem!important
	}
	.py-xxl-4 {
		padding-top:1.5rem!important;
		padding-bottom:1.5rem!important
	}
	.py-xxl-5 {
		padding-top:3rem!important;
		padding-bottom:3rem!important
	}
	.pt-xxl-0 {
		padding-top:0!important
	}
	.pt-xxl-1 {
	padding-top:.25rem!important
	}
	.pt-xxl-2 {
	padding-top:.5rem!important
	}
	.pt-xxl-3 {
		padding-top:1rem!important
	}
	.pt-xxl-4 {
		padding-top:1.5rem!important
	}
	.pt-xxl-5 {
		padding-top:3rem!important
	}
	.pe-xxl-0 {
		padding-right:0!important
	}
	.pe-xxl-1 {
	padding-right:.25rem!important
	}
	.pe-xxl-2 {
	padding-right:.5rem!important
	}
	.pe-xxl-3 {
		padding-right:1rem!important
	}
	.pe-xxl-4 {
		padding-right:1.5rem!important
	}
	.pe-xxl-5 {
		padding-right:3rem!important
	}
	.pb-xxl-0 {
		padding-bottom:0!important
	}
	.pb-xxl-1 {
	padding-bottom:.25rem!important
	}
	.pb-xxl-2 {
	padding-bottom:.5rem!important
	}
	.pb-xxl-3 {
		padding-bottom:1rem!important
	}
	.pb-xxl-4 {
		padding-bottom:1.5rem!important
	}
	.pb-xxl-5 {
		padding-bottom:3rem!important
	}
	.ps-xxl-0 {
		padding-left:0!important
	}
	.ps-xxl-1 {
	padding-left:.25rem!important
	}
	.ps-xxl-2 {
	padding-left:.5rem!important
	}
	.ps-xxl-3 {
		padding-left:1rem!important
	}
	.ps-xxl-4 {
		padding-left:1.5rem!important
	}
	.ps-xxl-5 {
		padding-left:3rem!important
	}
	.gap-xxl-0 {
		gap:0!important
	}
	.gap-xxl-1 {
	gap:.25rem!important
	}
	.gap-xxl-2 {
	gap:.5rem!important
	}
	.gap-xxl-3 {
		gap:1rem!important
	}
	.gap-xxl-4 {
		gap:1.5rem!important
	}
	.gap-xxl-5 {
		gap:3rem!important
	}
	.row-gap-xxl-0 {
		row-gap:0!important
	}
	.row-gap-xxl-1 {
	row-gap:.25rem!important
	}
	.row-gap-xxl-2 {
	row-gap:.5rem!important
	}
	.row-gap-xxl-3 {
		row-gap:1rem!important
	}
	.row-gap-xxl-4 {
		row-gap:1.5rem!important
	}
	.row-gap-xxl-5 {
		row-gap:3rem!important
	}
	.column-gap-xxl-0 {
		column-gap:0!important
	}
	.column-gap-xxl-1 {
	column-gap:.25rem!important
	}
	.column-gap-xxl-2 {
	column-gap:.5rem!important
	}
	.column-gap-xxl-3 {
		column-gap:1rem!important
	}
	.column-gap-xxl-4 {
		column-gap:1.5rem!important
	}
	.column-gap-xxl-5 {
		column-gap:3rem!important
	}
	.text-xxl-start {
		text-align:left!important
	}
	.text-xxl-end {
		text-align:right!important
	}
	.text-xxl-center {
		text-align:center!important
	}
	}
	@media(min-width:1200px) {
	.fs-1 {
		font-size:2.5rem!important
	}
	.fs-2 {
		font-size:2rem!important
	}
	.fs-3 {
		font-size:1.75rem!important
	}
	.fs-4 {
		font-size:1.5rem!important
	}
	}
	@media print {
	.d-print-inline {
		display:inline!important
	}
	.d-print-inline-block {
		display:inline-block!important
	}
	.d-print-block {
		display:block!important
	}
	.d-print-grid {
		display:grid!important
	}
	.d-print-inline-grid {
		display:inline-grid!important
	}
	.d-print-table {
		display:table!important
	}
	.d-print-table-row {
		display:table-row!important
	}
	.d-print-table-cell {
		display:table-cell!important
	}
	.d-print-flex {
		display:flex!important
	}
	.d-print-inline-flex {
		display:inline-flex!important
	}
	.d-print-none {
		display:none!important
	}
	}
	body {
		font-family:DM Sans, sans-serif;
		font-weight:400;
		font-size:18px;
		line-height:1.5;
		position:relative;
		height:100%;
		box-sizing:border-box;
		-webkit-font-smoothing:antialiased;
		-moz-osx-font-smoothing:grayscale
	}
	.contact-txt .call-widget a span, .faq-item button, .h1, .h2, .h3, .h4, .h5, .h6, .lead, .payment-option .accordion-button, .product-details-tab .nav-tabs li .nav-link, .slide-bar .box-item span, .slide-bar.reverse .box-item span, h1, h2, h3, h4, h5, h6 {
		font-family:Teko, sans-serif;
		color:#030523;
		margin-bottom:10px;
		font-weight:700
	}
	.h1, .slide-bar .box-item span, .slide-bar.reverse .box-item span, h1 {
		font-family:Teko, sans-serif;
		color:#030523;
		margin-bottom:10px;
		font-weight:700;
		font-size:60px
	}
	.h2, h2 {
		font-size:45px
	}
	.h2, .h3, h2, h3 {
		font-family:Teko, sans-serif;
		color:#030523;
		margin-bottom:10px;
		font-weight:700
	}
	.h3, h3 {
		font-size:32px
	}
	@media(max-width:1399.98px) {
	.h3, h3 {
		font-size:28px
	}
	}
	.contact-txt .call-widget a span, .faq-item button, .h4, .lead, .product-details-tab .nav-tabs li .nav-link, h4 {
		font-family:Teko, sans-serif;
		color:#030523;
		margin-bottom:10px;
		font-weight:700;
		font-size:24px
	}
	.h5, h5 {
		font-family:Teko, sans-serif;
		color:#030523;
		margin-bottom:10px;
		font-weight:700;
		font-size:20px
	}
	.h6, .payment-option .accordion-button, h6 {
		font-family:Teko, sans-serif;
		color:#030523;
		margin-bottom:10px;
		font-weight:700;
		font-size:18px
	}
	@media(min-width:1400px) {
	.container {
		max-width:1290px
	}
	}
	a {
		color:#030523
	}
	a, a:focus, a:hover {
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out;
		text-decoration:none
	}
	a:focus, a:hover {
		color:#e6c43a
	}
	.form-control, input, textarea {
		color:#696969;
		width:100%;
		padding:16px 20px;
		border:1px solid #e8e8e8;
		-webkit-border-radius:5px;
		-moz-border-radius:5px;
		-ms-border-radius:5px;
		border-radius:5px
	}
	.form-control.btn, input.btn, textarea.btn {
		cursor:auto
	}
	.form-control:focus, input:focus, textarea:focus {
		outline:0;
	box-shadow:0 0 0 .05rem rgba(248,188,26,.2)
	}
	.form-control::placeholder, input::placeholder, textarea::placeholder {
	color:#696969;
	opacity:1
	}
	.form-control:-ms-input-placeholder, input:-ms-input-placeholder, textarea:-ms-input-placeholder {
	color:#e8e8e8
	}
	.form-control::-ms-input-placeholder, input::-ms-input-placeholder, textarea::-ms-input-placeholder {
	color:#e8e8e8
	}
	input[type=checkbox] {
		padding:0
	}
	.sec-intro {
		max-width:520px
	}
	@media(max-width:575.98px) {
	.sec-intro {
		margin-bottom:50px
	}
	}
	.sec-padding {
		padding:120px 0
	}
	@media(min-width:992px) and (max-width:1199.98px) {
	.sec-padding {
		padding:100px 0
	}
	}
	@media(min-width:768px) and (max-width:991.98px) {
	.sec-padding {
		padding:90px 0
	}
	}
	@media(max-width:767.98px) {
	.sec-padding {
		padding:80px 0
	}
	}
	.mb-10 {
		margin-bottom:100px
	}
	.about-tab .nav-tabs li .nav-link, .about-txt ul li, .blog-page .cover-entry.list-entry, .btn-close, .cricket-banner, .cta-sec2, .error-sec, .faq-item .accordion-body, .feature-entry, .feature-sec, .feature-sec3 .feature-txt ul li, .footer3, .header.header-4 .navbar>.container, .match-schedule, .match-schedule .container, .overly, .pricing-card .pricing-body ul li, .sec-title.line, .sec-title.line-left, .single-brand, .single-entry2.cover-entry, .single-post .content-list li, .swiper-pagination span.swiper-pagination-bullet-active, .tab-txt ul li, .team-member, .team-sec, blockquote, section.promo-sec {
		position:relative;
		z-index:1
	}
	.about-tab .nav-tabs li .nav-link:before, .about-txt ul li:before, .blog-page .cover-entry.list-entry:before, .btn-close:before, .cricket-banner:before, .cta-sec2:before, .error-sec:before, .faq-item .accordion-body:before, .feature-entry:before, .feature-sec3 .feature-txt ul li:before, .feature-sec:before, .footer3:before, .header.header-4 .navbar>.container:before, .match-schedule .container:before, .match-schedule:before, .overly:before, .pricing-card .pricing-body ul li:before, .sec-title.line-left:before, .sec-title.line:before, .single-brand:before, .single-entry2.cover-entry:before, .single-post .content-list li:before, .swiper-pagination span.swiper-pagination-bullet-active:before, .tab-txt ul li:before, .team-member:before, .team-sec:before, blockquote:before, section.promo-sec:before {
		position:absolute;
		content:"";
		left:0;
		top:0;
		width:100%;
		height:100%;
		z-index:-1;
		background:#e6c43a
	}
	.sec-title {
		text-transform:uppercase;
		font-size:64px
	}
	@media(max-width:1399.98px) {
	.sec-title {
		font-size:55px
	}
	}
	@media(max-width:1199.98px) {
	.sec-title {
		font-size:48px
	}
	}
	@media(max-width:575.98px) {
	.sec-title {
		font-size:40px
	}
	}
	.sec-title.line, .sec-title.line-left {
		padding-bottom:30px
	}
	.sec-title.line-left:before, .sec-title.line:before {
		height:3px;
		width:120px;
		background-color:#136495;
		left:50%;
		transform:translateX(-50%);
		top:auto;
		bottom:15px
	}
	.sec-title.line.red:before, .sec-title.red.line-left:before {
		background-color:#136495
	}
	.sec-title.green.line-left:before, .sec-title.line.green:before {
		background-color:#35cc6c
	}
	.sec-title.line.primary:before, .sec-title.primary.line-left:before {
		background-color:#e6c43a
	}
	.sec-title.line-left:before {
		left:0;
		transform:translateX(0)
	}
	.sub-title {
		font-family:Teko, sans-serif;
		color:#030523;
		margin-bottom:10px;
		font-weight:700;
		font-size:40px
	}
	@media(max-width:575.98px) {
	.sub-title {
		font-size:32px
	}
	}
	.about-sec .counter-wrap .counter-card p, .footer .copy-right p, .header.header-4 .navbar-nav .nav-cta a, .header.header-4 .navbar-nav li a.nav-link, .pricing-card .h2, .pricing-card .pricing-body ul li, .pricing-card h2, .serv-item p, .testimonial2-sec .single-testimonial p, .text-mutes {
		color:hsla(0,0%,100%,.7)
	}
	.lead {
		letter-spacing:2.4px;
		text-transform:uppercase;
		font-weight:600
	}
	.bg-cover {
		background-size:cover;
		background-position:top;
		background-repeat:no-repeat
	}
	.text-nowrap {
		text-overflow:ellipsis;
		white-space:nowrap;
		overflow:hidden
	}
	ol.breadcrumb {
		display:block
	}
	ol.breadcrumb .breadcrumb-item {
		display:inline-block;
		color:#fff;
		font-size:16px;
		text-transform:capitalize
	}
	ol.breadcrumb .breadcrumb-item:before {
		color:#e6c43a;
		font-style:italic
	}
	ol.breadcrumb .breadcrumb-item a {
		color:#fff
	}
	.btn {
		font-weight:700;
		font-size:18px;
		text-transform:capitalize;
		-webkit-border-radius:32px;
		-moz-border-radius:32px;
		-ms-border-radius:32px;
		border-radius:32px;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.btn::visted, .btn:focus {
	box-shadow:none
	}
	.btn-group-sm>.btn, .btn.btn-sm {
		padding:12px 20px
	}
	.btn i {
		margin-left:10px
	}
	.btn.btn-gr {
		color:#000;
		background:#53d57f;
		background:-moz-linear-gradient(45deg, #53d57f 0, #d6f589 100%);
		background:-webkit-linear-gradient(45deg, #53d57f, #d6f589);
		background:linear-gradient(45deg, #53d57f, #d6f589);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#53d57f", endColorstr="#d6f589", GradientType=1)
	}
	.text-gr {
		background:-webkit-linear-gradient(#53d57f, #d6f589);
		appearance: text;
		background-clip: text;
		-webkit-background-clip:text;
		-webkit-text-fill-color:transparent
	}
	.ct-btn {
		display:flex
	}
	.ct-btn i {
		font-size:25px;
		vertical-align:middle
	}
	.btn-primary {
		border:none
	}
	.btn-primary:active, .btn-primary:focus, .btn-primary:hover {
		background-color:#b9d700;
		color:#030523
	}
	.icon, .icon-sm {
		display:inline-table;
		height:80px;
		width:80px;
		line-height:80px;
		text-align:center
	}
	.icon-sm i, .icon i {
		display:table-cell;
		vertical-align:middle
	}
	.icon-sm {
		width:64px;
		height:64px;
		line-height:64px
	}
	.text-xs {
		font-size:16px
	}
	.body-font {
		font-family:DM Sans, sans-serif
	}
	.btn-primary:focus {
		box-shadow:none!important
	}
	.back-top {
		cursor:pointer;
		position:fixed!important;
		bottom:40px;
		right:30px;
		display:block;
		z-index:99;
		visibility:hidden;
		opacity:0;
		-ms-filter:"alpha(opacity=0)";
		filter:alpha(opacity=0);
		-webkit-transform:translateY(50%);
		transform:translateY(50%);
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out;
		background:#000;
		color:#fff;
		border-radius:10%;
		width:52px;
		height:52px;
		line-height:52px;
		text-align:center;
		font-size:20px
	}
	.back-top.back-top-show {
		visibility:visible;
		opacity:1;
		-webkit-transform:translateY(0);
		transform:translateY(0)
	}
	select {
		display:none
	}
	.nice-select {
		-webkit-tap-highlight-color:transparent;
		background-color:transparent;
		border-radius:5px;
		border:none;
		font-size:16px
	}
	@media(max-width:767.98px) {
	.nice-select {
		font-size:15px
	}
	}
	.nice-select:after {
		border-color:#000;
		height:6px;
		width:6px
	}
	.pager a, .pager span {
		width:54px;
		height:54px;
		line-height:54px;
		display:inline-block;
		background-color:#f6f6f6;
		text-align:center;
		margin-right:5px;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.pager a:hover, .pager span:hover {
		background-color:#e6c43a;
		color:#696969
	}
	@media(max-width:767.98px) {
	.pager a, .pager span {
		margin:5px;
		width:45px;
		height:45px;
		line-height:45px
	}
	}
	.pager .current {
		background-color:#212529;
		color:#fff
	}
	blockquote {
		font-size:22px;
		color:#030523;
		margin:30px 0;
		line-height:32px;
		padding:25px
	}
	blockquote:before {
		background:url(../images/icons/quote.png) no-repeat 100% 100%;
		left:auto;
		right:30px
	}
	a#scrollUp {
		right:40px;
		bottom:60px;
		font-size:50px;
		width:40px;
		height:70px;
		color:#fff;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	a#scrollUp:before {
		position:absolute;
		content:"\f119";
		font-family:flaticon;
		-webkit-animation:updown 1.5s ease-in-out 0s infinite normal none running;
		animation:updown 1.5s ease-in-out 0s infinite normal none running;
		left:65%;
		transform:translateX(-50%)
	}
	a#scrollUp:after {
		position:absolute;
		left:-5px;
		top:-22px;
		width:60px;
		height:60px;
		background:#e6c43a;
		content:"";
		border-radius:50%;
		z-index:-1
	}
	.form-group.half-form {
		width:48%;
		float:left;
		margin-bottom:20px
	}
	@media(max-width:767.98px) {
	.form-group.half-form {
		width:100%;
		float:none
	}
	}
	.form-group.half-form:after {
		content:"";
		display:table;
		clear:both
	}
	.form-group.half-form:nth-child(2n) {
		float:right
	}
	.google-map iframe {
		height:100%
	}
	@media(min-width:768px) and (max-width:991.98px) {
	.google-map iframe {
		height:450px
	}
	}
	@media(max-width:767.98px) {
	.google-map iframe {
		height:350px
	}
	}
	@keyframes rotate {
	0% {
	transform:rotate(0deg)
	}
	50% {
	transform:rotate(-20deg)
	}
	to {
		transform:rotate(0deg)
	}
	}
	@keyframes updown {
	0% {
	transform:translateY(0)
	}
	50% {
	transform:translateY(30px)
	}
	to {
		transform:translateY(0)
	}
	}
	@keyframes slide-bar {
	0% {
	-webkit-transform:translateX(0);
	transform:translateX(0)
	}
	to {
		-webkit-transform:translateX(-100%);
		transform:translateX(-100%)
	}
	}
	@keyframes slide-bar-right {
	0% {
	-webkit-transform:translateX(-100%);
	transform:translateX(-100%)
	}
	to {
		-webkit-transform:translateX(0);
		transform:translateX(0)
	}
	}
	.slide-bar {
		-webkit-animation:slide-bar 20s linear infinite;
		animation:slide-bar 20s linear infinite
	}
	.slide-bar .box-item {
		white-space:nowrap;
		margin-right:30px
	}
	.slide-bar .box-item span {
		font-size:100px;
		color:#fff
	}
	@media(max-width:991.98px) {
	.slide-bar .box-item span {
		font-size:70px
	}
	}
	@media(max-width:575.98px) {
	.slide-bar .box-item span {
		font-size:50px
	}
	}
	.slide-bar .box-item span img {
		margin-right:20px
	}
	.slide-bar.green-slide .box-item span {
		color:#35cc6c
	}
	.slide-bar.reverse {
		-webkit-animation:slide-bar-right 20s linear infinite;
		animation:slide-bar-right 20s linear infinite
	}
	.slide-bar.reverse .box-item span {
		font-size:100px;
		text-shadow:2px 2px 0 #fff, -2px 2px 0 #fff, -2px -2px 0 #fff, 2px -2px 0 #fff;
		color:#136495;
		letter-spacing:2px
	}
	@media(max-width:991.98px) {
	.slide-bar.reverse .box-item span {
		font-size:70px
	}
	}
	@media(max-width:575.98px) {
	.slide-bar.reverse .box-item span {
		font-size:50px
	}
	}
	.slide-bar.reverse.green-slide .box-item span {
		color:#fff;
		text-shadow:2px 2px 0 #35cc6c, -2px 2px 0 #35cc6c, -2px -2px 0 #35cc6c, 2px -2px 0 #35cc6c
	}
	#preloader.done {
		visibility:hidden;
		opacity:0
	}
	#preloader {
		position:fixed;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background-color:#f7f7f7;
		z-index:999999
	}
	.preloader {
		padding:0;
		text-align:left;
		box-sizing:border-box;
		top:50%;
		left:50%;
		margin-left:-25px;
		margin-top:-25px
	}
	.preloader, .preloader span {
		width:50px;
		height:50px;
		display:inline-block;
		position:absolute
	}
	.preloader span {
		border-radius:100%;
		background:#dbff00;
		-webkit-animation:preloader 1.3s linear infinite;
		animation:preloader 1.3s linear infinite
	}
	.preloader span:last-child {
		animation-delay:-.8s;
		-webkit-animation-delay:-.8s
	}
	@keyframes preloader {
	0% {
	transform:scale(0);
	opacity:.5
	}
	to {
		transform:scale(1);
		opacity:0
	}
	}
	@-webkit-keyframes preloader {
	0% {
	-webkit-transform:scale(0);
	opacity:.5
	}
	to {
		-webkit-transform:scale(1);
		opacity:0
	}
	}
	.ratings {
		letter-spacing:-3px
	}
	.ratings img {
		width:18px
	}
	.header .top-bar{
		padding: 12px 0 !important;
	}
	.header .top-bar p {
		font-size:16px;
		margin-bottom:0;
		line-height:1;
		color:#000
	}
	.header .top-bar p i {
		font-weight:600
	}
	.header .top-bar a {
		color:#000;
		font-size:16px
	}
	.header .top-bar .nice-select {
		padding-left:10px;
		width:112px;
		color:#000
	}
	.header .top-bar .language-select span {
		color:#000
	}
	.header .top-bar .top-meta {
		flex:0 0 280px
	}
	.header .top-bar.header-text-white .cart-bag sup, .header .top-bar.header-text-white .language-select span, .header .top-bar.header-text-white .top-meta p, .header .top-bar.header-text-white a, .header .top-bar.header-text-white p i {
		color:#fff
	}
	.header .top-bar.header-text-white .nice-select:after {
		border-color:#fff
	}
	.header.header-2 .navbar .nav-cta a sup {
		background-color:#136495
	}
	.header.header-2 .navbar .navbar-nav .nav-item:hover>.nav-link:not(.disabled), .header.header-2 .navbar .navbar-nav .navbar-light .nav-item.active .nav-link:not(.disabled) {
		color:#000
	}
	.header.header-3 .navbar {
		background-color:rgba(0,0,0,.2)
	}
	.header.header-3 .navbar .navbar-nav .nav-cta a, .header.header-3 .navbar .navbar-nav li a.nav-link, .header.header-3 .navbar button.navbar-toggler.offcanvas-nav-btn {
		color:#fff
	}
	.header.header-3 .navbar .navbar-nav .dropdown-item:hover, .header.header-3 .navbar .navbar-nav .nav-item:hover>.nav-link:not(.disabled), .header.header-3 .navbar .navbar-nav .navbar-light .nav-item.active .nav-link:not(.disabled) {
		color:#136495
	}
	.header.header-3 .navbar .nav-cta a, .header.header-3 .navbar .nav-cta a sup {
		color:#fff
	}
	.header.header-3 .navbar.scroll-on {
		background-color:#000
	}
	.header.header-3 .navbar .btn {
		padding:10px 15px
	}
	.header.header-3 .toggle-bar, .header.header-3 .toggle-bar:after, .header.header-3 .toggle-bar:before {
		border-top:3px solid #fff
	}
	.header.header-4 {
		position:relative;
		z-index:3
	}
	.header.header-4 .navbar>.container:before {
		background:hsla(0,0%,100%,.2);
		height:1px;
		top:auto;
		width:calc(100% - 30px);
		left:50%;
		transform:translateX(-50%);
		bottom:0;
		z-index:-1
	}
	@media(max-width:991.98px) {
	.header.header-4 .navbar>.container:before {
		bottom:-13px
	}
	}
	.header.header-4 .navbar .navbar-nav .nav-item:hover>.nav-link:not(.disabled), .header.header-4 .navbar .navbar-nav .navbar-light .nav-item.active .nav-link:not(.disabled) {
		color:#fff
	}
	.header.header-4 .navbar .navbar-nav .dropdown-item:hover {
		color:#35cc6c
	}
	.header.header-4 .nav-cta a {
		color:hsla(0,0%,100%,.7)
	}
	.header.header-4 .nav-cta a:hover, .header.header-4 .top-bar p {
		color:#fff
	}
	.header.header-4 .toggle-bar, .header.header-4 .toggle-bar:after, .header.header-4 .toggle-bar:before {
		border-top:3px solid #fff
	}
	.header.header-4 .scroll-on.navbar>.container:before {
		display:none
	}
	.header.header-4 .navbar button.navbar-toggler.offcanvas-nav-btn {
		border:0;
		font-size:1.5rem;
		font-weight:700;
		color:#b3b3b3
	}
	.navbar {
		padding:0
	}
	.navbar .dropdown, .navbar .dropdown-center, .navbar .dropend, .navbar .dropstart, .navbar .dropup, .navbar .dropup-center {
		position:relative
	}
	.navbar .navbar-nav li a.nav-link {
		padding:25px 20px;
		color:#030523;
		text-transform:capitalize;
		font-weight:600;
		font-size:18px
	}
	.navbar .navbar-nav li a.nav-link:hover {
		color:#000
	}
	.navbar .navbar-nav li .dropdown-item {
		color:#030523
	}
	.navbar .navbar-nav .dropdown-menu {
		border:none;
		border-radius:8px;
		margin-top:0;
		padding:15px 0;
		min-width:240px;
		top:100%;
		left:0;
	box-shadow:0 .125rem 1.25rem rgba(0,0,0,.05);
		z-index:10
	}
	.navbar .navbar-nav .dropdown-menu .dropdown-item {
		align-items:center;
		display:flex;
		font-weight:500;
		padding:7px 15px;
		width:100%;
		font-size:16px;
		-webkit-transition:all .3s ease-in-out;
		-moz-transition:all .3s ease-in-out;
		-ms-transition:all .3s ease-in-out;
		-o-transition:all .3s ease-in-out;
		transition:all .3s ease-in-out
	}
	.navbar .navbar-nav .dropdown-menu .dropdown-item:focus, .navbar .navbar-nav .dropdown-menu .dropdown-item:hover {
		color:#000;
		background-color:transparent;
		padding-left:22px;
		-webkit-transition:all .3s ease;
		-moz-transition:all .3s ease;
		-ms-transition:all .3s ease;
		-o-transition:all .3s ease;
		transition:all .3s ease
	}
	.navbar .navbar-nav .dropdown-menu .dropdown-submenu a:after {
		position:absolute;
		right:16px;
		transform:rotate(-90deg)
	}
	.navbar .navbar-nav .dropdown-menu .dropdown-submenu.dropend .dropdown-menu {
		left:100%;
		right:auto;
		top:-18px
	}
	.navbar .navbar-nav .dropend>.dropdown-menu {
	transform:translateY(.625rem)
	}
	.navbar .navbar-brand {
		margin-right:0
	}
	@media(max-width:575.98px) {
	.navbar a.navbar-brand img {
		width:125px
	}
	}
	.navbar .navbar-toggler:focus {
		box-shadow:none
	}
	.navbar .dropdown-toggle:after {
		float:right
	}
	.navbar .dropdown .dropdown-toggle:after {
		content:"\f12f";
		font-family:feather-icons!important;
		border:0;
		margin-left:7px;
		margin-top:1px
	}
	@media(max-width:767.98px) {
	.navbar .dropdown .dropdown-toggle:after {
		margin-left:auto;
		margin-right:10px
	}
	}
	.navbar button.navbar-toggler.offcanvas-nav-btn {
		border:0;
		font-size:1.5rem;
		font-weight:700;
		color:#030523
	}
	.navbar button.navbar-toggler.offcanvas-nav-btn i {
	top:.125rem;
		position:relative
	}
	.navbar .dropdown-header {
		color:#030523;
		font-size:15px;
		font-weight:700;
	letter-spacing:.0625rem;
		padding:3px 15px;
		text-transform:uppercase
	}
	.navbar .custom-btn {
		border-radius:30px;
		padding:9px 28px
	}
	.navbar .nav-cta a {
		width:55px;
		text-align:center;
		color:#000
	}
	@media(max-width:575.98px) {
	.navbar .nav-cta a {
		width:40px
	}
	}
	@media only screen and (max-width:360px) {
	.navbar .nav-cta a {
		width:25px
	}
	}
	.navbar .nav-cta a sup {
		background:#e6c43a;
		color:#000;
		top:-12px;
		right:0;
		display:inline-block;
		width:20px;
		height:20px;
		line-height:20px;
		-webkit-border-radius:50%;
		-moz-border-radius:50%;
		-ms-border-radius:50%;
		border-radius:50%;
		position:absolute
	}
	.navbar .nav-cta a i {
		font-size:20px
	}
	.navbar .nav-cta a.btn {
		width:auto
	}
	.navbar .nav-cta .dropdown.nav-search a.dropdown-toggle {
		background:0 0
	}
	.navbar.nav-center .navbar-nav li:last-child a.nav-link {
		padding-right:0
	}
	.navbar .dropdown:hover>.dropdown-menu {
		opacity:1;
		visibility:visible
	}
	.navbar .dropdown-submenu:hover>.dropdown-menu {
		opacity:1;
	transform:translateY(.625rem);
		visibility:visible
	}
	@media(min-width:992px) {
	.row-cols-lg-4>* {
		flex:0 0 auto;
		width:33.33%
	}
	.navbar .dropdown-menu {
		display:block;
		opacity:0;
		transition:all .3s ease-in-out;
		visibility:hidden
	}
	}
	@media(max-width:991.98px) {
	.navbar {
		padding:10px 0
	}
	.navbar .offcanvas-header img {
		max-width:115px
	}
	.navbar .navbar-nav .dropdown-menu {
		background-color:transparent;
		min-width:0!important
	}
	.navbar .navbar-nav .dropdown-menu .dropdown-item {
		font-size:15px
	}
	.navbar .offcanvas.bg-dark .dropdown-menu {
		background-color:#fff
	}
	.navbar .offcanvas.bg-dark .navbar-nav .nav-item .nav-link {
		border-bottom:1px solid #2b2b2b
	}
	.navbar .dropdown-menu .dropdown-submenu.dropend .dropdown-menu {
		margin-left:10px
	}
	.navbar .navbar-nav .nav-item .nav-link {
		border-bottom:1px solid #e6e6e6;
		border-radius:0;
		padding:16px 0;
		font-size:16px;
		line-height:1
	}
	.navbar .navbar-nav .nav-item:last-child .nav-link {
		border:0
	}
	.header-2 .navbar .offcanvas .dropdown>.dropdown-toggle:after, .header-2 .navbar .offcanvas .navbar-nav li a.nav-link {
		color:#030523
	}
	}
	.header-actions {
		max-width:280px;
		flex:0 0 280px
	}
	.header-actions .cart-num {
		background-color:#e6c43a;
		color:#fff;
		-webkit-border-radius:50%;
		-moz-border-radius:50%;
		-ms-border-radius:50%;
		border-radius:50%;
		display:inline-block;
		width:24px;
		height:24px;
		text-align:center;
		position:absolute;
		top:-16px;
		font-size:13px;
		line-height:24px
	}
	.header-actions a:hover {
		background-color:#e6c43a;
		color:#fff!important
	}
	header nav.navbar.scroll-on {
		position:fixed;
		left:0;
		top:0;
		right:0;
		z-index:99;
		background:#fff;
	box-shadow:0 .25rem 1.25rem rgba(0,0,0,.07);
		width:100%;
		animation:headerSlideDown 1.3s ease forwards
	}
	header.header-2 nav.navbar.navbar-expand-lg.scroll-on {
		background-color:#000;
	box-shadow:0 .2rem .5rem rgba(0,0,0,.15)
	}
	@keyframes headerSlideDown {
	0% {
	margin-top:-6.25rem
	}
	to {
		margin-top:0
	}
	}
	.search-form {
		position:relative
	}
	.search-form:after {
		content:"\f1cd";
		font-family:feather-icons;
		position:absolute;
		z-index:1;
		width:20px;
		background-color:transparent;
		top:50%;
		transform:translateY(-50%)
	}
	.search-form input {
		border:0;
		padding-left:30px
	}
	.search-form input:focus {
		box-shadow:none
	}
	.offcanvas-header .btn-close {
		padding:4px
	}
	.btn-close {
		width:30px;
		height:30px;
		line-height:36px;
		flex:0 0 30px;
		text-align:center;
		display:table;
		background:0 0;
		opacity:.9;
		-ms-filter:"alpha(opacity=90)";
		filter:alpha(opacity=90);
		background-color:transparent
	}
	.btn-close:focus {
		border:0;
		box-shadow:none
	}
	.btn-close:before {
		font-family:feather-icons;
		font-size:1.3rem;
		content:"\f213";
		background-color:#e6c43a;
		opacity:1;
		-ms-filter:"alpha(opacity=100)";
		filter:alpha(opacity=100);
		-webkit-border-radius:50%;
		-moz-border-radius:50%;
		-ms-border-radius:50%;
		border-radius:50%;
		line-height:37px
	}
	.header-1 .navbar-toggler span, .header-1 .navbar .nav-cta a {
		color:#fff
	}
	.header-1 .navbar {
		background-color:transparent;
		position:absolute;
		z-index:2;
		width:100%
	}
	.header-1 .navbar .navbar-nav li a.nav-link {
		color:#fff
	}
	.header-1 .navbar.scroll-on {
		background-color:#000
	}
	section.promo-sec {
		padding:100px 0 30px
	}
	section.promo-sec:before {
		background: rgb(19 100 149);
	}
	.slide-bar .box-item section.promo-sec span, section.promo-sec .h1, section.promo-sec .slide-bar .box-item span, section.promo-sec h1 {
		font-size:60px;
		padding-bottom:20px
	}
	@media(max-width:767.98px) {
	.slide-bar .box-item section.promo-sec span, section.promo-sec .h1, section.promo-sec .slide-bar .box-item span, section.promo-sec h1 {
		font-size:50px
	}
	}
	section.promo-sec .breadcrumb-item+.breadcrumb-item:before {
		content:"\f114";
		font-family:feather-icons;
		font-style:normal;
		color:#fff;
		font-size:18px
	}
	section.promo-sec .breadcrumb .breadcrumb-item.active {
		color:#e6c43a
	}
	.footer {
		padding:130px 0 30px
	}
	@media(max-width:991.98px) {
	.footer {
		padding:100px 0 30px
	}
	.footer .footer-widget {
		margin-bottom:45px
	}
	}
	.footer .copy-right {
		padding:0;
		border-top:1px solid hsla(0,0%,100%,.15)
	}
	.footer .copy-right p {
		font-size:16px
	}
	.footer .instagram-widget {
		padding-left:100px
	}
	@media(min-width:992px) and (max-width:1399.98px) {
	.footer .instagram-widget {
		padding-left:45px
	}
	}
	@media(max-width:991.98px) {
	.footer .instagram-widget {
		padding:0
	}
	}
	.footer-widget .widget-title {
		margin-bottom:30px
	}
	.footer-widget ul {
		margin:0;
		padding:0;
		list-style:none
	}
	.footer-widget ul li {
		margin-bottom:20px;
		color:hsla(0,0%,100%,.6)
	}
	.footer-widget ul li span {
		width:48px;
		height:48px;
		background-color:hsla(0,0%,100%,.1);
		text-align:center;
		display:table;
		margin-right:10px;
		-webkit-border-radius:50%;
		-moz-border-radius:50%;
		-ms-border-radius:50%;
		border-radius:50%;
		flex:0 0 48px
	}
	.footer-widget ul li span i {
		color:#e6c43a;
		display:table-cell;
		vertical-align:middle
	}
	.footer-widget ul li a {
		color:hsla(0,0%,100%,.6)
	}
	.footer-widget ul li:hover a {
		color:#fff
	}
	.footer-widget p {
		color:hsla(0,0%,100%,.6)
	}
	.footer-widget.touch-widget li span {
		width:40px;
		height:40px;
		flex:0 0 40px
	}
	.footer-widget.touch-widget.green li span {
		background-color:#35cc6c
	}
	.subs-form i {
		position:absolute;
		top:50%;
		-webkit-transform:translateY(-50%);
		-ms-transform:translateY(-50%);
		transform:translateY(-50%);
		left:15px
	}
	.subs-form input {
		padding:15px 20px 15px 40px
	}
	.subs-form .btn {
		padding:14px
	}
	.footer2 .footer-widget li span {
		background-color:#136495;
		-webkit-border-radius:5px;
		-moz-border-radius:5px;
		-ms-border-radius:5px;
		border-radius:5px;
		margin-right:20px
	}
	.footer2 .footer-widget li span i {
		color:#fff
	}
	.instagrame-posts ul li {
		display:inline-block;
		width:29%;
		margin-bottom:10px;
		margin-right:10px;
		overflow:hidden;
		-webkit-border-radius:5px;
		-moz-border-radius:5px;
		-ms-border-radius:5px;
		border-radius:5px
	}
	.instagrame-posts ul li:nth-child(3n+3) {
		margin-right:0
	}
	.footer2 .copy-right {
		border-top:1px solid hsla(0,0%,100%,.1)
	}
	.footer3 {
		background-repeat:no-repeat;
		background-position:top;
		padding-bottom:20px
	}
	.footer3:before {
		background:#000;
		background:linear-gradient(180deg, rgba(0,0,0,.9) 30%, #000)
	}
	.footer3 .subs-form input {
		background-color:hsla(0,0%,100%,.1);
		border-color:hsla(0,0%,100%,.2)
	}
	.footer3 .footer-widget ul {
		margin-top:30px
	}
	.footer3 .footer-widget ul li span {
		background-color:transparent;
		height:auto;
		width:auto;
		display:inline-block;
		margin-right:10px
	}
	.footer3 .copy-right {
		border-top:1px solid hsla(0,0%,100%,.15)
	}
	.autoplay-progress {
		position:absolute;
		right:16px;
		bottom:16px;
		z-index:10;
		width:48px;
		height:48px;
		display:flex;
		align-items:center;
		justify-content:center;
		font-weight:700;
		color:#030523
	}
	.autoplay-progress svg {
	--progress:0;
		position:absolute;
		left:0;
		top:0;
		z-index:10;
		width:100%;
		height:100%;
		stroke-width:4px;
		stroke:#030523;
		fill:none;
	stroke-dashoffset:calc(125.6 - var(--progress)*125.6);
		stroke-dasharray:125.6;
		transform:rotate(-90deg)
	}
	.swiper-pagination {
		text-align:left;
		display:flex;
		align-items:center
	}
	.swiper-pagination span {
		width:10px;
		height:10px;
		background-color:#fff;
		opacity:1;
		-ms-filter:"alpha(opacity=100)";
		filter:alpha(opacity=100)
	}
	.swiper-pagination span.swiper-pagination-bullet-active {
		width:22px;
		height:22px;
		border:1px solid #000;
		background-color:transparent
	}
	.swiper-pagination span.swiper-pagination-bullet-active:before {
		width:10px;
		height:10px;
		background-color:#000;
		left:50%;
		top:50%;
		transform:translate(-50%, -50%);
		-webkit-border-radius:50%;
		-moz-border-radius:50%;
		-ms-border-radius:50%;
		border-radius:50%
	}
	.swiper-button-next, .swiper-button-prev {
		background:#000;
		display:inline-table;
		width:50px;
		height:50px;
		text-align:center;
		z-index:9;
		-webkit-border-radius:50%;
		-moz-border-radius:50%;
		-ms-border-radius:50%;
		border-radius:50%;
		color:#e6c43a;
		left:0
	}
	.swiper-button-prev {
		left:auto;
		right:0
	}
	.swiper-button-next:after, .swiper-button-prev:after {
		content:"\f112";
		font-family:feather-icons!important;
		font-size:25px;
		display:table-cell;
		vertical-align:middle
	}
	.swiper-button-prev:after {
		content:"\f114"
	}
	.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
		opacity:1;
		-ms-filter:"alpha(opacity=100)";
		filter:alpha(opacity=100)
	}
	.widget.search-widget input {
		border:1px solid #e8e8e8
	}
	.widget.search-widget button {
		position:absolute;
		right:0;
		top:0;
		border:0;
		background:0 0;
		width:56px;
		height:100%;
		-webkit-border-radius:0;
		-moz-border-radius:0;
		-ms-border-radius:0;
		border-radius:0
	}
	.widget {
		margin-bottom:30px
	}
	.widget .widget-title {
		text-transform:uppercase;
		font-size:24px;
		margin-bottom:20px
	}
	.widget .widget-inner {
		border:1px solid #e8e8e8
	}
	.widget ul {
		margin:0;
		padding:0;
		list-style:none
	}
	.widget ul li a {
		display:flex;
		justify-content:space-between;
		align-items:center;
		padding:12px 15px;
		border-bottom:1px solid #e8e8e8;
		text-transform:capitalize;
		color:#696969;
		font-size:16px
	}
	.widget ul li:hover a {
		background-color:#e6c43a;
		color:#212529
	}
	.widget ul li:last-child a {
		border:0
	}
	.widget .rc-thumb{
		width: 60px;
	}
	img{max-width: 100%;}
	.rc-info h5{font-size: 16px; font-weight: 600;}
	.widget .rc-entry {
		margin-bottom:15px;
		border-bottom:1px solid #e8e8e8;
		padding-bottom:15px
	}
	.widget .rc-entry:last-child {
		margin-bottom:0;
		border-bottom:0;
		padding-bottom:0
	}
	.widget .rc-entry>a {
		padding-top:7px
	}
	.widget .rc-entry .entry-meta span {
		font-size:15px;
		color:#696969
	}
	.widget .rc-entry .entry-meta span i {
		color:#e6c43a
	}
	.widget .rc-entry .entry-title {
		font-size:16px;
		line-height:23px
	}
	.widget .rc-entry:hover a {
		color:#e6c43a
	}
	.widget.widget-rating input {
		padding:0
	}
	.widget.instagrame-widget a {
		margin:0 7px 10px 0;
		display:inline-block
	}
	.widget.instagrame-widget a:nth-child(3n) {
		margin-right:0
	}
	.widget_tag_cloud a {
		font-weight:700;
		display:inline-block;
		font-size:14px!important;
		line-height:1;
		color:#696969;
		padding:8px 15px;
		margin:5px 5px 5px 0;
		text-transform:capitalize;
		-webkit-border-radius:5px;
		-moz-border-radius:5px;
		-ms-border-radius:5px;
		border-radius:5px;
		background:#f6f6f6
	}
	.widget_tag_cloud a:hover {
		background-color:#e6c43a
	}
	.social-share2 a {
		width:52px;
		height:52px;
		border:1px solid #222;
		display:inline-table;
		text-align:center;
		margin-right:10px
	}
	.social-share2 a i {
		display:table-cell;
		vertical-align:middle
	}
	.social-share2 a:hover {
		background-color:#e6c43a;
		color:#696969
	}
	.trip-widget {
		padding:60px 30px
	}
	.trip-widget .form-group {
		margin-bottom:15px
	}
	.contact-widget li {
		margin-bottom:20px
	}
	.filter .nice-select {
		width:214px
	}
	.price-widget .widget-inner {
		padding:30px
	}
	.price-widget .btn {
		padding:6px 20px 7px;
		font-size:16px
	}
	.noUi-horizontal {
		height:4px;
		border:0;
		box-shadow:none;
		background-color:#e8e8e8
	}
	.noUi-horizontal .noUi-connect {
		background:#e6c43a
	}
	.noUi-horizontal .noUi-handle {
		width:18px;
		height:18px;
		background-color:#e6c43a;
		-webkit-border-radius:50px;
		-moz-border-radius:50px;
		-ms-border-radius:50px;
		border-radius:50px;
		top:-8px
	}
	.noUi-horizontal .noUi-handle:after, .noUi-horizontal .noUi-handle:before {
		display:none
	}
	.banner-txt .h1, .banner-txt .slide-bar .box-item span, .banner-txt h1, .slide-bar .box-item .banner-txt span {
		font-size:110px;
		line-height:1.1
	}
	@media(max-width:1399.98px) {
	.banner-txt .h1, .banner-txt .slide-bar .box-item span, .banner-txt h1, .slide-bar .box-item .banner-txt span {
		font-size:85px
	}
	}
	@media(max-width:991.98px) {
	.banner-txt .h1, .banner-txt .slide-bar .box-item span, .banner-txt h1, .slide-bar .box-item .banner-txt span {
		font-size:65px
	}
	}
	@media(max-width:575.98px) {
	.banner-txt .h1, .banner-txt .slide-bar .box-item span, .banner-txt h1, .slide-bar .box-item .banner-txt span {
		font-size:54px
	}
	}
	.golf-banner {
		padding:200px 0 155px
	}
	@media(max-width:991.98px) {
	.golf-banner {
		padding:150px 0 100px
	}
	}
	.golfer-img {
		position:absolute;
		right:0;
		height:100%;
		top:0;
		width:45%;
		background-size:cover;
		background-repeat:no-repeat;
		background-position:top
	}
	.football-banner {
		padding:50px 0;
		background-position:50%
	}
	@media(max-width:1399.98px) {
	.football-banner {
		padding:90px 0 100px
	}
	}
	@media(max-width:991.98px) {
	.football-banner {
		padding:0 0 100px
	}
	}
	.football-banner .banner-txt .heading-box img {
		float:left;
		margin-right:27px
	}
	@media(max-width:1199.98px) {
	.football-banner .banner-txt .heading-box img {
		margin-right:15px;
		width:150px
	}
	}
	@media(min-width:1200px) and (max-width:1399.98px) {
	.football-banner .banner-txt .heading-box img {
		width:200px
	}
	}
	.football-banner .banner-txt p {
		max-width:90%
	}
	.football-banner .banner-txt .h1, .football-banner .banner-txt .slide-bar .box-item span, .football-banner .banner-txt h1, .slide-bar .box-item .football-banner .banner-txt span {
		font-size:96px;
		line-height:1
	}
	@media(max-width:1199.98px) {
	.football-banner .banner-txt .h1, .football-banner .banner-txt .slide-bar .box-item span, .football-banner .banner-txt h1, .slide-bar .box-item .football-banner .banner-txt span {
		font-size:70px
	}
	}
	.football-banner .banner-txt .h1 span, .football-banner .banner-txt .slide-bar .box-item span span, .football-banner .banner-txt h1 span, .slide-bar .box-item .football-banner .banner-txt span span {
		color:#136495
	}
	@media(min-width:1200px) and (max-width:1399.98px) {
	.football-banner .banner-txt .h1, .football-banner .banner-txt .slide-bar .box-item span, .football-banner .banner-txt h1, .slide-bar .box-item .football-banner .banner-txt span {
		font-size:85px
	}
	}
	@media(max-width:1199.98px) {
	.football-banner .banner-txt .btn {
		font-size:16px
	}
	}
	.football-banner .banner-txt a.btn.play-btn {
		padding:7px 30px 7px 7px;
		border:1px solid rgba(0,0,0,.1);
		margin-left:15px
	}
	.football-banner .banner-txt a.btn.play-btn:focus {
		color:#696969
	}
	@media(max-width:575.98px) {
	.football-banner .banner-txt .banner-cta a {
		margin:0 0 20px
	}
	.football-banner .banner-txt .banner-cta a.play-btn {
		margin-left:0
	}
	.football-banner .banner-txt .heading-box img {
		width:auto;
		float:none
	}
	.football-banner .banner-txt .h1, .football-banner .banner-txt .slide-bar .box-item span, .football-banner .banner-txt h1, .slide-bar .box-item .football-banner .banner-txt span {
		font-size:60px;
		margin-top:20px
	}
	}
	.football-banner .football-media {
		animation:updown 6s linear infinite
	}
	.basketball-banner {
		padding:168px 0 120px
	}
	.basketball-banner .banner-txt .h1, .basketball-banner .banner-txt .slide-bar .box-item span, .basketball-banner .banner-txt h1, .slide-bar .box-item .basketball-banner .banner-txt span {
		line-height:1.3;
		font-size:100px;
		padding:15px;
		position:absolute;
		top:10px;
		left:10px;
		line-height:1.1
	}
	.basketball-banner .banner-txt p {
		font-size:24px;
		margin:30px 0 50px;
		color:#fff;
		opacity:.6;
		-ms-filter:"alpha(opacity=60)";
		filter:alpha(opacity=60)
	}
	@media(min-width:1200px) and (max-width:1399.98px) {
	.basketball-banner .banner-txt .h1, .basketball-banner .banner-txt .slide-bar .box-item span, .basketball-banner .banner-txt h1, .slide-bar .box-item .basketball-banner .banner-txt span {
		font-size:90px
	}
	}
	@media(min-width:768px) and (max-width:991.98px) {
	.basketball-banner .banner-txt .h1, .basketball-banner .banner-txt .slide-bar .box-item span, .basketball-banner .banner-txt h1, .slide-bar .box-item .basketball-banner .banner-txt span {
		font-size:74px;
		top:50px
	}
	.basketball-banner .banner-txt svg {
		max-width:100%
	}
	}
	@media(min-width:576px) and (max-width:767.98px) {
	.basketball-banner {
		padding:100px 0
	}
	.basketball-banner .banner-txt .h1, .basketball-banner .banner-txt .slide-bar .box-item span, .basketball-banner .banner-txt h1, .slide-bar .box-item .basketball-banner .banner-txt span {
		font-size:60px
	}
	}
	@media(max-width:767.98px) {
	.basketball-banner {
		padding:100px 0
	}
	.basketball-banner .banner-txt .h1, .basketball-banner .banner-txt .slide-bar .box-item span, .basketball-banner .banner-txt h1, .slide-bar .box-item .basketball-banner .banner-txt span {
		font-size:70px;
		top:55px
	}
	.basketball-banner .banner-txt svg {
		max-width:100%
	}
	.basketball-banner .banner-txt p {
		margin-top:0
	}
	}
	@media(max-width:575.98px) {
	.basketball-banner {
		padding:100px 0
	}
	.basketball-banner .banner-txt .h1, .basketball-banner .banner-txt .slide-bar .box-item span, .basketball-banner .banner-txt h1, .slide-bar .box-item .basketball-banner .banner-txt span {
		font-size:45px;
		top:85px
	}
	.basketball-banner .banner-txt svg {
		max-width:100%
	}
	.basketball-banner .banner-txt p {
		margin-top:0
	}
	}
	@media(max-width:991.98px) {
	.basketball-banner {
		overflow:hidden
	}
	}
	.cricket-banner {
		padding:80px 0 300px
	}
	.cricket-banner:before {
		background:url(../images/batsman.png) no-repeat 0 0/contain;
		width:310px;
		top:10%;
		animation:updown 6s ease-in infinite
	}
	.cricket-banner:after {
		position:absolute;
		content:"";
		right:0;
		top:0;
		width:274px;
		height:100%;
		background:url(../images/bowler.png) no-repeat 100% 0/contain;
		animation:updown 5.5s ease-in infinite
	}
	.cricket-banner .h1, .cricket-banner .slide-bar .box-item span, .cricket-banner h1, .slide-bar .box-item .cricket-banner span {
		font-size:100px;
		margin-bottom:45px
	}
	@media(min-width:1200px) and (max-width:1399.98px) {
	.cricket-banner .h1, .cricket-banner .slide-bar .box-item span, .cricket-banner h1, .slide-bar .box-item .cricket-banner span {
		font-size:90px
	}
	}
	@media(min-width:992px) and (max-width:1199.98px) {
	.cricket-banner .h1, .cricket-banner .slide-bar .box-item span, .cricket-banner h1, .slide-bar .box-item .cricket-banner span {
		font-size:80px
	}
	}
	@media(min-width:768px) and (max-width:991.98px) {
	.cricket-banner .h1, .cricket-banner .slide-bar .box-item span, .cricket-banner h1, .slide-bar .box-item .cricket-banner span {
		font-size:70px
	}
	}
	@media(max-width:767.98px) {
	.cricket-banner {
		padding:60px 0 240px
	}
	.cricket-banner .h1, .cricket-banner .slide-bar .box-item span, .cricket-banner h1, .slide-bar .box-item .cricket-banner span {
		font-size:52px
	}
	}
	.banner-gallery {
		position:relative;
		margin-top:-150px;
		z-index:2
	}
	@media(max-width:767.98px) {
	.banner-gallery {
		margin-top:-50px
	}
	}
	.banner-gallery .col {
		cursor:pointer;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.banner-gallery .col:hover {
		transform:translateY(-10px)
	}
	.banner-gallery .col:nth-child(2), .banner-gallery .col:nth-child(3) {
		margin-top:-50px
	}
	@media(max-width:991.98px) {
	.banner-gallery .col:nth-child(2), .banner-gallery .col:nth-child(3) {
		margin-top:-15px
	}
	}
	@media(max-width:575.98px) {
	.banner-gallery .col:nth-child(2), .banner-gallery .col:nth-child(3) {
		margin-top:-10px
	}
	}
	.feature-sec:before {
		background:url(../images/feature-bg.png) no-repeat 100% 100%
	}
	.feature-entry {
		padding:40px 30px;
		border-top:2px solid transparent
	}
	.feature-entry, .feature-entry:before {
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.feature-entry:before {
		height:245px;
		width:1px;
		background-color:#000;
		opacity:.1;
		-ms-filter:"alpha(opacity=10)";
		filter:alpha(opacity=10);
		top:50%;
		transform:translateY(-50%)
	}
	@media(max-width:575.98px) {
	.feature-entry:before {
		height:0
	}
	}
	@media(min-width:576px) and (max-width:767.98px) {
	.feature-entry {
		padding:40px 20px
	}
	}
	@media(max-width:1399.98px) {
	.feature-entry .h3, .feature-entry h3 {
		font-size:27px
	}
	}
	.feature-entry span {
		margin-bottom:35px
	}
	.feature-entry a {
		margin-top:30px;
		display:block;
		font-weight:700;
		color:#000;
		opacity:0;
		-ms-filter:"alpha(opacity=0)";
		filter:alpha(opacity=0)
	}
	.feature-entry:hover {
		transform:translateY(-5px);
		border-top:2px solid #000;
		background-color:#e6c43a
	}
	.feature-entry:hover:before {
		opacity:0;
		-ms-filter:"alpha(opacity=0)";
		filter:alpha(opacity=0)
	}
	.feature-entry:hover a {
		opacity:1;
		-ms-filter:"alpha(opacity=100)";
		filter:alpha(opacity=100)
	}
	.feature-wrap {
		min-height:340px
	}
	.feature-entry2 {
		padding:18px;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out;
		background-color:#f6f6f6;
		position:relative;
		cursor:pointer
	}
	@media(max-width:991.98px) {
	.feature-entry2 {
		max-width:350px;
		margin:0 auto 30px
	}
	}
	.feature-entry2 .h3, .feature-entry2 h3 {
		font-size:30px
	}
	@media(min-width:1200px) and (max-width:1399.98px) {
	.feature-entry2 .h3, .feature-entry2 h3 {
		font-size:26px
	}
	}
	@media(min-width:992px) and (max-width:1199.98px) {
	.feature-entry2 {
		padding:30px 15px
	}
	.feature-entry2 .h3, .feature-entry2 h3 {
		font-size:24px
	}
	}
	@media(min-width:576px) and (max-width:767.98px) {
	.feature-entry2 {
		padding:20px
	}
	.feature-entry2 .h3, .feature-entry2 h3 {
		font-size:26px
	}
	}
	.feature-entry2 .icon-sm {
		background-repeat:no-repeat;
		background-position:50%
	}
	.feature-entry2 .btn, .feature-entry2 .icon-sm {
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.feature-entry2 .btn {
		opacity:0;
		-ms-filter:"alpha(opacity=0)";
		filter:alpha(opacity=0);
		visibility:hidden;
		position:absolute;
		padding:12px;
		bottom:0;
		left:50%;
		transform:translateX(-50%);
		width:200px;
		border-radius:0;
		border-top-left-radius:8px;
		border-top-right-radius:8px
	}
	.feature-entry2 .icon-soccer {
		background-image:url(../images/icons/soccer.svg)
	}
	.feature-entry2 .icon-glove {
		background-image:url(../images/icons/glove.png)
	}
	.feature-entry2 .icon-jersy {
		background-image:url(../images/icons/jersy.png)
	}
	.feature-entry2 .icon-shoe {
		background-image:url(../images/icons/shoe.png)
	}
	.feature-entry2:hover {
		padding-bottom:60px
	}
	.feature-entry2:hover .icon, .feature-entry2:hover .icon-sm {
		background-color:#136495
	}
	.feature-entry2:hover .icon-sm svg, .feature-entry2:hover .icon svg {
		color:#fff
	}
	.feature-entry2:hover .btn {
		opacity:1;
		-ms-filter:"alpha(opacity=100)";
		filter:alpha(opacity=100);
		visibility:visible
	}
	.feature-entry2:hover .icon-soccer {
		background-image:url(../images/icons/soccer-w.png)
	}
	.feature-entry2:hover .icon-jersy {
		background-image:url(../images/icons/jersy-w.png)
	}
	.feature-entry2:hover .icon-glove {
		background-image:url(../images/icons/glove-w.png)
	}
	.feature-entry2:hover .icon-shoe {
		background-image:url(../images/icons/shoe-w.png)
	}
	.feature-sec3 .feature-txt {
		max-width:550px
	}
	@media(min-width:768px) and (max-width:991.98px) {
	.feature-sec3 .feature-txt {
		margin-bottom:60px
	}
	}
	.feature-sec3 .feature-txt ul {
		padding:0;
		list-style:none;
		margin:35px 0 50px
	}
	.feature-sec3 .feature-txt ul li {
		padding-left:30px;
		margin-bottom:15px;
		vertical-align:middle
	}
	.feature-sec3 .feature-txt ul li:before {
		content:"\f12e";
		font-family:feather-icons;
		background-color:#136495;
		width:20px;
		height:20px;
		line-height:20px;
		font-size:15px;
		color:#fff;
		text-align:center;
		top:2px;
		-webkit-border-radius:50%;
		-moz-border-radius:50%;
		-ms-border-radius:50%;
		border-radius:50%
	}
	.top-feature {
		margin:70px 0
	}
	.top-feature .feature-media {
		position:relative;
		margin-top:-180px;
		animation:updown 4s ease-out infinite
	}
	.top-feature .feature-media.media-bottom {
		top:230px
	}
	.shop-section {
		padding:120px 0 90px
	}
	.product-entry, .product-entry2, .product-entry3 {
		margin-bottom:30px;
		overflow:hidden
	}
	.product-entry2 .product-thumb, .product-entry3 .product-thumb, .product-entry .product-thumb {
		overflow:hidden
	}
	.product-entry2 .product-thumb img, .product-entry3 .product-thumb img, .product-entry .product-thumb img {
		width:100%;
		transform:scale(1);
		-webkit-transition:all 1s ease;
		-moz-transition:all 1s ease;
		-ms-transition:all 1s ease;
		-o-transition:all 1s ease;
		transition:all 1s ease
	}
	.product-entry2 .product-txt, .product-entry3 .product-txt, .product-entry .product-txt {
		padding:20px
	}
	@media(max-width:1399.98px) {
	.product-entry2 .product-txt, .product-entry3 .product-txt, .product-entry .product-txt {
		padding:20px 15px
	}
	.product-entry2 .product-txt .ratings img, .product-entry3 .product-txt .ratings img, .product-entry .product-txt .ratings img {
		width:16px
	}
	}
	.product-entry2 .badge, .product-entry3 .badge, .product-entry .badge {
		position:absolute;
		background:#e6c43a;
		font-size:14px;
		left:15px;
		top:15px;
		color:#030523;
		z-index:1
	}
	.product-entry2 .product-cta, .product-entry3 .product-cta, .product-entry .product-cta {
		position:absolute;
		right:-10px;
		top:30px;
		visibility:hidden;
		opacity:0;
		-ms-filter:"alpha(opacity=0)";
		filter:alpha(opacity=0);
		-webkit-transition:all .4s ease;
		-moz-transition:all .4s ease;
		-ms-transition:all .4s ease;
		-o-transition:all .4s ease;
		transition:all .4s ease
	}
	.product-entry2 .product-cta a, .product-entry3 .product-cta a, .product-entry .product-cta a {
		display:table;
		width:48px;
		height:48px;
		text-align:center;
		background-color:#000;
		margin-bottom:10px;
		-webkit-border-radius:50%;
		-moz-border-radius:50%;
		-ms-border-radius:50%;
		border-radius:50%
	}
	.product-entry2 .product-cta a i, .product-entry3 .product-cta a i, .product-entry .product-cta a i {
		color:#e6c43a;
		display:table-cell;
		vertical-align:middle
	}
	.product-entry2 .h3, .product-entry2 h3, .product-entry3 .h3, .product-entry3 h3, .product-entry .h3, .product-entry h3 {
		font-family:DM Sans, sans-serif;
		font-size:18px
	}
	.product-entry2 .price, .product-entry3 .price, .product-entry .price {
		color:#030523;
		font-weight:600
	}
	.product-entry2 .price del, .product-entry3 .price del, .product-entry .price del {
		color:#696969;
		font-size:14px
	}
	.product-entry2:hover .h3 a, .product-entry2:hover h3 a, .product-entry3:hover .h3 a, .product-entry3:hover h3 a, .product-entry:hover .h3 a, .product-entry:hover h3 a {
		color:#000
	}
	.product-entry2:hover .product-cta, .product-entry3:hover .product-cta, .product-entry:hover .product-cta {
		opacity:1;
		-ms-filter:"alpha(opacity=100)";
		filter:alpha(opacity=100);
		visibility:visible;
		right:20px
	}
	.product-entry2:hover .product-thumb img, .product-entry3:hover .product-thumb img, .product-entry:hover .product-thumb img {
		transform:scale(1.2)
	}
	.product-entry2 .product-cta a {
		background-color:#136495
	}
	.product-entry2 .product-cta a i {
		color:#fff
	}
	.product-entry2:hover .h3 a, .product-entry2:hover h3 a {
		color:#136495
	}
	.product-entry3 .product-cta a i {
		color:#fff
	}
	.product-entry3 .product-cta a:hover {
		background-color:#136495
	}
	.shop-wrapper .nice-select {
		background:#f6f6f6;
		height:56px;
		line-height:56px;
		min-width:80px;
		margin-left:10px;
		font-size:18px;
		border:1px solid #e8e8e8
	}
	.shop-wrapper .icon-sm {
		height:56px;
		width:56px;
		line-height:56px
	}
	.shop-wrapper .icon-sm i {
		font-size:24px
	}
	.product-media-left {
		display:flex;
		flex-direction:column;
		margin-right:20px;
		width:20%
	}
	.product-media-left .product-thumb {
		height:500px
	}
	.product-media-left .product-thumb img {
		max-width:110px
	}
	@media(max-width:991.98px) {
	.product-media-left {
		margin-right:10px
	}
	}
	@media(max-width:767.98px) {
	.product-media-left .product-thumb {
		height:425px
	}
	.product-media-left .product-thumb img {
		width:95px
	}
	}
	.product-media-left .swiper-button-next, .product-media-left .swiper-button-prev {
		top:3px;
		transform:rotate(90deg);
		left:36%;
		width:35px;
		height:35px;
		background-color:#fff;
		color:#000;
		-webkit-box-shadow:0 0 12px rgba(33,37,41,.25);
		-moz-box-shadow:0 0 12px rgba(33,37,41,.25);
		box-shadow:0 0 12px rgba(33,37,41,.25)
	}
	.product-media-left .swiper-button-next:after, .product-media-left .swiper-button-prev:after {
		font-size:17px
	}
	.product-media-left .swiper-button-prev {
		top:auto;
		bottom:-10px
	}
	@media(max-width:575.98px) {
	.product-media-left {
		width:100%;
		margin-right:0;
		display:inline-block
	}
	.product-media-left .product-thumb {
		height:auto;
		margin-bottom:20px
	}
	.product-media-left .product-thumb img {
		height:auto
	}
	.product-media-left .swiper-button-next, .product-media-left .swiper-button-prev {
		transform:rotate(0deg);
		left:0;
		top:40%
	}
	.product-media-left .swiper-button-prev {
		bottom:auto;
		right:0;
		left:auto
	}
	}
	.coverItem {
		width:80%
	}
	@media(max-width:575.98px) {
	.coverItem {
		width:100%;
		text-align:center
	}
	}
	.select-color .color {
		margin-right:10px;
		width:82px;
		height:82px;
		cursor:pointer
	}
	.select-color .color span {
		display:none
	}
	.select-color .color .active img {
		opacity:.6;
		-ms-filter:"alpha(opacity=60)";
		filter:alpha(opacity=60)
	}
	.cart-cta .btn {
		width:215px;
		margin-left:10px
	}
	@media(max-width:575.98px) {
	.cart-cta .btn {
		margin-top:20px
	}
	}
	.cart-number {
		flex-basis:180px
	}
	.cart-number .cart-counter {
		border:1px solid #e8e8e8;
		-webkit-border-radius:50px;
		-moz-border-radius:50px;
		-ms-border-radius:50px;
		border-radius:50px;
		padding:0 20px
	}
	.cart-number .cart-counter>div {
		cursor:pointer
	}
	.cart-number input {
		text-align:center;
		border:0
	}
	.product-meta p {
		margin:0 0 15px
	}
	.product-meta p strong {
		color:#000
	}
	.payment-method span {
		width:200px
	}
	@media(max-width:575.98px) {
	.pay-options img {
		margin-bottom:10px
	}
	}
	.product-details-tab .nav-tabs li {
		margin:0 10px
	}
	.product-details-tab .nav-tabs li .nav-link {
		background-color:#000;
		text-transform:uppercase;
		color:#e6c43a;
		padding:17px 40px 15px;
		margin:0;
		border:0;
		-webkit-border-radius:50px;
		-moz-border-radius:50px;
		-ms-border-radius:50px;
		border-radius:50px;
		line-height:1;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.product-details-tab .nav-tabs li .nav-link.active, .product-details-tab .nav-tabs li .nav-link:focus, .product-details-tab .nav-tabs li .nav-link:hover {
		background-color:#e6c43a;
		color:#212529
	}
	@media(max-width:575.98px) {
	.product-details-tab .nav-tabs li {
		margin-bottom:10px
	}
	}
	.product-details-tab .ratings a {
		color:#e6c43a
	}
	.product-details-tab .ratings a:hover {
		color:gold
	}
	.product-additional-info .item, .product-details-tab .tab-content ul li {
		margin-bottom:15px
	}
	.product-additional-info .item label {
		min-width:150px
	}
	.ratings-form .form-group label {
		margin-bottom:5px
	}
	.ratings-form .form-group input, .ratings-form .form-group textarea {
		background-color:#f6f6f6
	}
	.ratings-form .form-group input[type=checkbox] {
		padding:8px
	}
	.ratings-form .form-group.checkbox-group {
		margin:20px 0 40px
	}
	.testimonial-nav {
		width:135px;
		position:absolute;
		left:auto;
		right:0;
		bottom:40px
	}
	.testimonial-slider {
		max-width:570px;
		margin-left:0
	}
	.single-testimonial p {
		font-size:24px
	}
	.testimonial-media {
		animation:updown 6s ease-in infinite
	}
	@media(max-width:991.98px) {
	.testimonial-sec .sec-title {
		font-size:40px
	}
	.testimonial-sec .swiper-button-next {
		left:20px
	}
	}
	@media(max-width:767.98px) {
	.testimonial-content {
		margin-top:30px
	}
	}
	.testimonial2-sec .testominial-bg {
		right:0;
		top:0;
		width:40%;
		height:100%
	}
	.testimonial2-sec .testimonial-wrap {
		max-width:630px
	}
	.testimonial2-sec .testimonial-wrap .swiper-button-next, .testimonial2-sec .testimonial-wrap .swiper-button-prev {
		background:#136495;
		color:#fff
	}
	.testimonial2-sec .single-testimonial .border-top {
		border-color:hsla(0,0%,100%,.15)!important
	}
	.brand-sec {
		padding:80px 0
	}
	.brand-sec .swiper-slide {
		display:flex;
		justify-content:center;
		align-items:center
	}
	.single-brand {
		display:block;
		width:250px;
		height:150px;
		margin:0 auto;
		text-align:center;
		line-height:148px
	}
	.single-brand:before {
		background-color:#000;
		height:50%;
		top:auto;
		bottom:0;
		opacity:0;
		-ms-filter:"alpha(opacity=0)";
		filter:alpha(opacity=0);
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.single-brand a img {
		-webkit-transition:all 1.5s cubic-bezier(0, 0, .05, 1);
		transition:all 1.5s cubic-bezier(0, 0, .05, 1)
	}
	.single-brand img.hover-img {
		position:absolute;
		top:50%;
		left:50%;
		-webkit-transform:translate(-50%, -50%);
		-ms-transform:translate(-50%, -50%);
		transform:translate(-50%, -50%);
		z-index:2;
		opacity:0;
		visibility:hidden;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.single-brand:hover:before {
		opacity:1;
		-ms-filter:"alpha(opacity=100)";
		filter:alpha(opacity=100);
		height:100%
	}
	.single-brand:hover a img.hover-img {
		opacity:1;
		visibility:visible
	}
	.brand2-sec {
		padding:80px 0
	}
	@media(max-width:767.98px) {
	.brand2-sec {
		padding:20px 0
	}
	}
	.brand2-sec .single-brand:hover:before {
		opacity:0
	}
	.blog-sec {
		margin-bottom:110px
	}
	@media(max-width:991.98px) {
	.blog-sec {
		margin-bottom:0
	}
	}
	@media(min-width:992px) and (max-width:1199.98px) {
	.blog-sec {
		margin-bottom:140px
	}
	}
	.single-entry {
		position:relative
	}
	.single-entry .entry-thumb {
		overflow:hidden;
		max-width:calc(100% - 85px)
	}
	.single-entry .entry-thumb img {
		transform:scale(1);
		-webkit-transition:all .8s ease;
		-moz-transition:all .8s ease;
		-ms-transition:all .8s ease;
		-o-transition:all .8s ease;
		transition:all .8s ease
	}
	.single-entry .entry-content {
		padding:30px 30px 20px;
		background-color:#f5f8e5;
		position:absolute;
		left:80px;
		top:68%;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	@media(max-width:991.98px) {
	.single-entry .entry-content {
		left:15px
	}
	}
	.single-entry .entry-content .entry-meta span {
		margin-right:10px
	}
	.single-entry .entry-content .h3, .single-entry .entry-content h3 {
		font-size:40px;
		line-height:1.2;
		margin:10px 0 20px
	}
	@media(max-width:1399.98px) {
	.single-entry .entry-content .h3, .single-entry .entry-content h3 {
		font-size:34px
	}
	}
	@media(min-width:992px) and (max-width:1199.98px) {
	.single-entry .entry-content .h3, .single-entry .entry-content h3 {
		font-size:28px
	}
	}
	.single-entry .entry-content a.read-more {
		font-weight:700;
		color:#030523
	}
	.single-entry .entry-content a.read-more i {
		font-size:22px;
		position:relative;
		top:2px
	}
	.single-entry:hover a.read-more {
		color:#030523
	}
	.single-entry:hover .entry-content {
		background-color:#e6c43a
	}
	.single-entry:hover .entry-content a {
		color:#000
	}
	.single-entry:hover .entry-thumb img {
		transform:scale(1.1)
	}
	@media(max-width:991.98px) {
	.single-entry {
		margin-bottom:170px
	}
	}
	@media(max-width:575.98px) {
	.single-entry {
		margin-bottom:30px
	}
	.single-entry .entry-thumb {
		max-width:100%
	}
	.single-entry .entry-content {
		position:relative;
		left:auto
	}
	.single-entry .entry-content .h3, .single-entry .entry-content h3 {
		font-size:32px
	}
	}
	.entry-meta span {
		margin-right:15px;
		font-size:16px;
		font-weight:500
	}
	.entry-meta span a {
		color:#696969
	}
	.entry-meta span a:hover {
		color:#e6c43a
	}
	@media(max-width:575.98px) {
	.entry-meta span {
		font-size:15px
	}
	}
	.entry-meta span i {
		margin-right:5px;
		font-size:18px
	}
	@media(max-width:767.98px) {
	.blog-page .list-entry, .single-entry2 {
		margin-bottom:30px
	}
	}
	.blog-page .list-entry .btn-link, .single-entry2 .btn-link {
		text-decoration:none;
		font-weight:700
	}
	.blog-page .list-entry .entry-media, .single-entry2 .entry-media {
		flex:0 0 33%
	}
	.blog-page .cover-entry.list-entry, .single-entry2.cover-entry {
		background-size:cover;
		background-position:top;
		overflow:hidden
	}
	.blog-page .cover-entry.list-entry:before, .single-entry2.cover-entry:before {
		background-color:#000;
		opacity:.5;
		-ms-filter:"alpha(opacity=50)";
		filter:alpha(opacity=50)
	}
	.blog-page .cover-entry.list-entry .btn-link, .blog-page .cover-entry.list-entry .entry-meta span, .blog-page .cover-entry.list-entry .h3 a, .blog-page .cover-entry.list-entry h3 a, .single-entry2.cover-entry .btn-link, .single-entry2.cover-entry .entry-meta span, .single-entry2.cover-entry .h3 a, .single-entry2.cover-entry h3 a {
		color:#fff
	}
	.blog-page .list-entry:hover .btn-link, .blog-page .list-entry:hover .h3 a, .blog-page .list-entry:hover h3 a, .single-entry2:hover .btn-link, .single-entry2:hover .h3 a, .single-entry2:hover h3 a {
		color:#136495
	}
	.list-entry2 {
		overflow:hidden;
		margin-bottom:30px;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.list-entry2 .entry-media {
		flex:0 0 33%
	}
	@media(max-width:575.98px) {
	.list-entry2 .entry-media {
		display:block;
		min-height:200px
	}
	}
	.list-entry2 .entry-content {
		padding:20px
	}
	.list-entry2:hover {
		transform:translateY(-5px)
	}
	.list-entry2:hover .h3 a, .list-entry2:hover h3 a {
		color:#35cc6c
	}
	.blog-info .single-entry .entry-content.bg-success .h3 a, .blog-info .single-entry .entry-content.bg-success h3 a {
		color:#fff
	}
	.single-post .content-list {
		padding:0;
		list-style:none;
		margin:30px 0 40px
	}
	.single-post .content-list li {
		width:50%;
		padding-left:30px;
		margin-bottom:15px
	}
	.single-post .content-list li:before {
		background-color:transparent;
		content:"\f12d";
		font-family:feather-icons
	}
	@media(max-width:575.98px) {
	.single-post .content-list li {
		width:100%
	}
	}
	.single-post .article-footer {
		border-top:1px solid #e8e8e8;
		border-bottom:1px solid #e8e8e8
	}
	.social a {
		display:inline-block;
		padding:10px;
		line-height:1;
		background-color:#f6f6f6;
		color:#696969;
		-webkit-border-radius:5px;
		-moz-border-radius:5px;
		-ms-border-radius:5px;
		border-radius:5px
	}
	.social a:hover {
		background-color:#e6c43a
	}
	.post-comments>ol {
		padding-left:0
	}
	.post-comments ol {
		list-style:none
	}
	.post-comments .comment-entry {
		position:relative;
		padding-right:40px
	}
	.post-comments .comment-entry .comment-thumb {
		flex:0 0 90px
	}
	.post-comments .comment-entry p {
		margin-top:20px
	}
	.post-comments .comment-entry .comment-reply {
		position:absolute;
		right:30px;
		top:30px
	}
	.post-comments .comment-entry .comment-reply img {
		margin-right:10px
	}
	@media(max-width:767.98px) {
	.blog-page .blog-wrap .list-entry .entry-content, .blog-wrap .blog-page .list-entry .entry-content, .blog-wrap .single-entry2 .entry-content {
		margin:30px 0
	}
	}
	.blog-page section.blog2-sec .list-entry .entry-media, section.blog2-sec .blog-page .list-entry .entry-media, section.blog2-sec .single-entry2 .entry-media {
		flex:0 0 33%
	}
	.blog-page .list-entry, .blog-page .single-entry2 {
		margin-bottom:30px;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.blog-page .list-entry {
		margin-bottom:30px
	}
	.blog-page .list-entry .entry-media {
		flex:0 0 33%
	}
	.blog-page .list-entry .entry-content {
		padding:30px 60px 30px 20px
	}
	@media(max-width:991.98px) {
	.blog-page .list-entry .entry-content {
		padding:15px
	}
	}
	.blog-page .list-entry:hover {
		transform:translateY(-5px)
	}
	.grid-item, .grid-sizer {
		background-clip:content-box;
		width:33.33%;
		margin-bottom:30px;
		max-height:355px;
		overflow:hidden
	}
	.grid-item img, .grid-sizer img {
		width:100%
	}
	.grid-item.height2, .grid-sizer.height2 {
		height:740px;
		max-height:740px
	}
	@media(max-width:991.98px) {
	.grid-item.height2, .grid-sizer.height2 {
		height:463px
	}
	}
	@media(min-width:576px) and (max-width:767.98px) {
	.grid-item.height2, .grid-sizer.height2 {
		height:345px
	}
	}
	.grid-item.height2 img, .grid-sizer.height2 img {
		height:100%
	}
	@media(max-width:575.98px) {
	.grid-item, .grid-sizer {
		width:100%;
		margin-bottom:15px
	}
	.grid-item.height2, .grid-sizer.height2 {
		height:auto
	}
	}
	.gallery-masionary {
		padding-bottom:90px
	}
	.video-box {
		max-width:556px;
		margin-left:auto;
		margin-right:0
	}
	@media(max-width:991.98px) {
	.video-box {
		margin:0 auto 50px 0
	}
	}
	.video-box img {
		width:48%
	}
	.video-box .video-popup {
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
		width:104px;
		height:104px;
		-webkit-border-radius:50%;
		-moz-border-radius:50%;
		-ms-border-radius:50%;
		border-radius:50%;
		background-color:#136495;
		border:10px solid #fff;
		text-align:center;
		display:table
	}
	.video-box .video-popup i {
		display:table-cell;
		vertical-align:middle;
		color:#fff;
		font-size:30px
	}
	.video-sec {
		padding:200px 0
	}
	@media(max-width:991.98px) {
	.video-sec {
		padding:150px 0
	}
	}
	.video-sec a.btn.btn-outline-info {
		width:130px;
		height:130px;
		border:2px solid #fff;
		display:inline-table;
		text-align:center;
		border-radius:50%;
		font-size:35px
	}
	.video-sec a.btn.btn-outline-info i {
		display:table-cell;
		vertical-align:middle
	}
	.video-sec.golf-video {
		padding:300px 0;
		background-position:50%;
		background-size:cover
	}
	.single-match {
		padding:40px;
		cursor:pointer;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.single-match span.versus {
		width:48px;
		height:48px;
		line-height:48px;
		border:1px solid #e8e8e8;
		text-align:center;
		margin:0 40px;
		-webkit-border-radius:50%;
		-moz-border-radius:50%;
		-ms-border-radius:50%;
		border-radius:50%;
		flex:0 0 47px;
		justify-content:center
	}
	.single-match .btn:hover {
		background-color:#136495;
		border:1px solid #136495
	}
	@media(max-width:991.98px) {
	.single-match span.versus {
		margin:0 20px
	}
	.single-match .team-logo {
		max-width:190px;
		margin:30px 0
	}
	.single-match .btn {
		padding:10px 20px
	}
	}
	.single-match:hover {
		-webkit-box-shadow:0 0 40px 10px rgba(0,0,0,.05);
		-moz-box-shadow:0 0 40px 10px rgba(0,0,0,.05);
		box-shadow:0 0 40px 10px rgba(0,0,0,.05)
	}
	@media(max-width:1199.98px) {
	.single-match {
		padding:40px 20px
	}
	}
	.match-schedule {
		overflow:hidden
	}
	.match-schedule:before {
		border-right:120px solid transparent
	}
	.match-schedule:after, .match-schedule:before {
		width:0;
		height:0;
		background-color:transparent;
		border-top:100px solid #fff
	}
	.match-schedule:after {
		border-left:120px solid transparent;
		right:0;
		top:0;
		position:absolute;
		content:""
	}
	.match-schedule .line:before, .match-schedule .sec-title.line-left:before {
		background-color:#fff
	}
	.match-schedule .container:after, .match-schedule .container:before {
		width:317px;
		height:250px;
		background-color:hsla(0,0%,100%,.15);
		top:28%;
		left:-5%
	}
	.match-schedule .container:after {
		position:absolute;
		left:auto;
		right:-5%;
		content:""
	}
	.match-schedule .swiper {
		padding-bottom:80px
	}
	.match-schedule .swiper-pagination {
		bottom:0;
		justify-content:center
	}
	.match-schedule .swiper-pagination span.swiper-pagination-bullet {
		margin:0 7px
	}
	.match-schedule .swiper-pagination span.swiper-pagination-bullet-active {
		width:32px;
		height:32px;
		border:1px solid #fff
	}
	.match-schedule .swiper-pagination span.swiper-pagination-bullet-active:before {
		background-color:#fff
	}
	.league-gallery img {
		width:49%
	}
	@media(max-width:767.98px) {
	.league-gallery img {
		width:100%;
		margin-bottom:20px
	}
	}
	.team-member:before {
		height:60%;
		top:80px;
		border-top-right-radius:200px;
		border-top-left-radius:200px;
		background:#a4c9f2;
		background:linear-gradient(180deg, #a4c9f2, rgba(199,219,249,0))
	}
	.team-member .social-share {
		opacity:0;
		-ms-filter:"alpha(opacity=0)";
		filter:alpha(opacity=0);
		visibility:hidden;
		transform:translateY(10px);
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.team-member:hover .social-share {
		opacity:1;
		-ms-filter:"alpha(opacity=100)";
		filter:alpha(opacity=100);
		visibility:visible;
		transform:translateY(0)
	}
	.team-member:hover .h3 a, .team-member:hover h3 a {
		color:#136495
	}
	.team-sec {
		overflow:hidden
	}
	.team-sec:before {
		height:440px;
		width:440px;
		-webkit-border-radius:50%;
		-moz-border-radius:50%;
		-ms-border-radius:50%;
		border-radius:50%;
		left:-10%;
		top:-10%;
		z-index:-1;
		background:#ededed;
		background:linear-gradient(180deg, hsla(0,0%,92.9%,0), #ededed);
		animation:updown 4s ease infinite
	}
	.team-page {
		overflow:hidden
	}
	.social-share a {
		width:56px;
		height:56px;
		background-color:#fff;
		-webkit-border-radius:50%;
		-moz-border-radius:50%;
		-ms-border-radius:50%;
		border-radius:50%;
		display:inline-table;
		text-align:center;
		margin:0 5px;
		box-shadow:0 0 30px 0 rgba(0,0,0,.06)
	}
	@media(max-width:767.98px) {
	.social-share a {
		width:45px;
		height:45px;
		margin:0 5px 0 0
	}
	}
	.social-share a i {
		display:table-cell;
		vertical-align:middle
	}
	.social-share a:hover {
		background-color:#136495;
		color:#fff
	}
	@media(min-width:768px) and (max-width:991.98px) {
	.social-share a {
		width:45px;
		height:45px;
		margin:0
	}
	}
	.team-member2 {
		position:relative
	}
	.team-member2 .social-share {
		position:absolute;
		left:-20px;
		top:30px;
		opacity:0;
		-ms-filter:"alpha(opacity=0)";
		filter:alpha(opacity=0);
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out;
		visibility:hidden
	}
	.team-member2 .social-share a {
		border-radius:0;
		margin-bottom:5px;
		font-size:20px
	}
	.team-member2 .social-share a:hover {
		background-color:#35cc6c
	}
	.team-member2:hover .social-share {
		opacity:1;
		-ms-filter:"alpha(opacity=100)";
		filter:alpha(opacity=100);
		visibility:visible;
		left:20px
	}
	.team-member2:hover .h3 a, .team-member2:hover h3 a {
		color:#35cc6c
	}
	.persional-info ul {
		margin:0;
		padding:0;
		list-style:none
	}
	.persional-info ul li {
		margin-bottom:15px;
		font-size:16px;
		font-weight:700
	}
	.persional-info ul li .head {
		display:block;
		float:left;
		line-height:28px;
		text-transform:capitalize;
		width:120px;
		color:#2b2b2b
	}
	.persional-info ul li .info {
		display:block;
		font-weight:400;
		font-size:16px;
		line-height:28px;
		margin-left:150px
	}
	.team-page .social a {
		padding:15px
	}
	.contact-txt {
		max-width:520px
	}
	.contact-txt .call-widget a {
		color:#696969;
		font-size:16px
	}
	.contact-txt .call-widget a span {
		color:#000
	}
	.contact-txt .call-widget i {
		font-size:25px
	}
	@media(max-width:991.98px) {
	.contact-txt {
		max-width:100%;
		margin:0 0 60px
	}
	}
	.google-map {
		height:500px;
		width:100%
	}
	.contact-form input, .contact-form textarea {
		-webkit-border-radius:32px;
		-moz-border-radius:32px;
		-ms-border-radius:32px;
		border-radius:32px
	}
	.contact-form input {
		padding-left:45px
	}
	.contact-form .form-group {
		margin-bottom:20px;
		font-size:17px;
		position:relative
	}
	.contact-form .form-group i {
		position:absolute;
		top:21px;
		left:30px
	}
	.contact-form .nice-select {
		background:#fff;
		height:60px;
		line-height:60px;
		width:100%;
		border:1px solid #e8e8e8;
		border-radius:32px
	}
	.contact-form .nice-select:after {
		right:20px
	}
	.contact-card {
		background-color:#f6f6f6;
		padding:40px;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.contact-card a, .contact-card p {
		font-size:16px;
		color:#000
	}
	.contact-card .icon, .contact-card .icon-sm {
		background-color:#e6c43a;
		width:106px;
		height:106px;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.contact-card .icon-sm i, .contact-card .icon i {
		font-size:30px;
		color:#000
	}
	.contact-card .h3, .contact-card h3 {
		margin:20px 0;
		text-transform:uppercase
	}
	.contact-card:hover {
		background-color:#e6c43a
	}
	.contact-card:hover .icon, .contact-card:hover .icon-sm {
		background-color:#fff
	}
	.contact-card:hover a {
		color:#000
	}
	@media(max-width:991.98px) {
	.contact-card {
		padding:30px 10px
	}
	}
	.contact2-sec .form-group .nice-select, .contact2-sec .form-group input, .contact2-sec .form-group textarea {
		background-color:#f6f6f6
	}
	.account-form {
		padding:80px 60px 30px
	}
	.account-form .btn {
		width:300px
	}
	.account-form .fb-btn {
		background-color:#2c49ab
	}
	.account-form .gl-btn {
		background-color:#e0594b
	}
	@media(max-width:767.98px) {
	.account-form {
		padding:40px 20px
	}
	.account-form .btn {
		width:255px;
		font-size:18px
	}
	.account-form .btn.fb-btn {
		margin-bottom:10px
	}
	}
	.subscribe-sec {
		background-position:50%;
		background-repeat:no-repeat;
		background-size:cover
	}
	.subs-card .line:before, .subs-card .sec-title.line-left:before {
		background-color:#fff
	}
	.subs-card form {
		position:relative;
		margin-top:50px
	}
	.subs-card form .form-group {
		width:68%;
		float:left
	}
	.subs-card form .form-group input {
		-webkit-border-radius:30px;
		-moz-border-radius:30px;
		-ms-border-radius:30px;
		border-radius:30px;
		background-color:hsla(0,0%,100%,.15);
		border:0;
		color:#fff;
		padding-left:45px
	}
	.subs-card form .form-group input::placeholder {
	color:#fff
	}
	.subs-card form .form-group i {
		position:absolute;
		left:20px;
		top:20px;
		color:#fff
	}
	.subs-card form button {
		position:absolute;
		right:0;
		top:0;
		padding:15px 30px
	}
	.counter-card .display-1 {
		font-size:100px;
		font-family:Teko, sans-serif;
		text-shadow:2px 2px 0 #136495, -2px 2px 0 #136495, -2px -2px 0 #136495, 2px -2px 0 #136495;
		color:#fff;
		letter-spacing:2px
	}
	.counter-card p {
		max-width:175px;
		font-size:18px
	}
	.counter-sec2 {
		padding-bottom:100px
	}
	.counter-sec2 .container {
		border-top:1px solid #e8e8e8
	}
	.counter-sec2 .counter-card {
		padding-left:60px;
		margin-top:60px;
		border-left:1px solid #e8e8e8
	}
	.counter-sec2 .counter-card .display-1 {
		color:#e6c43a;
		text-shadow:2px 2px 0 #b9d700, -2px 2px 0 #b9d700, -2px -2px 0 #b9d700, 2px -2px 0 #b9d700
	}
	@media(min-width:768px) and (max-width:991.98px) {
	.counter-wrap {
		margin-bottom:60px
	}
	}
	.services-alt-sec {
		padding:80px 0
	}
	.serv-item {
		background-color:hsla(0,0%,100%,.1);
		padding:30px 40px 30px 30px
	}
	.contact-txt .call-widget a .serv-item span, .faq-item .serv-item button, .product-details-tab .nav-tabs li .serv-item .nav-link, .serv-item .contact-txt .call-widget a span, .serv-item .faq-item button, .serv-item .h4, .serv-item .lead, .serv-item .product-details-tab .nav-tabs li .nav-link, .serv-item h4 {
		color:#fff
	}
	.serv-item p {
		margin-bottom:0
	}
	.faq-item {
		margin-bottom:20px;
		overflow:hidden;
		padding:20px;
		border:1px solid #e8e8e8;
		-webkit-border-radius:10px;
		-moz-border-radius:10px;
		-ms-border-radius:10px;
		border-radius:10px
	}
	.faq-item button {
		text-transform:uppercase;
		margin:0;
		padding:0 15px 0 65px
	}
	.faq-item button:focus {
		border:none;
		box-shadow:none
	}
	.faq-item .accordion-body {
		padding:15px 0 0;
		margin-top:25px
	}
	.faq-item .accordion-body:before {
		background-color:#e8e8e8;
		height:1px;
		width:0;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.accordion-collapse.show .accordion-body:before {
		width:100%
	}
	.accordion-item:not(:first-of-type) {
		border-top:1px solid #e8e8e8
	}
	.accordion-button:focus {
		border:0;
		box-shadow:none
	}
	.accordion-item:first-of-type {
		border-top-left-radius:12px;
		border-top-right-radius:12px
	}
	.accordion-item:last-of-type {
		border-bottom-right-radius:12px;
		border-bottom-left-radius:12px
	}
	.accordion-button:after {
		position:absolute;
		left:0;
		width:50px;
		height:50px;
		-webkit-border-radius:50%;
		-moz-border-radius:50%;
		-ms-border-radius:50%;
		border-radius:50%;
		background-position:50%;
		background-repeat:no-repeat;
		-webkit-box-shadow:0 0 20px rgba(0,0,0,.05);
		-moz-box-shadow:0 0 20px rgba(0,0,0,.05);
		box-shadow:0 0 20px rgba(0,0,0,.05);
		background-color:#fff
	}
	.accordion-button:not(.collapsed) {
		background-color:#fff;
		box-shadow:none;
		color:#030523
	}
	.about-txt {
		max-width:550px
	}
	.about-txt p {
		margin-top:15px
	}
	.about-txt ul {
		margin:0;
		padding:0;
		list-style:none
	}
	.about-txt ul li {
		padding-left:30px;
		padding-bottom:10px
	}
	.about-txt ul li:before {
		background:url(../images/icons/checkbox.png) no-repeat 0 0;
		top:4px
	}
	.about-sec {
		padding-bottom:120px
	}
	.about-sec .counter-wrap {
		position:absolute;
		width:calc(100% - 80px);
		left:50%;
		transform:translateX(-50%);
		bottom:30px;
		text-align:left
	}
	.about-sec .counter-wrap .counter-card {
		flex:0 0 48%
	}
	.about-sec .counter-wrap .counter-card .text-gr {
		font-family:Teko, sans-serif;
		color:#030523;
		font-weight:700;
		line-height:1;
		margin-bottom:0;
		font-size:100px
	}
	@media(min-width:992px) and (max-width:1199.98px) {
	.about-sec .counter-wrap .counter-card .text-gr {
		font-size:75px
	}
	}
	@media(max-width:767.98px) {
	.about-sec .counter-wrap .counter-card .text-gr {
		font-size:75px
	}
	}
	@media(max-width:575.98px) {
	.about-sec .counter-wrap {
		position:relative;
		width:100%;
		bottom:-30px
	}
	}
	.cta-sec2:before {
		background:url(../images/cta-abs-left.png) no-repeat 0/contain;
		background-color:#000;
		width:60%;
		z-index:-1
	}
	.cta-sec2 .cta-txt {
		max-width:550px
	}
	.cta-sec2:after {
		background:url(../images/cta-abs-right.png) no-repeat 0/contain;
		position:absolute;
		right:0;
		top:0;
		width:40%;
		height:100%;
		content:"";
		z-index:-1
	}
	.cta-sec2 .cta-actions .icon-sm {
		font-size:20px
	}
	.contact-txt .call-widget a .cta-sec2 .cta-actions span a, .cta-sec2 .cta-actions .contact-txt .call-widget a span a, .cta-sec2 .cta-actions .faq-item button a, .cta-sec2 .cta-actions .h4 a, .cta-sec2 .cta-actions .lead a, .cta-sec2 .cta-actions .product-details-tab .nav-tabs li .nav-link a, .cta-sec2 .cta-actions h4 a, .faq-item .cta-sec2 .cta-actions button a, .product-details-tab .nav-tabs li .cta-sec2 .cta-actions .nav-link a {
		color:#fff
	}
	.about-tab .nav-tabs {
		border:0;
		display:flex;
		width:100%;
		justify-content:center;
		align-items:center
	}
	.about-tab .nav-tabs li {
		font-size:40px;
		text-align:center;
		width:33.33%
	}
	@media(min-width:576px) and (max-width:767.98px) {
	.about-tab .nav-tabs li {
		font-size:24px
	}
	}
	@media(max-width:575.98px) {
	.about-tab .nav-tabs li {
		width:100%
	}
	}
	.about-tab .nav-tabs li .nav-link {
		text-transform:uppercase;
		border:0;
		width:100%;
		font-family:Teko, sans-serif;
		color:#030523;
		margin-bottom:10px;
		font-weight:700
	}
	.about-tab .nav-tabs li .nav-link:before {
		height:2px;
		top:auto;
		bottom:0;
		background-color:#e8e8e8
	}
	.about-tab .nav-tabs li .nav-link.active, .about-tab .nav-tabs li .nav-link:focus {
		border:0;
		box-shadow:none;
		background-color:transparent
	}
	.about-tab .nav-tabs li .nav-link.active:before, .about-tab .nav-tabs li .nav-link:focus:before {
		background-color:#e6c43a
	}
	.about-tab .nav-tabs li .nav-link:hover {
		border:0
	}
	.img-gallery {
		padding-right:10px
	}
	.img-gallery img {
		width:48%
	}
	@media(min-width:576px) and (max-width:767.98px) {
	.img-gallery {
		margin-bottom:30px
	}
	.img-gallery img {
		width:47%
	}
	}
	@media(max-width:575.98px) {
	.img-gallery {
		padding:0 0 20px
	}
	.img-gallery img {
		margin-bottom:20px
	}
	}
	.tab-txt .h3, .tab-txt h3 {
		text-transform:uppercase
	}
	.tab-txt a.btn {
		color:#e6c43a
	}
	.tab-txt ul {
		padding:0;
		list-style:none;
		margin:30px 0 0
	}
	.tab-txt ul li {
		padding:0 30px;
		margin:15px 0
	}
	.tab-txt ul li:before {
		content:"\f12c";
		font-family:feather-icons;
		background-color:transparent;
		top:50%;
		transform:translateY(-50%)
	}
	.pricing-card {
		background-color:#000;
		padding:40px;
		position:relative
	}
	.pricing-card, .pricing-card .pricing-header {
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.pricing-card .pricing-header {
		border-bottom:1px solid hsla(0,0%,100%,.2);
		margin-bottom:40px;
		padding-bottom:40px
	}
	.pricing-card .pricing-header .icon, .pricing-card .pricing-header .icon-sm {
		width:104px;
		height:104px;
		line-height:104px;
		background-color:#e6c43a
	}
	.pricing-card .price-rate, .pricing-card .pricing-header .icon, .pricing-card .pricing-header .icon-sm {
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.pricing-card .price-rate {
		font-family:Teko, sans-serif;
		color:#030523;
		margin-bottom:10px;
		font-weight:700;
		color:#fff;
		font-size:40px
	}
	.pricing-card .price-rate sub {
		font-weight:400;
		font-size:20px
	}
	.pricing-card .btn {
		background-color:#e6c43a;
		color:#000;
		-webkit-border-radius:5px;
		-moz-border-radius:5px;
		-ms-border-radius:5px;
		border-radius:5px;
		display:block;
		position:absolute;
		bottom:-1px;
		width:calc(100% - 80px);
		left:50%;
		transform:translateX(-50%)
	}
	.pricing-card .pricing-body {
		padding-bottom:60px
	}
	.pricing-card .pricing-body ul {
		margin:0;
		padding:0;
		list-style:none
	}
	.pricing-card .pricing-body ul li {
		margin-bottom:25px;
		padding-left:30px;
		font-size:16px;
		-webkit-transition:all .4s ease-in-out;
		-moz-transition:all .4s ease-in-out;
		-ms-transition:all .4s ease-in-out;
		-o-transition:all .4s ease-in-out;
		transition:all .4s ease-in-out
	}
	.pricing-card .pricing-body ul li:before {
		background:url(../images/icons/checkbox-p.png) no-repeat 0
	}
	.pricing-card.active, .pricing-card:hover {
		background-color:#e6c43a
	}
	.pricing-card.active .pricing-header, .pricing-card:hover .pricing-header {
		border-bottom:1px solid rgba(0,0,0,.2)
	}
	.pricing-card.active .icon, .pricing-card.active .icon-sm, .pricing-card:hover .icon, .pricing-card:hover .icon-sm {
		background-color:#fff
	}
	.pricing-card.active .h2, .pricing-card.active .price-rate, .pricing-card.active .pricing-body li, .pricing-card.active h2, .pricing-card:hover .h2, .pricing-card:hover .price-rate, .pricing-card:hover .pricing-body li, .pricing-card:hover h2 {
		color:#000
	}
	.pricing-card.active .pricing-body li:before, .pricing-card:hover .pricing-body li:before {
		background:url(../images/icons/checkbox-b.png) no-repeat 0
	}
	.pricing-card.active .btn, .pricing-card:hover .btn {
		background-color:#000;
		color:#fff
	}
	@media(max-width:1199.98px) {
	.pricing-card {
		padding:20px
	}
	.pricing-card .icon, .pricing-card .icon-sm {
		flex:0 0 80px;
		height:80px
	}
	.pricing-card .price-rate {
		line-height:1
	}
	.pricing-card .btn {
		width:calc(100% - 50px)
	}
	}
	.event-details .h2, .event-details h2 {
		font-size:40px;
		text-transform:uppercase
	}
	.event-details p {
		margin-bottom:30px
	}
	.event-details ul {
		margin:0;
		padding:0;
		list-style:none
	}
	.event-item .ms-3 {
		border-bottom:1px solid #e8e8e8;
		margin-bottom:20px
	}
	.event-item:last-child .ms-3 {
		border:0;
		margin-bottom:0
	}
	.event-item:last-child .ms-3 p {
		margin:0
	}
	.poins-table {
		border-radius:5px;
		overflow-x:auto
	}
	.poins-table table {
		border-collapse:collapse;
		width:100%
	}
	.poins-table table tr {
		border-bottom:1px solid #e8e8e8
	}
	.poins-table table th:first-child {
		text-align:center
	}
	.poins-table table th:nth-child(2) {
		padding-left:30px
	}
	.poins-table table td {
		font-weight:500
	}
	.poins-table table td:first-child {
		border-right:1px solid #e8e8e8;
		padding:15px 20px;
		text-align:center
	}
	.poins-table table td:nth-child(2) {
		padding-left:30px
	}
	.poins-table table td img {
		margin-right:5px
	}
	.poins-table td, .poins-table th {
		text-align:left;
		padding:20px 15px;
		background-color:#f6f6f6
	}
	.woocommerce {
		background-color:#f6f6f6;
		padding:30px
	}
	@media(max-width:575.98px) {
	.woocommerce {
		padding:10px
	}
	.woocommerce .woocommerce-cart-form {
		overflow-x:auto;
		margin-bottom:30px;
		overflow-y:hidden
	}
	}
	.woocommerce .shop_table {
		text-align:left;
		width:100%;
		border-collapse:separate;
		-webkit-border-radius:10px;
		-moz-border-radius:10px;
		-ms-border-radius:10px;
		border-radius:10px;
		border-spacing:0 20px
	}
	.woocommerce .shop_table thead tr th {
		padding:15px 20px;
		background-color:#fff;
		font-size:22px;
		font-weight:600;
		color:#030523
	}
	@media(max-width:575.98px) {
	.woocommerce .shop_table thead tr th {
		font-size:18px;
		padding:20px
	}
	}
	.woocommerce .shop_table thead tr th:first-child, .woocommerce .shop_table thead tr th:last-child {
		-webkit-border-radius:10px;
		-moz-border-radius:10px;
		-ms-border-radius:10px;
		border-radius:10px
	}
	.woocommerce .shop_table tr {
		background-color:#fff;
		color:#696969
	}
	.woocommerce .shop_table tr td:first-child {
		border-left-style:solid;
		border-top-left-radius:10px;
		border-bottom-left-radius:10px
	}
	.woocommerce .shop_table tr td:last-child {
		border-right-style:solid;
		border-bottom-right-radius:10px;
		border-top-right-radius:10px
	}
	.woocommerce .shop_table tbody tr.woocommerce-cart-form__cart-item.cart_item td {
		font-family:DM Sans, sans-serif;
		font-weight:600;
		align-items:center;
		vertical-align:middle
	}
	@media(max-width:575.98px) {
	.woocommerce .shop_table tbody tr.woocommerce-cart-form__cart-item.cart_item td {
		font-size:14px
	}
	}
	.woocommerce .shop_table tbody tr.woocommerce-cart-form__cart-item.cart_item td.product-thumbnail {
		width:15%;
		padding:15px 0 15px 15px
	}
	@media(max-width:575.98px) {
	.woocommerce .shop_table tbody tr.woocommerce-cart-form__cart-item.cart_item td.product-thumbnail {
		padding:5px 0 5px 5px
	}
	.woocommerce .shop_table tbody tr.woocommerce-cart-form__cart-item.cart_item td.product-thumbnail img {
		max-width:100%
	}
	}
	.woocommerce th {
		font-weight:700;
		padding:9px 12px;
		line-height:1.5em
	}
	.woocommerce td.product-remove a.remove {
		height:25px;
		width:25px;
		line-height:25px;
		background:#e6c43a;
		display:inline-block;
		text-align:center;
		-webkit-border-radius:50px;
		-moz-border-radius:50px;
		-ms-border-radius:50px;
		border-radius:50px
	}
	.woocommerce td.product-remove a.remove i {
		color:#030523;
		font-weight:600;
		font-size:15px
	}
	@media(max-width:575.98px) {
	.woocommerce .table.shop_table.cart>tbody>tr>td.product-price, .woocommerce .table.shop_table.cart>tbody>tr>th.product-price, .woocommerce .table.shop_table.cart>tfoot>tr>td.product-price, .woocommerce .table.shop_table.cart>tfoot>tr>th.product-price, .woocommerce .table.shop_table.cart>thead>tr>td.product-price, .woocommerce .table.shop_table.cart>thead>tr>th.product-price, .woocommerce table.shop_table.cart>tbody>tr>td.product-price, .woocommerce table.shop_table.cart>tbody>tr>th.product-price, .woocommerce table.shop_table.cart>tfoot>tr>td.product-price, .woocommerce table.shop_table.cart>tfoot>tr>th.product-price, .woocommerce table.shop_table.cart>thead>tr>td.product-price, .woocommerce table.shop_table.cart>thead>tr>th.product-price {
		display:none
	}
	}
	.woocommerce .coupon {
		position:relative;
		flex:0 0 50%
	}
	.woocommerce .coupon input {
		border:1px solid #e8e8e8;
		padding:14px;
		font-size:15px
	}
	.woocommerce .coupon input:focus {
		outline:0;
		box-shadow:none
	}
	.woocommerce .coupon .btn {
		position:absolute;
		right:-2px;
		border-radius:5px;
		top:-1px;
		font-size:15px;
		height:101%;
		line-height:1
	}
	@media(max-width:575.98px) {
	.woocommerce .coupon {
		flex:0 0 100%
	}
	}
	.woocommerce .shop_table tr:last-child {
		border-top-left-radius:0;
		border-bottom-left-radius:0
	}
	.cart-quanty {
		display:flex;
		justify-content:space-between;
		width:100px;
		padding:4px 13px;
		background-color:#f6f6f6;
		-webkit-border-radius:5px;
		-moz-border-radius:5px;
		-ms-border-radius:5px;
		border-radius:5px;
		font-size:15px
	}
	.cart-quanty input {
		padding:0;
		background:0 0;
		text-align:center;
		font-weight:600
	}
	.cart-quanty i {
		cursor:pointer
	}
	@media(max-width:575.98px) {
	.cart-totals {
		margin-top:30px
	}
	}
	@media(min-width:576px) and (max-width:767.98px) {
	.cart-totals {
		margin-top:30px
	}
	}
	.cart-totals table {
		width:100%;
		border-spacing:0 10px;
		border-collapse:separate
	}
	.cart-totals table th {
		color:#030523
	}
	.cart-totals table td {
		font-weight:500
	}
	.cart-totals table tbody tr td {
		padding:15px 10px;
		text-align:right
	}
	.cart-totals table tr:first-child {
		font-weight:700
	}
	.cart-totals table tr.product-list {
		background:#fff
	}
	.cart-totals table tr.product-list td, .cart-totals table tr.product-list th {
		padding:10px 15px;
		color:#696969
	}
	.cart-totals table tr.product-list th {
		border-top-left-radius:10px;
		border-bottom-left-radius:10px
	}
	.cart-totals table tr.product-list td {
		border-bottom-right-radius:10px;
		border-top-right-radius:10px;
		font-size:20px
	}
	@media(max-width:575.98px) {
	.order-summery {
		margin-top:30px
	}
	}
	@media(min-width:576px) and (max-width:767.98px) {
	.order-summery {
		margin-top:30px
	}
	}
	.coupon-wrap input {
		flex:0 0 65%
	}
	@media(max-width:991.98px) {
	.coupon-wrap {
		margin-bottom:20px
	}
	.coupon-wrap input {
		flex:0 0 55%
	}
	}
	.checkout-coupon .h6, .checkout-coupon .payment-option .accordion-button, .checkout-coupon h6, .payment-option .checkout-coupon .accordion-button {
		margin-bottom:0;
		font-family:DM Sans, sans-serif
	}
	.checkout-coupon .h6 button, .checkout-coupon .payment-option .accordion-button button, .checkout-coupon h6 button, .payment-option .checkout-coupon .accordion-button button {
		border:0;
		color:#136495;
		background:0 0
	}
	.checkout-coupon form {
		max-width:600px
	}
	.checkout-coupon form .btn {
		border-radius:5px
	}
	form.form.checkout-form label {
		margin-bottom:5px;
		font-weight:500;
		color:#030523;
		font-size:17px
	}
	form.form.checkout-form select {
		padding:17px 10px;
		display:block!important;
		width:100%;
		border:1px solid #e8e8e8;
		color:#696969;
		background-color:#fff
	}
	form.form.checkout-form input, form.form.checkout-form select {
		font-size:16px;
		margin-bottom:30px
	}
	form.form.checkout-form input[type=checkbox] {
		width:auto
	}
	.payment-option {
		margin-bottom:30px
	}
	.payment-option .accordion-button {
		font-family:DM Sans, sans-serif
	}
	.payment-option .accordion-button:after {
		right:0;
		background:0 0;
		border-radius:0;
		background-repeat:no-repeat;
		background-position:50%;
		background-image:url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxNicgaGVpZ2h0PScxNicgZmlsbD0nY3VycmVudENvbG9yJyBjbGFzcz0nYmkgYmktY2hldnJvbi1kb3duJz48cGF0aCBmaWxsLXJ1bGU9J2V2ZW5vZGQnIGQ9J00xLjY0NiA0LjY0NmEuNS41IDAgMDEuNzA4IDBMOCAxMC4yOTNsNS42NDYtNS42NDdhLjUuNSAwIDAxLjcwOC43MDhsLTYgNmEuNS41IDAgMDEtLjcwOCAwbC02LTZhLjUuNSAwIDAxMC0uNzA4eicvPjwvc3ZnPg==);
		box-shadow:none
	}
	.payment-option .accordion-button:not(.collapsed):after {
		background-image:url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxNicgaGVpZ2h0PScxNicgZmlsbD0nY3VycmVudENvbG9yJyBjbGFzcz0nYmkgYmktY2hldnJvbi1kb3duJz48cGF0aCBmaWxsLXJ1bGU9J2V2ZW5vZGQnIGQ9J00xLjY0NiA0LjY0NmEuNS41IDAgMDEuNzA4IDBMOCAxMC4yOTNsNS42NDYtNS42NDdhLjUuNSAwIDAxLjcwOC43MDhsLTYgNmEuNS41IDAgMDEtLjcwOCAwbC02LTZhLjUuNSAwIDAxMC0uNzA4eicvPjwvc3ZnPg==);
		background-color:transparent;
		transform:rotate(0deg)
	}
	.error-sec {
		min-height:100vh;
		overflow:hidden
	}
	.error-sec:before {
		background:url(../images/error-footer.png) no-repeat 0 100%;
		left:-7%;
		top:7%
	}
	.error-sec:after {
		position:absolute;
		right:-6%;
		top:-15%;
		content:"";
		background-color:#e6c43a;
		-webkit-border-radius:50%;
		-moz-border-radius:50%;
		-ms-border-radius:50%;
		border-radius:50%;
		width:252px;
		height:252px
	}
	.error-sec .error-txt {
		padding:200px 0
	}
	.error-sec .error-title.h1, .error-sec .slide-bar .box-item span.error-title, .error-sec h1.error-title, .slide-bar .box-item .error-sec span.error-title {
		font-size:425px;
		line-height:.5
	}
	.error-sec .error-title.h1 img, .error-sec .slide-bar .box-item span.error-title img, .error-sec h1.error-title img, .slide-bar .box-item .error-sec span.error-title img {
		position:relative;
		top:-50px;
		margin:0 20px
	}
	@media(max-width:1199.98px) {
	.error-sec:before {
		left:-25%
	}
	.error-sec .error-title.h1, .error-sec .slide-bar .box-item span.error-title, .error-sec h1.error-title, .slide-bar .box-item .error-sec span.error-title {
		font-size:300px
	}
	.error-sec .error-title.h1 img, .error-sec .slide-bar .box-item span.error-title img, .error-sec h1.error-title img, .slide-bar .box-item .error-sec span.error-title img {
		width:200px
	}
	}
	@media(max-width:991.98px) {
	.error-sec:after {
		right:-12%
	}
	}
	@media(max-width:575.98px) {
	.error-sec:before {
		left:-55%;
		top:20%
	}
	.error-sec .error-title.h1, .error-sec .slide-bar .box-item span.error-title, .error-sec h1.error-title, .slide-bar .box-item .error-sec span.error-title {
		font-size:200px
	}
	.error-sec .error-title.h1 img, .error-sec .slide-bar .box-item span.error-title img, .error-sec h1.error-title img, .slide-bar .box-item .error-sec span.error-title img {
		width:140px;
		top:-20px
	}
	}
	.Developed a{ Color:#136495;}