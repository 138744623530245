/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.slider-content {
    position: relative;
    z-index: 2;
    margin-bottom: 130px;
}
.video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    z-index: 1;
}
.slider-bg .banner-img {
    overflow: hidden;
    height: 738px;
    margin-top: 115px;
    margin-right: -62px;
}

.slider-content.s-slider-content.text2 {
    margin-top: 0;
}

.slider-content.s-slider-content.text3 {
    margin-top: 168px;
}

.slider-price {
    position: absolute;
    right: 0;
    bottom: 0;
}

.slider-price h3 {
    color: #fff;
}

.slider-price h2 {
    color: #e9b503;
    font-size: 60px;
    font-weight: 500;
}

.slider-btn {
    display: inline-block;
    position: relative;
}

.slider-active .slick-dots {
    position: absolute;
    top: 40%;
    left: 22%;
}

.slider-active .slick-dots li button {
    text-indent: -99999px;
    border: none;
    padding: 0;
    height: 1px;
    margin-left: 10px;
    background: #e9b503;
    border-radius: 50px;
    z-index: 1;
    cursor: pointer;
    transition: .3s;

}

.slider-active .slick-dots li.slick-active button {
    width: 50px;
    background: #e9b503;
}

.slider-active .slick-dots li.slick-active button::before {

    opacity: 1;
}

.slider-active .slick-dots li button::before {
    content: "";
    width: 19px;
    height: 19px;
    float: left;
    position: relative;
    margin-top: -9px;
    left: -19px;
    border-radius: 50%;
    opacity: .7;
    background-repeat: no-repeat;
    background-position: center;
}

.overlay-bg-01 {
    position: absolute;
    left: 0;
    top: 0;
}

.overlay-bg-02 {
    position: absolute;
    left: 0;
    bottom: 0;
}

.overlay-bg-03 {
    position: absolute;
    right: 0;
    top: 30px;
}

.overlay-bg-04 {
    position: absolute;
    right: 0;
    bottom: 100px;
}

.slider-bg {
    min-height: 400px !important;
    background-position: center bottom;
    background-size: cover;
    position: relative;
    z-index: 1;
}

.slider-bg .image-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover !important;
    transform: scale(1);
    transition: transform 7000ms ease;
    z-index: 1;
}

.slider-bg.slick-active .image-layer {
    transform: scale(1.15);
}

.slider-bg .video-i.popup-video {
    text-align: center;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
}

.slider-bg .video-i.popup-video img {
    display: inline-block;
}

.show-bg3::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: .7;
    z-index: 1;
}

.slider-bg2 {
    min-height: 900px;
    background-position: center;
    background-size: cover;
    position: relative;
    margin-top: -118px;
    z-index: 1;
}

.slider-bg2 .slider-content>span::before {
    content: "";
    background: #383838;
    height: 3px;
    float: left;
    width: 60px;
    margin-top: 15px;
    margin-right: 10px;
}

.slider-bg2 .slider-content h2 {
    color: #fff;
}

.slider-bg2 .slider-content p {
    color: #676f67;
    font-size: 16px;
    margin-bottom: 0;
}

.slider-bg2 .slider-content>span {
    font-size: 22px;
    font-weight: 500;
    color: #e9b503;
    display: block;
    margin-bottom: 20px;
    margin-top: 30px;
}

.slider-bg .video-i i {
    background: #fff;
    padding: 14px;
    border-radius: 100%;
    width: 60px;
    display: inline-block;
    text-align: center;
    color: #e9b503;
    height: 60px;
    line-height: 30px;
    box-shadow: 1px 1.732px 60px 0px rgba(250, 63, 108, 0.1);

}

.slider-bg2 .video-i {
    background: #383838;
    padding: 14px;
    border-radius: 100%;
    width: 51px;
    display: inline-block;
    text-align: center;
    color: #fff;
    margin-left: 20px;
}

.slider-bg2 .video-i:hover {
    background: #e9b503;
    padding: 14px;
    border-radius: 100%;
    width: 51px;
    display: inline-block;
    text-align: center;
    color: #fff;
    margin-left: 20px;
}

.slider-three .slider-bg {
    min-height: 959px !important;
}

.slider-three .slider-content h5::after {
    display: none;
}

#particles-js {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.slider-content>span {
    font-size: 16px;
    font-weight: 500;
    color: #e9b503;
    text-transform: uppercase;
    letter-spacing: 6px;
    display: block;
    margin-bottom: 20px;
}

.slider-content h2 {
    font-size: 64px;
    line-height: 1.2;
    margin-bottom: 40px;
    color: #fff;
}

.slider-content h2 span {
    color: #fff;
    position: relative;
}

.slider-content h2 span::after {
    bottom: 13px;
    height: 2px;
    width: 100%;
    content: "";
    position: absolute;
    display: inline-block;
    background: #e9b503;
    left: 0;
}

.slider-content h5 {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 18px;
    letter-spacing: 3px;
    margin-bottom: 15px;
}

.slider-content h5::after {
    content: "";
    height: 2px;
    width: 55px;
    position: relative;
    display: inline-block;
    background: #e9b503;
    top: -5px;
    left: 15px;
}

.slider-content p span {
    display: inline-block;
    height: 2px;
    width: 40px;
    background: #e9b503;
    margin-right: 20px;
    position: relative;
    top: -4px;
}

.slider-content p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 0;
}

.p-relative {
    position: relative;
}

.down-arrow {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    color: #fff;
    z-index: 1;
    height: 60px;
    width: 30px;
    margin: auto;
    text-align: center;
    line-height: 60px;
    border: 2px solid #fff;
    border-radius: 50px;
    font-size: 18px;
}

.down-arrow:hover {
    color: #fff;
}

.slider-active .slick-arrow {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    left: 5%;
    color: #00163b;
    font-size: 26px;
    line-height: 60px;
    border: none;
    text-align: center;
    z-index: 9;
    cursor: pointer;
    padding: 0;
    background: none;
    transition: .5s;
    width: 60px;
    height: 60px;
    background: #fff;
    box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}


.slider-active .slick-next {
    right: 5%;
    left: inherit;
}

.slider-active .slick-arrow,
.slider-active .slick-next {
    transition: all 0.3s ease 0s;
    opacity: 0;
}

.slider-active:hover .slick-prev {
    left: 2%;
}

.slider-active:hover .slick-next {
    right: 2%;
}

.slider-active:hover .slick-arrow,
.slider-active:hover .slick-next {
    opacity: 1;
}

.slider-active .slick-arrow:hover {
    color: #fff;
    background: #e9b503;
}

.second-slider-content h2 {
    font-size: 90px;
    margin-bottom: 20px;
}

.second-slider-bg::before {
    opacity: .5;
}

.second-slider-content {
    padding-top: 85px;
    padding-bottom: 5px;
}

.s-slider-content h2 {
    margin-bottom: 25px;
}

.s-slider-content p {
    font-size: 18px;
    color: #fff;
    line-height: 26px;
    margin: auto;
    padding-right: 60px;
}

.slider-four .s-slider-content p {
    font-size: 18px;
    color: #fff;
    line-height: 26px;
    padding-bottom: 30px;
    width: 90%;
    margin: auto;
}

.btn-icon {
    position: absolute;
    left: 10px;
    top: 10px;
    bottom: 10px;
    background: #ffb3a5;
    padding: 10px 15px;
    color: #fff;
}

.btn.ss-btn.active {
    background: none;
    color: #fff;
    border: 1px solid #fff;
    box-shadow: none;
}

.slick-slider .line {
    float: left;
    margin-right: 15px;
}

.slider-shape {
    position: absolute !important;
    z-index: 9;
}

.ss-one {
    top: 220px !important;
    left: 200px !important;
}

.ss-two {
    top: 290px !important;
    left: 49% !important;
}

.ss-three {
    top: 77% !important;
    left: 7% !important;
}

.ss-four {
    top: 56% !important;
    left: 37% !important;
}

.ss-five {
    left: 61% !important;
    top: 83% !important;
}

.ss-six {
    left: 88% !important;
    top: 80% !important;
}

.ss-seven {
    top: 20% !important;
    left: 89% !important;
}

.slider-bg .text2 h2 {
    font-size: 60px;
}

.slider-bg .text2 a {
    color: #FFF;
}

.slider-bg .text2 .btn.ss-btn.active {
    background: #e9b503;
    border: 2px solid #e9b503;
    color: #FFF;
}

.slider-bg .text2 p {
    padding-right: 22%;
    color: #fff;
}

.slider-text-2 h2 {
    color: #fff;
}

.slider-content2 h2 {
    color: #006293;
}

.slider-content2 p {
    color: #777777;
}

.slider-content2 h5 {
    color: #777777;
}

@media(min-width:1200px) and (max-width:1500px) {
    .slider-bg{
        height: 100vh;
    }
    .slider-active .slick-arrow {
        left: 20px
    }

    .slider-active .slick-next {
        right: 20px;
        left: auto
    }

    .slider-bg.slider-two {
        min-height: 600px !important;
        background-repeat: no-repeat;
        background-position: 0 -100px
    }

    .slider-active .slick-arrow {
        left: 20px
    }

    .slider-active .slick-next {
        right: 20px;
        left: auto
    }

    .slider-content h2 {
        font-size: 60px
    }

    .slider-bg .banner-img {
        height: 645px
    }
}

@media(min-width:1500px) and (max-width:1875px) {
    .slider-bg{
        height: 100vh;
    }
    .slider-active .slick-arrow {
        left: 2%
    }

    .slider-active .slick-next {
        right: 1%;
        left: inherit
    }

    .slider-active .slick-arrow {
        left: 2%
    }

    .slider-active .slick-next {
        right: 1%;
        left: inherit
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .slider-active .slick-arrow {
        display: none !important
    }

    .slick-dots {
        display: none !important
    }

    .slider-active .slick-arrow {
        display: none !important
    }

    .slider-bg .banner-img {
        height: 550px
    }

    .slider-content h2 {
        font-size: 55px
    }

    .services-active2 .slider-nav .slick-list.draggable {
        width: 920px;
        float: right
    }
}

@media(min-width:768px) and (max-width:991px) {
    .slider-active .slick-track::before {
        background: #fff5f4
    }

    .slick-slide img {
        display: inline-block
    }

    .slider-content h2 {
        font-size: 48px
    }

    .slider-content h5 {
        margin-top: 60px
    }

    .slider-bg .banner-img {
        height: 400px;
        margin-top: 0
    }

    .slider-bg .banner-img {
        height: 400px
    }

    .slider-active .slick-track::before {
        background: #fff5f4
    }

    .services-active2 .slider-nav {
        top: 200px
    }

    .services-active2 .slider-nav .slick-list.draggable {
        width: 650px
    }

    .slider-bg {
        min-height: 700px
    }

    .slider-four .slider-bg h5 {
        font-size: 90px
    }

    .s-slider-content p {
        padding-right: 0
    }
}

@media(max-width:767px) {
    .slick-slide img {
        display: inline-block
    }

    .class-active .slick-arrow,
    .button-group button::before {
        display: none !important
    }

    .slider-active .slick-track::before {
        background: #fff5f4
    }

    .s-slider-content p {
        padding-right: 0
    }

    .slider-bg .banner-img {
        overflow: hidden;
        height: 405px;
        margin-top: 0;
        margin-right: 0
    }

    .slider-btn.mt-30.mb-160 {
        margin-bottom: 50px
    }

    .slider-content {
        margin-bottom: 35px;
        margin-top: 120px
    }

    .slider-content.s-slider-content.slider-text-2 {
        margin-top: 0
    }

    .slider-content h5 {
        font-size: 15px;
        margin-top: 0
    }

    .slider-four .slider-bg h5 {
        font-size: 60px
    }

    .slider-content.s-slider-content.text2 {
        margin-top: 50px
    }

    .slider-bg .text2 p {
        padding-right: 0
    }

    .slider-btn .video-i {
        display: none
    }

    .slider-three .slider-img {
        margin-left: 0;
        margin-top: 50px;
        margin-right: 0
    }

    .slider-content.s-slider-content.text3 {
        margin-top: 0
    }

    .slider-bg .text2 h2 {
        font-size: 32px
    }

    .slider-four .slider-img {
        margin-left: 0;
        margin-top: 50px;
        margin-right: 0
    }

    .slider-active .slick-track::before {
        background: #fff5f4
    }

    .services-active2 .slider-nav .slick-list.draggable {
        width: 300px;
        float: right
    }

    .services-active2 .slider-nav {
        position: absolute;
        top: 150px
    }

    .slider-price {
        display: none
    }

    .slider-bg2 .slider-content>span {
        font-size: 18px
    }

    .slider-bg2 .video-i {
        display: none
    }

    .slider-bg2 {
        min-height: 700px;
        position: relative;
        margin-top: -118px;
        z-index: 1;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat
    }

    .slider-bg .video-i.popup-video {
        display: none
    }

    .slider-content h2 {
        font-size: 36px;
        letter-spacing: 0;
        margin-bottom: 30px
    }

    .slider-four .s-slider-content p {
        width: 100%
    }

    .slider-shape {
        display: none !important
    }

    .s-slider-content p {
        padding-right: 0
    }

    .slider-btn.mt-55 {
        margin-top: 25px
    }

    .second-slider-content {
        padding-top: 0
    }
}

@media only screen and (min-width:480px) and (max-width:767px) {
    .slider-bg{
        height: 400px;
    }
    .services-active2 .slider-nav .slick-list.draggable {
        width: 400px;
        float: right
    }

    .slider-content h2 {
        font-size: 32px
    }
}

@media only screen and (min-width:600px) and (max-width:767px) {
    .services-active2 .slider-nav .slick-list.draggable {
        width: 500px;
        float: right
    }
}

.slider-active .mt-130 {
    margin-top: 130px;
}
.slider-active .btn {
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1;
    margin-bottom: 0;
    padding: 20px 30px;
    text-align: center;
    text-transform: unset;
    touch-action: manipulation;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
    white-space: nowrap;
    position: relative;
    z-index: 1;
    text-transform: capitalize;
    background: #2a4e97;
    border: 1px solid #2a4e97;
    overflow: hidden;
}
.slider-active .btn:hover{
    background-color: #000;
    color: #FFF;
}

.volume-control {
    top: 50px;
    right: 50px;
    position: absolute;
}